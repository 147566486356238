import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import filtersFilledImg from "../../../assets/images/symbols/filters_filled.svg";
import cross from "../../../assets/images/symbols/cross_grey.svg";

import cn from "classnames";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";

import {
  checkBoxFiltersData,
  optionsFiltersData,
  sortFiltersData,
} from "../../../constants/notifications";
import { transformRoleText } from "../../../utils/rolesHelper";
import useFindTranslations from "../../../hooks/useFindTranlsations";
import FilterDropDown from "../../FilterDropdown/FilterDropDown";
import DotsButton, { DOTS_VARIANTS } from "../../DotsButton/DotsButton";

const FiltersBlock = ({
  title,
  sortFilter,
  setSortFilter,
  dateFilter,
  setDateFilter,
  roleFilter,
  setRoleFilter,
  setReadFilter,
  userBranchRoles,
  setSelectFilter,
  selectFilter,
  dataLength,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const optionRef = useRef(null);
  const dropDownRef = useRef(null);

  const t = useFindTranslations();

  const closeOptions = () => {
    setShowOptions(false);
  };

  useOutsideClick(dropDownRef, closeOptions, optionRef);

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const onClickOption = (option) => {
    setReadFilter(option);
    closeOptions();
  };

  const transformedRoles = userBranchRoles
    ?.filter((role) => {
      const roleName = role?.role?.name.toLowerCase();
      return (
        roleName.includes("mentor") ||
        roleName.includes("student") ||
        roleName.includes("curator") ||
        roleName.includes("role_admin")
      );
    })
    .map((role) => ({
      name: transformRoleText(role?.role?.name, role?.branch?.company?.name),
      roleId: role?.role?.id,
    }));

  const renderSelectedFilter = (value, onClearHandler) => {
    return (
      <div className={styles.selected_filters_item}>
        <span>{value}</span>
        <img
          className={styles.cross}
          onClick={onClearHandler}
          src={cross}
          alt=""
        />
      </div>
    );
  };

  const isHasRoles = !!userBranchRoles?.length;

  return (
    <div>
      <div
        className={cn(
          styles.filtersBlock,
          "noselect",
          isHasRoles && styles.withRoles,
        )}
      >
        <FilterDropDown
          clearable={false}
          withDate
          data={sortFiltersData}
          placeholder={t?.filter}
          value={dateFilter ? dateFilter : sortFilter?.name}
          onChange={(value) => setSortFilter(value)}
          maxFrontHeight={"30px"}
          img={dateFilter || sortFilter?.name ? filtersFilledImg : filtersImg}
          onChangeDate={(value) => setDateFilter(value)}
          dateFilter={dateFilter}
        />
        {isHasRoles && (
          <FilterDropDown
            capitalize
            placeholder={t?.roles}
            data={transformedRoles}
            value={roleFilter?.name}
            onChange={(value) => setRoleFilter(value)}
            maxFrontHeight={"30px"}
          />
        )}

        <FilterDropDown
          data={checkBoxFiltersData.map((item) => ({
            ...item,
            disabled: !dataLength,
          }))}
          placeholder={""}
          withCheckBox={true}
          value={selectFilter?.name === "Select" ? selectFilter : ""}
          onChange={(value) => setSelectFilter(value)}
          maxFrontHeight={"30px"}
          noLeftRightPadding
        />

        <div className={styles.options_wrapper}>
          <DotsButton
            dotsRef={optionRef}
            isOpen={showOptions}
            onToggle={toggleOptions}
            variant={DOTS_VARIANTS.notificationVariant}
          />

          {showOptions && (
            <div ref={dropDownRef} className={styles.dropdown}>
              {optionsFiltersData?.map((option) => (
                <p
                  key={option.name}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickOption(option);
                  }}
                >
                  {t?.returnTranslation(option?.name)}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
      {(sortFilter?.name || dateFilter || roleFilter?.name) && (
        <div className={styles.selected_filters}>
          {sortFilter?.name &&
            renderSelectedFilter(sortFilter?.name, () => {
              setSortFilter("");
            })}
          {dateFilter &&
            renderSelectedFilter(dateFilter, () => {
              setDateFilter("");
            })}
          {roleFilter?.name &&
            renderSelectedFilter(roleFilter?.name, () => {
              setRoleFilter("");
            })}
        </div>
      )}
    </div>
  );
};

export default FiltersBlock;
