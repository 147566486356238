import { Droppable } from "react-beautiful-dnd";
import compareIcon from "../../../../assets/images/compare_icon.svg";
import {
   COMPARE_ANSWER_CODE,
   MULTIPLE_CHOICE_ANSWER_CODE,
   ONE_CHOICE_ANSWER_CODE,
   SEQUENCE_ANSWER_CODE,
   TRUE_FALSE_ANSWER_CODE,
} from "../../../../utils/questionsHelper";
import DraggingArrows from "../../../../components/DraggingArrows/DraggingArrows";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

import styles from "./styles.module.scss";
import cn from "classnames";

const SomeAnswerInputs = ({
   type,
   item,
   answerIdx,
   inputsData,
   setInputsData,
   onSelectAnswer,
}) => {
   /*                         Types codes and correspondence: 
          0: One choice,       
          1: Multiple choice      
          6: True/False
      */

   const t = useFindTranslationsByString();

   const onSelectAnswerRadio = (id) => {
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData.answers.map((answer) =>
            answer.id === id
               ? { ...answer, isChosen: true }
               : { ...answer, isChosen: false }
         ),
      }));
   };

   if (type === MULTIPLE_CHOICE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={cn(styles.answer, styles.no_padding)}>
            <label
               htmlFor={`${item.content} ${item?.id}`}
               className={cn(
                  styles.answer_block,
                  styles.label,
                  item?.isChosen ? styles.answer_block_isSelected : "",
                  styles.multiple_answer
               )}
            >
               <div className={styles.checkbox}>
                  <input
                     type="checkbox"
                     id={`${item.content} ${item?.id}`}
                     checked={item?.isChosen}
                     onChange={() => onSelectAnswer(item?.id)}
                  />
               </div>

               {item?.content}
            </label>
         </div>
      );
   }

   if (type === ONE_CHOICE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={cn(styles.answer, styles.no_padding)}>
            <label
               htmlFor={`${item.content} ${item?.id}`}
               className={cn(
                  styles.answer_block,
                  styles.label,
                  item?.isChosen ? styles.answer_block_isSelected : ""
               )}
            >
               <div className={cn(styles.checkbox, styles.radio)}>
                  <input
                     id={`${item.content} ${item?.id}`}
                     type="checkbox"
                     checked={item?.isChosen}
                     onChange={() => onSelectAnswerRadio(item?.id)}
                     className={styles.radioButton}
                  />
                  {item?.content}
               </div>
            </label>
         </div>
      );
   }

   if (type === TRUE_FALSE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={styles.answer}>
            <label
               htmlFor={`${item.content} ${item?.id}`}
               className={cn(
                  styles.answer_block,
                  styles.true_false,
                  item?.isChosen ? styles.answer_block_isSelected : ""
               )}
            >
               <div className={cn(styles.checkbox, styles.radio)}>
                  <input
                     type="checkbox"
                     id={`${item?.content} ${item?.id}`}
                     checked={item?.isChosen}
                     onChange={() => onSelectAnswerRadio(item?.id)}
                     className={styles.radioButton}
                  />
               </div>

               {t(item?.content)}
            </label>
         </div>
      );
   }

   // Return nothing in case of type is not here
   return <></>;
};

export default SomeAnswerInputs;
