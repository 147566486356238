import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const StepIndicator = ({ step, setStep, bottom= '-10px', classNames = ''}) => {
   return (
      <div className={cn(styles.step_indicator, setStep ? styles.active_steps : '', classNames)}
        style={bottom && {bottom}}
      >
         <span
            onClick={() => setStep(1)}
            className={step === 1 ? styles.active_step : styles.inactive_step}
         ></span>
         <span
            onClick={() => setStep(2)}
            className={step === 2 ? styles.active_step : styles.inactive_step}
         ></span>
      </div>
   );
};

export default StepIndicator;
