
export const validateChangePassword = (passwordData, setErrors) => {
   const validationErrors = {};
   if (!passwordData.old_password.length) validationErrors.old_password = "Old password is required";
   if (!passwordData.new_password.length) validationErrors.new_password = "New passoword is required";
   if (!passwordData.confirm_new_password.length) validationErrors.confirm_new_password = "Confirm new passoword is required";


   if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false; 
   } else {
      return true
   }
};

export const validateChangeStrongPassword = (passwordData, setErrors) => {
   const validationErrors = {};
   const msg = 'New password must include at least 8 characters with at least one uppercase letter, one lowercase letter, one number, and one special character.';

  if (passwordData?.new_password.length && !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}/.test(passwordData?.new_password)) {
   validationErrors.new_password = msg
   setErrors(validationErrors);
   return msg
   }
 };

const errorMessages = {
   long: 'Password must be at least 8 characters long.',
   doNotMatch: 'New passwords do not match.',
   invalidOld: 'Invalid old password.'
}

export const validateChangePasswordServer = (errorMsg, passowordData, setErrors) => {
   const validationErrors = {};
   if (errorMsg === errorMessages.long) {
      if(passowordData?.old_password?.length < 8) validationErrors.old_password =  errorMessages.long;
      if(passowordData?.new_password?.length < 8) validationErrors.new_password =  errorMessages.long;
      if(passowordData?.confirm_new_password?.length < 8) validationErrors.confirm_new_password =  errorMessages.long;
   }

   if (errorMsg === errorMessages.doNotMatch) {
      validationErrors.new_password = errorMessages.doNotMatch;
      validationErrors.confirm_new_password = errorMessages.doNotMatch;
   }

   if (errorMsg === errorMessages.invalidOld) {
      validationErrors.old_password = errorMessages.invalidOld;
   }


   if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false; 
   } else {
      return true
   }
};

export const isPasswordValid = (password) => {
   // Password must be at least 8 characters long
   if (password.length < 8) {
     return false;
   }
 
   // Check for uppercase, lowercase letters, numbers, and special characters
   const uppercaseRegex = /[A-Z]/;
   const lowercaseRegex = /[a-z]/;
   const numberRegex = /[0-9]/;
   const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
 
   // Validate password against regex patterns
   if (
     !uppercaseRegex.test(password) ||
     !lowercaseRegex.test(password) ||
     !numberRegex.test(password) ||
     !specialCharRegex.test(password)
   ) {
     return false;
   }
 
   return true;
 }