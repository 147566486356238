import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import AgencyApi from "../../../utils/api/AgencyApi";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";

const IconUploadModal = ({ icon, isFavicon, setRenderedIconFile, setIconFile, onSaveIcon, onClose, isActive, title, desc, iconEditModal }) => {
   const [drag, setDrag] = useState(false);

   const fileLoadHandler = (e) => {
      e.preventDefault();

      if(e.target.files[0]) setIconFile(e.target.files[0]);

      // for show icons straign away
      const reader = new FileReader();

      reader.onload = () => {
         setRenderedIconFile(reader.result);
      };
      
       if(e.target.files[0]) {
         reader.readAsDataURL(e.target.files[0]);
       }
   };

   const dragStartHandler = (e) => {
      e.preventDefault();
      setDrag(true);
   };

   const dragLeaveHandler = (e) => {
      e.preventDefault();
      setDrag(false);
   };

   const dropHandler = (e) => {
      e.preventDefault();
      
      const files = [...e.dataTransfer.files];

      if(files[0]) setIconFile(files[0])

       // for show icons straign away
       const reader = new FileReader();
      
       reader.onload = () => {
          setRenderedIconFile(reader.result);
       };
        if(files[0]) {
          reader.readAsDataURL(files[0]);
        }
   };

   const onSave = () => {
      const size = isFavicon ? 500 * 1024 : 1024 * 1024;

      if (icon && icon.size > size) {
         return toast(<ToastMsg text="Icon file size exceeds required size. Please select a smaller file." isError/>);
      }

      if (!icon) {
         return toast(<ToastMsg text="Add an icon, please" isError/>);
      }

      onSaveIcon();
   }

   return (
      <ModalLayout
         isOpen={isActive}
         maxHeight={"400px"}
         maxWidth={"500px"}
         onClose={onClose}
         withCross
      >
         <div className={styles.popup_upload_container}>
            <div className={styles.settings_logo_header}>
               <span
                  className={`${styles.settings_box_icon} ${styles.settings_box_icon_favicon}`}
               />
               <div>
                  <p className={styles.settings_logo_title}>{title}</p>
                  <p className={styles.settings_logo_description}>{desc}</p>
               </div>
            </div>
            <div
               onDragStart={dragStartHandler}
               onDragOver={dragStartHandler}
               onDragLeave={dragLeaveHandler}
               onDrop={dropHandler}
               className={styles.upload_image_box}
            >
               <div
                  className={`${styles.drag_drop_image_box}
                              ${drag ? styles.drag : ""}`}
               >
                  <div className={styles.drag_drop_image_text}>
                     <label className={styles.drag_drop_image_button}>
                        <input 
                           accept=".png, .svg, .jpeg, .jpg"
                           onChange={fileLoadHandler} type="file" 
                        />
                        Choose file
                     </label>
                     or Drag & Drop to upload
                  </div>
                  <div>{icon?.name}</div>
               </div>
            </div>
            <div className={styles.popup_button_grid}>
               <button
                  onClick={()=> {
                     onClose();
                     setRenderedIconFile();
                  }}
                  type="button"
                  className={styles.button_popup_cancel}
               >
                  Cancel
               </button>
               <button
                type="button" 
                className={styles.button_popup_save}
                onClick={onSave}
               >
                  Next
               </button>
            </div>
         </div>
      </ModalLayout>
   );
};

export default IconUploadModal;
