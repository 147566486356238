import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/path";
import Input from "../../Input/Input";
import PaginatedDropdown from "../../PaginatedDropdown/PaginatedDropdown";

const ChangeTeamMentorModal = ({
   onClose,
   onConfirm,
   isOpen,
   title,
   teamMembers,
   onSelectMentor,
   selectedMentor,
   isLoading,
   lastSpecialUserRef,
}) => {
   const navigate = useNavigate();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={"257px"}
         maxWidth={"416px"}
         withCross
         visibleOverflow
      >
         <div className={styles.confirm_wrapper}>
            <p className={styles.title}>{title}</p>
            <PaginatedDropdown
               value={selectedMentor.name}
               lastItemRef={lastSpecialUserRef}
               onChange={onSelectMentor}
               data={teamMembers}
               placeholder={"Select team mentor"}
               field={"name"}
               isUsers
            />
            <div className={styles.btns}>
               <Button title={"Cancel"} onClick={onClose} isBlack />
               <Button
                  disabled={isLoading}
                  title={"Confirm"}
                  onClick={onConfirm}
               />
            </div>
         </div>
      </ModalLayout>
   );
};

export default ChangeTeamMentorModal;
