import styles from "./styles.module.scss";
import cn from "classnames";

const CompareAnswer = ({
   answer,
   question,
   answerIdx,
   correctImg,
   iconWrong,
   compareImg,
}) => {
   return (
      <div
         className={cn(
            styles.sequence_right_answers,
            styles.answer_item,
            answer.status === 1 ? styles.correct_answer : styles.wrong_answer
         )}
      >
         <div className={styles.compare_right_wrapper}>
            <div className={styles.compare_left_side}> {question?.options[answerIdx]} </div>
            <div className={styles.compare_right_side}>
               <img className={styles.compareIcon} src={compareImg} alt="" />
               <div> {answer?.content} </div>
               <img className={styles.resultIcon} src={answer.status === 1 ? correctImg : iconWrong} alt="" />
            </div>
         </div>
      </div>
   );
};

export default CompareAnswer;
