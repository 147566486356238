import { useState } from "react";
import Fuse from "fuse.js";

// Hook for returning sorted and searched data in a table
const useSortableAndFuzzyData = (data, searchedKeys, threshold = 0.3) => {
   const [sortColumn, setSortColumn] = useState("");
   const [sortDirection, setSortDirection] = useState("");
   const [searchedData, setSearchedData] = useState(data);

   const handleSort = (column) => {
      if (sortColumn === column) {
         setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
         setSortColumn(column);
         setSortDirection("asc");
      }
   };

   const performSearch = (searchValue) => {
      if (searchValue) {
         const options = {
            keys: searchedKeys,
            threshold: threshold,
         };

         const fuse = new Fuse(data, options);
         const searchResult = fuse
            .search(searchValue)
            .map((result) => result.item);
         setSearchedData(searchResult);
      } else {
         setSearchedData(data);
      }
   };

   const sortedAndSearchedData = sortColumn
   ? searchedData?.sort((a, b) => {
       try {
         if (sortDirection === "asc") {
           if (typeof a[sortColumn] === "string" && typeof b[sortColumn] === "string") {
             return a[sortColumn]?.localeCompare(b[sortColumn]);
           } else {
             return a[sortColumn] - b[sortColumn];
           }
         } else {
           if (typeof a[sortColumn] === "string" && typeof b[sortColumn] === "string") {
             return b[sortColumn]?.localeCompare(a[sortColumn]);
           } else {
             return b[sortColumn] - a[sortColumn];
           }
         }
       } catch (error) {
         console.error("Error sorting data:", error);
         return 0;
       }
     })
   : searchedData;
 

   return {
      sortedAndSearchedData,
      handleSort,
      performSearch,
      sortColumn,
      sortDirection,
   };
};

export default useSortableAndFuzzyData;
