import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import { useSelector } from 'react-redux';

const CustomSwitch = ({ leftText, rightText, maxWidth = "100%", isRight, setSwitch, isHeader }) => {

   const isUploadingFiles = useSelector(
      (state) => state?.sidebar?.confirmModalNeeded
   );
   
   return (
      <div 
         style={{ 
            maxWidth, 
            marginTop: isHeader ? '0px' : '22px' 
         }} 
         className={cn(
            styles.is_theory_btns,
            isHeader ? styles.header_btns : ''
         )}
      >
         <button
            className={cn(
               styles.is_theory_btn,
               !isRight ? styles.active_theory : ""
            )}
            onClick={() => setSwitch(false)}
            disabled={isUploadingFiles}
         >
            {" "}
            {leftText}
         </button>
         <button
            className={cn(
               styles.is_theory_btn,
               isRight ? styles.active_theory : ""
            )}
            onClick={() => setSwitch(true)}
            disabled={isUploadingFiles}
         >
            {" "}
            {rightText}
         </button>
         <div className={styles.active_indicator}>
            {!isRight ? leftText : rightText}
         </div>
      </div>
   );
};

export default CustomSwitch;
