import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import avatar from '../../../assets/images/avatar.svg'
import email from '../../../assets/images/email.svg'
import statusActive from '../../../assets/images/active.svg'
import statusPaused from '../../../assets/images/paused.svg'
import statusDeleted from '../../../assets/images/deleted.svg'
import courseImg from "../../../assets/images/course_thumbnail.png";
import Switch from "../../Switch/Switch";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import UserApi from '../../../utils/api/UserApi';
import { COURSE_STATUS_DELETED, STUDENT_STATUS_ACTIVE, STUDENT_STATUS_DELETED, STUDENT_STATUS_PAUSED } from '../../../constants/courses';
import { toast } from 'react-toastify';
import ToastMsg from '../../ToastMsg/ToastMsg';
import { useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import { returnTemporaryLink } from '../../../utils/usersHelper';
import { returnTemporaryCourseLink } from '../../../utils/coursesHelper';
import { ROLE_ID_STUDENT } from "../../../constants/user";
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const CourseStatusModal = ({onClose, isOpen, item, initTableData, refetchCourseUsers, isRevert}) => {
    const [status, setStatus] = useState(STUDENT_STATUS_ACTIVE);
    const [isEmailNotify, setIsEmailNotify] = useState(false);
    const [isChangeLoading, setIsChangeLoading] = useState(false);

    const [avatarSrc, setAvatarSrc] = useState(avatar);
    const [courseImage, setCourseImage] = useState(courseImg);

    const t = useFindTranslationsByString();

    const params = useParams();

    const onChangeStatus = (id) => setStatus(id);
    const onChangeEmailNotify = () => setIsEmailNotify(!isEmailNotify);

    const onAssignUserAsDefaultAdminFlag = async () => {
      const dataToSend = {
           users: [
            {
               user_id: isRevert ? params?.id : item.id,
               is_admin_as_student: false
            }
           ]
         }
      const res = await new UserApi().changeAdminStatusInCourse(isRevert ? item?.id : params?.id, dataToSend);
    }

    const onSave = async () => {
      if(status === COURSE_STATUS_DELETED) onAssignUserAsDefaultAdminFlag();

      const newUserData = {
        status,
        notify: isEmailNotify
      };

      setIsChangeLoading(true);
   
      try {
         const res = await new UserApi().updateUserStatusInCourse(isRevert ? params?.id : item.id, isRevert ? item?.id : params?.id, newUserData);

         if (res?.success) {
            refetchCourseUsers();
            return toast(<ToastMsg text={res?.success?.message} />);
         }
         if(res?.error?.message) {
            return toast(<ToastMsg text={res?.error?.message} isError />);
         }
      } catch (error) {
         console.error("Failed to update user status:", error);
      } finally {
         setIsChangeLoading(false);
         onClose();
      }
   };

    useEffect(()=> {
      setStatus(item?.status_on_course);
    }, [item?.status_on_course, isOpen])

   const getUserAvatar = async () => {
      const linkArray = await returnTemporaryLink(item);

      if(linkArray?.[0]?.fileUrl) {
         setAvatarSrc(linkArray?.[0]?.fileUrl);
      } 
   }

   const getCourseImage = async () => {
      const linkArray = await returnTemporaryCourseLink(params?.id, item);

      if(linkArray?.[0]?.fileUrl) {
         setCourseImage(linkArray?.[0]?.fileUrl);
      }
   }

   useEffect(()=> {
      if(item?.avatar) getUserAvatar();
      if(item?.image) getCourseImage();

      return () => {
         setAvatarSrc('');
         setCourseImage('');
      }
   }, [item]);

   const isPausedStatusNeeded = item?.role_id === ROLE_ID_STUDENT || item?.is_as_student;

    return (
        <ModalLayout
            maxWidth={'506px'}
            isOpen={isOpen}
            onClose={onClose}
            maxHeight={!isPausedStatusNeeded ? '470px' : '545px'}
            withCross
            customPadding={'32px 24px'}
        >
            <p className={styles.title}>
               {isRevert ? t("Choose course status for user") : t("Choose user status 'on course'")}
            </p>

            <div className={styles.user}>
               {!isRevert && 
                  <div className={styles.user_avatar}>
                     <img src={avatarSrc || avatar} alt={''}/>
                  </div>
               }

               {isRevert &&
                  <div className={styles.course_avatar}>
                     <img src={courseImage || courseImg} alt={''}/>
                  </div>
               }
               
                { item?.name ? item?.name : item?.team }
            </div>

            <div className={styles.list}>
                <div className={styles.status}>
                    <div className={styles.status_wrapper}>
                        <img src={statusActive} alt={''}/>
                        <div>
                            <p className={styles.status_title}>{t("Active")}</p>
                            <p className={styles.status_text}>{t("The user may take the test")}</p>
                        </div>
                    </div>
                    <Checkbox
                        isRound
                        isChecked={status === STUDENT_STATUS_ACTIVE}
                        setIsChecked={() => onChangeStatus(STUDENT_STATUS_ACTIVE)}
                    />
                </div>

               {!!isPausedStatusNeeded && 
                <div className={styles.status}>
                    <div className={styles.status_wrapper}>
                        <img src={statusPaused} alt={''}/>
                        <div>
                            <p className={styles.status_title}>{t("Paused")}</p>
                            <p className={styles.status_text}>{t("The user cannot continue learning, but the lessons are available to him. If there is a deadline, it will be suspended")}</p>
                        </div>
                    </div>
                    <Checkbox
                        isRound
                        isChecked={status === STUDENT_STATUS_PAUSED}
                        setIsChecked={() => onChangeStatus(STUDENT_STATUS_PAUSED)}
                    />
                </div>
               }

                <div className={styles.status}>
                    <div className={styles.status_wrapper}>
                        <img src={statusDeleted} alt={''}/>
                        <div>
                            <p className={styles.status_title}>{t("Deleted")}</p>
                            <p className={styles.status_text}>{t("The user does not see the course. His progress in the course will be deleted irretrievably")}</p>
                        </div>
                    </div>
                    <Checkbox
                        isRound
                        isChecked={status === STUDENT_STATUS_DELETED}
                        setIsChecked={() => onChangeStatus(STUDENT_STATUS_DELETED)}
                    />
                </div>
            </div>


            <div className={styles.email}>
                <div className={styles.email_notify}>
                    <img src={email} alt={''}/>
                    {t("Email notify")}
                </div>
                <Switch onChange={onChangeEmailNotify} checked={isEmailNotify}/>
            </div>

            <div className={styles.buttons}>
                <Button title={t('Cancel')} onClick={onClose} isBlack/>
                <Button disabled={isChangeLoading} title={t('Save changes')} onClick={onSave}/>
            </div>
            
            {isChangeLoading && 
               <div className='default_loader_wrapper'>
                  <Loader size={"small"}/>
               </div>
            }

        </ModalLayout>
    );
};

export default CourseStatusModal;