import { useRef, useCallback } from "react";

const useIntersectionObserver = (onIntersect, dependencies) => {
   const observerRef = useRef(null);

  const observerCallback = useCallback(
    (node) => {
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
          onIntersect();
        }
      });

      if (node) observerRef.current.observe(node);

      return () => {
        if (observerRef.current) observerRef.current.disconnect();
      };
    },
    [onIntersect, ...dependencies]
  );

  return observerCallback;
};

export default useIntersectionObserver;
