export const DEPARTMENTS = [
   { id: 0, name: 'All', label: 'all' },
   { id: 1, name: 'Software Development', label: 'Software Development' },
   { id: 2, name: 'QA (Quality Assurance)', label: 'QA (Quality Assurance)' },
   { id: 3, name: 'IT Infrastructure', label: 'IT Infrastructure' },
   { id: 4, name: 'Product Management', label: 'Product Management' },
   { id: 5, name: 'Project Management', label: 'Project Management' },
   { id: 6, name: 'UX/UI Design', label: 'UX/UI Design' },
   { id: 7, name: 'HR (Human Resources)', label: 'HR (Human Resources)' },
   { id: 8, name: 'Marketing', label: 'Marketing' },
   { id: 9, name: 'Finance', label: 'Finance' },
   { id: 10, name: 'Analytics', label: 'Analytics' },
   { id: 11, name: 'Legal Department', label: 'Legal Department' },
   { id: 12, name: 'Support', label: 'Support' },
   { id: 13, name: 'Moderation', label: 'Moderation' },
   { id: 14, name: 'Administration', label: 'Administration' },
   { id: 15, name: 'Executive Management', label: 'Executive Management' },
   { id: 16, name: 'PPC', label: 'PPC' },
 ];

 export const POSITIONS = [
   { id: 0, name: 'All', label: 'all' },
   { id: 1, name: 'Software Developer', label: 'software_developer' },
   { id: 2, name: 'Software Architect', label: 'software_architect' },
   { id: 3, name: 'QA Engineer', label: 'qa_quality_assurance' },
   { id: 4, name: 'Test Automation Engineer', label: 'test_automation_engineer' },
   { id: 5, name: 'System Administrator', label: 'system_administrator' },
   { id: 6, name: 'DevOps', label: 'devops' },
   { id: 7, name: 'Product Manager', label: 'product_manager' },
   { id: 8, name: 'Product Owner', label: 'product_owner' },
   { id: 9, name: 'UX/UI Designer', label: 'ux_ui_design' },
   { id: 10, name: 'Graphic Designer', label: 'graphic_designer' },
   { id: 11, name: 'HR Manager', label: 'hr_manager' },
   { id: 12, name: 'Recruiter', label: 'recruiter' },
   { id: 13, name: 'Sourcer', label: 'sourcer' },
   { id: 14, name: 'Marketer', label: 'marketer' },
   { id: 15, name: 'Content Manager', label: 'content_manager' },
   { id: 16, name: 'SMM Manager', label: 'smm_manager' },
   { id: 17, name: 'Financial Manager', label: 'financial_managee' },
   { id: 18, name: 'Accountant', label: 'accountant' },
   { id: 19, name: 'Billing Manager', label: 'billing_manager' },
   { id: 20, name: 'Analyst', label: 'analist' },
   { id: 21, name: 'Lawyer', label: 'lawyer' },
   { id: 22, name: 'Support Specialist', label: 'support_specialist' },
   { id: 23, name: 'Moderator', label: 'moderator' },
   { id: 24, name: 'Business Assistant', label: 'business_assistant' },
   { id: 25, name: 'Personal Assistant', label: 'personal_assistant' },
   { id: 26, name: 'Chief Operating Officer (COO)', label: 'coo' },
   { id: 27, name: 'Chief Financial Officer (CFO)', label: 'cfo' },
   { id: 28, name: 'Chief Technical Officer (CTO)', label: 'cto' },
   { id: 29, name: 'Chief Marketing Officer (CMO)', label: 'cmo' },
   { id: 30, name: 'PPC Specialist', label: 'ppc' },
   { id: 31, name: 'Affiliate Manager', label: 'affiliate_manager' },
   { id: 32, name: 'SEO Specialist', label: 'seo_specialist' },
 ];
 
 export const DEPARTMENTS_HEADERS = [
   "department",
   "position",
   "users",
   "actions",
 ];

 export const POSITIONS_HEADERS = [
   "position",
   "department",
   "users",
   "actions",
 ];

 export const mapDepartments = (item) => ({
   ...item,
   department: item?.name,
   position: item?.positions?.length ? item?.positions?.filter(item => item?.isActive) : '-',
   actions: ''
 })

 export const mapPositions = (item) => ({
   ...item,
   position: item?.name,
   department: item?.department ? item?.department : '-',
 })
