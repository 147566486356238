import React, { useState } from "react";
import styles from "./styles.module.scss";

import EmptyBlock from "../EmptyBlock/EmptyBlock";
import MainLayout from "../../layouts/MainLayout/MainLayout";

import cn from "classnames";

const NoAccessToThePage = () => {
   return (
      <MainLayout darkBg>
         <div className="no_access_text">
            <EmptyBlock text={"access to the page."} key={"no_access"} />
         </div>
      </MainLayout>
   );
};

export default NoAccessToThePage;
