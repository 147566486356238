
import Circle from "../Circle/Circle";

import styles from "./index.module.scss";
import { useState } from "react";
import Planets from "../Planets/Planets";

const UniverseProgress = ({ currentLevel = 2, setCurrentLevel, minHeight }) => {

   const clickPlanet = ({ level }) => setCurrentLevel(level);

   return (
      <div className={styles.universe}>
         <div style={{ minHeight: minHeight }} className={`${styles.universe_progress} tile`}>
            <Planets currentLevel={currentLevel} clickPlanet={clickPlanet} />
         </div>
      </div>
   );
};

export default UniverseProgress;
