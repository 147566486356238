import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import Button from "../../../components/Button/Button";
import saveImg from "../../../assets/images/symbols/save.svg";
import cn from "classnames";

const Subheader = ({ title, text, onSave, onCancel, t }) => {
   const isSideBarFixed = useSelector((state) => state.sidebar.isFixed);
   
   return (
      <div
         className={cn(
            styles.subheader,
            isSideBarFixed ? styles.sidebar_fixed : ""
         )}
      >
         <div>
            <p className={styles.title}>{t?.returnTranslation(title)}</p>
            <p className={styles.text}>{t?.returnTranslation(text)}</p>
         </div>

         <div className={styles.buttons}>
            <Button title={t?.cancel} isBlack onClick={onCancel} />
            <Button title={t?.saveChanges} image={saveImg} onClick={onSave} />
         </div>
      </div>
   );
};

export default Subheader;
