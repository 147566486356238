import React from "react";
import styles from "./DotsButton.module.scss";
import cn from "classnames";

export const DOTS_VARIANTS = {
    main: 'main',
    notificationVariant: 'notificationVariant'
}

const DotsButton = ({ isOpen, onToggle, dotsRef, variant = 'main', position = "absolute", className = '' }) => {
  return (
    <div
      className={cn(
          styles.dots,
          DOTS_VARIANTS[variant] && styles[variant],
          {[styles.active]: isOpen},
          className,
          position !== "absolute" && styles.relative
      )}
      onClick={onToggle}
      ref={dotsRef}
    >
      <div className={styles.dots_wrapper}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </div>
    </div>
  );
};

export default DotsButton;
