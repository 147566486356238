import React, { useCallback, useEffect, useState, useRef } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./styles.module.scss";
import { pathList } from "../../routes/path";
import { useNavigate, useParams } from "react-router-dom";
import AgencyApi from "../../utils/api/AgencyApi";
import TranslationApi from "../../utils/api/TranslationApi";
import AgencyCard from "../../components/AgencyCard/AgencyCard";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import NoEntityAdded from "../../components/NoEntityAdded/NoEntityAdded";
import UserApi from "../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import { useDispatch } from "react-redux";
import { setCompanyInfo, setCompanyLanguages, setCompanyTranslation, setInfoLoading } from "../../store/actions/sidebar";
import { useSelector } from "react-redux";
import { PART_OF_MENTOR_NAME, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, returnCompanyFirstRoleName } from "../../constants/user";
import useUserRole from "../../hooks/useUserRole";
import { transformRoleText } from "../../utils/rolesHelper";
import { returnTemporaryCompaniesLinks, mapAgenciesWithNewLinks, returnTemporaryCompanyLink } from "../../utils/usersHelper";
import { COMPANY_EXTRA_GROUP_ONE, COMPANY_EXTRA_GROUP_TWO } from "../../utils/api/serialization_groups";
import useFindTranslations from "../../hooks/useFindTranlsations";

export default function AgenciesPage() {
   const navigate = useNavigate();
   const params = useParams();
   const dispatch = useDispatch();

   const [agenciesList, setAgenciesList] = useState([]);
   const [agenciesExtraInfo, setAgenciesExtraInfo] = useState([]);
   const [loading, setLoading] = useState(false);
   const [limit, setLimit] = useState(10);
   const [page, setPage] = useState(1);
   const [lastAgencyId, setLastAgencyId] = useState(0);
   const [allDataLoaded, setAllDataLoaded] = useState(false);
   const [isMountedWithDelay, setIsMountedWithDelay] = useState(false);
   const userLanguage = useSelector((state) => state.sidebar.chosenLanguage);

   const t = useFindTranslations();

   const observer = useRef(null);

   const userInfo = useSelector(state => state?.user?.info);
   const userId = useSelector(state => state?.user?.info?.id);
   const agencyId = useSelector((state) => state.sidebar.companyId);
   const isAdminAsStudent = useSelector((state) => state.sidebar.isAdminAsStudent);

   const { userRoleId, userRoles, userRoleName, userRoleCompanyId } = useUserRole(agencyId);

   const lastElementRef = useCallback(
      (node) => {
         if (loading || allDataLoaded) return;
         if (observer.current) observer.current.disconnect();

         observer.current = new IntersectionObserver((entries) => {
            if (entries[0]?.isIntersecting) {
               getAgencies();
            }
         });

         if (node) observer.current.observe(node);
      },
      [loading, allDataLoaded]
   );

   const mapUserData = (item) => ({
      ...item,
      id: item?.id,
      email: item?.email ? item?.email : "-",
      role: transformRoleText(returnCompanyFirstRoleName(userRoles, item?.id), item?.name),
      name: item?.name,
      courses: 0,
      profiles: 0,
   });

   const getAgencies = async () => {
      if (allDataLoaded || loading) return;

      setLoading(true);

      // Fetch agencies data
      const agenciesRes = await new AgencyApi().getAgencies(page, limit, COMPANY_EXTRA_GROUP_TWO, '', null, userRoleId === ROLE_ID_SUPER_ADMIN);

      if (agenciesRes?.success?.data) {
         if (agenciesRes?.success?.code === 204){
             // Stop pushing data if there's no data in the request
             setAllDataLoaded(true);
         }

         const links = await returnTemporaryCompaniesLinks(userId, agenciesRes?.success?.data?.filter(company => company?.companyLogo?.iconLogo));

         const agenciesWithUpdatedLinks = agenciesRes?.success?.data?.map((agency) => mapAgenciesWithNewLinks(agency, links));

          // Update agencies list with new data
          setAgenciesList((prevData) => [...prevData, ...agenciesWithUpdatedLinks]);
          setPage((prevPage) => prevPage + 1);
      } else {
          // Stop pushing data if there's no data in the request
          setAllDataLoaded(true);
      }

      setLoading(false);
   };

   const getAgenciesExtraInfo = async () => {
      const data = {
         company_ids: agenciesList?.map(agency => agency?.id)
      }

      const res = await new AgencyApi().getAgencySpecificInfo(data);

      if(res?.success?.data) {
         setAgenciesExtraInfo(res?.success?.data)
      }
   }

   useEffect(()=> {
      if(agenciesList?.length)   
         getAgenciesExtraInfo();
   }, [agenciesList])

   const getTranslations = async (companyId) => {
      const res = await new TranslationApi().getTranslations({
         companyId: companyId,
         locale: userLanguage?.code,
      });

      if (res?.success?.data) {
         dispatch(setCompanyTranslation(res?.success?.data));
      }
   };

   const changeCurrentSuperAdminCompany = async (company, iconSrc) => {
      dispatch(setInfoLoading(true));

      const data = {
         new_current_company_id: company?.id
      }

      dispatch(setCompanyInfo(company.id, company.name, iconSrc, company.branches[0]?.id));
      dispatch(setCompanyLanguages(company?.availableLanguages));
      getTranslations(company?.id)

      const res = await new UserApi().changeCurrentSuperAdminCompany(data);

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />)
      }

      if(res?.error?.message) {
         const prevCompany = agenciesList?.find(agency => agency?.id === agencyId);
         const updatedCompanyIcon = await returnTemporaryCompanyLink(userInfo?.id, prevCompany);
         dispatch(setCompanyInfo(prevCompany.id, prevCompany.name, updatedCompanyIcon, prevCompany.branches[0]?.id));
         dispatch(setCompanyLanguages(prevCompany?.availableLanguages));
         toast(<ToastMsg text={res?.error?.message} isError />)
      } 

      dispatch(setInfoLoading(false));
   }

   useEffect(() => {
      if(userId) getAgencies();

      return () => {
         if (observer.current) {
            observer.current.disconnect();
         }

         setAgenciesList([]);
      };
   }, [userId]);

   const redirectToCreateAgencyPage = () => {
     navigate(pathList.agencySettings.path)
   }

   const mappedCompanies = agenciesList?.map(mapUserData);
   const displayedCompanies = mappedCompanies?.map(agency => {
      const matchingLink = agenciesExtraInfo?.find(a => a?.companyId === agency?.id);

      if (matchingLink) {
         return {
            ...agency,
            profiles: matchingLink?.usersWithSomeRoleInCompany,
            courses: matchingLink?.coursesInCompany,
         };
      } else {
         return agency;
      }
    })

   return (
      <MainLayout>
         <main className={styles.agencies_page}>
            <div className={styles.page_header}>
               <h2 className={styles.title}>{t?.companies}</h2>
               {userRoleId === ROLE_ID_SUPER_ADMIN && 
                  <button
                     type="button"
                     className={styles.button_add}
                     onClick={redirectToCreateAgencyPage}
                     title={t?.addCompany}
                  />
               }
            </div>

            {agenciesList?.length === 0 && !loading && userRoleId === ROLE_ID_SUPER_ADMIN && 
                <NoEntityAdded action={redirectToCreateAgencyPage} entity={t?.company} />
            }

            {agenciesList?.length === 0 && !loading && userRoleId && userRoleId !== ROLE_ID_SUPER_ADMIN  && 
                `${t?.somethingWentWrong}...`
            }

            <TransitionGroup className={styles.agencies_grid}>
               {displayedCompanies?.map((agency, idx) => (
                  <CSSTransition key={agency.id} classNames="fade" timeout={300}>
                     <AgencyCard
                        lastElementRef={agenciesList.length === idx + 1 ? lastElementRef : null}
                        loading={loading}
                        t={t}
                        agency={agency}
                        changeCurrentSuperAdminCompany={changeCurrentSuperAdminCompany}
                        isMountedWithDelay={isMountedWithDelay}
                        setIsMountedWithDelay={setIsMountedWithDelay}
                        isSpecialRole={(userRoleId !== ROLE_ID_STUDENT && !userRoleName?.includes(PART_OF_MENTOR_NAME) && agency?.id === userRoleCompanyId) || userRoleId === ROLE_ID_SUPER_ADMIN}
                     />
                  </CSSTransition>
               ))}
            </TransitionGroup>

            {loading &&
             <div className="default_loader_wrapper">
                <SecondLoader />
             </div>
            }
            {!userId && !loading &&
             <div className="default_loader_wrapper">
                <SecondLoader />
             </div>
            }

         </main>
      </MainLayout>
   );
}
