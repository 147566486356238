import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import filtersFilledImg from "../../../assets/images/symbols/filters_filled.svg";
import cross from "../../../assets/images/symbols/cross_grey.svg";
import arrow from "../../../assets/images/symbols/arrow.svg";

import cn from "classnames";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import useFindTranlsations from "../../../hooks/useFindTranlsations";
import { useSelector } from "react-redux";
import useUserRole from "../../../hooks/useUserRole";
import Button from "../../../components/Button/Button";
import { TESTS_CHECKED_FILTERS } from "../../../constants/tests";
import Dropdown from "../../../components/Dropdown/Dropdown";
import DropdownWithSearch from "../../../components/DropdownWithSearch/DropdownWithSearch";
import { LEFT_ICON, OPENED, RIGHT_ICON } from "../../../constants/classNames";
import useGetTeams from "../../../hooks/api/useGetTeamsOfCompany";
import { TEAM_EXTRA_GROUP_TABLE } from "../../../utils/api/serialization_groups";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const coursesSortFiltersData = ["First new", "Old first"];

const KnowledgeCheckFilters = ({
  students,
  activeFilter,
  activeSortFilter,
  currentTags,
  handleStudentsFilterClick,
  studentsSearchedValue,
  setStudentsSearchedValue,
  selectedStudents,
  setSelectedStudents,
  lastStudentRef,
  onFilterApply,
  selectedLanguages,
  handleFilterClick,
  showStatusFilter,
  setSelectedTeams,
  selectedTeams,
  handleTeamsFilterClick,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [showFolderPlusOptions, setShowFolderPlusOptions] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [showTagsOptions, setShowTagsOptions] = useState(false);
  const [tags, setTags] = useState(currentTags || []);
  const companyId = useSelector((state) => state.sidebar.companyId);

  const t = useFindTranlsations();
  const tNew = useFindTranslationsByString();

  const currentCompanyId = useSelector((state) => state.sidebar.companyId);
  const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

  const optionRef = useRef(null);
  const dropDownRef = useRef(null);
  const dateFilterRef = useRef(null);

  // Options for adding either a course to folder or creating a topic.
  const plusFolderRef = useRef(null);
  const dropDownFolderRef = useRef(null);

  const optionTagRef = useRef(null);
  const dropDownTagRef = useRef(null);
  const openDateRef = useRef(null);

  const closeOptions = () => setShowOptions(false);
  const closeTagsOptions = () => setShowTagsOptions(false);
  const closeFolderPlusOptions = () => setShowFolderPlusOptions(false);
  const closeDateOptions = () => setDateFilterOpen(false);

  useOutsideClick(dropDownRef, closeOptions, optionRef);
  useOutsideClick(dropDownTagRef, closeTagsOptions, optionTagRef);
  useOutsideClick(dropDownFolderRef, closeFolderPlusOptions, plusFolderRef);
  useOutsideClick(dateFilterRef, closeDateOptions, openDateRef);

  const toggleOptions = (e) => {
    setShowOptions((prev) => !prev);
  };

  useEffect(() => {
    if (currentTags?.length) {
      if (searchedValue) {
        const filteredTags = currentTags.filter((tag) =>
          tag
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(searchedValue.toLowerCase().replace(/\s/g, "")),
        );
        setTags(filteredTags);
      } else {
        setTags(currentTags);
      }
    }
  }, [currentTags, searchedValue]);

  const isExtendedFiltersOpen =
    coursesSortFiltersData.includes(activeSortFilter) ||
    selectedLanguages?.length;

  const teamManager = useGetTeams({
    companyId,
    userRoleName,
    serializationGroup: TEAM_EXTRA_GROUP_TABLE,
  });
  const {
    teamsList,
    searchedValue: teamSearchedValue,
    setSearchedValue: setTeamSearchedValue,
  } = teamManager;

  const lastTeamRef = useIntersectionObserver(() => {
    if (
      !teamManager.isTeamsLoading &&
      teamManager.teamsList?.length < teamManager.totalTeams
    ) {
      if (teamManager.teamsList?.length === 0) {
        teamManager.setCurrentPage(1);
      } else {
        teamManager.setCurrentPage((prev) => prev + 1);
      }
    }
  }, [teamManager.isTeamsLoading, teamManager.totalTeams]);

  const countOfFilters = useMemo(() => {
    let count = 0;
    activeFilter && activeFilter.status && (count += 1);
    selectedTeams?.length > 0 && (count += 1);
    selectedStudents?.length > 0 && (count += 1);

    return count;
  }, [activeFilter, selectedTeams, selectedStudents]);

  const clearExtendedFilters = (e) => {
    e.stopPropagation();
    setSelectedTeams([]);
    setSelectedStudents([]);
    handleFilterClick(null);
    onFilterApply();
  };

  return (
    <div className={cn(styles.block_extended, showOptions ? OPENED : "")}>
      <div
        className={styles.filter_wrapper}
        ref={optionRef}
        onClick={toggleOptions}
      >
        <img
          src={countOfFilters ? filtersFilledImg : filtersImg}
          alt={""}
          className={LEFT_ICON}
        />
        {countOfFilters ? (
          <span className={styles.light_text}>+{countOfFilters}</span>
        ) : (
          tNew("Filters")
        )}

        {!!countOfFilters && (
          <img
            onClick={clearExtendedFilters}
            className={cn(RIGHT_ICON, styles.options_arrow)}
            src={cross}
            alt=""
          />
        )}

        {!countOfFilters && (
          <img
            className={cn(
              RIGHT_ICON,
              styles.options_arrow,
              showOptions ? styles.options_opened : "",
            )}
            src={arrow}
            alt={""}
          />
        )}
      </div>

      {showOptions && (
        <div className={styles.options_wrapper} ref={dropDownRef}>
          <DropdownWithSearch
            simpleVariant={true}
            searchNeeded
            data={students}
            field={"label"}
            placeholder={t?.users}
            value={activeFilter?.label}
            onChange={(filter) => handleStudentsFilterClick(filter)}
            searchValue={studentsSearchedValue}
            setSearchValue={setStudentsSearchedValue}
            selectedItems={selectedStudents}
            setSelectedItems={setSelectedStudents}
            lastItemRef={lastStudentRef}
          />

          <DropdownWithSearch
            simpleVariant={true}
            searchNeeded
            data={teamsList}
            field={"label"}
            placeholder={t?.teams}
            value={activeFilter?.label}
            onChange={(filter) => handleTeamsFilterClick(filter)}
            searchValue={teamSearchedValue}
            setSearchValue={setTeamSearchedValue}
            selectedItems={selectedTeams}
            setSelectedItems={setSelectedTeams}
            lastItemRef={lastTeamRef}
          />

          {showStatusFilter && (
            <div className={styles.status_filter}>
              <Dropdown
                data={TESTS_CHECKED_FILTERS}
                field={"label"}
                maxHeightFrontEl={"40px"}
                maxHeight={"144px"}
                placeholder={t?.status}
                value={activeFilter?.label}
                onClear={() => {
                  handleFilterClick(null);
                }}
                withClear
                onChange={(filter) => handleFilterClick(filter)}
                optionsClassName={styles.status_options}
                testStatuses
              />
            </div>
          )}

          <Button
            disabled={false}
            title="Apply"
            className={styles.apply_button}
            onClick={(e) => {
              onFilterApply();
              toggleOptions();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default KnowledgeCheckFilters;
