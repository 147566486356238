import styles from "./styles.module.scss";
import filtersImg from "../../assets/images/symbols/calendar.svg";

import cn from "classnames";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { KNOWLEDGE_CHECK_DATE_OPTIONS } from "../../constants/user";

const ExtendedDateDropdown = ({
  dateFilter,
  setDateFilter,
  frontTimeFilterValue,
  setFrontTimeFilterValue,
}) => {
  const getDateRange = (label) => {
    const today = new Date();
    let startDate, endDate;

    switch (label) {
      case "for_today":
        startDate = endDate = formatDate(today);
        break;
      case "for_yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        startDate = endDate = formatDate(yesterday);
        break;
      case "in_a_week":
        startDate = formatDate(
          new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
        );
        endDate = formatDate(today);
        break;
      case "in_a_month":
        startDate = formatDate(
          new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
        );
        endDate = formatDate(today);
        break;
      default:
        startDate = endDate = "";
    }

    return `${startDate} - ${endDate}`;
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className={cn(styles.filtersBlock, "noselect")}>
      <CustomDropdown
        data={KNOWLEDGE_CHECK_DATE_OPTIONS}
        value={dateFilter}
        maxFrontHeight40
        onChange={(value) => {
          setDateFilter(getDateRange(value.label));
          setFrontTimeFilterValue(value?.name);
        }}
        frontTimeFilterValue={frontTimeFilterValue}
        setFrontTimeFilterValue={setFrontTimeFilterValue}
        maxHeight={"400px"}
        img={filtersImg}
        isWithDateRange
        onChangeDate={(value) => setDateFilter(value)}
        dateFilter={dateFilter}
      />
    </div>
  );
};

export default ExtendedDateDropdown;
