import React, { useState } from "react";
import styles from "./styles.module.scss";

const SecurityBlock = ({
   blockRef,
   setSecurityConditions,
   securityConditions,
   termsChecked,
   setTermsChecked,
   t
}) => {
   const securityChangeHandler = (isChecked, field) => {
      setSecurityConditions((prevConditions) => ({
         ...prevConditions,
         [field]: isChecked ? 1 : 0,
      }));
   };
   return (
      <div ref={blockRef} className={styles.settings_box}>
         <p className={styles.setting_box_name}>{t?.security}</p>
         <div className={styles.checkbox_row}>
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="disable_content_selection"
               checked={
                  securityConditions.content_and_context_menu_disabled === 1
               }
               onChange={(e) =>
                  securityChangeHandler(
                     securityConditions.content_and_context_menu_disabled === 0,
                     "content_and_context_menu_disabled"
                  )
               }
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="disable_content_selection"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>
                  {t?.disableContentSelection}
               </p>
               <p className={styles.checkbox_row_description}>
                  {t?.studentsWillNotBeAbleToSelectContent}.
               </p>
            </div>
         </div>
         {/* <div className={styles.checkbox_row}>
         FOR FUTURE
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="disable_clipboard"
               checked={
                  securityConditions.clickboard_disabled === 1
               }
               onChange={(e) =>
                  securityChangeHandler(
                     securityConditions.clickboard_disabled === 0,
                     "clickboard_disabled"
                  )
               }
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="disable_clipboard"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>Disable clipboard</p>
               <p className={styles.checkbox_row_description}>
                  Students will not be able to copy content to the clipboard.
                  The setting disables the "Copy" button using the "Ctrl + C"
                  hotkeys.
               </p>
            </div>
         </div>
         <div className={styles.checkbox_row}>
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="access_domain"
               checked={
                  securityConditions.provide_access_by_domain === 1
               }
               onChange={(e) =>
                  securityChangeHandler(
                     securityConditions.provide_access_by_domain === 0,
                     "provide_access_by_domain"
                  )
               }
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="access_domain"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>
                  Provide access by domain
               </p>
               <p className={styles.checkbox_row_description}>
                  Students will be able to log in to your branch using the email
                  domains specified. If existing students have emails with
                  different domains, they will no longer be able to log in.
               </p>
            </div>
         </div> */}
         <div className={styles.checkbox_row}>
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="strong_password"
               checked={
                  securityConditions.strong_password === 1
               }
               onChange={(e) =>
                  securityChangeHandler(
                     securityConditions.strong_password === 0,
                     "strong_password"
                  )
               }
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="strong_password"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>{t?.strongPassword}</p>
               <p className={styles.checkbox_row_description}>
                  {t?.pupilPasswordsMustBeAtLeast}.
               </p>
            </div>
         </div>
         <div className={styles.checkbox_row}>
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="terms_use"
               checked={termsChecked}
               onChange={(e) => setTermsChecked(!termsChecked)}
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="terms_use"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>{t?.termsOfUse}</p>
               <p className={styles.checkbox_row_description}>
                  {t?.checkTheTermsOfUseBox}
               </p>
            </div>
         </div>
         {/* <div className={styles.checkbox_row}>
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="ip_access"
               checked={
                  securityConditions.provide_access_to_ip === 1
               }
               onChange={(e) =>
                  securityChangeHandler(
                     securityConditions.provide_access_to_ip === 0,
                     "provide_access_to_ip"
                  )
               }
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="ip_access"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>
                  Provide access to the IP
               </p>
               <p className={styles.checkbox_row_description}>
                  Pupils will only be able to log in to the branch from specific
                  IP addresses. Existing pupils will not be able to log in from
                  an IP address that is not on that list.
               </p>
            </div>
         </div>
         <div className={styles.checkbox_row}>
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="two_factor_auth"
               checked={
                  securityConditions.two_factor_auth === 1
               }
               onChange={(e) =>
                  securityChangeHandler(
                     securityConditions.two_factor_auth === 0,
                     "two_factor_auth"
                  )
               }
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="two_factor_auth"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>
                  Two-factor authentication
               </p>
               <p className={styles.checkbox_row_description}>
                  Provides an extra layer of security for student accounts. If
                  you enable two-factor authentication, all students will be
                  prompted to enter a 6-digit OTR (one-time password) to confirm
                  their details when logging in.
               </p>
            </div>
         </div> */}
      </div>
   );
};

export default SecurityBlock;
