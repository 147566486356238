export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEBAR_FIXED = 'TOGGLE_SIDEBAR_FIXED';
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';
export const SET_COMPANY_ICON = 'SET_COMPANY_ICON';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_COMPANY_AVAILABLE_LANGUAGES = 'SET_COMPANY_AVAILABLE_LANGUAGES';
export const SET_USER_AS_ADMIN = 'SET_USER_AS_ADMIN';
export const SET_CURATOR_MODAL_HASH = 'SET_CURATOR_MODAL_HASH';
export const SET_IS_CONFIRM_MODAL_NEEDED = 'SET_IS_CONFIRM_MODAL_NEEDED';
export const SET_COMPANY_APP_TRANSLATION = 'SET_COMPANY_APP_TRANSLATION';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_INFO_LOADING = 'SET_INFO_LOADING';
export const SET_CHOSEN_LANGUAGE = 'SET_CHOSEN_LANGUAGE';
export const TOGGLE_SIDEBAR_MOBILE = 'TOGGLE_SIDEBAR_MOBILE';
export const CHANGE_SIDEBAR_PROFILE_MOBILE = 'CHANGE_SIDEBAR_PROFILE_MOBILE';

