import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import curatorImg from "../../../assets/images/curator/curator_img.svg";
import cross from "../../../assets/images/symbols/cross_grey.svg";
import { CURATOR_MODAL_LIST } from "../../../constants/user";
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString'

const BecomeCuratorModal = ({
   onClose,
   onConfirm,
   isOpen,
   confirmButtonText,
   isLoading,
}) => {
   const t = useFindTranslationsByString();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={"630px"}
         maxWidth={"460px"}
         customPadding={"0px"}
      >
         <div className={styles.wrapper}>
            <div className={styles.cross}>
               <img src={cross} alt={""} onClick={onClose} />
            </div>

            <div className={styles.topBlock}>
               <img src={curatorImg} alt="" />
            </div>
            <div className={styles.content}>
               <div className={styles.title}>
                  <h2>{t("Become the curator of this course!")}</h2>
               </div>

               <div className={styles.list_wrapper}>
                  {CURATOR_MODAL_LIST.map(item => 
                     <div key={item.id} className={styles.list_item}>
                        <div className={styles.left}>
                           <img src={item.icon} alt="" />
                        </div>
                        <div className={styles.right}>
                           <p>{t(item.title)}</p>
                           <p className={styles.gray}>{t(item.desc)}</p>
                        </div>
                     </div>
                  )}
               </div>

               <div className={styles.btns}>
                  <Button title={t("Decline")} onClick={onClose} isBlack className={styles.decline} />
                  <Button
                     disabled={isLoading}
                     title={confirmButtonText ? confirmButtonText : t("Confirm")}
                     onClick={onConfirm}
                  />
               </div>

               <div className="default_loader_wrapper">
                  {isLoading && <Loader size={"small"} />}
               </div>
            </div>
         </div>
      </ModalLayout>
   );
};

export default BecomeCuratorModal;
