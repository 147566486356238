import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import courseImg from "../../../assets/images/course_thumbnail.png";
import avatarPlaceholder from "../../../assets/images/avatar.svg";
import TableStars from "../TableStars/TableStars";
import { useState, useRef } from "react";

import styles from "./styles.module.scss";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";

const renderCellContent = (
   column,
   row,
   maxWordsLength,
   tableFor,
   handleMouseEnter,
   handleMouseLeave,
   showProfile,
   isProgressTable,
) => {
   if (column === "team_name") {
      if (row[column]?.length === 0) return "-";
      const arr = row[column]?.split(",");
      const text = arr?.map((name) => name?.trim())?.join(", ") || row[column];

      return (
         <div className={styles.few_items}>
            <CustomTooltip
               id={row[column]}
               limit={maxWordsLength}
               text={text}
               maxWidth="250px"
            />
         </div>
      );
   }
   
   if (column === "student") {
      return (
         <Link className={styles.link} target="_blank" to={`/users/profile/${row?.userId}`}>
            <CustomTooltip
               id={row[column]}
               limit={maxWordsLength}
               text={row[column]}
               maxWidth="250px"
            />
         </Link>
      );
   }
   if (column === "integration" || column === 'progress') {
      const rowEmpty = row[column] === "-";

      return (
         <div className={styles.integration_column_wrapper}>
            <span>{!rowEmpty ? row[column] : "0"}%</span>
            <div className={styles.line_wrapper}>
               <div
                  style={{
                     width: `${!rowEmpty ? row[column] : 0}%`,
                  }}
                  className={styles.line}
               ></div>
            </div>
         </div>
      );
   }

   if (column === "user" && tableFor === "users") {
      return (
         <div className={styles.user_name_item}>
            <CustomTooltip
               id={row[column]}
               limit={maxWordsLength}
               text={row[column]}
               maxWidth="500px"
            />
            {showProfile && (
               <div
                  onClick={(e) => {
                     e.stopPropagation();
                  }}
                  className={styles.user_profile}
               >
                  <img src={row?.avatarUrl || avatarPlaceholder} alt="" />
                  <div className={styles.user_name}>
                     <CustomTooltip
                        id={`${row.id}_user_name`}
                        limit={10}
                        text={row.name}
                        maxWidth="500px"
                     />
                  </div>
                  <div className={styles.user_email}>
                     <CustomTooltip
                        id={`${row.id}_user_email`}
                        limit={14}
                        text={row.email}
                        maxWidth="500px"
                     />
                  </div>
               </div>
            )}
         </div>
      );
   }

   if (column === "course_name" || column === "course") {
      return (
         <div>
            <img
               src={row?.image || courseImg}
               alt="Course Image"
               className={cn(styles.courseImage, isProgressTable ? styles.hide : '')}
            />

           
            <div className={cn(styles.course_with_image, isProgressTable ?styles.no_margin  : '')}>
               <CustomTooltip
                  id={row[column]}
                  limit={maxWordsLength}
                  text={row[column]}
                  maxWidth="500px"
               />
            </div>
         </div>
      );
   } else if (column === "rating_from_users") {
      // Assuming you have a StarRating component that takes a rating prop
      const ratingValue = parseInt(row[column], 10);
      return (
         <div className={styles.rating_wrapper}>
            <span>{ratingValue || ""}</span>
            <TableStars rating={ratingValue} total={5} />
         </div>
      );
   } else if (column === "courses") {
      if (row[column]?.length === 0) return "-";
      const arr = row[column]?.split(",");
      const text = arr?.map((name) => name?.trim())?.join(", ") || row[column];

      return (
         <div className={styles.few_items}>
            <CustomTooltip
               id={row[column]}
               limit={maxWordsLength}
               text={text}
               maxWidth="350px"
            />

            {arr?.length > 1 && <span>+{arr?.length}</span>}
         </div>
      );
   } else {
      return (
         <CustomTooltip
            id={row[column]}
            limit={maxWordsLength}
            text={row[column]}
            maxWidth="500px"
         />
      );
   }
};

const StatisticsBodyItem = ({
   row,
   columns,
   index,
   onRowClick,
   tableFor,
   maxWordsLength = 20,
   rowClickable,
   isProgressTable
}) => {
   const [showProfile, setShowProfile] = useState(false);
   const timeoutRef = useRef(null);

   const handleMouseEnter = () => {
      timeoutRef.current = setTimeout(() => {
        setShowProfile(true);
      }, 500);
    };
  
    const handleMouseLeave = () => {
      clearTimeout(timeoutRef.current);
      setShowProfile(false);
    };

   const navigate = useNavigate();
   const redirectToClickedEl = () => {
      if (row.id) {
         window.open(`/statistics/user/${row.id}`, '_blank', 'noopener,noreferrer');
      }
   };

   return (
      <tr
         onClick={redirectToClickedEl}
         key={`row-${index}`}
         className={cn(
            index % 2 === 0 ? styles.rowOdd : "",
            tableFor === "teams" ? styles.link : "",
            rowClickable ? styles.clickable : ""
         )}
      >
         {columns.map((column, columnIndex) => (
            <td 
               onMouseEnter={(e)=> {
                  if(column === 'user' && tableFor === 'users')
                  handleMouseEnter(e);
               }}
               onMouseLeave={handleMouseLeave}
               key={`cell-${index}-${columnIndex}`}
            >
               <div className={styles.cellContent}>
                  {renderCellContent(
                     column,
                     row,
                     maxWordsLength,
                     tableFor,
                     handleMouseEnter,
                     handleMouseLeave,
                     showProfile,
                     isProgressTable
                  )}
               </div>
            </td>
         ))}
      </tr>
   );
};

export default StatisticsBodyItem;
