import { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import UserApi from "../../utils/api/UserApi";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { DEFAULT_DATA_LIMIT } from "../../constants/user";

const useGetUsersOfFolder = (folderId, companyId, selectedTeamId, serializationGroup) => {
   const [isUsersLoading, setIsUsersLoading] = useState(false);
   const [searchedValue, setSearchedValue] = useState("");
   const [userList, setUserList] = useState([]);
   const [totalUsers, setTotalUsers] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [coursesProgress, setCoursesProgress] = useState([]);

   const fetchUsers = async (page) => {
      setIsUsersLoading(true);

      try {
         const res = await new UserApi().getUsersByFolderID(
            DEFAULT_DATA_LIMIT,
            page ? page : currentPage,
            folderId,
            searchedValue,
            companyId,
            selectedTeamId,
            serializationGroup
         );

         if (res?.success?.data) {
            const users = res?.success?.data || [];

            if (page) {
               setUserList(users);
            } else {
               setUserList((prev) => [...prev, ...users]);
            }

            setTotalUsers(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setUserList([]);
         }
      } catch (error) {
         console.error("Error fetching users:", error);
      } finally {
         setIsUsersLoading(false);
      }
   };

   const debouncedFetchUsers = debounce(
      () => fetchUsers(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchUsers = () => {
      debouncedFetchUsers();
      setCurrentPage(1);
   }

   useEffect(() => {
      if (currentPage > 1 && userList?.length <= totalUsers) {
         fetchUsers();
      }
   }, [currentPage]);

   useEffect(() => {
      if (folderId && companyId) {
         setCurrentPage(1);
         debouncedFetchUsers();
      }

      return () => debouncedFetchUsers.cancel();
   }, [searchedValue, folderId, companyId, selectedTeamId]);

   return {
      isUsersLoading,
      userList,
      totalUsers,
      currentPage,
      fetchUsers,
      debouncedFetchUsers,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      setTotalUsers,
      refetchUsers
   };
};

export default useGetUsersOfFolder;
