import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import Loader from "../Loader/Loader";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";
import arrow from "../../assets/images/symbols/arrow.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import cn from "classnames";
import Checkbox from "../Checkbox/Checkbox";
import crossImg from "../../assets/images/symbols/cross_grey.svg";

const SelectAllDropdown = ({
   data,
   placeholder,
   onChange,
   value = "",
   title = null,
   isWhiteBlack = false,
   maxHeight,
   isRadiobuttons = false,
   dontCloseOnClick = false,
   selectedItems,
   setSelectedItems,
   icon,
   isAddLoading,
   isActive,
}) => {
   const [isOpen, setIsOpen] = useState(false);

   const dropdownRef = useRef();
   const inputRef = useRef();
   const inputRefTop = useRef();

   const t = useFindTranslationsByString();

   const onClose = () => {
      setIsOpen(false);
   };

   const onToggle = () => {
      setIsOpen(!isOpen);
   };

   const onChangeValue = (val) => onChange(val);

   useOutsideClick(dropdownRef, onClose, inputRef);

   return (
     <>
       {title && <p className={styles.title}>{title}</p>}
       <div
         className={cn(styles.dropdown_wrapper, isOpen && styles.opened)}
         onClick={onToggle}
         ref={inputRef}
       >
         <div className={styles.front}>
           <Input
             placeholder={placeholder}
             ref={inputRefTop}
             customClass={isOpen ? styles.input_open : null}
             disabled={true}
             value={value}
             isWhiteBlack={isWhiteBlack}
             icon={icon}
           />
         </div>

         <div className={styles.click_el}>
           <div className={styles.checkbox}>
             <Checkbox
               isChecked={selectedItems?.length}
               isRound={isRadiobuttons}
               extraMargin
               onChange={() => onChangeValue(item)}
               title={""}
             />
           </div>
         </div>

         {isAddLoading && isOpen && (
           <div className={styles.loader_wrapper}>
             <Loader size={"small"} />
           </div>
         )}

         {
           <img
               onClick={(e) => {
                  e.stopPropagation();
                  if(isActive) {
                     setSelectedItems([]);
                     onChange("");
                  }
               }}
             src={isActive ? crossImg:  isWhiteBlack ? arrowWhite : arrow}
             alt={""}
             className={cn(styles.arrow, isOpen && !isActive && styles.arrow_open)}
           />
         }

         {isOpen && (
           <div
             style={{ maxHeight: maxHeight ? maxHeight : "300px" }}
             className={cn(
               styles.dropdown,
               isWhiteBlack && styles.dropdown_isWhiteBlack,
             )}
           >
             {data?.map((item, key) => {
               const classNameActive = cn(
                 styles.dropdown_item,
                 item === value && styles.dropdown_item_active,
               );

               return (
                 <div
                   key={key}
                   onClick={(e) => {
                     if (dontCloseOnClick) e.stopPropagation();
                     onChangeValue(item?.label);
                   }}
                   className={cn(classNameActive, styles.drop_item)}
                 >
                   {t(item?.name)}
                 </div>
               );
             })}
           </div>
         )}
       </div>
     </>
   );
};

export default SelectAllDropdown;
