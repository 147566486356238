import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const Button = ({
  title,
  maxWidth = "100%",
  withoutBorders,
  disabled,
  isBlack,
  image = null,
  onClick,
  isRed,
  isGreen,
  imageRight,
  isGradient,
  small,
  isWrap,
  className = "",
  isHoverPurple
}) => {
  return (
    <div
      className={cn(
        styles.button,
        disabled ? styles.button_disabled : "",
        isBlack ? styles.button_isBlack : "",
        isRed ? styles.button_isRed : "",
        isGreen ? styles.button_isGreen : "",
        withoutBorders ? styles.no_borders : "",
        isGradient ? styles.purple_gradient : "",
        small ? styles.small : "",
        "unselectable",
        isWrap ? styles.wrap : "",
        className,
        isHoverPurple ? styles.button_isHoverPurple : ''
      )}
      style={{ maxWidth: maxWidth }}
      onClick={disabled ? () => {} : onClick}
    >
      {image && !imageRight && (
        <img className={styles.button_img} src={image} alt={""} />
      )}
      {title}
      {image && imageRight && (
        <img className={styles.button_img} src={image} alt={""} />
      )}
    </div>
  );
};

export default Button;
