import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import Button from "../../components/Button/Button";
import LessonApi from "../../utils/api/LessonApi";
import iconBack from "../../assets/images/icon_back.svg";
import {
   LESSON_TYPE_WITHOUT_TESTING_NUMBER,
   LESSON_TYPE_WITH_TESTING_NUMBER,
} from "../../constants/courses";
import Loader from "../../components/Loader/Loader";
import LessonTypeLesson from "./LessonTypeLesson/LessonTypeLesson";

import cn from "classnames";
import styles from "./styles.module.scss";
import LessonTypeTest from "./LessonTypeTest/LessonTypeTest";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import { useSelector } from "react-redux";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const LessonSettingPage = () => {
   const navigate = useNavigate();
   const params = useParams();
   const [lesson, setLesson] = useState({});

   const [inputsData, setInputsData] = useState({});
   const [loading, setLoading] = useState(false);

   const isSideBarFixed = useSelector(state => state.sidebar.isFixed);

   const t = useFindTranslationsByString();

   const initInputsData = () => {
      setInputsData({
         new_name: lesson?.name ? lesson?.name : "",
         new_is_protected: lesson?.isProtected ? 1 : null,
         new_available_date_range: lesson?.availableDateRange ? lesson?.availableDateRange : null,
         new_time_allowed: lesson?.timeAllowed ? lesson?.timeAllowed : '' ,
         new_passing_score: lesson?.passingScore ? lesson?.passingScore : 50,
         new_pass_attempts: lesson?.passAttempts ? lesson?.passAttempts : null,
         new_is_shuffle_answers: lesson?.isShuffleAnswers ? 1 : 0,
         new_is_hard: lesson?.isHard ? 1 : 0,
         new_is_show_test_result: lesson?.isShowTestResult ? 1 : 0,
         new_is_show_test_score: lesson?.isShowTestScore ? 1 : 0,
         new_welcome_message: lesson?.welcomeMessage ? lesson?.welcomeMessage : '',
         new_congratulatory_message: lesson?.congratulatoryMessage ? lesson?.congratulatoryMessage : '',
      });
   };

   const updateLesson = async () => {
      if (!inputsData.new_name.length)
         return toast(<ToastMsg text={"Name cannot be empty"} isError />);

      const updatedInputsData = { ...inputsData };

      if (updatedInputsData.new_pass_attempts === 0 || updatedInputsData.new_pass_attempts === null) {
          delete updatedInputsData.new_pass_attempts;
      }

      if (updatedInputsData.new_available_date_range === '' || updatedInputsData.new_available_date_range === null) {
          delete updatedInputsData.new_available_date_range;
      }
      if (updatedInputsData.new_time_allowed === '' || updatedInputsData.new_time_allowed === null) {
          delete updatedInputsData.new_time_allowed;
      }
      if (updatedInputsData.new_is_protected === null) {
          delete updatedInputsData.new_is_protected;
      }

      const res = await new LessonApi().updateLesson(params?.id, updatedInputsData);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
      }

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }
   };

   const onSave = () => {
      updateLesson();
   };

   const getLesson = async () => {
      setLoading(true);
      const res = await new LessonApi().getOneLesson(params.id, false);

      if (res?.success?.data) {
         setLesson(res?.success?.data);
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
      }

      setLoading(false);
   };

   useEffect(() => {
      getLesson();
   }, []);

   useEffect(() => {
      initInputsData();
   }, [lesson]);

   return (
      <MainLayout>
         <div className={styles.lesson_settings}>
            <div className={cn(styles.settings_header, isSideBarFixed ? styles.sidebar_open : '')}>
               <button className={styles.back_icon} onClick={() => navigate(-1)}>
                  <img src={iconBack} alt="" />
               </button>
               <h2 className={styles.title}>
                  {lesson?.type === LESSON_TYPE_WITH_TESTING_NUMBER ? (
                     t("Task settings")
                  ) : lesson?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER ? (
                     t("Lesson settings")
                  ) : loading ? (
                     <Loader size={"small"} />
                  ) : null}
               </h2>
               <div>
                  <Button title={t("Save Changes")} onClick={onSave} />
               </div>
            </div>
            <div className={styles.settings_wrapper}>
               {lesson?.type === LESSON_TYPE_WITH_TESTING_NUMBER && (
                  <LessonTypeTest
                     inputsData={inputsData}
                     setInputsData={setInputsData}
                     loading={loading}
                  />
               )}

               {lesson?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER && (
                  <LessonTypeLesson
                     inputsData={inputsData}
                     setInputsData={setInputsData}
                     loading={loading}
                  />
               )}
            </div>
         </div>
      </MainLayout>
   );
};

export default LessonSettingPage;
