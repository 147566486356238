import React, { useEffect, useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import TextArea from "../../TextArea/TextArea";
import FolderApi from "../../../utils/api/FolderApi";
import CourseApi from "../../../utils/api/CourseApi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import CertificateApi from "../../../utils/api/CertificateApi";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { setCuratorModalHash } from "../../../store/actions/sidebar";
import { COURSE_LANGUAGES_LIST } from "../../../constants/languages";
import DropdownWithCheckboxes from "../../DropdownWithCheckboxes/DropdownWithCheckboxes";
import useFindTranlsations from '../../../hooks/useFindTranlsations'
import cn from "classnames";

const AddCourseModal = ({ onClose, isOpen, isCourseToFolder }) => {
   const [title, setTitle] = useState("");
   const [desc, setDesc] = useState("");
   const [isDisabled, setIsDisabled] = useState(true);

   const dispatch = useDispatch();
   const userInfo = useSelector((state) => state?.user?.info);
   const navigate = useNavigate();
   const params = useParams();

   const t = useFindTranlsations();

   const [selectedLanguages, setSelectedLanguages] = useState([]);
   const [mainLanguage, setMainLanuage] = useState("");

   const currentCompanyFirstBranchId = useSelector(
      (state) => state.sidebar.currentCompanyBranchId
   );

   const onChangeTitle = (e) => setTitle(e?.target?.value);
   const onChangeDesc = (e) => setDesc(e?.target?.value);

   const addCourseToFolder = async (courseId) => {
      await new FolderApi().addCourseToFolder(params?.id, courseId);
   };

   const addAccessToTheCourse = async (courseId) => {
      await new CourseApi().addOneUserToCourse(
         userInfo?.id,
         courseId,
         {
            status: 1,
         }
      );
   };

   const setDefaultCertificateTemplate = async (courseId) => {
      const data = {
         name: "first",
         language: "en",
         type: "first_type_1",
         color_palette: "1",
         font_family: "Roboto",
         template: "Initial",
      };

      const res = await new CertificateApi().createCertificateTemplate(data);

      if (res?.success?.data?.certificateTemplate) {
         await new CourseApi().editCourseSetting(courseId, {
            new_certificate_template_id:
               res?.success?.data?.certificateTemplate?.id,
         });
      }
   };

   const onCreateCourse = () => {

      if(!mainLanguage?.code) {
         return toast(<ToastMsg text={"Select main language"} isError />);
      }

      const langs = selectedLanguages?.map(lang => lang?.code)?.filter(item => item !== mainLanguage?.code)
      const langsPlusMain = [...langs, mainLanguage?.code];

      setIsDisabled(true);
      const course = {
         branch_id: currentCompanyFirstBranchId,
         name: title,
         status: 1,
         is_linear: 1,
         is_issue_certificate: 1,
         default_language: mainLanguage?.code,
         available_languages: langsPlusMain
      };

      if (desc.length) {
         course.description = desc;
      }

      new CourseApi()
         .createCourse(course)
         .then((res) => {
            if (res?.success?.data) {
               setTitle("");
               setDesc("");
               addAccessToTheCourse(res?.success?.data?.course?.id);
               const uId = uuidv4();

               dispatch(setCuratorModalHash(uId));
               const course = res?.success?.data?.course;

               if (isCourseToFolder) addCourseToFolder(course?.id);
               navigate(
                  `/courses/edit/${course?.id}/modal/${uId}${
                     course?.defaultLanguage
                        ? `/${course?.defaultLanguage}`
                        : "en_US"
                  }/${course?.availableLanguages?.length > 1}`
               );

               setDefaultCertificateTemplate(res?.success?.data?.course?.id);
            }

            if (res?.error?.message) {
               toast(<ToastMsg text={res?.error?.message} isError />);
            }
         })
         .finally(() => setIsDisabled(false));
   };

   useEffect(() => {
      if (title?.trim()?.length) setIsDisabled(false);
      else setIsDisabled(true);
   }, [title, desc]);

   const closeModal = () => {
      onClose();
      setDesc("");
      setTitle("");
      setSelectedLanguages([]);
      setMainLanuage('')
   };

   const onSelectedLanguagesChange = (item) => {
      if (selectedLanguages.some((lang) => lang.id === item.id)) {
         setSelectedLanguages(
            selectedLanguages.filter((lang) => lang.id !== item.id)
         );
      } else {
         setSelectedLanguages([...selectedLanguages, item]);
      }
   };

   return (
      <ModalLayout
         onClose={closeModal}
         isOpen={isOpen}
         maxHeight={"481px"}
         maxWidth={"416px"}
         withCross
      >
         
         <p className={styles.title}>{t?.createNewCourse}</p>
         <div className={styles.lang_dropdown}>
            <DropdownWithCheckboxes
               field={"name"}
               inputClassName={styles.input_height}
               maxHeight={"215px"}
               data={COURSE_LANGUAGES_LIST}
               placeholder={
                  selectedLanguages?.length
                     ? selectedLanguages?.map((item) => item.name)?.join(", ")
                     : t?.selectAvailableLanguages
               }
               dontCloseOnClick
               onChange={onSelectedLanguagesChange}
               selectedItems={selectedLanguages}
            />
         </div>

         <div className={styles.lang_dropdown}>
            <DropdownWithCheckboxes
                isMultiple={false}
                inputClassName={styles.input_height}
               field={"name"}
               maxHeight={"153px"}
               data={COURSE_LANGUAGES_LIST}
               placeholder={
                  mainLanguage?.name
                     ? mainLanguage?.name
                     : t?.selectMainLanguage
               }
                value={mainLanguage?.name}
               isRadiobuttons
               onChange={(value) => setMainLanuage(value)}
               mainItem={mainLanguage}
            />
         </div>

         <Input
            max={40}
            placeholder={t?.title}
            value={title}
            onChangeValue={onChangeTitle}
            withCounter
            customClass={cn(styles.input_height, styles.input)}
         />
         <div className={styles.desc}>
            <TextArea
                textareaClassname={styles.input}
                max={200}
                placeholder={t?.description}
                value={desc}
                onChangeValue={onChangeDesc}
                withCounter
            />
         </div>
         <Button
            title={t?.create}
            disabled={isDisabled}
            onClick={onCreateCourse}
         />
      </ModalLayout>
   );
};

export default AddCourseModal;
