import { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import CertificatesApi from "../../utils/api/CertificateApi";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { DEFAULT_DATA_LIMIT } from "../../constants/user";
import { useSelector } from "react-redux";

const useGetCertificates = (userId, companyId) => {
   const [isLoading, setIsLoading] = useState(true);
   const [searchedValue, setSearchedValue] = useState("");
   const [certificates, setCertificates] = useState([]);
   const [allCertificates, setAllCertificates] = useState([]);
   const [totalCertificates, setTotalCertificates] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [tags, setTags] = useState([]);
   const [order, setOrder] = useState("desc");

   const currentCompanyLogo = useSelector((state) => state.sidebar.companyLogo);
   const currentCompanyName = useSelector((state) => state.sidebar.companyName);

   const mapCertificates = (item) => ({
      ...item,
      course_name: item?.course?.name,
      result: item?.result,
      serial_number: item?.serialNumber,
      user_name: `${item?.user?.firstName} ${item?.user?.lastName || ""}`,
      company_logo: currentCompanyLogo,
      company_name: currentCompanyName,
      end_date: item?.dateCompleted,
      color_palette: item?.certificateTemplate?.colorPalette || "1",
      font_family: item?.certificateTemplate?.fontFamily,
      sertificateTemplateId: item?.certificateTemplate?.id,
      language: item?.certificateTemplate?.language,
      name: item?.certificateTemplate?.name,
      type: item?.certificateTemplate?.type,
   });

   const getAllCert = async () => {
      const res = await new CertificatesApi().getUserCertificates(300, 1, userId, companyId);

      if (res?.success?.data) {
         setAllCertificates(res?.success?.data);
      }
   }

   useEffect(()=> {
      if(userId && companyId) {
         getAllCert();
      }
   }, [userId, companyId])

   const fetchCertificates = async (page) => {
      setIsLoading(true);

      try {
         const res = await new CertificatesApi().getUserCertificates(
            DEFAULT_DATA_LIMIT,
            page ? page : currentPage,
            userId,
            companyId,
            searchedValue,
            tags,
            order
         );

         if (res?.success?.data) {
            const certificates = res?.success?.data?.map(mapCertificates) || [];

            if (page) {
               setCertificates(certificates);
            } else {
               setCertificates((prev) => [...prev, ...certificates]);
            }

            setTotalCertificates(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setCertificates([]);
         }
      } catch (error) {
         console.error("Error fetching users:", error);
      } finally {
         setIsLoading(false);
      }
   };

   const debouncedFetchCertificates = debounce(
      () => fetchCertificates(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchCertificates = () => {
      debouncedFetchCertificates();
      setCurrentPage(1);
   };

   useEffect(() => {
      if (currentPage > 1 && certificates?.length <= totalCertificates) {
         fetchCertificates();
      }
   }, [currentPage]);

   useEffect(() => {
      if (companyId && userId) {
         setCurrentPage(1);
         debouncedFetchCertificates();
      }

      return () => debouncedFetchCertificates.cancel();
   }, [searchedValue, userId, companyId, tags, order]);

   return {
      isLoading,
      certificates,
      totalCertificates,
      currentPage,
      fetchCertificates,
      debouncedFetchCertificates,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      setTotalCertificates,
      refetchCertificates,
      setTags,
      tags,
      order,
      setOrder,
      allCertificates
   };
};

export default useGetCertificates;
