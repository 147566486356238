import React, {useState} from 'react';
import styles from './styles.module.scss'
import MainLayout from '../../layouts/MainLayout/MainLayout';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

const VerifiedPage = () => {
   const navigate = useNavigate();

    return (
        <MainLayout>
            <div className={styles.wrapper}>
               <h2>Email has been successfuly verified!</h2>
               <Button onClick={()=> navigate('/home')} isBlack title={"Go to main page"}/>
            </div>
        </MainLayout>
    );
};


export default VerifiedPage;