import React, { useState } from "react";
import styles from "./styles.module.scss";
import searchIcon from "../../assets/images/symbols/Search.svg";
import notFound from "../../assets/images/symbols/nothing_found.svg";
import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
import logoPlaceholder from "../../assets/images/favicon.png";
import plus from "../../assets/images/symbols/plus.svg";
import cn from 'classnames';
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const SelectEntities = ({
   setSelectedData,
   selectedData,
   dataList,
   isDataLoading,
   frontTitle,
   emptyMsg,
   lastAgencyRef
}) => {
   const [search, setSearch] = useState("");
   const [isOpen, setIsOpen] = useState(false);

   const t = useFindTranslationsByString();

   const hideBlock = () => setIsOpen(false);
   const showBlock = () => setIsOpen(true);

   const onSelectFilters = (agency) => {
      if (selectedData?.find((item) => item?.id === agency?.id))
         setSelectedData(
            selectedData?.filter((item) => item?.id !== agency?.id)
         );
      else setSelectedData([...selectedData, agency]);
   };

   const filterBySearch = (list) =>
      list?.filter((item) =>
         search?.length
            ? item?.name?.toLowerCase()?.includes(search?.toLowerCase())
            : true
      );

   if (!isOpen) {
      return (
         <div className={styles.front_block}>
            <div className={styles.front_header}>
               <p>{frontTitle}</p>
               <img onClick={showBlock} src={plus} alt="" />
            </div>

            <div>
               {selectedData?.map((item, key) => (
                  <div
                     key={key}
                     onClick={() => onSelectFilters(item)}
                     className={cn(styles.front_item, styles.list_item)}
                  >
                   <div className={styles.item_inside}>
                     <img
                           src={`${
                              item?.logo
                                 ? item.logo
                                 : logoPlaceholder
                           }`}
                           alt=""
                        />
                        {item?.name}
                   </div>

                     <div className={styles.delete_wrapepr}>
                        <img src={plus} alt="" />
                     </div>
                  </div>
               ))}

               {selectedData?.length === 0 && 
                  <EmptyBlock text={emptyMsg} classNames={styles.empty_block} />
               }
            </div>
         </div>
      );
   }

   return (
      <div className={styles.select_wrapper}>
         <div className={styles.search}>
            <img src={searchIcon} alt={""} />
            <input
               placeholder={t("Search")}
               value={search}
               onChange={(event) => setSearch(event?.target?.value)}
            />
         </div>
         <p className={styles.selected}>
            {t("Selected")}: <span>{selectedData?.length}</span>
         </p>

         {!!filterBySearch(dataList)?.length ? (
            <div className={styles.list}>
               {filterBySearch(dataList)?.map((item, key) => (
                  <div
                     ref={lastAgencyRef && dataList?.length - 1 === key ? lastAgencyRef : null}
                     key={key}
                     onClick={() => onSelectFilters(item)}
                     className={styles.list_item}
                  >
                     <Checkbox
                        isChecked={selectedData?.some(
                           (sItem) => sItem?.id === item?.id
                        )}
                        isGrey
                     />
                     <img
                        src={`${
                           item?.logo
                              ? item.logo
                              : logoPlaceholder
                        }`}
                        alt=""
                     />
                     {item?.name}
                  </div>
               ))}
            </div>
         ) : (
            <div className={styles.notFound}>
               <img src={notFound} alt={""} />
               {t("Not found")}
            </div>
         )}

         <div className={styles.btn_wrapper}>
            <Button
               title={t("Cancel")}
               isBlack
               onClick={() => {
                  if (setSelectedData) setSelectedData([]);
                  hideBlock();
               }}
            />
            <Button title={t("Confirm")} onClick={hideBlock} />
         </div>
      </div>
   );
};

export default SelectEntities;
