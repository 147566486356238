import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import ProfileBodyItem from "./ProfileBodyItem";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import useSortData from "../../../hooks/useSortData";
import { useSelector } from "react-redux";
import useUserRole from "../../../hooks/useUserRole";
import { ROLE_ID_STUDENT } from '../../../constants/user';
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ProfileTable = ({ columns, data, tableFor, loading, lastAgencyRef }) => {
   const [clickedUserId, setClickedUserId] = useState(null);
   const { sortedData, handleSort } = useSortData(data, columns);

   const t = useFindTranslationsByString();

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId);

   const navigate = useNavigate();

   const onRowClick = (id, course) => {
      if (tableFor === "teams" && userRoleId && userRoleId !== ROLE_ID_STUDENT) {
         window.open(`/users/team/${id}`, '_blank');
      };

      if(tableFor === "curator") {
         const courseEditLink = `/courses/edit/${course?.id}/default_lang/${course?.defaultLanguage || 'en_US'}/${course?.availableLanguages?.length > 1}`;

         window.open(courseEditLink, '_blank');
      }

      return;
   };

   if (sortedData?.length === 0 && !loading) {
      return <EmptyBlock text={"data"} />;
   }

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={styles.header}
                     >
                        <span>{t(column?.replace("_", " "))}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {sortedData?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <ProfileBodyItem
                              tableFor={tableFor}
                              row={row}
                              columns={columns}
                              index={index}
                              onRowClick={onRowClick}
                              lastItemRef={sortedData?.length - 1 === index && lastAgencyRef ? lastAgencyRef : null }
                           />
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
            </tbody>
         </table>
      </div>
   );
};

export default ProfileTable;
