import Base from "./Base";

export default class ModuleApi extends Base {
    getYourModules() {
        return super.get("api/v1/module/get-your-modules");
    }

    getYourOngoingModules() {
        return super.get("api/v1/module/get-your-ongoing-modules");
    }

    getYourCompletedModules() {
        return super.get("api/v1/module/get-your-completed-modules");
    }

    getModulesInCourse(courseID, serializationGroup, languageHeader) {
      return super.get(`api/v1/course/${courseID}/module`, null, null, serializationGroup, languageHeader);
    }
    
    getYourModulesInCourse(courseID, serializationGroup, languageHeader) {
      return super.get(`api/v1/course/${courseID}/get-your-modules
      `, null, null, serializationGroup, languageHeader);
    }
    
    createModule(data) {
      return super.post("api/v1/module", data);
    }

    updateModule(moduleID, data) {
      return super.put(`api/v1/module/${moduleID}`, data);
    }
    
    changeModulesSequence(data) {
      return super.put(`api/v1/module/change-sequences`, data);
    }

    deleteModule(moduleID) {
      return super.delete(`api/v1/module/${moduleID}`);
    }
}