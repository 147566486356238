import React from "react";
import dots from "../../../../assets/images/symbols/three_dots_white.svg";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import useOptions from "../../../../hooks/useOptions";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.scss";
import arrow from "../../../../assets/images/symbols/Arrow - Down.svg";

import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE_ID_SUPER_ADMIN } from "../../../../constants/user";
import {
   STUDENT_STATUS_ACTIVE,
   STUDENT_STATUS_DELETED,
   STUDENT_STATUS_PAUSED,
} from "../../../../constants/courses";
import useUserRole from "../../../../hooks/useUserRole";
import ProgressLine from "../../../../components/ProgressLine/ProgressLine";

const CoursesOrTopicsBodyItem = ({
   row,
   columns,
   index,
   tableFor,
   openConfirmModal,
   onOpenStatusModal,
   lastItemRef,
   isCourses,
   confirmDeleteFromFolderModalOpen,
   onConfirmDeleteOpen,
   onConfirmHideOpen,
   onConfirmOpenRestore,
   onConfirmOpenDelete,
   onConfirmOpenChangeHidenStatus,
   onOpenRenameModal,
   activeStatusFilter,
   t
}) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   const companyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId } = useUserRole(companyId);

   const navigate = useNavigate();

   const redirectToUserProfileAdminView = (id) => {
      navigate(`/users/profile/${id}`);
   };

   const redirectToCourseSettingsPage = (id) => {
      navigate(`/courses/settings/${id}`);
   };

   const redirectToCourseEditPage = (course) => {
      const editLink = `/courses/edit/${course?.id}/default_lang/${course?.defaultLanguage || 'en_US'}/${course?.availableLanguages?.length > 1}`;

      navigate(editLink);
   };

   return (
      <tr
         ref={lastItemRef}
         key={`row-${index}`}
         className={cn(index % 2 === 0 ? styles.rowOdd : "")}
      >
         {columns?.map((column, columnIndex) => (
            <React.Fragment key={`cell-${index}-${columnIndex}`}>
               <td>
                  <div className={styles.cellContent}>
                     <CustomTooltip
                        id={row[column?.field]}
                        text={row[column?.field]}
                        limit={40}
                     />
                  </div>
               </td>
            </React.Fragment>
         ))}

         <td className={styles.option_wrapper}>
            <img
               ref={dotsRef}
               onClick={() => toggleOptions()}
               src={dots}
               alt={"options"}
            />

            {isOptionsOpen && (
               <div ref={dropdownRef} className={styles.dropdown}>
                  <p
                     className={styles.options}
                     onClick={() => redirectToUserProfileAdminView(row?.id)}
                  >
                     {t?.deleteFromFolder}
                  </p>
               </div>
            )}
            {isOptionsOpen && (
               <div ref={dropdownRef} className={styles.dropdown}>
                  {isCourses && (
                     <>
                        <p
                           className={styles.options}
                           onClick={() => redirectToCourseSettingsPage(row?.id)}
                        >
                           {t?.settings}
                        </p>
                        <p
                           className={styles.options}
                           onClick={() => redirectToCourseEditPage(row)}
                        >
                           {t?.edit}
                        </p>
                        <p
                           className={styles.options}
                           onClick={() =>
                              confirmDeleteFromFolderModalOpen(row?.id)
                           }
                        >
                           {t?.deleteFromFolder}
                        </p>
                     </>
                  )}

                  {!isCourses && (
                     <>
                        <p
                           onClick={(e) =>
                              onOpenRenameModal(row?.id)
                           }
                        >
                           {t?.rename}
                        </p>
                        {activeStatusFilter === "hidden" && (
                           <p
                              onClick={(e) =>
                                 onConfirmOpenChangeHidenStatus(e, row)
                              }
                           >
                              {t?.changeToVisible}
                           </p>
                        )}

                        {activeStatusFilter === "deleted" && (
                           <>
                              <p
                                 onClick={(e) => onConfirmOpenRestore(e, row)}
                              >
                                 {t?.restore}
                              </p>
                              <p onClick={(e) => onConfirmOpenDelete(e, row)}>
                                 {t?.delete}
                              </p>
                           </>
                        )}

                        {activeStatusFilter !== "deleted" && (
                           <p onClick={(e) => onConfirmDeleteOpen(e, row)}>
                              {t?.delete}
                           </p>
                        )}

                        {activeStatusFilter !== "hidden" && (
                           <p onClick={(e) => onConfirmHideOpen(e, row)}>
                              {t?.hide}
                           </p>
                        )}
                     </>
                  )}
               </div>
            )}
         </td>
      </tr>
   );
};

export default CoursesOrTopicsBodyItem;
