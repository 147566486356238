import { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { mapAgenciesWithNewLinks, returnTemporaryCompaniesLinks, returnTemporaryLinks } from "../../utils/usersHelper";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { BIGGER_DATA_LIMIT, DEFAULT_DATA_LIMIT } from "../../constants/user";
import AgencyApi from "../../utils/api/AgencyApi";
import { COMPANY_EXTRA_GROUP_TWO } from "../../utils/api/serialization_groups";

const useGetAgencies = (userId) => {
   const [isAgenciesLoading, setIsAgenciesLoading] = useState(false);
   const [searchedValue, setSearchedValue] = useState("");
   const [agenciesList, setAgenciesList] = useState([]);
   const [totalAgencies, setTotalAgencies] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [selectAll, setSelectAll] = useState(false);

   const abortController = new AbortController();
   const signal = abortController.signal;

   const fetchAgencies = async (page) => {
      setIsAgenciesLoading(true);

      try {
         const res = await new AgencyApi().getAgencies(
            page ? page : currentPage,
            selectAll ? BIGGER_DATA_LIMIT : DEFAULT_DATA_LIMIT,
            COMPANY_EXTRA_GROUP_TWO,
            searchedValue,
            signal
         );

         if (res?.success?.data) {
            const links = await returnTemporaryCompaniesLinks(userId, res?.success?.data?.filter(company => company?.companyLogo?.iconLogo));

            const agenciesWithUpdatedLinks = res?.success?.data?.map((agency) => mapAgenciesWithNewLinks(agency, links));
            
            if (page) {
               setAgenciesList(agenciesWithUpdatedLinks);
            } else {
               setAgenciesList((prev) => [...prev, ...agenciesWithUpdatedLinks]);
            }

            setTotalAgencies(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setAgenciesList([]);
         }
      } catch (error) {
         console.error("Error fetching agencies:", error);
      } finally {
         setIsAgenciesLoading(false);
      }
   };

   const debouncedFetchAgencies = debounce(
      () => fetchAgencies(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchAgencies = () => {
      setCurrentPage(1);
      debouncedFetchAgencies();
   }

   useEffect(() => {
      if (currentPage > 1 && agenciesList?.length <= totalAgencies) {
         fetchAgencies();
      }
   }, [currentPage]);

   useEffect(() => {
      if (userId) {
         setCurrentPage(1);
         debouncedFetchAgencies();
      }

      return () => debouncedFetchAgencies.cancel();
   }, [searchedValue, userId]);

   useEffect(()=> {
      return () => {
         abortController.abort();
         setSearchedValue('')
         setAgenciesList([]);
      }
   }, [])

   const clearAllAgenciesFilters = () => {
      if(searchedValue) setSearchedValue('');
   };

   useEffect(() => {
      if (selectAll && agenciesList?.length !== totalAgencies && agenciesList?.length < BIGGER_DATA_LIMIT) {
         setCurrentPage(1);
         debouncedFetchAgencies();
      }

      return () => debouncedFetchAgencies.cancel();
   }, [selectAll]);

   return {
      isAgenciesLoading,
      clearAllAgenciesFilters,
      agenciesList,
      totalAgencies,
      currentPage,
      refetchAgencies,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      selectAll,
      setSelectAll
   };
};

export default useGetAgencies;
