import { TEST_STATUS_APPROVED, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED } from "../../../../constants/tests";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";
import { QUESTIONS_THAT_CAN_BE_AUTOCHECKED_AND_NOT_CHOOSEN } from "../../../../utils/questionsHelper";
import styles from "./styles.module.scss";
import cn from "classnames";

const CheckQuestionResult = ({ question, correctImg, iconWrong, partiallyCorrectIcon, manualChecked }) => {

   const t = useFindTranslationsByString();

   return (
      <div className={styles.auto_check_wrapper}>
         <div className={styles.auto_check_title}>
            {manualChecked ? t('Grade for the task') : t('Automatic grading for task')} 
            {question?.status === 0 && <p className={styles.no_check}>{t("On check")}*</p>}
         </div>
         <div className={styles.complete_btns}>
            <button disabled className={cn(styles.button_true, question.status === TEST_STATUS_APPROVED ? styles.active_true: '')}>
               <img src={correctImg} alt="" />
               <span>{t("True")}</span>
            </button>
            <button disabled className={cn(styles.button_partly, question.status ===  TEST_STATUS_ON_REWORK ? styles.active_partly: '')}>
               <img src={partiallyCorrectIcon} alt="" />
               <span>{t("Partially")}</span>
            </button>
            <button disabled className={cn(styles.button_false, question.status === TEST_STATUS_REJECTED || (question.status === 0 &&  (QUESTIONS_THAT_CAN_BE_AUTOCHECKED_AND_NOT_CHOOSEN.includes(question?.type) && !manualChecked)) ? styles.active_wrong : '')}>
               <img src={iconWrong} alt="" />
               <span>{t("False")}</span>
            </button>
         </div>
      </div>
   );
};

export default CheckQuestionResult;
