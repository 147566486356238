import React, { useEffect } from "react";
import Modal from "react-modal";
import { customStylesRight } from "./custumModalStyles";
import cross from "../../assets/images/symbols/cross_grey.svg";
import styles from "./styles.module.scss";
import cn from "classnames";

const NotificationModalLayout = ({
   isOpen,
   onClose,
   children,
   maxWidth,
   maxHeight,
   withCross,
   withScroll = false,
   customPadding = null,
   visibleOverflow = false,
}) => {
   useEffect(() => {
      Modal.setAppElement("body");
   }, []);

   useEffect(() => {
      if (isOpen && !withScroll) {
         document.body.style.overflowY = "hidden";
      } else {
         document.body.style.overflowY = "auto";
      }

      // Cleanup function to remove the style when the component unmounts or modal is closed
      return () => {
         document.body.style.overflowY = "auto";
      };
   }, [isOpen]);

   return (
      <Modal
         isOpen={isOpen}
         onRequestClose={onClose}
         style={{
            ...customStylesRight,
            content: {
               ...customStylesRight?.content,
               maxWidth: "100%",
               maxHeight: "100%",
               ...(withScroll ? { overflowY: "scroll" } : {}),
               ...(visibleOverflow ? { overflow: "visible" } : {}),
               padding: customPadding ? customPadding : "40px",
               height: "100%",
               zIndex: "-1",
            },
         }}
      >
         <div
            className={styles.modal}
            onClick={onClose}
         >
            {withCross && (
               <div className={cn(styles.crossNotification)}>
                  <img src={cross} alt={""} onClick={onClose} />
               </div>
            )}
            <div
               style={{
                  height: maxHeight,
                  background: "#28292B",
                  right: isOpen ? "0" : "-100%",
                  transition: 'all 1s ease',
                  position: "absolute",
               }}
               className={cn(maxWidth ? styles.maxWidth : '')}
               onClick={(e) => e.stopPropagation()}
            >
               {children}
            </div>
         </div>
      </Modal>
   );
};

export default NotificationModalLayout;
