import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import searchIcon from '../../../assets/images/symbols/Search.svg';
import exclMark from '../../../assets/images/icon_info.svg';
import avatarPlaceholder from '../../../assets/images/avatar.svg';
import notFound from '../../../assets/images/symbols/nothing_found.svg';
import Button from "../../Button/Button";
import EmailsInput from "../../EmailsInput/EmailsInput";
import Checkbox from "../../Checkbox/Checkbox";
import PaginatedDropdown from '../../PaginatedDropdown/PaginatedDropdown';
import Loader from "../../Loader/Loader";
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';
import cn from 'classnames';
import useGetDepartments from "../../../hooks/api/departmentsAndPostitions/useGetDepartments";
import useGetPositions from "../../../hooks/api/departmentsAndPostitions/useGetPositions";
import { useSelector } from "react-redux";

const SelectUserModal = ({isOpen, onClose, onConfirm, setSelectedUsers, selectedUsers, usersList, oneStep, lastUserRef = null, userSearchValue, setUserSearchValue, hint, isAddUsersToTeam, isDataLoading, isSearchByEmailsNeeded, setEmails, selectedDepartment, setSelectedDepartment, selectedPosition, setSelectedPosition}) => {
    const companyId = useSelector(state => state.sidebar.companyId)

    const t = useFindTranslationsByString();

    const onSelectDepartment = (department) => {
      setSelectedDepartment(department);
    };

    const onSelectPosition = (position) => {
      setSelectedPosition(position);
    }

    const onSelectFilters = (course) => {
        if (selectedUsers?.find(item => item?.id === course?.id)) setSelectedUsers(selectedUsers?.filter(item => item?.id !== course?.id))
        else setSelectedUsers([...selectedUsers, course])
    }

    const closeModal = () => {
      onClose();
      setSelectedDepartment(null);
      setSelectedPosition(null);
      if(oneStep) setSelectedUsers([]);
    }

    const {
      data: departments,
      lastItemRef: lastFilterDepartmentRef,
      searchedValue: filterSearchedValueDep,
      setSearchedValue: setFilterSearchedValueDep,
   } = useGetDepartments({
      companyId: companyId,
   });

   const {
      data: positions,
      lastItemRef: lastFilterPositionRef,
      searchedValue: filterSearchedValuePos,
      setSearchedValue: setFilterSearchedValuePos,
      refetch: refetchFilterPositions,
   } = useGetPositions({
      companyId: companyId,
   });

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={closeModal}
            maxHeight={isSearchByEmailsNeeded ? '655px' : '600px'}
            maxWidth={'500px'}
            withCross
        >
            <div className={styles.title}>{t("Select students")} 
               {hint && 
                  <div className={styles.exp_mark}>
                     <span className={styles.hint}>{t(hint)} </span>
                     <img src={exclMark} alt="" />
                  </div>
               }
            </div>
            <div className='default_loader_wrapper'>
               {isDataLoading && 
                  <Loader size={"small"} />
               }
            </div>
            <p className={styles.selected}>{t("Selected")} : <span>{selectedUsers?.length}</span></p>
            <div className={styles.search}>
                <img src={searchIcon} alt={''}/>
                <input
                    placeholder={t('Search')}
                    value={userSearchValue}
                    onChange={(event) => setUserSearchValue(event?.target?.value)}
                />
            </div>

         <div className={styles.dropdowns}>
            <div className={styles.dropdown}>

               <PaginatedDropdown
                  data={departments}
                  field={"name"}
                  value={selectedDepartment?.name}
                  placeholder={t("Select by department")}
                  onChange={onSelectDepartment}
                  maxHeight={"220px"}
                  searchNeeded
                  crossNeeded
                  searchValue={filterSearchedValueDep}
                  setSearchValue={setFilterSearchedValueDep}
                  lastItemRef={lastFilterDepartmentRef}
               />
            </div>
            <div className={styles.dropdown}>
               <PaginatedDropdown
                  data={positions}
                  maxHeight={"180px"}
                  field={"name"}
                  value={selectedPosition?.name}
                  placeholder={t("Select by Position")}
                  onChange={onSelectPosition}
                  searchNeeded
                  crossNeeded
                  searchValue={filterSearchedValuePos}
                  setSearchValue={setFilterSearchedValuePos}
                  lastItemRef={lastFilterPositionRef}
               />
               </div>
         </div>

            {isSearchByEmailsNeeded && 
               <EmailsInput
                  placeholder={t("Search by emails")} 
                  setEmails={setEmails}
               />
            }

            {
                !!usersList?.length
                    ? <div className={styles.list}>
                        {usersList?.map((item, key) =>
                            <div ref={ usersList?.length - 1 === key ? lastUserRef : null} key={key} onClick={() => onSelectFilters(item)} className={styles.list_item}>
                                <Checkbox isChecked={selectedUsers?.some(sItem => sItem?.id === item?.id)} isGrey/>
                                <img
                                 src={`${item.avatar ? item.avatar : avatarPlaceholder}`}
                                 alt=""
                                 />
                                {item?.firstName || 'no name'} {item?.lastName}
                            </div>)}
                    </div>
                    : <div className={styles.notFound}>
                        <img src={notFound} alt={''}/>
                        {t("Not found")} 
                    </div>
            }

            <Button disabled={selectedUsers.length === 0 || isDataLoading} title={t('Confirm')} onClick={onConfirm ? ()=> {
               if(!isAddUsersToTeam) 
                  closeModal();
               onConfirm();
            }  : onClose}/>

        </ModalLayout>
    );
};

export default SelectUserModal;