import React, { useState } from "react";
import styles from "./styles.module.scss";
import magicImg from '../../assets/images/magic_img.png';
import plus from "../../assets/images/symbols/plus.svg";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const NoEntityAdded = ({ action, entity }) => {

   const t = useFindTranslationsByString();

   return (
      <div className={styles.no_entity_block}>
         <img
            className={styles.create_img}
            src={magicImg}
            alt="create"
         />
         <div onClick={action} className={styles.add}>
            <img src={plus} alt={""} />
            {t("Add")} {t(entity)}
         </div>
      </div>
   );
};

export default NoEntityAdded;
