export const AGENCY_SETTINGS_SUBMENU = [
   {
      id: 1,
      text: "Basic info",
      el: "basicInfo",
   },
   {
      id: 2,
      text: "Upload logotypes",
      el: "uploadLogo",
   },
   // FOR FUTURE / be careful order is important here
   // last tab (end_of_course) should be changed to 6
   // {
   //    id: 3,
   //    text: "Domain",
   //    el: "domain",
   // },
   // {
   //    id: 4,
   //    text: "Sending emails",
   //    el: "sending_emails",
   // },
   // {
   //    id: 5,
   //    text: "Integration",
   //    el: "integration",
   // },
   {
      id: 3,
      text: "Security",
      el: "end_of_course",
   },
];

export const TIME_ZONES = ['Europe/Lisbon', 'Europe/Paris', 'Europe/Bucharest']

export const UPLOAD_LARGE_LOGO_TEXT = "Up to 1 MB & 1400x240px (PNG or JPG)"
export const UPLOAD_SMALL_LOGO_TEXT = "Up to 500 KB & 400x400px (PNG or JPG)"


export const USERS_AND_ROLES_COLUMNS = [
   "name",
   "email",
 ];