import React, {useState, useRef} from 'react';
import styles from './styles.module.scss'
import copyImg from "../../assets/images/symbols/copy.svg";

const CopyInput = ({text, value, customClass, titleAfterCopied}) => {
    const [isCopied, setIsCopied] = useState(false)

    const textRef = useRef()

    const setInputText = (inputText) => {
        if (inputText?.length > 26) {
            return inputText?.slice(0, 26) + '...'
        } else return inputText
    }

    const onCopyImg = () => {
        if (isCopied) return

        navigator.clipboard.writeText(textRef?.current?.value).then(() => {
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 3000)
        })
    }

    return (
        <>
            <div onClick={onCopyImg} className={`${styles.copyInput} ${customClass ? customClass : ''}`}>
                <span>{isCopied ? titleAfterCopied : setInputText(text || value)}</span>
                <input value={value} ref={textRef} hidden onChange={() => {}}/>
                {!isCopied && <img src={copyImg} alt={'copy'}/>}
            </div>
        </>
    );
};

export default CopyInput;
