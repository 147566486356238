import React, { useState } from "react";
import styles from "./styles.module.scss";

const FilterRadio = ({ withCount, title, count, active, onClick }) => {
   return (
      <div
         className={`${styles.filterRadio} ${active ? styles.active : ""}`}
         onClick={onClick}
      >
         <div
            className={`${styles.customCircle} ${active ? styles.active : ""}`}
         ></div>
         <div className={styles.title}>{title}</div>
         {withCount && <div className={styles.count}>{count}</div>}
      </div>
   );
};

export default FilterRadio;
