import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setConfirmModalNeeded } from "../store/actions/sidebar";
import useHandleModal from './dom/useHandleModal';

const useConfirm = () => {
   const [intendedPagePath, setIntendedPagePath] = useState(null);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const confirmModal = useHandleModal();

   const isUploadingFiles = useSelector(
      (state) => state?.sidebar?.confirmModalNeeded
   );

   const handleLinkClick = (e, intendedPath) => {
      if (isUploadingFiles) {
         e.preventDefault();
         confirmModal.open();
         setIntendedPagePath(intendedPath);
      } else {
         navigate(intendedPath)
      }
   };

   const redirectToPage = () => {
      confirmModal.close();
      dispatch(setConfirmModalNeeded(false));
      navigate(intendedPagePath);
   };

   return { handleLinkClick, redirectToPage, confirmModal, dispatch, setConfirmModalNeeded };
};

export default useConfirm;
