import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import searchIcon from '../../../assets/images/symbols/Search.svg';
import notFound from '../../../assets/images/symbols/nothing_found.svg';
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import logoPlaceholder from '../../../assets/images/favicon.png';
import Loader from '../../Loader/Loader';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';
import useFindTranslations from '../../../hooks/useFindTranlsations';

const SelectAgencyModal = ({ isOpen, onClose, setSelectedAgencies, selectedAgencies, agenciesList, onAddAgencies, isDataLoading, refetch, agencyManager, lastAgencyRef }) => {

   const t = useFindTranslations();

    const onSelectFilters = (agency) => {
        if (selectedAgencies?.find(item => item?.id === agency?.id)) setSelectedAgencies(selectedAgencies?.filter(item => item?.id !== agency?.id))
        else setSelectedAgencies([...selectedAgencies, agency])
    }

    const closeModal = () => {
      onClose();
      agencyManager?.clearAllAgenciesFilters();
    }

    const toggleSelectAll = () => {
      agencyManager.setSelectAll(!agencyManager.selectAll);
      if(agencyManager.selectAll) setSelectedAgencies([]);
   };

  useEffect(()=> {
   if(agencyManager?.isAgenciesLoading) return;

   if (agencyManager?.selectAll) {
      setSelectedAgencies(agenciesList);
   } else {
      setSelectedAgencies(selectedAgencies);
   }
  }, [agencyManager?.selectAll, agencyManager?.isAgenciesLoading])

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={closeModal}
            maxHeight={'555px'}
            maxWidth={'426px'}
            withCross
        >
            <p className={styles.title}>{t?.selectCompany}</p>
            <p className={styles.selected}>{t?.selected}: <span>{selectedAgencies?.length}</span></p>
            <div className={styles.search}>
                <img src={searchIcon} alt={''}/>
                <input
                    placeholder={t?.search}
                    value={agencyManager.searchedValue}
                    onChange={(event) => agencyManager?.setSearchedValue(event?.target?.value)}
                />
            </div>

            {agenciesList?.length > 0 && 
               <div className={styles.selectAllButton} onClick={toggleSelectAll}>
                  <Checkbox isChecked={agencyManager?.selectAll} isGrey />
                  <span>{t?.selectAll}</span>
               </div>
            }

            {
                !!agenciesList?.length
                    ? <div className={styles.list}>
                        {agenciesList?.map((item, key) =>
                            <div ref={lastAgencyRef && agenciesList?.length - 1 === key ? lastAgencyRef : null} key={key} onClick={() => onSelectFilters(item)} className={styles.list_item}>
                                <Checkbox isChecked={selectedAgencies?.some(sItem => sItem?.id === item?.id)} isGrey/>
                                <img
                                 src={`${item?.logo ? item.logo : logoPlaceholder}`}
                                 alt=""
                                 />
                               <CustomTooltip text={item?.name} id={item?.id} limit={17}/>
                            </div>)}
                    </div>
                    : <div className={styles.notFound}>
                        <img src={notFound} alt={''}/>
                        {t?.thereAreNoData}
                    </div>
            }

            <div className={styles.btn_wrapper}>
             <Button title={t?.cancel} isBlack onClick={closeModal}/>
             <Button disabled={isDataLoading || !selectedAgencies?.length} title={t?.confirm} onClick={()=> {
               onAddAgencies().then(()=> {
                  refetch();
                  agencyManager?.clearAllAgenciesFilters();
               });
             }}/>

             {isDataLoading && 
               <div className='default_loader_wrapper'>
                  <Loader size={"small"} />
               </div>
             }
            </div>

        </ModalLayout>
    );
};

export default SelectAgencyModal;