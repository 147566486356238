import React from "react";
import styles from "./styles.module.scss";
import email from "../../assets/images/email.svg";
import { validateEmail } from "../../utils";
import { useState } from "react";
import { INIT_FORGET_PASSWORD_FORM } from "../../constants/init";
import UserApi from "../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../ToastMsg/ToastMsg";
import Loader from "../Loader/Loader";
import useFindTranlsations from "../../hooks/useFindTranlsations";

export default function ForgetPassword({ onLogin }) {
   const [formData, setFormData] = useState(INIT_FORGET_PASSWORD_FORM);
   const [successMsg, setSuccessMsg] = useState("");
   const [isLoading, setIsLoading] = useState(false);

   const isInvalidEmail = !validateEmail(formData?.email);
   const t = useFindTranlsations();

   const onChangeValue = (event) =>
      setFormData({ ...formData, [event?.target?.name]: event?.target?.value });
   const onSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);

      if (validateEmail(formData?.email)) {
         const res = await new UserApi().resetPassword({
            email: formData?.email,
         });

         if (res?.success?.message) {
            toast(<ToastMsg text={res?.success?.message} />);
            setSuccessMsg(res?.success?.message);
         }

         if (res?.error?.message) {
            toast(<ToastMsg text={res?.error?.message} isError />);
         }
      }

      setIsLoading(false);
   };

   if (successMsg?.length) {
      return (
         <div className={styles.wrapper}>
            <div>
               <img src={email} alt="" />
            </div>

            <p className={styles.title}>Check your mailbox</p>
            <p className={styles.subtitle}>We have sent the instructions to your mail, please check it.</p>

            <p className={styles.authorization_block__details}>
               <span
                  className={styles.authorization_block__link}
                  onClick={onLogin}
               >
                  {t?.goBack}
               </span>
            </p>
         </div>
      );
   }

   return (
      <div className={styles.authorization_block}>
         <div className={styles.sign_in_text}>Reset Password</div>
         <form onSubmit={onSubmit} className={styles.authorization_form}>
            <fieldset className={styles.authorization_form__fieldset}>
               <p className={styles.input_wrap}>
                  <input
                     className={styles.authorization_form__input}
                     type="email"
                     required
                     placeholder={t?.email}
                     name="email"
                     value={formData?.email}
                     onChange={onChangeValue}
                  />
               </p>
            </fieldset>

            <button
               disabled={isLoading}
               type="submit"
               className={styles.authorization_form__submit}
            >
               {isLoading ? <Loader size={"small"} /> : "Reset"}
            </button>
         </form>
         <p className={styles.authorization_block__details}>
            {t?.changedMind}
            <span
               className={styles.authorization_block__link}
               onClick={onLogin}
            >
               {t?.goBack}
            </span>
         </p>
      </div>
   );
}
