import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import RightsSubheader from "../RightsSubheader/RightsSubheader";
import SwitchItem from "../SwitchItem/SwitchItem";
import { usersRightsData, usersRightsDataFeedback, usersRightsDataSettings } from "../../../constants/user";
import Roles from "../../../utils/api/Roles";

const SettingItems = ({ activeRole, inputsData, setInputsData, t }) => {


   const handleChangeValue = (name) => {
      setInputsData((prevData) => ({
         ...prevData,
         [name]: !prevData[name],
      }));
   };

   return (
      <div className={styles.main_settings}>
         <h2 className={styles.settings_title}>Main settings</h2>
         {usersRightsData.map((item) => (
            <SwitchItem
               key={item.name}
               value={inputsData[item.name]}
               changeValue={() => handleChangeValue(item.name)}
               title={t?.returnTranslation(item.title)}
               text={t?.returnTranslation(item.text)}
            />
         ))}
         {/* FOR FUTURE <h2 className={styles.settings_title}>Feedback</h2>
         {usersRightsDataFeedback.map((item) => (
            <SwitchItem
               key={item.name}
               value={inputsData[item.name]}
               changeValue={() => handleChangeValue(item.name)}
               title={item.title}
               text={item.text}
            />
         ))} */}
         <h2 className={styles.settings_title}>Settings</h2>
         {usersRightsDataSettings.map((item) => (
            <SwitchItem
               key={item.name}
               value={inputsData[item.name]}
               changeValue={() => handleChangeValue(item.name)}
               title={t?.returnTranslation(item.title)}
               text={t?.returnTranslation(item.text)}
            />
         ))}
      </div>
   );
};

export default SettingItems;
