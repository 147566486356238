import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
   PROFILE_BASIC_SETTINGS_SUBMENU,
   PROFILE_SAFETY_SETTINGS_SUBMENU,
   ROLE_ID_STUDENT,
} from "../../../constants/user";
import LogoBlock from "../LogoBlock/LogoBlock";
import useUserRole from "../../../hooks/useUserRole";
import { useSelector } from "react-redux";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import arrowRight from "../../../assets/images/arrow_right.svg";
import shortLogo from "../../../assets/images/avatar.svg";
import useHandleModal from '../../../hooks/dom/useHandleModal';
import ConfirmModal from '../../../components/Modals/ConfirmModal/ConfirmModal';
import AvatarUploadModal from "../UploadModals/AvatarUploadModal";
import AvatarEditModal from "../UploadModals/AvatarEditModal";
import UserApi from "../../../utils/api/UserApi";
import { useDispatch } from 'react-redux';
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import { returnTemporaryLink } from "../../../utils/usersHelper";
import { setInfo } from "../../../store/actions/user";
import cn from "classnames";

const ProfileSidebar = ({ onMenuItemClick, activeElement, avatar, setAvatar, user }) => {
   const [submenuVisible, setSubmenuVisible] = useState(true);
   const [submenuSafetyVisible, setSubmenuSafetyVisible] = useState(false);
   const [renderedIconFile, setRenderedIconFile] = useState(null);
   const [isChangeIconLoading, setIsChangeIconLoading] = useState(false);

   const uploadAvatarModal = useHandleModal();
   const editAvatarModal = useHandleModal();
   const confirmModalDelete = useHandleModal();
   
   const dispatch = useDispatch();
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const mobProfileMenuVisibleInfo = useSelector((state) => state.sidebar.mobProfileSidebarInfo);

   const { userRoleId } = useUserRole(currentCompanyId)

   const t = useFindTranslationsByString();

   const toggleSubmenuVisibility = () => {
      setSubmenuVisible(!submenuVisible);
   };

   const toggleSubmenuSafetyVisibility = () => {
      setSubmenuSafetyVisible(!submenuSafetyVisible);
   };

   const fetchUserData = async() => {
      const res = await new UserApi().getYourUserData();
      
      if(res?.success?.data) {
         const restrictedAvatarLink = await returnTemporaryLink(res?.success?.data);

         dispatch(setInfo({
            ...res.success.data,
            avatar: restrictedAvatarLink?.[0]?.fileUrl || null
         }));
      }
  }

   useEffect(()=> {
      if(user?.avatar) setRenderedIconFile(user?.avatar);
   }, [user])

   const saveAvatar = async () => {
      setIsChangeIconLoading(true);
      const formData = new FormData();
      formData.append("new_avatar", avatar);
      const res = await new UserApi().changeUserAvatar(formData);

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         fetchUserData();
         uploadAvatarModal.close();
         editAvatarModal.close();
      }

      setIsChangeIconLoading(false);
      if(res?.error?.message) {
        return toast(<ToastMsg text={res?.error?.message} isError />)
      }
   }

   const deleteFaviconMobile = async () => {
      new UserApi().deleteUserAvatar().then((res) => {
         if (res?.success) {
            setAvatar(shortLogo);
            setRenderedIconFile('');
            dispatch(setInfo({
               ...user,
               avatar: null
            }));
            return toast(<ToastMsg text={res?.success?.message} />);
         }
         if (res?.error) {
            return toast(<ToastMsg text={res?.error?.message} isError />);
         }
      }).finally(()=> {
         setIsChangeIconLoading(false);
         confirmModalDelete.close();
         uploadAvatarModal.close();
      });
   };

   return (
      <aside className={cn(
             styles.page_navigation,
             mobProfileMenuVisibleInfo?.isClosed ? styles.closed : ''
         )}>

      <div className={styles.logo_block_wrapper_mobile}>
         <LogoBlock 
               setRenderefIcon={setRenderedIconFile} 
               renderedIconFile={renderedIconFile} 
               avatar={avatar}
               setAvatar={setAvatar}
               uploadAvatarModal={uploadAvatarModal} 
            />
       </div>
         <ul className={styles.page_menu}>
            <li
               className={cn(
                  styles.page_menu_item,
                  submenuVisible ? styles.active : ""
               )}
            >
               <button
                  className={cn(
                     styles.icon_before,
                     styles.icon_before_settings,
                     styles.dropdown_button,
                     submenuVisible ? styles.active : ""
                  )}
                  onClick={toggleSubmenuVisibility}
               >
                  {t("Settings")}
               </button>
               <ul
                  className={cn(
                     styles.page_submenu,
                     submenuVisible ? "" : styles.page_menu_item_hide
                  )}
               >
                {PROFILE_BASIC_SETTINGS_SUBMENU.map((item) => {
                     if (userRoleId && userRoleId === ROLE_ID_STUDENT && item.el === "position") {
                        return null; // Don't render the "Position" tab for students
                     }

                     return (
                        <li key={item.id} className={styles.page_submenu_item}>
                           <a
                           className={cn(
                              item.id === activeElement?.id ? styles.activeTab : "",
                              styles.page_submenu_link
                           )}
                           href="#"
                           onClick={(e) => onMenuItemClick(e, item)}
                           >  
                           <div className={styles.img_mob}>
                              <img src={item?.icon} alt="" />
                           </div>
                              {t(item.name)}
                           <div className={styles.img_mob_arrow}>
                              <img src={arrowRight} alt="" />
                           </div>
                           </a>
                        </li>
                     );
                  })}
               </ul>
            </li>

            <li
               className={cn(
                  styles.page_menu_item,
                  submenuSafetyVisible ? styles.active : ""
               )}
            >
               <button
                  className={cn(
                     styles.dropdown_button,
                     styles.icon_before,
                     styles.icon_before_safety,
                     submenuSafetyVisible ? styles.active : ""
                  )}
                  onClick={toggleSubmenuSafetyVisibility}
               >
                  {t("Safety settings")}
               </button>

               <ul
                  className={cn(
                     styles.page_submenu,
                     submenuSafetyVisible ? "" : styles.page_menu_item_hide
                  )}
               >
                  {PROFILE_SAFETY_SETTINGS_SUBMENU.map((item) => (
                     <li key={item.id} className={styles.page_submenu_item}>
                        <a
                           className={cn(
                              item.id === activeElement?.id ? styles.activeTab : "",
                              styles.page_submenu_link
                           )}
                           href="#"
                           onClick={(e) => onMenuItemClick(e, item)}
                        >
                           <div className={styles.img_mob}>
                              <img src={item?.icon} alt="" />
                           </div>
                              {t(item.name)}
                           <div className={styles.img_mob_arrow}>
                              <img src={arrowRight} alt="" />
                           </div>
                        </a>
                     </li>
                  ))}
               </ul>
            </li>
         </ul>

         <AvatarUploadModal 
            setAvatar={setAvatar}
            avatar={avatar}
            setRenderedIconFile={setRenderedIconFile}
            isActive={uploadAvatarModal.isActive} 
            onClose={uploadAvatarModal.close} 
            isLoading={isChangeIconLoading}
            desc={`${t("Recommended")}:  500 KB & 400x400px (PNG ${t('or')} JPG)`}
            title={t("Upload new photo")} 
            isUploadMobileModalSize
            isSidebar
            iconEditModal={editAvatarModal}
            deleteFaviconMobileOpen={confirmModalDelete.open}
            isChangeIconLoadingFromParent={isChangeIconLoading}
         />

         <AvatarEditModal 
            setAvatar={setAvatar}
            avatar={avatar}
            renderedIconFile={renderedIconFile}
            isActive={editAvatarModal.isActive} 
            onClose={editAvatarModal.close}
            desc={`${t("Recommended")}:  500 KB & 400x400px (PNG ${t('or')} JPG)`}
            title={t("Edit avatar")}
            setRenderedIconFile={setRenderedIconFile}
            onSaveIcon={saveAvatar}
            uploadAvatarModal={uploadAvatarModal}
         />

         <ConfirmModal
            confirmButtonText={t("Delete")}
            onConfirm={deleteFaviconMobile}
            maxHeight={'270px'}
            onClose={confirmModalDelete.close}
            isOpen={confirmModalDelete.isActive}
            title={t("Are you sure?")}
         />
      </aside>
   );
};

export default ProfileSidebar;
