import { useState, useEffect } from "react";
import StatisticsApi from "../../utils/api/StatisticsApi";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";

const useGetStatisticsUserCourses = (companyId, userId, order_by, order, progressRange, signal) => {
   const [loading, setLoading] = useState(true);
   const [page, setPage] = useState(1);
   const [data, setData] = useState([]);
   const [searchedValue, setSearchedValue] = useState("");
   const [totalCount, setTotalCount] = useState(0);

   const fetchData = async (passedPage) => {
      setLoading(true);

      try {
         const res = await new StatisticsApi().getUserCourseStatistics(
            passedPage ? passedPage : page,
            companyId,
            userId,
            searchedValue,
            order_by,
            order,
            progressRange,
            signal
         );

         const data = res?.success?.data?.course || [];

         if (res?.success?.data?.course) {
            if (passedPage) {
               setData(data);
            } else {
               setData((prev) => [...prev, ...data]);
            }

            setTotalCount(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setPage(1);
         }

         if (res?.error?.message && passedPage) {
            setData([]);
         }
      } catch (error) {
         // Handle error
         console.error("Error fetching data:", error);
      }

      setLoading(false);
   };

   const debouncedFetchCourses = debounce(
      () => fetchData(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   useEffect(() => {
      if (page > 1 && data?.length <= totalCount) {
         fetchData();
      }
   }, [page]);

   useEffect(() => {
      if (companyId && userId) {
         setPage(1);
         debouncedFetchCourses();
      }

      return () => debouncedFetchCourses.cancel();
   }, [searchedValue, companyId, userId, order_by, order, progressRange]);

   return {
      data,
      loading,
      page,
      setPage,
      searchedValue,
      setSearchedValue,
      totalCount,
   };
};

export default useGetStatisticsUserCourses;
