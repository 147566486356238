import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import FiltersBlock from "./FiltersBlock/FiltersBlock";
import CourseBlock from "../../components/CourseBlock/CourseBlock";
import useHandleModal from "../../hooks/dom/useHandleModal";
import AddCourseModal from "../../components/Modals/AddCourseModal/AddCourseModal";
import FolderApi from "../../utils/api/FolderApi";
import {
   COURSE_STATUS_ACTIVE,
   COURSE_STATUS_DELETED,
   COURSE_STATUS_HIDEN,
} from "../../constants/courses";
import SelectCourseModal from "../../components/Modals/SelectCourseModal/SelectCourseModal";
import { useSelector } from "react-redux";
import CourseApi from "../../utils/api/CourseApi";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import {
   filterActiveCourses,
   mapCoursesWithNewLinks,
   returnTemporaryCoursesLinks,
} from "../../utils/coursesHelper";
import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import pausedIcon from "../../assets/images/symbols/pause.svg";

import styles from "./styles.module.scss";
import cn from "classnames";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import SelectAgencyModal from "../../components/Modals/SelectAgencyModal/SelectAgencyModal";
import useDuplicateAgencies from "../../hooks/dom/useDuplicateAgencies";
import useChangeCourse from "../../hooks/useChangeCourse";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import useUserRole from "../../hooks/useUserRole";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useGetEntetiesForUpdateFolder from "../../hooks/api/useGetEntetiesForUpdateFolder";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import useFindTranslations from "../../hooks/useFindTranlsations";

const TopicPage = () => {
   const params = useParams();
   const handleAddCourseModal = useHandleModal();
   const handleCreateTopicModal = useHandleModal();
   const confirmDeleteFromTopicModal = useHandleModal();

   const t = useFindTranslations();

   const navigate = useNavigate();

   const [topic, setTopic] = useState({});
   const [clickdedCourse, setClickdedCourse] = useState({});
   const [selectedCourses, setSelectedCourses] = useState([]);
   const [topicLoading, setTopicLoading] = useState(false);
   const [isAddLoading, setIsAddLoading] = useState(false);

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId);
   const userInfo = useSelector((state) => state?.user?.info);
   const isUserAsStudent = useSelector(
      (state) => state.sidebar.isUserAsStudent
   );
   const userId = useSelector((state) => state?.user?.info?.id);

   const [allTags, setAllTags] = useState([]);

   const getAllTags = async () => {
      const res = await new CourseApi().getAllTags();
      if (res?.success?.data) {
         setAllTags(res?.success?.data);
      }
   };

   useEffect(() => {
      getAllTags();
   }, []);

   const {
      onDuplicateAgencies,
      onOpenDuplicateModal,
      duplicateModal,
      setSelectedAgencies,
      selectedAgencies,
      isAgenciesDuplicateLoading,
      setIsAgenciesDuplicateLoading,
   } = useDuplicateAgencies();

   const agencyManager = useGetAgencies(userId);
   const updateFolderManager = useGetEntetiesForUpdateFolder(params?.topicId, currentCompanyId);

   const lastAgencyRef = useIntersectionObserver(() => {
      if (
         !agencyManager.isAgenciesLoading &&
         agencyManager.agenciesList?.length < agencyManager.totalAgencies
      ) {
         if (agencyManager.agenciesList?.length === 0) {
            agencyManager.setCurrentPage(1);
         } else {
            agencyManager.setCurrentPage((prev) => prev + 1);
         }
      }
   }, [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]);

   const getTopic = async (loding = false) => {
      if (!loding) setTopicLoading(true);
      const res = await new FolderApi().getOneFolder(params?.topicId);

      if (res?.success?.data) {
         setTopic(res?.success?.data);
      }

      if (!loding) setTopicLoading(false);
   };

   const isUserAsStudentAndNotSuperAdmin =
      userRoleId === ROLE_ID_SUPER_ADMIN || userRoleId === ROLE_ID_STUDENT ? false : isUserAsStudent;

   const isExcludeCoursesWhereAdminAsStudent =
      userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT;

   // all your courses apart from that one in topic
   const {
      courseList,
      isCoursesLoading,
      refetchCourses,
      totalCourses,
      setCurrentPage,
      currentPage,
      clearAllCourseFilters,
      searchedValue,
      setSearchedValue,
      selectAll,
      setSelectAll,
   } = useGetYourCoursesInCompany(
      currentCompanyId,
      isUserAsStudentAndNotSuperAdmin,
      false,
      params.topicId,
      isExcludeCoursesWhereAdminAsStudent
   );

   const lastCourseRef = useIntersectionObserver(() => {
      if (!isCoursesLoading && courseList?.length < totalCourses) {
         if (courseList?.length === 0) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      }
   }, [isCoursesLoading, totalCourses]);

   // All courses in topic
   const coursesManager = useGetYourCoursesInCompany(
      currentCompanyId,
      isUserAsStudentAndNotSuperAdmin,
      params.topicId,
      null,
      isExcludeCoursesWhereAdminAsStudent
   );

   const lastCourseInTopicRef = useIntersectionObserver(() => {
      if (
         !coursesManager.isCoursesLoading &&
         coursesManager.courseList?.length < coursesManager.totalCourses
      ) {
         if (coursesManager.courseList?.length === 0) {
            coursesManager.setCurrentPage(1);
         } else {
            coursesManager.setCurrentPage((prev) => prev + 1);
         }
      }
   }, [coursesManager.isCoursesLoading, coursesManager.totalCourses]);

   const refetchFullCourses = () => {
      refetchCourses();
      coursesManager.refetchCourses();
   };

   const {
      isChangeLoading,
      setIsChangeLoading,
      confirmModalToogleShowStatus,
      confirmHideModal,
      confirmDeleteModal,
      retakeModal,
      onCourseChangeStatus,
      onClose,
      onConfirmOpenStatus,
      changeCourseStatusToActive,
      onConfirmOpenRestore,
      confirmModalRestore,
      onConfirmOpenHide,
      onRetakeCourse,
      onRetakeModalOpen,
      onConfirmOpenDelete,
      deleteCourse,
      onConfirmPausedOpen,
      confirmPausedModal,
      onRedirectClickedCourse
   } = useChangeCourse(refetchFullCourses);

   const onOpenDeleteFromTopic = (course) => {
      onClose();
      setClickdedCourse(course);
      confirmDeleteFromTopicModal.open();
   };

   const onAddCourse = async () => {
      setIsAddLoading(true);
      const res = await new FolderApi().addCoursesToFolder(topic.id, {
         courses: selectedCourses?.map((item) => item?.id),
      });

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         getTopic(true);
         refetchFullCourses();
         handleAddCourseModal.close();
         updateFolderManager.onUpdateFolderMaterialsForUsers(selectedCourses, true);
         updateFolderManager.refetchAll();
         setSelectedCourses([]);
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsAddLoading(false);
   };

   useEffect(() => {
      getTopic();

      return () => {
         getTopic({});
      };
   }, []);

   const onCourseDeleteFromTopic = async () => {
      setIsChangeLoading(true);
      const res = await new FolderApi().removeCourseFromFolder(
         topic?.id,
         clickdedCourse?.id
      );

      confirmDeleteFromTopicModal.close();

      setIsChangeLoading(false);

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchFullCourses();
         updateFolderManager.onUpdateFolderMaterialsForUsers(clickdedCourse?.id, false);
         updateFolderManager.refetchAll();
      }
   };

   const courseListWithoutThatOneInTopic = courseList?.filter((course) =>
      course?.folders?.every((topic) => topic?.id !== +params?.topicId)
   );

   const onRedirectToEditTopicPage = (e, topic) => {
      e.stopPropagation();
      navigate(`/courses/folder/${params?.folderId}/topic/${params?.topicId}/edit`);
   }

   return (
      <MainLayout>
         <div className={styles.coursesPage}>
            <FiltersBlock
               title={topic?.name}
               onRedirectSettingsPage={onRedirectToEditTopicPage}
               isTopic
               searchWhat={t?.searchCourseNameTags}
               setActiveFilter={coursesManager?.setActiveCourseFilter}
               activeFilter={coursesManager?.activeCourseFilter}
               activeTagsFilter={coursesManager?.activeCourseTagsFilter}
               setActiveTagsFilter={coursesManager?.setActiveCourseTagsFilter}
               activeSortFilter={coursesManager?.activeCourseSortFilter}
               setActiveSortFilter={coursesManager?.setActiveCourseSortFilter}
               onClickPlus={handleAddCourseModal.open}
               onClickCreateTopic={handleCreateTopicModal.open}
               currentTags={allTags}
               paginatedSearchedValue={coursesManager?.searchedValue}
               setPaginatedSearchedValue={coursesManager?.setSearchedValue}
            />

            <div className={styles.list}>
               {coursesManager?.isCoursesLoading &&
               coursesManager?.courseList?.length === 0 ? (
                  <div className={cn(styles.w_full, styles.skeleton_wrapper_inside_folder)}>
                     {Array(4)
                        .fill(null)
                        .map((item, idx) => (
                           <div
                              key={idx}
                              className={styles.sceletonCourse_wrapper}
                           >
                              <div
                                 key={idx}
                                 className={cn(styles.sceletonCourse)}
                              ></div>
                              <div className={styles.loadingTitleCourse}></div>
                              <div className={styles.loadingTitleCourse2}></div>
                           </div>
                        ))}
                  </div>
               ) : (
                  coursesManager?.courseList?.map((item, key) => (
                     <CourseBlock
                        refetchCourses={() => {
                           getTopic(true);
                           refetchCourses();
                        }}
                        lastCourseRef={lastCourseInTopicRef}
                        key={key}
                        course={item}
                        activeCourseFilter={coursesManager?.activeCourseFilter}
                        folder={topic}
                        onOpenDuplicateModal={onOpenDuplicateModal}
                        onConfirmOpenStatus={onConfirmOpenStatus}
                        onConfirmOpenRestore={onConfirmOpenRestore}
                        onConfirmOpenHide={onConfirmOpenHide}
                        onRetakeModalOpen={onRetakeModalOpen}
                        onConfirmOpenDelete={onConfirmOpenDelete}
                        onOpenDeleteFromFolder={onOpenDeleteFromTopic}
                        onConfirmPausedOpen={onConfirmPausedOpen}
                     />
                  ))
               )}

               {coursesManager?.courseList?.length === 0 &&
                  !coursesManager?.isCoursesLoading && (
                     <EmptyBlock left text={t?.courses} />
                  )}
            </div>

            <SelectCourseModal
               onClose={handleAddCourseModal.close}
               isOpen={handleAddCourseModal.isActive}
               setSelectedCourses={setSelectedCourses}
               selectedCourses={selectedCourses}
               onAddCourse={onAddCourse}
               isDataLoading={isAddLoading || isCoursesLoading}
               coursesList={courseListWithoutThatOneInTopic}
               lastCourseRef={lastCourseRef}
               searchedValue={searchedValue}
               setSearchedValue={setSearchedValue}
               selectAll={selectAll}
               setSelectAll={setSelectAll}
               coursesLoading={isCoursesLoading}
            />

            <SelectAgencyModal
               onClose={() => {
                  setSelectedAgencies([]);
                  duplicateModal.close();
               }}
               isOpen={duplicateModal.isActive}
               selectedAgencies={selectedAgencies}
               setSelectedAgencies={setSelectedAgencies}
               agenciesList={agencyManager.agenciesList}
               onAddAgencies={onDuplicateAgencies}
               refetch={refetchCourses}
               agencyManager={agencyManager}
               isDataLoading={
                  agencyManager.isAgenciesLoading || isAgenciesDuplicateLoading
               }
            />

            <ConfirmModal
               confirmButtonText={t?.confirm}
               onConfirm={() =>
                  changeCourseStatusToActive(confirmModalToogleShowStatus)
               }
               onClose={confirmModalToogleShowStatus.close}
               isOpen={confirmModalToogleShowStatus.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.restore}
               onConfirm={() => changeCourseStatusToActive(confirmModalRestore)}
               onClose={confirmModalRestore.close}
               isOpen={confirmModalRestore.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.hideText}
               onConfirm={() => onCourseChangeStatus(COURSE_STATUS_HIDEN)}
               onClose={confirmHideModal.close}
               isOpen={confirmHideModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />
            <ConfirmModal
               confirmButtonText={t?.retakeCourse}
               onConfirm={onRetakeCourse}
               onClose={retakeModal.close}
               isOpen={retakeModal.isActive}
               title={t?.areYouSure}
            />
            <ConfirmModal
               icon={pausedIcon}
               confirmButtonText={t?.open}
               onConfirm={onRedirectClickedCourse}
               onClose={confirmPausedModal.close}
               isOpen={confirmPausedModal.isActive}
               title={t?.courseWasPaused}
               subtitle={t?.youCannotContinueLearning}
            />
            <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteFromTheBin}
               onConfirm={deleteCourse}
               onClose={confirmDeleteModal.close}
               isOpen={confirmDeleteModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
               subtitle={t?.youWillNotBeAbleToChangeYourChoice}
            />
            <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteText}
               onConfirm={onCourseDeleteFromTopic}
               onClose={confirmDeleteFromTopicModal.close}
               isOpen={confirmDeleteFromTopicModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />
         </div>
      </MainLayout>
   );
};

export default TopicPage;
