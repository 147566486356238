import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FolderApi from "../../utils/api/FolderApi";
import CourseApi from "../../utils/api/CourseApi";
import UserApi from "../../utils/api/UserApi";
import { FOLDER_EXTRA_GROUP_ONE } from "../../utils/api/serialization_groups";
import {
   COURSE_STATUS_DELETED,
   COURSE_STATUS_ACTIVE,
} from "../../constants/courses";
import { ROLE_ID_SUPER_ADMIN } from "../../constants/user";

const useGetEntetiesForUpdateFolder = (folderId, companyId) => {
   const [users, setUsers] = useState([]);
   const [courses, setCourses] = useState([]);
   const [allFolderCourses, setAllFolderCourses] = useState([]);
   const [topics, setTopics] = useState([]);
   const userId = useSelector(state => state?.user?.info?.id);

   const onUpdateFolderMaterialsForUsers = async (
      passedCourses,
      isAdd = true
   ) => {
      if(!users?.length) return;
      // means that the course is in other folder and no need to change status of it
      if(typeof passedCourses === "number" && !isAdd) {
      const courseToRemove = allFolderCourses?.find(course => +course?.id === +passedCourses);
         if(courseToRemove) {
            const foldersWithoutTopics = courseToRemove?.folders?.filter(folder => !folder?.isTopic)

            if(foldersWithoutTopics?.length > 1) {
               return;
            }
         }
      }

      const usersVisibilityData = {
         user_data: users?.map((user) => ({
            user_id: user?.id,
            add: isAdd,
         })),
      };

      const usersData = {
         user_ids: users?.map((user) => user?.id),
         course_ids:
            typeof passedCourses === "number"
               ? [passedCourses]
               : passedCourses?.map((item) => item?.id),
         status: isAdd ? COURSE_STATUS_ACTIVE : COURSE_STATUS_DELETED,
         notification_for_folder_id: folderId,
         ...(isAdd ? {overwrite_user_course_active_or_paused_statuses: false} : {})
      };

      const usersAccessRes =
         await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersData);
   };

   //  remove access for courses inside topic
   const removeAccessForCoursesInsideTopic = async (passedUserId, coursesInsideTopics) => {
      const res = await new CourseApi().getAllCoursesOfUser({ companyId, userId: passedUserId });

      const userCourses = res?.success?.data;

      if(userCourses?.length) {
         const filteredCourses = userCourses.filter(userCourse =>
            coursesInsideTopics.some(topicCourse => +topicCourse?.id === +userCourse?.id)
          );

          const finalFilteredCourses = filteredCourses.filter(course => {
            return course?.folders?.length < 2;
          });

          if(finalFilteredCourses?.length) {
            const usersDataToRemove = {
               user_ids: [passedUserId],
               course_ids: finalFilteredCourses?.map((item) => item?.id),
               status: COURSE_STATUS_DELETED,
               notification_for_folder_id: folderId,
            };

            const usersToopicCoursesAccessRes =
               await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersDataToRemove);
          }

      }
   }

   const changeTopicsVisibility = async (
      passedUsers,
      isAdd = true,
   ) => {

      if(!topics?.length) return;
   
      const usersVisibilityData = {
         user_ids: passedUsers?.map(user => user?.id),
         folder_ids: topics?.map(topic => topic?.id),
         is_visible: isAdd ? 1 : 0
      };

      const usersVisibilityRes =
         await new FolderApi().changeManyFolderVisibilityForUsers(usersVisibilityData);

      const coursesInsideTopics = topics
         ?.map(topic => topic?.courses) 
         .flat()                      
         .reduce((uniqueCourses, course) => {
            if (!uniqueCourses?.some(c => c?.id === course?.id)) {
               uniqueCourses?.push(course);  
            }
            return uniqueCourses;
         }, []);

         // means that we are removing access for courses inside topics inside folder.
         if(!isAdd) {
            const res = await new CourseApi().getAllCoursesOfUser({ companyId, userId: passedUsers?.[0]?.id });

            const userCourses = res?.success?.data;
            if(userCourses?.length) {
               const filteredCourses = userCourses.filter(userCourse =>
                  coursesInsideTopics.some(topicCourse => +topicCourse?.id === +userCourse?.id)
                );

                const finalFilteredCourses = filteredCourses.filter(course => {
                  return course?.folders?.length < 2;
                });

                if(finalFilteredCourses?.length) {
                  const usersDataToRemove = {
                     user_ids: [passedUsers?.[0]?.id],
                     course_ids: finalFilteredCourses?.map((item) => item?.id),
                     status: isAdd ? COURSE_STATUS_ACTIVE : COURSE_STATUS_DELETED,
                     notification_for_folder_id: folderId,
                  };

                  const usersToopicCoursesAccessRes =
                     await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersDataToRemove);
                     return;
                }

            }
         }

      if(!coursesInsideTopics?.length) return;

      const usersData = {
         user_ids: passedUsers?.map((user) => user?.id),
         course_ids: coursesInsideTopics?.map((item) => item?.id),
         status: isAdd ? COURSE_STATUS_ACTIVE : COURSE_STATUS_DELETED,
         notification_for_folder_id: folderId,
         ...( isAdd ? {overwrite_user_course_active_or_paused_statuses: false} : {} )
      };

      const usersAccessRes =
         await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersData);
   };

   const getAllUsersOfFolder = async () => {
      const res = await new UserApi().getAllUsersOfFolder(folderId, companyId);

      if (res?.success?.data) {
         const filteredUsers = res?.success?.data?.filter(user => (user?.userBranchRoles?.every(role => role?.role?.id !== ROLE_ID_SUPER_ADMIN)) && +user?.id !== +userId)

         setUsers(filteredUsers);
      }
   };

   const getAllCoursesOfFolder = async () => {
      const res = await new CourseApi().get100CoursesInFolder(companyId, folderId);
      if (res?.success?.data) {
         setAllFolderCourses(res?.success?.data);

         const filteredCourses = res?.success?.data?.filter((item) => {
            if (item?.folders?.length === 0) {
                return true; 
            }
            if (item.folders.length === 1) {
                return !item.folders[0]?.isTopic; 
            }
            if (item.folders.length === 2) {
                return !item.folders[0]?.isTopic && !item.folders[1]?.isTopic; 
            }
            return false; 
        });

         setCourses(filteredCourses);
      }
   };

   const getAllTopicsOfFolder = async () => {
      const res = await new FolderApi().getAllTopicsInFolder(
         companyId,
         folderId,
         FOLDER_EXTRA_GROUP_ONE
      );

      if (res?.success?.data) {
         setTopics(res?.success?.data);
      }
   };

   const refetchAll = () => {
      getAllUsersOfFolder();
      getAllCoursesOfFolder();
      getAllTopicsOfFolder();
   };

   useEffect(() => {
      if (companyId) getAllUsersOfFolder();
   }, [companyId]);

   useEffect(() => {
     if(companyId) {
      getAllCoursesOfFolder();
      getAllTopicsOfFolder();
     }
   }, [companyId]);

   return {
      refetchAll,
      users,
      courses,
      topics,
      onUpdateFolderMaterialsForUsers,
      changeTopicsVisibility,
      removeAccessForCoursesInsideTopic
   };
};

export default useGetEntetiesForUpdateFolder;
