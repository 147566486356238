import Button from "../../../../components/Button/Button";
import styles from "./styles.module.scss";
import cn from "classnames";
import { HTTPS_REGEX } from "../../../../constants/preLinks";
import React from "react";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { returnTemporaryAnswerFileLinks } from "../../../../utils/questionsHelper";
import ReactPlayer from "react-player";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

function getFileNameFromURL(url) {
   if (!url?.length) return;

   const parts = url.split("/");
   const filenameWithParams = parts[parts.length - 1];
   const filenameParts = filenameWithParams.split("?");
   return filenameParts[0];
}

const VideoFromUser = ({ question, courseId }) => {
   const userId = useSelector((state) => state?.user?.info?.id);
   const [fileUrls, setFileUrls] = useState([]);

   const t = useFindTranslationsByString();

   const getUpdatedUrlLink = async () => {
      const links = question?.answers?.[0]?.content?.split(",");

      const updatedLinks = await returnTemporaryAnswerFileLinks(
         userId,
         links,
         courseId
      );

      setFileUrls(updatedLinks);
   };

   useEffect(() => {
      if (userId && question?.answers?.[0]?.content?.includes("https")) {
         getUpdatedUrlLink();
      }
   }, [question, userId]);

   return (
      <>
         {fileUrls?.length === 0 && (
            <div>{t("No file was added!")}</div>
         )}

         {fileUrls?.map((item, index) => (
            <React.Fragment key={index}>
               {!item?.fileUrl ? (
                  <div className={styles.no_file}>{t("No file was added!")}</div>
               ) : (
                  <ReactPlayer
                     url={item?.fileUrl}
                     controls
                     width="100%"
                     height="300px"
                  />
               )}
            </React.Fragment>
         ))}
      </>
   );
};

export default VideoFromUser;
