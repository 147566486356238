import { pathList } from "../../routes/path";
import styles from "./styles.module.scss";

export const navLinksTop = [
   {
      title: "Courses",
      path: pathList.courses.path,
      iconClass: styles.menu_link_icon_courses,
   },
   {
      title: "Knowledge check",
      path: pathList.knowledgeCheck.path,
      iconClass: styles.menu_link_icon_knowledge_check,
   },
];

export const navLinksTopMobile = [
   {
      title: "Home",
      path: pathList.home.path,
      iconClass: styles.menu_link_icon_home,
   },
   {
      title: "Courses",
      path: pathList.courses.path,
      iconClass: styles.menu_link_icon_courses,
   },
   {
      title: "Knowledge check",
      path: pathList.knowledgeCheck.path,
      iconClass: styles.menu_link_icon_knowledge_check,
   },
];

export const companiesInfo = {
   title: "Companies",
   path: pathList.agencies.path,
   iconClass: styles.menu_link_icon_agencies,
}

