import {
   SET_NOTIFICATIONS_INFO,
   DELETE_NOTIFICATION,
   TOGGLE_MUTED,
} from "../constants/notifications";

export function setNotificationsInfo(value) {
   return function (dispatch) {
      return dispatch({
         type: SET_NOTIFICATIONS_INFO,
         info: value,
      });
   };
}

export function deleteNotificationById(notificationIds) {
   return {
      type: DELETE_NOTIFICATION,
      ids: notificationIds,
   };
}

export function toggleMuted(muted) {
   return {
      type: TOGGLE_MUTED,
      muted: muted,
   };
}
