import useFindTranslationsByString from '../../../../hooks/useFindTranslationsByString';
import { COMPARE_ANSWER_CODE, FILE_UPLOAD_ANSWER_CODE, MULTIPLE_CHOICE_ANSWER_CODE, ONE_CHOICE_ANSWER_CODE, RATE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, STARS_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE, VIDEO_ANSWER_CODE, WRITTEN_ANSWER_CODE } from '../../../../utils/questionsHelper';
import styles from './styles.module.scss';

const answerTitles = {
   [MULTIPLE_CHOICE_ANSWER_CODE]: 'Please select several correct answers:',
   [ONE_CHOICE_ANSWER_CODE]: 'Please select 1 correct answer',
   [TRUE_FALSE_ANSWER_CODE]: 'Please make a choice',
   [FILE_UPLOAD_ANSWER_CODE]: 'Please upload the file below',
   [WRITTEN_ANSWER_CODE]: 'Please write your answer below',
   [COMPARE_ANSWER_CODE]: 'Please match the correct order of pairs',
   [VIDEO_ANSWER_CODE]: 'Please upload the video below',
   [SEQUENCE_ANSWER_CODE]: 'Please match the correct order',
};

const AnswerSubheader = ({ questionType, questionRate }) => {
   const t = useFindTranslationsByString();

   const title = answerTitles[questionType] || '';
   let dynamicTitle = title;

   if (questionType === RATE_ANSWER_CODE || questionType === STARS_ANSWER_CODE) {
      dynamicTitle = `Please rate on a scale from 1 to ${questionRate}`;
    }

    return dynamicTitle ? <div className={styles.answers_title}>{t(dynamicTitle)}</div> : null;
}

export default AnswerSubheader;
