import React, { useEffect, useMemo, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import KnowledgeCheckAPI from "../../utils/api/KnowledgeCheckAPI";
import UsersTestsTable from "./UsersTestsTable/UsersTestsTable";

import styles from "./styles.module.scss";
import cn from "classnames";
import {
  USERS_TESTS_COLUMN,
  USER_TESTS_COLUMN,
  USER_TESTS_COLUMN_CHECKED,
  USERS_TESTS_COLUMN_CHECKED,
} from "../../constants/tests";
import KnowledgeSubheader from "./KnowledgeSubheader/KnowledgeSubheader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_DATA_LIMIT,
  PART_OF_MENTOR_NAME,
  ROLE_ID_STUDENT,
  ROLE_ID_SUPER_ADMIN,
} from "../../constants/user";
import { testsMap } from "../../utils/coursesHelper";
import { isMobileDevice } from "../../utils/usersHelper";
import Loader from "../../components/Loader/Loader";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import useUserRole from "../../hooks/useUserRole";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import debounce from "lodash/debounce";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetActiveUsersOfCompany from "../../hooks/api/useGetActiveUsersOfCompany";
import useFindTranslations from "../../hooks/useFindTranlsations";
import { TEST_PLUS_TEAM_EXTRA_GROUP_THREE } from "../../utils/api/serialization_groups";
import courseImg from "../../assets/images/courses_icon.svg";
import knowledge_check_done from "../../assets/images/knowledge_check_done.svg";
import knowledge_check_review from "../../assets/images/knowledge_check_review.svg";
import knowledge_check_failed from "../../assets/images/knowledge_check_failed.svg";
import rework_icon from "../../assets/images/rework_icon.svg";
import end_date_img from "../../assets/images/date_icon_gray.svg";
import pass_time_img from "../../assets/images/pass_time.svg";
import score_img from "../../assets/images/score.svg";

// This is the front page of knowledge check without actual check
// We redirect to ActualKnowledgeCheck page when we choose a student from the table
// This is also a page for student as well as fo admins.

const KnowledgeCheckPage = () => {
  const [searchedValue, setSearchedValue] = useState("");
  const [activeTab, setActiveTab] = useState("awaiting_checking");
  const [activeFilter, setActiveFilter] = useState({ status: null });
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [tests, setTests] = useState([]);
  const [testsPage, setTestsPage] = useState(1);
  const [testsLoading, setTestsLoading] = useState(true);
  const [totalTests, setTotalTests] = useState(0);

  const [testsFilter, setTestsFilter] = useState(null);

  const navigate = useNavigate();
  const t = useFindTranslations();

  //  for filtered students
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [frontTimeFilterValue, setFrontTimeFilterValue] = useState(null);

  const [filterApplied, setFilterApplied] = useState(false);

  const onFilterApply = () => {
    setFilterApplied(!filterApplied);
  };

  const companyId = useSelector((state) => state.sidebar.companyId);
  const { userRoleId, userRoleName } = useUserRole(companyId);

  const isUserAsStudent = useSelector((state) => state.sidebar.isUserAsStudent);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setTestsPage(1);
  };

  const {
    setCurrentPage,
    totalUsers,
    userList: studentsList,
    isUsersLoading: isStudentLoading,
    searchedValue: studentsSearchedValue,
    setSearchedValue: setStudentsSearchedValue,
  } = useGetActiveUsersOfCompany(companyId);

  const lastStudentRef = useIntersectionObserver(() => {
    if (!isStudentLoading && studentsList?.length < totalUsers) {
      if (studentsList?.length === 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage((prev) => prev + 1);
      }
    }
  }, [isStudentLoading, totalUsers]);

  const fetchTests = async (page, limit = null) => {
    if (!companyId || !userRoleId) return;

    // const isCurator = userRoleName?.includes(PART_OF_CURATOR_NAME); TODO if needed show tests of courses where you are curator.
    const isMentor = userRoleName?.includes(PART_OF_MENTOR_NAME);

    const isShowRelatedToYouTests =
      userRoleId !== ROLE_ID_SUPER_ADMIN &&
      userRoleId !== ROLE_ID_STUDENT &&
      !isUserAsStudent &&
      !isMentor;

    const isYourTests = userRoleId === ROLE_ID_STUDENT || isUserAsStudent;
    const isAdminAsStudent =
      userRoleId !== ROLE_ID_SUPER_ADMIN && isUserAsStudent;
    const usersIds = selectedStudents?.map((item) => item?.id);
    const teamIds = selectedTeams?.map((item) => item?.id);

    const activeTabValue = activeTab === "awaiting_checking" ? 0 : 1;
    const checkFilter = isMobileDevice() ? null : activeTabValue;

    setTestsLoading(true);
    const res = await new KnowledgeCheckAPI().getUsersTest(
      limit ? limit : DEFAULT_DATA_LIMIT,
      page || testsPage,
      companyId,
      checkFilter,
      testsFilter?.label?.length > 0
        ? testsFilter?.status
        : activeFilter?.status,
      searchedValue,
      isShowRelatedToYouTests,
      isAdminAsStudent,
      isYourTests,
      isMentor,
      usersIds,
      dateFilter,
      TEST_PLUS_TEAM_EXTRA_GROUP_THREE,
      teamIds,
      sortDirection,
      sortColumn,
    );

    if (res?.success?.data) {
      if (testsPage === 1) {
        setTests(res?.success?.data);
      } else {
        setTests((prev) => [...prev, ...res?.success?.data]);
      }

      setTotalTests(res?.success?.totalCount);
    }

    if (res?.success?.data?.length === 0) {
      setTestsPage(1);
    }

    if (res?.error?.message && page) {
      setTests([]);
    }

    setTestsLoading(false);
  };

  const lastTestRef = useIntersectionObserver(() => {
    if (!testsLoading && tests?.length < totalTests) {
      if (tests?.length === 0) {
        setTestsPage(1);
      } else {
        setTestsPage((prev) => prev + 1);
      }
    }
  }, [testsLoading, totalTests, sortDirection, sortColumn, tests]);

  useEffect(() => {
    if (testsPage > 1 && tests?.length <= totalTests) {
      fetchTests();
    }
  }, [testsPage, userRoleId]);

  const debouncedFetchTests = debounce(
    (page) => fetchTests(page),
    DEFAULT_DEBOUNCE_DELAY,
  );

  useEffect(() => {
    if (companyId && userRoleId) {
      setTests([]);
      setTestsLoading(true);
      setTestsPage(1);
      debouncedFetchTests(1);
    }
    return () => debouncedFetchTests.cancel();
  }, [
    searchedValue,
    sortDirection,
    sortColumn,
    activeTab,
    userRoleId,
    filterApplied,
    dateFilter,
    isUserAsStudent,
    testsFilter,
  ]);

  useEffect(() => {
    setTests([]);
    setTestsLoading(true);
    setSearchedValue("");
    setActiveFilter({ status: null });
  }, [activeTab]);

  const columnsCondition =
    (userRoleId && userRoleId !== ROLE_ID_STUDENT && !isUserAsStudent) ||
    userRoleId === ROLE_ID_SUPER_ADMIN
      ? USERS_TESTS_COLUMN
      : USER_TESTS_COLUMN;

  const columnsConditionChecked =
    (userRoleId && userRoleId !== ROLE_ID_STUDENT && !isUserAsStudent) ||
    userRoleId === ROLE_ID_SUPER_ADMIN
      ? USERS_TESTS_COLUMN_CHECKED
      : USER_TESTS_COLUMN_CHECKED;

  const mappedTests = useMemo(
    () => (tests?.length ? tests?.map(testsMap) : []),
    [tests],
  );

  const generateCsvData = () => {
    return [
      activeTab === "awaiting_checking"
        ? columnsCondition
        : columnsConditionChecked,
      ...mappedTests?.map(
        ({ student, course_name, test_name, end_date, pass_time, result }) => [
          student,
          course_name,
          test_name,
          end_date,
          pass_time,
          result,
        ],
      ),
    ];
  };

  const onSortData = (columnName) => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    setSortColumn(columnName);
    setTestsPage(1);
  };

  const borderColors = {
    1: "#44DD65",
    0: "#0B88F8",
    2: "#D64343",
    3: "yellow",
  };

  const testIcons = {
    0: knowledge_check_review,
    1: knowledge_check_done,
    2: knowledge_check_failed,
    3: rework_icon,
  };

  const defineBorderLeft = (status) =>
    `6px solid ${borderColors[status] || "#000"}`;

  return (
    <MainLayout darkBg>
      <main className={styles.knowledge_check_front_page}>
        <div className={styles.tab_navigation}>
          <button
            className={cn(
              styles.tab,
              activeTab === "awaiting_checking" && styles.activeTab,
            )}
            onClick={() => handleTabChange("awaiting_checking")}
          >
            {t?.awaitingChecking}
          </button>

          <button
            className={cn(
              styles.tab,
              activeTab === "checked" && styles.activeTab,
            )}
            onClick={() => handleTabChange("checked")}
          >
            {t?.checked}
          </button>
        </div>

        {testsLoading && (
          <div className="default_loader_wrapper">
            <Loader size="small" />
          </div>
        )}

        {activeTab === "awaiting_checking" && (
          <>
            <KnowledgeSubheader
              setSearchedValue={setSearchedValue}
              searchedValue={searchedValue}
              csvData={generateCsvData()}
              fetchTests={fetchTests}
              isDataLoading={testsLoading}
              students={studentsList}
              studentsSearchedValue={studentsSearchedValue}
              setStudentsSearchedValue={setStudentsSearchedValue}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              setSelectedTeams={setSelectedTeams}
              selectedTeams={selectedTeams}
              lastStudentRef={lastStudentRef}
              onFilterApply={onFilterApply}
              dateFilter={dateFilter}
              setActiveFilter={setActiveFilter}
              setDateFilter={setDateFilter}
              frontTimeFilterValue={frontTimeFilterValue}
              setFrontTimeFilterValue={setFrontTimeFilterValue}
              setTestsFilter={setTestsFilter}
              testsFilter={testsFilter}
            />

            <UsersTestsTable
              onSortData={onSortData}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              isTestsLoading={testsLoading}
              data={mappedTests}
              columns={columnsCondition}
              userRoleId={userRoleId}
              lastTestRef={lastTestRef}
              t={t}
            />
          </>
        )}
        {activeTab === "checked" && (
          <>
            <KnowledgeSubheader
              setSearchedValue={setSearchedValue}
              searchedValue={searchedValue}
              csvData={generateCsvData()}
              checked
              activeFilter={activeFilter}
              fetchTests={fetchTests}
              isDataLoading={testsLoading}
              setActiveFilter={setActiveFilter}
              students={studentsList}
              studentsSearchedValue={studentsSearchedValue}
              setStudentsSearchedValue={setStudentsSearchedValue}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              lastStudentRef={lastStudentRef}
              onFilterApply={onFilterApply}
              dateFilter={dateFilter}
              setSelectedTeams={setSelectedTeams}
              selectedTeams={selectedTeams}
              setDateFilter={setDateFilter}
              frontTimeFilterValue={frontTimeFilterValue}
              setFrontTimeFilterValue={setFrontTimeFilterValue}
              t={t}
            />

            <UsersTestsTable
              sortColumn={sortColumn}
              onSortData={onSortData}
              sortDirection={sortDirection}
              columns={columnsConditionChecked}
              userRoleId={userRoleId}
              isTestsLoading={testsLoading}
              data={mappedTests}
              lastTestRef={lastTestRef}
              t={t}
            />
          </>
        )}

        {isMobileDevice() && (
          <div className={styles.mob_tests_list}>
            {mappedTests?.map((test, idx) => (
              <div
                onClick={() => {
                  navigate(`/knowledge_check/info/${test?.id}`);
                }}
                key={`${test?.id}_mob`}
                className={styles.test_item}
                style={{
                  borderLeft: defineBorderLeft(test?.status),
                }}
                ref={mappedTests?.length - 1 === idx ? lastTestRef : null}
              >
                <div className={styles.left}>
                  <div className={styles.course_name}>
                    <img src={courseImg} alt="" />
                    <span>{test?.course_name}</span>
                  </div>
                  <div className={styles.test_name}>{test?.test_name}</div>
                  <div className={styles.test_end_date}>
                    <img src={end_date_img} alt="" />
                    <span className={styles.gray}>
                      {t.returnTranslation("End date: ")}
                    </span>
                    <span>{test?.end_date}</span>
                  </div>

                  <div className={styles.info}>
                    {test?.pass_time && (
                      <div className={styles.info_item}>
                        <img src={pass_time_img} alt="Pass time icon"/>
                        <span className={styles.gray}>
                          {t?.returnTranslation("Pass time:")}
                        </span>
                        <span>{test?.pass_time}</span>
                      </div>
                    )}
                    {test?.successPercentage > 0 && (
                      <div className={styles.info_item}>
                        <img src={score_img} alt="Score icon"/>
                        <span className={styles.gray}>
                          {t?.returnTranslation("Score")}
                        </span>
                        <span>{test?.score}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={cn(
                    styles.right,
                    test?.status === 3 ? styles.yellowBg : "",
                  )}
                >
                  <img src={testIcons[test?.status]} alt="" />
                </div>
              </div>
            ))}

            {mappedTests?.length === 0 && !testsLoading && <EmptyBlock />}
          </div>
        )}
      </main>
    </MainLayout>
  );
};

export default KnowledgeCheckPage;
