
import astronaut from "../../assets/images/austronaut.svg";
import styles from "./index.module.scss";
import Circle from "../Circle/Circle";
import { levelsData } from "../../constants/courses";

const Planets = ({ currentLevel, clickPlanet }) => {
   return (
      <>
         {levelsData.map((planet) => (
            <div
               key={planet.level}
               onClick={() => clickPlanet && clickPlanet(planet)}
               className={styles.planet_wrapper}
            >
               <img
                  className={styles.planet_img}
                  src={planet.planetImage}
                  alt="Planet"
               />
               {planet.level === currentLevel && (
                  <>
                     <img
                        className={styles.astronaut_img}
                        src={astronaut}
                        alt="Astronaut above the planet"
                     />
                     <Circle
                        progress={planet.progress}
                        width="90%"
                        height="100%"
                     />
                  </>
               )}
            </div>
         ))}
      </>
   );
};

export default Planets;
