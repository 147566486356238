import { useEffect, useState } from "react";
import { returnTemporaryLink } from "../../utils/usersHelper";
import UserApi from "../../utils/api/UserApi";
import { USER_EXTRA_GROUP_TWO } from "../../utils/api/serialization_groups";

const useGetOneUser = (userId) => {
   const [user, setUser] = useState({id: userId});
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const fetchUser = async () => {
      setLoading(true);
      try {
         const res = await new UserApi().getUser(userId, USER_EXTRA_GROUP_TWO);

         if (res?.success?.data) {
            const avatarLink = await returnTemporaryLink(res?.success?.data);

            setUser({
               ...res?.success?.data,
               avatar: avatarLink?.[0]?.fileUrl,
               name: `${res?.success?.data?.firstName || ""} ${
                  res?.success?.data?.lastName || ""
               }`,
            });
         }
      } catch (error) {
         setError(error);
         console.log(error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (userId) fetchUser();
   }, []);

   return {
      user,
      setUser,
      loading,
      error,
   };
};

export default useGetOneUser;
