import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import saveImg from "../../../assets/images/symbols/save.svg";

import styles from "./styles.module.scss";

const RightsSubheader = ({ onSave, t }) => {
   return (
      <div className={styles.subheader}>
         <div className={styles.left}>
            <div>{t?.rights}</div>
            <p>{t?.editRoleCapabilitiesAndPermissions}.</p>
         </div>
         <div className={styles.right}>
            <Button title={t?.saveChanges} image={saveImg} onClick={onSave} />
         </div>
      </div>
   );
};

export default RightsSubheader;
