import Base from "./Base";

export default class TeamApi extends Base {
    getTeams({ limit, page, companyId, isMentor, activeTeamsFilter, searchedValue, signal, serializationGroup }) {
        let url = `api/v1/team?limit=${limit}&page=${page}`;

        if(companyId) {
          url += `&filter[company]=${companyId}`;
        }

        if(isMentor) {
          url += `&filter[teams_where_you_are_mentor]=1`;
        }

        if(searchedValue) {
         url += `&filter[search]=${searchedValue}`;
        }

        if(activeTeamsFilter === 'Undefined') {
            url += `&filter[team_has_department]=${0}`;
            url += `&filter[team_has_position]=${0}`;
        } 

         if (activeTeamsFilter === 'Department') {
            url += `&filter[team_has_department]=${1}`;
         }

         if (activeTeamsFilter === 'Position') {
            url += `&filter[team_has_position]=${1}`;
         }

        url += `&order=desc`;

        return super.get(url, null, signal, serializationGroup ? serializationGroup : '')
    }

    getAllTeams({ limit, page, excludeCompanyId, searchedValue, serializationGroup, signal }) {
      let url = `api/v1/team?limit=${limit}&page=${page}`;

      if(excludeCompanyId) {
        url += `&filter[excluded_company]=${excludeCompanyId}`;
      }

      if(searchedValue) {
       url += `&filter[search]=${searchedValue}`;
      }

      url += `&order=desc`;

      return super.get(url, null, signal, serializationGroup ? serializationGroup : '')
    }
    
    getOneTeam(teamId, serializationGroup) {
        return super.get(`api/v1/team/${teamId}`, null, null, serializationGroup);
    }

    getAvailableCourses(teamId) {
        return super.get(`api/v1/team/${teamId}/get-available-courses?limit=100&order=desc`);
    }

    addCoursesToTeam(teamId, data) {
        return super.put(`api/v1/team/${teamId}/courses`, data);
    }

    createTeam(data) {
        return super.post(`api/v1/team`, data);
    }

    addUserToTeam(teamID, userID) {
        return super.post(`api/v1/team/${teamID}/user/${userID}`);
    }

    addUsersToTeam(teamID, data) {
      return super.put(`api/v1/team/${teamID}/add-many-users`, data);
    }

    removeUserFromTeam(teamID, userID) {
        return super.delete(`api/v1/team/${teamID}/user/${userID}`);
    }

    deleteTeam(teamID) {
      return super.delete(`api/v1/team/${teamID}`)
    }

    updateTeam(teamID, data) {
      return super.put(`api/v1/team/${teamID}`, data)
    }

    editTeamMentors(teamID, data) {
      return super.put(`api/v1/team/${teamID}/mentors`, data)
    }
}