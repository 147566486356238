import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

const AnswerStarsOption = ({ id, label, checked, onChange }) => (
  <div className={styles.answer}>
    <div className={cn(styles.answer_block, checked ? styles.answer_block_isSelected : '')}>
      <div className={cn(styles.checkbox, styles.radio)}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          className={styles.radioButton}
        />
      </div>
      <label className={styles.radio_label} htmlFor={id}>
        {label}
      </label>
    </div>
  </div>
);

export default AnswerStarsOption;
