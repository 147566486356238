import { isMobileDevice } from "../../utils/usersHelper"

export const customStyles = {
	content: {
		boxShadow: '0px 16px 50px rgba(0, 0, 0, 0.25)',
		borderRadius: '10px',
      border: "1px solid #FFFFFF1A",
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '40px',
		boxSizing: 'border-box',
		overflow: 'hidden',
		width: isMobileDevice() ? '87%' : '96%',
		height: '100%'
	},
	overlay: {
		backdropFilter: 'blur(2.5px)',
      WebkitBackdropFilter: 'blur(2.5px)',
		background: 'rgba(0, 0, 0, 0.3)',
		zIndex: 300
	}
}

export const customStylesNoBlur = {
	content: {
		boxShadow: '0px 16px 50px rgba(0, 0, 0, 0.25)',
		borderRadius: '10px',
      border: "1px solid #FFFFFF1A",
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '40px',
		boxSizing: 'border-box',
		overflow: 'hidden',
		width: '100%',
		height: '100%'
	},
	overlay: {
      background: 'rgba(0, 0, 0, 0.7)',
		zIndex: 300
	}
}

export const customStylesBlackBg = {
	content: {
		boxShadow: '0px 16px 50px rgba(0, 0, 0, 0.25)',
		borderRadius: '10px',
      border: "1px solid #FFFFFF1A",
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '40px',
		boxSizing: 'border-box',
		overflow: 'hidden',
		width: '100%',
		height: '100%'
	},
	overlay: {
      background: 'rgba(0, 0, 0, 1)',
		zIndex: 300
	}
}

export const customStylesSecond = {
	content: {
		boxShadow: '0px 16px 50px rgba(0, 0, 0, 0.25)',
		borderRadius: '0px',
      border: "1px solid #FFFFFF1A",
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		boxSizing: 'border-box',
		overflow: 'hidden',
		width: '100%',
		height: '100%',
      top: '0', 
      left: '0',
      right: '0',
      bottom: '0',
      transform: 'translateY(0%)',
      background: 'rgba(0, 0, 0, .3)',
	},
	overlay: {
		backdropFilter: 'blur(2.5px)',
      WebkitBackdropFilter: 'blur(2.5px)',
		background: 'rgba(0, 0, 0, 0.75)',
		zIndex: 300
	}
}

export const customStylesRight = {
	content: {
		boxShadow: '0px 16px 50px rgba(0, 0, 0, 0.25)',
		borderRadius: '0px',
      border: "1px solid #FFFFFF1A",
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		boxSizing: 'border-box',
		overflow: 'hidden',
		width: '100%',
		height: '100%',
      top: '0', 
      left: '0',
      right: '0',
      bottom: '0',
      transform: 'translateY(0%)',
      background: 'rgba(0, 0, 0, .3)',
	},
	overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
		zIndex: 300
	}
}

