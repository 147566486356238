import Base from "./Base";

export default class BranchApi extends Base {
   createBranch(data) {
      return super.post(`api/v1/branch`, data);
   }

   deleteUserRoleInBranch(branchID, userID, roleID) {
      return super.delete(`api/v1/user/${userID}/branch/${branchID}/role/${roleID}`);
   }

   getCompanyBranches(companyID) {
      return super.get(`api/v1/company/${companyID}/branch`);
   }
}
