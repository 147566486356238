import React, {useState} from 'react';
import styles from './styles.module.scss'
import logo from '../../assets/images/logo.svg';
import {validateEmail} from "../../utils";
import {useNavigate} from "react-router-dom";
import {INIT_SIGN_UP_FORM} from "../../constants/init";
import {pathList} from "../../routes/path";
import UserApi from "../../utils/api/UserApi";
import AuthApi from "../../utils/api/AuthApi";
import {localStorageSet} from "../../utils/localStorage";
import {toast} from "react-toastify";
import ToastMsg from "../ToastMsg/ToastMsg";

export default function SignUp() {
    const [formData, setFormData] = useState(INIT_SIGN_UP_FORM);
    const [isPasswordType, setIsPasswordType] = useState(false);
    const [isPasswordRepeatType, setIsPasswordRepeatType] = useState(false);

    const navigate = useNavigate();

    const isInvalidEmail = !validateEmail(formData?.email);
    const isInvalidPassword = !!(formData?.password?.length && formData?.password?.length < 8);
    const isInvalidRepeatPassword = !!(formData?.passwordRepeat?.length && formData?.password !== formData?.passwordRepeat);

    const onChangeValue = (event) => setFormData({...formData, [event?.target?.name]: event?.target?.value});
    const onChangeChecked = (event) => setFormData({...formData, [event?.target?.name]: event?.target?.checked});
    const onLogin = () => navigate(pathList.login.path);
    const onSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (validateEmail(formData?.email)
            && formData?.password?.length >= 8
            && formData?.password === formData?.passwordRepeat
            && formData?.name?.length
            && formData?.lastname?.length
            && !!formData?.isAgree
        ) {
            const resRegister = await new UserApi().register({
                email: formData?.email,
                password: formData?.password,
                confirm_password: formData?.passwordRepeat,
                first_name: formData?.name,
                last_name: formData?.lastname
            });

            if (resRegister?.error?.message) return toast(<ToastMsg text={resRegister?.error?.message} isError/>)

            if (resRegister?.success?.data?.user?.status) {
                const resLogin = await new AuthApi().login({
                    username: formData?.email,
                    password: formData?.password,
                });

                if (resLogin?.error?.message) return toast(<ToastMsg text={resLogin?.error?.message} isError/>)

                if (resLogin?.success?.data?.accessToken) {
                    localStorageSet('token', resLogin?.success?.data?.accessToken)
                    navigate(pathList.home.path + '?welcome=true');
                }
            }
        }
    }

    return (
        <div className={styles.authorization_block}>
            <form className={styles.authorization_form}>
                <fieldset className={styles.authorization_form__fieldset}>
                    <div className={styles.authorization_form__input_group}>
                        <p className={`${styles.input_wrap} ${formData?.name?.length ? styles.valid : ''}`}>
                            {/*<span className={styles.validation_message}>Uncorrect name</span>*/}
                            <input
                                className={styles.authorization_form__input}
                                type="text"
                                placeholder="Your name"
                                required
                                name="name"
                                value={formData?.name}
                                onChange={onChangeValue}
                            />
                        </p>
                        <p className={`${styles.input_wrap} ${formData?.lastname?.length ? styles.valid : ''}`}>
                            {/*<span className={styles.validation_message}>Uncorrect surname</span>*/}
                            <input
                                className={styles.authorization_form__input}
                                type="text"
                                placeholder="Your surname"
                                required
                                name="lastname"
                                value={formData?.lastname}
                                onChange={onChangeValue}
                            />
                        </p>
                    </div>
                    <p className={`${styles.input_wrap} ${formData?.email?.length ? (isInvalidEmail ? styles.invalid : styles.valid) : ''}`}>
                        {isInvalidEmail && <span className={styles.validation_message}>Uncorrect email</span>}
                        <input
                            className={styles.authorization_form__input}
                            type="email"
                            placeholder="Your email"
                            required
                            name="email"
                            value={formData?.email}
                            onChange={onChangeValue}
                        />
                    </p>
                    <p className={`${styles.input_wrap_password} ${formData?.password?.length ? (isInvalidPassword ? styles.invalid : styles.valid) : ''}`}>
                        {
                            isInvalidPassword
                            && <span className={styles.validation_message}>Uncorrect password</span>
                        }
                        <input
                            className={styles.authorization_form__input}
                            type={isPasswordType ? "text" : "password"}
                            placeholder="Password (8+ characters)"
                            required
                            name="password"
                            value={formData?.password}
                            onChange={onChangeValue}
                        />
                        <button
                            type="button"
                            className={`${styles.input_password_btn} ${isPasswordType ? styles.hide : styles.show}`}
                            onClick={() => setIsPasswordType(!isPasswordType)}
                        />
                    </p>
                    <p className={`${styles.input_wrap_password} ${formData?.passwordRepeat?.length ? (isInvalidRepeatPassword ? styles.invalid : styles.valid) : ''}`}>
                        {
                            isInvalidRepeatPassword
                            && <span className={styles.validation_message}>Uncorrect password</span>
                        }
                        <input
                            className={styles.authorization_form__input}
                            type={isPasswordRepeatType ? "text" : "password"}
                            placeholder="Repeat password"
                            required
                            name="passwordRepeat"
                            value={formData?.passwordRepeat}
                            onChange={onChangeValue}
                        />
                        <button
                            type="button"
                            className={`${styles.input_password_btn} ${isPasswordRepeatType ? styles.hide : styles.show}`}
                            onClick={() => setIsPasswordRepeatType(!isPasswordRepeatType)}
                        />
                    </p>
                </fieldset>
                <input
                    className={styles.authorization_form__agreement_checkbox}
                    type="checkbox"
                    id="register_agreement"
                    required
                    name="isAgree"
                    checked={formData?.isAgree}
                    onChange={onChangeChecked}
                />
                <label className={styles.authorization_form__agreement_label}
                       htmlFor="register_agreement">By
                    clicking “Register”
                    you agree with the <a className={styles.agreement_link} href="#">Terms and
                        Conditions,</a> <a className={styles.agreement_link} href="#">Privacy Policy</a>
                </label>
                <button type="button" className={styles.authorization_form__submit} onClick={onSubmit}>Register</button>
            </form>
            <p className={styles.authorization_block__line}>or</p>
            <ul className={styles.authorization_buttons}>
                <li className={styles.authorization_buttons__item}>
                    <button type="button"
                            className={`${styles.authorization_buttons__btn} ${styles.icon_before} ${styles.icon_before__google}`}>
                        Continue with Google
                    </button>
                </li>
                <li className={styles.authorization_buttons__item}>
                    <button type="button"
                            className={`${styles.authorization_buttons__btn} ${styles.icon_before} ${styles.icon_before__facebook}`}>
                        Continue with Facebook
                    </button>
                </li>
                <li className={styles.authorization_buttons__item}>
                    <button type="button"
                            className={`${styles.authorization_buttons__btn} ${styles.icon_before} ${styles.icon_before__linkedin}`}>
                        Continue with LinkedIn
                    </button>
                </li>
            </ul>
            <p className={styles.authorization_block__details}>Already have an account?
                <span className={styles.authorization_block__link} onClick={onLogin}>Sign In</span>
            </p>
        </div>
    );
};