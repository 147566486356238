import React, { useEffect, useState } from "react";
import useSortableAndFuzzyData from "../../../hooks/useSortableAndFuzzyData";
import styles from "./styles.module.scss";
import { ROLE_ID_STUDENT, USER_STATUS_ACTIVE, USER_STATUS_BANNED, PART_OF_MENTOR_NAME, PART_OF_CURATOR_NAME } from "../../../constants/user";
import UserApi from "../../../utils/api/UserApi";
import { USER_EXTRA_GROUP_TWO } from "../../../utils/api/serialization_groups";
import { useNavigate } from "react-router-dom";
import cn from 'classnames';
import UsersBodyItem from "./UsersBodyItem";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import BranchApi from "../../../utils/api/BranchApi";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import Checkbox from "../../../components/Checkbox/Checkbox";
import ChangeRoleModal from "../../../components/Modals/ChangeRoleModal/ChangeRoleModal";
import { useSelector } from "react-redux";
import { FixedSizeList as List } from 'react-window';

const UsersPageTable = ({ columns, data, searchedValue, tableFor, loading, refetchUsers, lastUserRef, t, selectedUsers, setSelectedUsers, toggleSelectAllUsersInTable, selectMode, listHeight = window.innerHeight * 0.58 }) => {
    const { sortedAndSearchedData, handleSort, performSearch } = useSortableAndFuzzyData(data, columns);
    
   const [clickedUserId, setClickedUserId] = useState(null);
   const [clickedUserCourses, setClickedUserCourses] = useState([]);
   const [selectedRole, setSelectedRole] = useState({});
   const [isChangeLoading, setIsChangeLoading] = useState(false);

    const confirmModal = useHandleModal();
    const confirmModalUnblock = useHandleModal();
    const confirmModalDelete = useHandleModal();
    const changeRoleModal = useHandleModal();
    const confirmModalChangeRoleFromStudent = useHandleModal();
    const currentBranchId = useSelector(state => state.sidebar.currentCompanyBranchId);

    const clickedUserData = data?.find(user => user?.id === clickedUserId);

    useEffect(()=> {
      performSearch(searchedValue);
    }, [searchedValue, data])

    const onSelectRole = (role) => {
      setSelectedRole(role);
   };

   const isStudentRoleIsOnlyRole = clickedUserData?.isStudentRoleIsTheOnlyRoleInCompany === 'yes';
   
   // check for curators and mentors 
   const needToRemovePrevRole = clickedUserData?.roleObj?.branch?.id && (!clickedUserData?.roleObj?.role?.name?.includes(PART_OF_MENTOR_NAME) && !clickedUserData?.roleObj?.role?.name?.includes(PART_OF_CURATOR_NAME));

    const onChangeRole = async (branchId, noStudentCheck = false) => {
      if(clickedUserData?.roleObj?.role?.id === ROLE_ID_STUDENT && selectedRole?.id !== ROLE_ID_STUDENT && !noStudentCheck && isStudentRoleIsOnlyRole) {
         confirmModalChangeRoleFromStudent.open();
         return;
      }

      setIsChangeLoading(true);

      const res = await new UserApi().changeUserRole(clickedUserId, branchId, selectedRole?.id, {});
   
      if(res?.success?.message) {
         if(needToRemovePrevRole) {
            const resDeleteOldRole = await new BranchApi().deleteUserRoleInBranch(clickedUserData?.roleObj?.branch?.id, clickedUserData?.id, clickedUserData?.roleObj?.role?.id);
         }

         refetchUsers();
         toast(<ToastMsg text={res.success.message} />);
      } 
      
      if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
      } 

      setIsChangeLoading(false);

      changeRoleModal.close();
    }

    const getClickedUserCourses = async () => {
      if(!clickedUserId || clickedUserData?.roleObj?.role?.id !== ROLE_ID_STUDENT) return;

      setIsChangeLoading(true);

      const res = await new UserApi().getUser(clickedUserId, USER_EXTRA_GROUP_TWO);

      if(res?.success?.data?.userCourses?.length) {
         setClickedUserCourses(res?.success?.data?.userCourses)
      }

      setIsChangeLoading(false);
    }

    useEffect(()=> {
      getClickedUserCourses();
    }, [clickedUserData])

    const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };

    const onGiveAccessToCourses = async () => {
      const chunkSize = 100;
      const courseChunks = chunkArray(clickedUserCourses, chunkSize);
      const allAddUserPromises = [];
      
      let dataAmountProcceded = 0;
    
      try {
        for (const courseChunk of courseChunks) {
          const coursesToAdd = courseChunk?.map((course) => ({
            course_id: course?.course?.id,
            user_id: clickedUserId,
            is_admin_as_student: true
          }));

          const res = await new UserApi().changeManyAdminAsStudentStatuses(coursesToAdd);

          allAddUserPromises.push(res);
        }

        setClickedUserCourses([]);
      } catch (error) {
        console.error(`Error updating access for courses: ${error}`);
        throw error;
      } 
    
      return allAddUserPromises;
    };

    const changeRoleFromStudentToSpecialRole = async () => {
      setIsChangeLoading(true);
      
      const res = await new UserApi().changeUserRole(clickedUserId, currentBranchId, selectedRole?.id, {});
      
      if(res?.success) {
         if(needToRemovePrevRole) {
            const resDeleteOldRole = await new BranchApi().deleteUserRoleInBranch(clickedUserData?.roleObj?.branch?.id, clickedUserData?.id, clickedUserData?.roleObj?.role?.id);
         }

        await onGiveAccessToCourses();
      }

      setIsChangeLoading(false);
      refetchUsers();

      confirmModalChangeRoleFromStudent.close();
      changeRoleModal.close();
    }
    
    const unblockUser = async () => {
      setIsChangeLoading(true);

      const res = await new UserApi().updateUser(clickedUserId, {
         new_status: USER_STATUS_ACTIVE
      })

      if(res?.success?.message) {
         refetchUsers();
         toast(<ToastMsg text={res.success.message} />)
      } 
      
      if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
      } 

      setIsChangeLoading(false);

      confirmModalUnblock.close();
    }

    const deleteUser = async () => {
      setIsChangeLoading(true);
      const res = await new UserApi().deleteUser(clickedUserId)
   
      if(res?.success?.message) {
        if(refetchUsers) refetchUsers();
         toast(<ToastMsg text={res.success.message} />)
      } 
      
      if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
      } 

      setIsChangeLoading(false);

      confirmModalDelete.close();
    }

    const openConfirmModal = (userId) => {
      setClickedUserId(userId);
      confirmModal.open();
    }

    const openChangeRoleModal = (userId) => {
      setClickedUserId(userId);
      changeRoleModal.open();
    }

    const openConfirmModalUnblock = (userId) => {
      setClickedUserId(userId);
      confirmModalUnblock.open();
    }

    const openConfirmModalDelete = (userId) => {
      setClickedUserId(userId);
      confirmModalDelete.open();
    }

    const onDeleteFromPlatform = async () => {
      setIsChangeLoading(true);

      const res = await new UserApi().updateUser(clickedUserId, {
         new_status: USER_STATUS_BANNED
      });

      if(res?.success?.message) {
         refetchUsers()
         toast(<ToastMsg text={res.success.message} />)
      } 

      if(res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError/>)
      setIsChangeLoading(false);
      confirmModal.close();
    }

    const onRowClick = (row) => {
      if(tableFor === "teams") {
         window.open(`/users/team/${row?.id}`, '_blank', 'noopener,noreferrer');
         return;
      }

      if (selectMode?.length) {
         if (selectedUsers?.find(item => item?.id === row?.id)) {
            setSelectedUsers(selectedUsers?.filter(item => item?.id !== row?.id))
         } else setSelectedUsers([...selectedUsers, row])
      }
    }    

   {data?.length === 0 && !loading && (
      <EmptyBlock />
   )}

      const Row = ({ index, style }) => {
      const row = data[index];
      
      return (
        <div
          style={style}
          className={cn(styles.row, {
            [styles.row_selected]: selectedUsers?.includes(row.id),
          })}
        >
          <UsersBodyItem
            tableFor={tableFor}
            row={row}
            columns={columns}
            index={index}
            onRowClick={onRowClick}
            unblockUser={unblockUser}
            openConfirmModal={openConfirmModal}
            openConfirmModalUnblock={openConfirmModalUnblock}
            openConfirmModalDelete={openConfirmModalDelete}
            openChangeRoleModal={openChangeRoleModal}
            lastUserRef={
              index === sortedAndSearchedData?.length - 1 ? lastUserRef : null
            }
            t={t}
            selectedUsers={selectedUsers}
            isRowsClickable={selectMode?.length > 0}
          />
        </div>
      );
   };

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={cn(styles.header, column === "checkbox" && styles.header_checkbox)}
                     >
                        {column === "checkbox" ? (
                           <div 
                              onClick={toggleSelectAllUsersInTable} 
                              className={styles.checkbox_wrapper}>
                              <Checkbox
                                  extraMargin
                                  isChecked={selectedUsers?.length === data?.length}
                                  isGrey
                                 />
                                 <span className={styles.num}>{selectedUsers?.length > 0 ? selectedUsers?.length : ''}</span>
                           </div>
                        ) : (
                           <>
                              <span>{t?.returnTranslation(column?.replace('_', ' '))}</span>
                              <div className={styles.arrow_wrapper}>
                                 <span className={styles.arrow}></span>
                              </div>
                           </>
                        )}
                     </th>
                  ))}
                  {/* needed for making space for options */}
                  {tableFor !== 'requests' && tableFor !== 'teams' &&
                     <th className={styles.option_th}></th> 
                  }
               </tr>
            </thead>
         </table>

         {!!data?.length && 
            <List
               height={listHeight}
               itemCount={data?.length}
               itemSize={48}
               width="100%"
            >
               {Row}
            </List>
         }

         {data?.length === 0 && !loading && (
            <EmptyBlock />
         )}

         <ConfirmModal
               confirmButtonText={t?.blockUser}
               onConfirm={onDeleteFromPlatform}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={t?.areYouSure}
               isLoading={isChangeLoading}
            />

         <ConfirmModal
               confirmButtonText={t?.unblockUser}
               onConfirm={unblockUser}
               onClose={confirmModalUnblock.close}
               isOpen={confirmModalUnblock.isActive}
               title={t?.areYouSure}
               isLoading={isChangeLoading}
            />
         <ConfirmModal
               confirmButtonText={t?.delete}
               isRemove
               onConfirm={deleteUser}
               onClose={confirmModalDelete.close}
               isOpen={confirmModalDelete.isActive}
               title={t?.areYouSure}
               subtitle={t?.youWillNotBeAbleToChangeYourChoice}
               isLoading={isChangeLoading}
            />

         <ChangeRoleModal
            onConfirm={onChangeRole}
            onClose={()=> {
               changeRoleModal.close();
               setSelectedRole({});
            }}
            isOpen={changeRoleModal.isActive}
            selectedRole={selectedRole}
            onSelectRole={onSelectRole}
            title={t?.changeRole}
            isLoading={isChangeLoading}
            t={t}
         />

         <ConfirmModal
            confirmButtonText={t?.confirm}
            onConfirm={changeRoleFromStudentToSpecialRole}
            onClose={confirmModalChangeRoleFromStudent.close}
            isOpen={confirmModalChangeRoleFromStudent.isActive}
            title={t?.areYouSure}
            isLoading={isChangeLoading}
            subtitle={t?.transitioningFromStudentToSpecialRole}
            maxHeight={"410px"}
         />
        
      </div>
   );
};

export default UsersPageTable;
