import { Droppable } from "react-beautiful-dnd";
import compareIcon from "../../../../assets/images/compare_icon.svg";
import {
   COMPARE_ANSWER_CODE,
   MULTIPLE_CHOICE_ANSWER_CODE,
   ONE_CHOICE_ANSWER_CODE,
   SEQUENCE_ANSWER_CODE,
   TRUE_FALSE_ANSWER_CODE,
} from "../../../../utils/questionsHelper";
import DraggingArrows from "../../../../components/DraggingArrows/DraggingArrows";

import styles from "./styles.module.scss";
import cn from "classnames";

const AnswerSequence = ({
   type,
   item,
   answerIdx,
   inputsData,
   setInputsData,
   isDragging
}) => {
   return (
      <div key={answerIdx} className={cn(styles.answer, styles.no_padding)}>
         <div
            className={cn(
               styles.answer_block,
               styles.answer_sequence,
               styles.label,
               isDragging ? styles.dragging_answer : ''
            )}
         >
            <DraggingArrows isDragging={isDragging} />
            <div>{item?.content}</div>
         </div>
      </div>
   );
};

export default AnswerSequence;
