import React, { useEffect, useState } from "react";
import {
   ALL_STUDENTS_TABLE_HEADERS,
} from "../../../constants/user";

import styles from "./styles.module.scss";
import UsersPageTable from "../UsersPageTable/UsersPageTable";

const TableBlock = ({
   headers,
   data,
   tableFor,
   refetchUsers,
   loading,
   lastUserRef,
   t,
   selectMode,
   selectedUsers,
   setSelectedUsers,
   toggleSelectAllUsersInTable,
   listHeight
}) => {
   return (
      <div className={styles.table_block}>
         <div className={styles.table_wrapper}>
            <UsersPageTable
               columns={headers}
               data={data}
               tableFor={tableFor}
               refetchUsers={refetchUsers}
               loading={loading}
               lastUserRef={lastUserRef}
               t={t}
               selectMode={selectMode}
               selectedUsers={selectedUsers}
               setSelectedUsers={setSelectedUsers}
               toggleSelectAllUsersInTable={toggleSelectAllUsersInTable}
               listHeight={listHeight}
            />
         </div>
      </div>
   );
};

export default TableBlock;
