import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import searchIcon from "../../../assets/images/symbols/Search.svg";
import notFound from "../../../assets/images/symbols/nothing_found.svg";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import Loader from "../../Loader/Loader";
import cn from "classnames";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const SelectItemsModal = ({
   isOpen,
   onClose,
   setSelectedItems,
   selectedItems,
   data,
   onRequest,
   oneStep,
   isDataLoading,
   searchedValue,
   setSearchedValue,
   loading,
   lastItemRef,
   isOneStep,
}) => {
   const t = useFindTranslationsByString();

   const onSelectFilters = (course) => {
      if (selectedItems?.find((item) => item?.id === course?.id))
         setSelectedItems(
            selectedItems?.filter((item) => item?.id !== course?.id)
         );
      else setSelectedItems([...selectedItems, course]);
   };

   const closeModal = () => {
      setSearchedValue('')
      onClose();
      if (oneStep && setSelectedItems) setSelectedItems([]);
   };

   useEffect(() => {
      return () => {
         if(setSelectedItems) setSelectedItems([]);
      };
   }, []);

   return (
      <ModalLayout
         isOpen={isOpen}
         onClose={closeModal}
         maxHeight={"550px"}
         maxWidth={"416px"}
         withCross
         noBlur
      >
         <p className={styles.title}>{t?.selectCourse}</p>
         <p className={styles.selected}>
            {t("Selected")}: <span>{selectedItems?.length}</span>
         </p>
         <div className={styles.search}>
            <img src={searchIcon} alt={""} />
            <input
               placeholder={t("Search")}
               value={searchedValue}
               onChange={(event) => {
                  setSearchedValue(event?.target?.value);
               }}
            />
         </div>

         {!!data?.length ? (
            <div className={styles.list}>
               {data?.map((item, key) => (
                  <div
                     ref={
                        data?.length - 1 === key && lastItemRef
                           ? lastItemRef
                           : null
                     }
                     key={key}
                     onClick={() => onSelectFilters(item)}
                     className={styles.list_item}
                  >
                     <Checkbox
                        isChecked={selectedItems?.some(
                           (sItem) => sItem?.id === item?.id
                        )}
                        isGrey
                     />
                     <span>{item?.name}</span>

                     {item?.department?.name?.length > 0 && 
                        <span className={styles.depName}>{`(${item?.department?.name})`}</span>
                     }
                  </div>
               ))}
            </div>
         ) : (
            <div className={styles.notFound}>
               <img src={notFound} alt={""} />
               {t("There are no data")}
            </div>
         )}

         <Button
            disabled={isDataLoading || !selectedItems?.length}
            title={t("Done")}
            onClick={isOneStep ? onRequest : closeModal}
         />

         {isDataLoading && (
            <div className="default_loader_wrapper">
               <Loader size={"small"} />
            </div>
         )}
      </ModalLayout>
   );
};

export default SelectItemsModal;
