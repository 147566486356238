import deleteIcon from "../../../assets/images/delete_icon.svg";
import editIcon from "../../../assets/images/edit_icon_gray.svg";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import useOptions from "../../../hooks/useOptions";
import styles from "./styles.module.scss";
import cn from "classnames";

const BodyItem = ({
   row,
   columns,
   index,
   onClickDeleteIcon,
   onClickEditIcon,
   isDepartments,
   onRemoveAccess,
   lastItemRef,
   data,
   t,
}) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   return (
      <tr
         ref={data?.length - 1 === index ? lastItemRef : null}
         key={`row-${index}`}
         className={index % 2 === 0 ? styles.rowOdd : ""}
      >
         {columns.map((column, columnIndex) => (
            <td key={`cell-${index}-${columnIndex}`}>
               <div className={cn(styles.cellContent)}>
                  {Array.isArray(row[column]) ? (
                     row[column].map((item, itemIdx) => (
                        <div
                           className={styles.pos_item}
                           key={`array-cell-${index}-${columnIndex}-${itemIdx}`}
                        >
                           {typeof item === "object" ? item.name : item}{" "}
                        </div>
                     ))
                  ) : typeof row[column] === "object" ? (
                     <div>{row[column]?.name}</div>
                  ) : column === "actions" ? (
                     <div className={styles.actions_wrapper}>
                        <div
                           onClick={() => onClickEditIcon(row, isDepartments)}
                           className={styles.action_img}
                        >
                           <img src={editIcon} alt="" />
                        </div>
                        <div
                           onClick={() => onClickDeleteIcon(row, isDepartments)}
                           className={styles.action_img}
                        >
                           <img src={deleteIcon} alt="" />
                        </div>
                     </div>
                  ) : (
                     <CustomTooltip
                        id={`cell-${index}-${columnIndex}`}
                        limit={120}
                        text={row[column]}
                     />
                  )}
               </div>
            </td>
         ))}
      </tr>
   );
};

export default BodyItem;
