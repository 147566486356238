import Base from "./Base";

export default class UserAnswerApi extends Base {
    getYourUserAnswer() {
        return super.get("api/v1/user-answer/get-your-answers");
    }

    createAnswer(data) {
      return super.post("api/v1/user-answer", data);
    }

    createManyAnswers(data, serializationGroup) {
      return super.post("api/v1/user-answer/process-many", data, null, null, serializationGroup);
    }

    getPresignedQuestionFilesUploadUrls(data) {
      return super.post(`api/v1/user-answer/get-aws-s3-presigned-upload-urls`, data)
    }
}