import React, { useEffect, useState, useRef } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";
import csvImg from "../../../assets/images/symbols/scv.svg";
import plus from "../../../assets/images/symbols/plus.svg";
import cross from "../../../assets/images/symbols/cross_grey.svg";
import SearchInput from "../../../components/SearchInput/SearchInput";

import Button from "../../../components/Button/Button";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";

import styles from "./styles.module.scss";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { CSVLink } from "react-csv";
import SelectCourseModal from "../../../components/Modals/SelectCourseModal/SelectCourseModal";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import { BIGGER_DATA_LIMIT } from "../../../constants/user";
import SelectItemsModal from "../../../components/Modals/SelectItemsModal/SelectItemsModal";
import CreateDepOrPosModal from "../../../components/Modals/CreateDepOrPosModal/CreateDepOrPosModal";
import PaginatedDropdown from "../../../components/PaginatedDropdown/PaginatedDropdown";

const Subheader = ({
   searchedValue,
   setSearchedValue,
   csvData,
   data,
   fetchDataForCSV,
   isDepartments,
   isLoading,
   setSelectedItems,
   selectedItems,
   lastDepOrPosRef,
   setEntityName,
   entityName,
   onCreateDepartment,
   onCreatePosition,
   createModal,
   onRemoveSelectedItem,
   setSelectedItem,
   selectedItem,
   activeFilter,
   setActiveFilter,
   searchedValueDep,
   setSearchedValueDep,
   searchedValuePos,
   setSearchedValuePos,
   filterDepartments,
   filterPositions,
   lastFilterPosRef,
   lastFilterDepRef,
   actionData,
   setActionData,
   isAddLoading
}) => {
   const csvLinkRef = useRef(null);   

   const selectItemsModal = useHandleModal();

   const t = useFindTranslationsByString();

   const handleCSV = () => {
      if (fetchDataForCSV) {
         fetchDataForCSV(1, BIGGER_DATA_LIMIT).then(() =>
            csvLinkRef.current.link.click()
         );
      } else {
         csvLinkRef.current.link.click();
      }
   };

   const handleFilterClick = (item, isDep) => {
      if (isDep) {
         setActiveFilter({
            ...activeFilter,
            department: item,
         });
      } else {
         setActiveFilter({
            ...activeFilter,
            position: item,
         });
      }
   };

   return (
      <div className={styles.subheader}>
         <div className={styles.line}></div>
         <div className={styles.search_wrapper}>
            <SearchInput
               placeholder={t("Search")}
               searchedValue={searchedValue}
               setSearchedValue={setSearchedValue}
            />
         </div>

         <div className={styles.filters_plus_btns}>
            <div className={styles.filter}>
               <PaginatedDropdown
                  crossNeeded
                  data={filterDepartments}
                  field={"name"}
                  placeholder={t("Department")}
                  value={activeFilter?.department?.name || ""}
                  onChange={(filter) => handleFilterClick(filter, true)}
                  searchValue={searchedValueDep}
                  setSearchValue={setSearchedValueDep}
                  lastItemRef={lastFilterDepRef}
                  searchNeeded
               />
            </div>

            <div className={styles.exropt_btn_rwapper}>
               <Button
                  title={t("Export to CSV")}
                  image={csvImg}
                  isBlack
                  maxWidth={"180px"}
                  onClick={handleCSV}
                  disabled={isLoading}
               />

               <CSVLink
                  ref={csvLinkRef}
                  style={{ textDecoration: "none" }}
                  data={csvData}
               />

<Button
               title={
                  isDepartments ? t("Create department") : t("Create position")
               }
               image={plus}
               maxWidth={"260px"}
               onClick={createModal.open}
            />
            </div>

          
         </div>

         <CreateDepOrPosModal
            title={
               isDepartments
                  ? t(
                       actionData?.data?.id
                          ? "Edit department"
                          : "Create department"
                    )
                  : t(
                       actionData?.data?.id
                          ? "Edit position"
                          : "Create position"
                    )
            }
            actionData={actionData}
            setActionData={setActionData}
            confirmBtnText={actionData?.data?.id ? "Confirm" : "Create"}
            isDepartments={isDepartments}
            data={data}
            entityName={entityName}
            setEntityName={setEntityName}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            onOpenSelectModal={() => {
               selectItemsModal.open();
               createModal.close();
               setSearchedValue("");
            }}
            isOpen={createModal.isActive}
            onClose={() => {
               createModal.close();
               setSearchedValue("");
               setEntityName("");
               setActionData(null)
               if (setSelectedItems) setSelectedItems([]);
               if (setSelectedItem) setSelectedItem("");
            }}
            createSuccessCallback={
               isDepartments ? onCreateDepartment : onCreatePosition
            }
            onRemove={onRemoveSelectedItem}
            lastItemRef={lastDepOrPosRef}
            isAddLoading={isAddLoading}
         />

         <SelectItemsModal
            onClose={() => {
               selectItemsModal.close();
               createModal.open();
            }}
            isOpen={selectItemsModal.isActive}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            data={filterPositions}
            loading={isLoading}
            searchedValue={searchedValuePos}
            setSearchedValue={setSearchedValuePos}
            lastItemRef={lastFilterPosRef}
         />
      </div>
   );
};

export default Subheader;
