import { FOLDER_STATUS_ACTIVE, FOLDER_STATUS_DELETED, FOLDER_STATUS_HIDEN } from "../../constants/courses";
import Base from "./Base";
import { FOLDER_EXTRA_GROUP_ONE } from "./serialization_groups";

export default class FolderApi extends Base {
    
    getOneFolder(folderID) {
        return super.get(`api/v1/folder/${folderID}`, null, null, FOLDER_EXTRA_GROUP_ONE);
    }

    deleteFolder(folderID) {
        return super.delete(`api/v1/folder/${folderID}`);
    }

    changeFolderVisibilityForUsers(folderID, data) {
        return super.put(`api/v1/folder/${folderID}/user-visibility`, data);
    }

    getFoldersInfo(data) {
        return super.post(`api/v1/folder/get-folder-info`, data);
    }

    changeFolderVisibilityForTeams(data) {
        return super.put(`api/v1/folder/add-visibility-to-teams`, data);
    }

    changeManyFolderVisibilityForUsers(data) {
        return super.put(`api/v1/folder/add-visibility-to-users`, data);
    }

    editFolderSetting(folderID, data) {
        return super.put(`api/v1/folder/${folderID}`, data);
    }

    getAllYourActiveTopics(companyId) {
      const url = `api/v1/folder?limit=100&page=1&filter[is_topic]=1&filter[company]=${companyId}&filter[parent_folder_status]=${FOLDER_STATUS_ACTIVE}&filter[status]=${FOLDER_STATUS_ACTIVE}&order=desc`;
      return super.get(url)
    }

    getAllTopicsInFolder(companyId, folderId, serializationGroup) {
      const url = `api/v1/folder?limit=100&page=1&filter[company]=${companyId}&filter[is_topic]=1&filter[parent_id]=${folderId}&order=desc`;
      return super.get(url, null, null, serializationGroup)
    }

    getYourFolders({ limit, page, companyId, userId, isTopic, status, name, order, signal, searchedValue, serializationGroup, topicsIdsFilter, parentId} ) {
      let url = `api/v1/folder?limit=${limit}&page=${page}&filter[folders_visible_to_user]=${userId}&filter[company]=${companyId}`

      if(isTopic) {
         url += `&filter[is_topic]=${1}`;
      } else {
         url += `&filter[is_topic]=${0}`;
      }

      if(parentId) {
         url += `&filter[parent_id]=${parentId}`;
      }

      if(topicsIdsFilter?.length > 0) {
         url += `&filter[get_parents_by_multiple_children_ids]=[${topicsIdsFilter?.map(item => item?.id)}]`;
      }

      if(status === 'deleted') {
         url += `&filter[status]=${FOLDER_STATUS_DELETED}`;
      }

      if(status === 'hidden') {
         url += `&filter[status]=${FOLDER_STATUS_HIDEN}`;
      }

      if(!status) {
         url += `&filter[status]=${FOLDER_STATUS_ACTIVE}`;
      }

      if(searchedValue) {
         url += `&filter[search]=${searchedValue}`;
      }

      if(order) {
         url += `&order=${order}`  
      } else {
         url += `&order=desc` 
      }

        return super.get(url, null, signal, serializationGroup);
    }

    getAccessibleCoursesUsers() {
        return super.get("api/v1/folder/accessible-courses-users");
    }

    addCoursesToFolder(folderID, data) {
        return super.post(`api/v1/folder/${folderID}/courses`, data);
    }

    removeCourseFromFolder(folderID, courseID) {
        return super.delete(`api/v1/folder/${folderID}/course/${courseID}`);
    }

    addCourseToFolder(folderID, courseID) {
        return super.put(`api/v1/folder/${folderID}/course/${courseID}`);
    }

    updateFolder(folderID, data) {
        return super.put(`api/v1/folder/${folderID}`, data);
    }

    createFolder(data) {
        return super.post("api/v1/folder", data);
    }

    duplicateFolder(folderID, destinationBranchID) {
      return super.post(`api/v1/folder/${folderID}/duplicate-to-new-branch/${destinationBranchID}`);
  }
}