import { LESSON_TYPE_WITH_TESTING_NUMBER } from "../../constants/courses";
import { DEFAULT_DATA_LIMIT } from "../../constants/user";
import Base from "./Base";

export default class StatisticsApi extends Base {
   getYourIntegration() {
      return super.get(`api/v1/user/get-your-integration-data`);
   }

   getAllUsersGrowth() {
      return super.get(`api/v1/statistics/all-user-growth`);
   }

   getAllUsersGrowthForCompany(companyId) {
      return super.get(`api/v1/statistics/user-growth/company/${companyId}`);
   }

   getAllUsersOnline() {
      return super.get(`api/v1/statistics/all-active-users`);
   }

   getTotalRegistrated() {
      return super.get(`api/v1/statistics/registered-user-total`);
   }

   getCoursesStatistics({ page = 1, companyId, courseId, searchQuery, tag, order_by, order }) {
      let endpoint = `api/v1/statistics/course?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (tag) {
         endpoint += `&filter[course_tag_name]=${tag}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (courseId) {
         endpoint += `&filter[course_id]=${courseId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }

   getTestsStatistics(page = 1, searchQuery, companyId, courseId, order_by, order) {
      let endpoint = `api/v1/statistics/lesson?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;
      endpoint += `&filter[type]=${LESSON_TYPE_WITH_TESTING_NUMBER}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (courseId) {
         endpoint += `&filter[course_id]=${courseId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }
   
   getCourseProgressStatistics({ page = 1, searchQuery, companyId, courseId, order_by, order }) {
      let endpoint = `api/v1/statistics/course-progress?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (courseId) {
         endpoint += `&filter[course_id]=${courseId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }

   getOneCourseProgressStatistics(courseId) {
      let endpoint = `api/v1/statistics/course-progress?limit=${1}&page=${1}`;

      if (courseId) {
         endpoint += `&filter[course_id]=${courseId}`;
      }

      return super.get(endpoint);
   }

   getUserCourseStatistics(page = 1, companyId, userId, searchQuery, order_by, order, progressRange, signal) {
      let endpoint = `api/v1/statistics/user-course/user/${userId}?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;
      
      endpoint += `&filter[status]=${1}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (progressRange) {
         endpoint += `&filter[course_progress_range]=${progressRange}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } else {
         endpoint += `&order_by=progress`;
      }

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint, null, signal);
   }

   //  We get one user to get his score
   getUserScoreStatistics(companyId, userId, signal) {
      return super.get(`api/v1/statistics/users?limit=${1}&page=${1}&filter[company_id]=${companyId}&filter[user_id]=${userId}`, null, signal);
   }

   getUsersStatistics(page = 1, searchQuery, companyId, courseId, teamId, userId,  order_by, order) {
      let endpoint = `api/v1/statistics/users?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (teamId) {
         endpoint += `&filter[team_id]=${teamId}`;
      }

      if (userId) {
         endpoint += `&filter[user_id]=${userId}`;
      }

      if (courseId) {
         endpoint += `&filter[course_id]=${courseId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }

   getUserIntegrationStatistics(companyId, userId, signal) {
      return super.get(`api/v1/statistics/user-integration?filter[user_id]=${userId}&filter[company_id]=${companyId}`, null, signal);
   }

   getIntegrationStatistics(page = 1, searchQuery, companyId, teamId, order_by, order) {
      let endpoint = `api/v1/statistics/user-integration?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (teamId) {
         endpoint += `&filter[team_id]=${teamId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }

   getQuestionsStatistics(page, searchQuery, companyId, courseId, order_by, order) {
      let endpoint = `api/v1/statistics/question?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (courseId) {
         endpoint += `&filter[course_id]=${courseId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }

   getCertificatesStatistics(page, searchQuery, companyId, teamId, order_by, order) {
      let endpoint = `api/v1/statistics/certificate?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (teamId) {
         endpoint += `&filter[team_id]=${teamId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }

   getCompaniesStatistics(page, searchQuery, companyId, teamId, order_by, order) {
      let endpoint = `api/v1/statistics/company?limit=${DEFAULT_DATA_LIMIT}&page=${page}`;
      
      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (companyId) {
         endpoint += `&filter[company_id]=${companyId}`;
      }

      if (teamId) {
         endpoint += `&filter[team_id]=${teamId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      } 

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint);
   }
}
