import { useEffect, useState } from "react";
import CourseApi from "../../utils/api/CourseApi";
import CertificateApi from "../../utils/api/CertificateApi";

const useGetCoursesCount = (companyId, userId, isCertificatesCountNeeded) => {
   const [coursePassingInfo, setCoursePassingInfo] = useState({
      completed: 0,
      ongoing: 0,
      notStarted: 0,
   });

   const [userCertificatesCount, setUserCertificatesCount] = useState(0);

   const abortController = new AbortController();
   const signal = abortController.signal;

   const getUserCoursesCount = async () => {
      try {
         const resOngoing = await new CourseApi().getUserOngoingCoursesCount(
            companyId,
            userId,
            signal
         );
         const resCompleted =
         await new CourseApi().getUserCompletedCoursesCount(
            companyId,
            userId,
            signal
         );
       
            const resAllAvailable =
            await new CourseApi().getUserAllAvailableCoursesCount(
               companyId,
               userId,
               signal
            );

         const allAvailableCount = resAllAvailable?.success?.totalCount || 0;

         const ongoingCount = resOngoing?.success?.totalCount || 0;
         const completedCount = resCompleted?.success?.totalCount || 0;
         const notStartedCount = Math.max(allAvailableCount - (ongoingCount + completedCount), 0);

         setCoursePassingInfo({
            ongoing: ongoingCount,
            notStarted: notStartedCount,
            completed: completedCount,
         });
      } catch (error) {
         console.error("Error fetching user courses count:", error);
      }
   };

   const getUserCertificateCount = async () => {
      if(!isCertificatesCountNeeded) return;

      const res = await new CertificateApi().getUserCertificatesCount(companyId, userId);
      
      if (res?.success?.data) {
         setUserCertificatesCount(res?.success?.totalCount || 0)
      }
   }

   useEffect(() => {
      if (userId && companyId) {
         getUserCoursesCount();
         getUserCertificateCount();
      }

      return ()=> {
         abortController.abort();
      }
   }, [userId, companyId]);

   return {
      coursePassingInfo,
      userCertificatesCount,
      setCoursePassingInfo,
   };
};

export default useGetCoursesCount;
