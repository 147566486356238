import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

import Subheader from "./Subheader/Subheader";

import {
   DEPARTMENTS_HEADERS,
   POSITIONS_HEADERS,
   mapDepartments,
   mapPositions,
} from "../../constants/departmentsAndPositions";

import { useNavigate, useParams } from "react-router-dom";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import useOptions from "../../hooks/useOptions";
import useUserRole from "../../hooks/useUserRole";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import InfoModal from "../../components/Modals/InfoModal/InfoModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import RenameModal from "../../components/Modals/RenameModal/RenameModal";

import { useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import useGetDepartments from "../../hooks/api/departmentsAndPostitions/useGetDepartments";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";
import DepAndPosTable from "./DepAndPosTable/DepAndPosTable";
import alertImage from "../../assets/images/alert_image.png";
import cn from "classnames";
import styles from "./styles.module.scss";
import DepartmentsAndPositionsApi from "../../utils/api/DepartmentsAndPositionsApi";
import useGetPositions from "../../hooks/api/departmentsAndPostitions/useGetPositions";
import { PART_OF_ADMIN_NAME, ROLE_ID_SUPER_ADMIN } from "../../constants/user";

const initialActiveFilter = {
   department: null,
   position: null,
};

const DepartmentsAndPositionsPage = () => {
   const params = useParams();
   const navigate = useNavigate();

   const t = useFindTranslationsByString();
   const confirmModal = useHandleModal();
   const unableToDeleteModal = useHandleModal();

   const [isAddLoading, setIsAddLoading] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [activeTab, setActiveTab] = useState(params.tab);

   const [activeFilter, setActiveFilter] = useState(initialActiveFilter);

   const [entityName, setEntityName] = useState("");

   const [searchedValue, setSearchedValue] = useState("");
   const [isChangeLoading, setIsChangeLoading] = useState(false);

   const [selectedDepartment, setSelectedDepartment] = useState([]);
   const [selectedPositionsList, setSelectedPositionsList] = useState([]);

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const userId = useSelector((state) => state?.user?.info?.id);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const redirectToUserProfileAdminView = (userId) => {
      navigate(`/users/team/${team?.id}/profile/${userId}`);
   };

   const createModal = useHandleModal();

   const [depOrder, setDepOrder] = useState("");
   const [depOrderBy, setDepOrderBy] = useState("");

   const [posOrder, setPosOrder] = useState("");
   const [posOrderBy, setPosOrderBy] = useState("");

   const {
      data: departments,
      lastItemRef: lastDepartmentRef,
      refetch: refetchDepartments,
      searchedValue: searchedValueDep,
      setSearchedValue: setSearchedValueDep,
      loading: isDepLoading,
      fetchData: fetchDeps,
   } = useGetDepartments({
      companyId: currentCompanyId,
      order_by: depOrderBy,
      order: depOrder,
      activeFilter,
   });

   const {
      data: positions,
      lastItemRef: lastPositionRef,
      refetch: refetchPositions,
      searchedValue: searchedValuePos,
      setSearchedValue: setSearchedValuePos,
      loading: isPosLoading,
      fetchData: fetchPositions,
   } = useGetPositions({
      companyId: currentCompanyId,
      order_by: posOrderBy,
      order: posOrder,
      activeFilter,
   });

   const {
      data: filterDepartments,
      lastItemRef: lastFilterDepartmentRef,
      searchedValue: filterSearchedValueDep,
      setSearchedValue: setFilterSearchedValueDep,
      refetch: refetchFilterDepartments,
   } = useGetDepartments({
      companyId: currentCompanyId,
   });

   const {
      data: filterPositions,
      lastItemRef: lastFilterPositionRef,
      searchedValue: filterSearchedValuePos,
      setSearchedValue: setFilterSearchedValuePos,
      refetch: refetchFilterPositions,
   } = useGetPositions({
      companyId: currentCompanyId,
   });

   const initialActionData = {
      data: null,
      isDepartments: false,
   };

   const [actionData, setActionData] = useState(initialActionData);

   const onClickEditIcon = (item, isDepartments) => {
      createModal.open();

      setActionData({
         data: item,
         isDepartments,
      });

      const activePositions = item?.positions?.filter(item => item?.isActive);

      if (activePositions?.length) {
         setSelectedPositionsList(activePositions);
      }
   };

   const onClickDeleteIcon = (item, isDepartments) => {
      if (item?.users > 0) {
         unableToDeleteModal.open();
         return;
      } else {
         confirmModal.open();
      }

      setActionData({
         data: item,
         isDepartments,
      });
   };

   const onDeleteEntity = async () => {
      setIsAddLoading(true);

      if (activeTab === "1") {
         const res = await new DepartmentsAndPositionsApi().deleteDepartment(
            actionData?.data?.id
         );

         if (res?.success?.message) {
            toast(<ToastMsg text={res?.success?.message} />);
            confirmModal.close();

            refetchDepartments();
            refetchFilterDepartments();
            refetchFilterPositions();
            refetchPositions();
            setActiveFilter(initialActiveFilter);
         }

         if (res?.error?.message) {
            toast(<ToastMsg text={res?.error?.message} isError />);
         }
      }

      if (activeTab === "2") {
         const res = await new DepartmentsAndPositionsApi().deletePosition(
            actionData?.data?.id
         );

         if (res?.success?.message) {
            toast(<ToastMsg text={res?.success?.message} />);
            confirmModal.close();

            refetchPositions();
            refetchFilterPositions();
            refetchDepartments();
         }

         if (res?.error?.message) {
            toast(<ToastMsg text={res?.error?.message} isError />);
         }
      }
      setActionData(initialActionData);
      setIsAddLoading(false);
   };

   const renameEntity = async (entity) => {
      setIsAddLoading(true);
      const data = {
         new_name: entityNam?.trim(),
      };

      const res = await new TeamApi().updateTeam(params?.id, data);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         renameModal.close();
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsAddLoading(false);
   };

   const deleteEntity = async (entity) => {
      setIsChangeLoading(true);
      const res = await new TeamApi().deleteTeam(entity?.id);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         navigate("/users");
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }
      setIsChangeLoading(false);
   };

   const handleTabChangeInParams = (tab) => {
      navigate(`/departments_and_positions/${tab}`);
   };

   const handleTabClick = (item) => {
      setActiveTab(item);
   };

   useEffect(() => {
      handleTabChangeInParams(activeTab);
   }, [activeTab]);

   useEffect(()=> {
      // needed for updation selected list on refetch data to prevent wrong list.
      if(actionData?.data?.position?.length > 0) {
         const matchedDep = departments?.find(d => d?.id === actionData?.data?.id);
         setSelectedPositionsList(matchedDep?.positions?.filter(item => item?.isActive));
      }
   }, [departments])


   const onCreateDepartment = async () => {
      setIsAddLoading(true);

      const data = {
         name: entityName?.trim(),
         company_id: currentCompanyId,
         ...(selectedPositionsList?.length
            ? { positions: selectedPositionsList?.map((item) => item?.id) }
            : {}),
      };

      const dataForUpdate = {
         ...(entityName?.length ? { new_name: entityName?.trim() } : {}),
         ...(selectedPositionsList?.length
            ? { new_positions: selectedPositionsList?.map((item) => item?.id) }
            : {}),
            do_change_active_status_current_positions_from_department: 1,
      };

      const res = actionData?.data?.id
         ? await new DepartmentsAndPositionsApi().updateDepartment(
              dataForUpdate,
              actionData?.data?.id
           )
         : await new DepartmentsAndPositionsApi().createDepartment(data);

      if (res?.success) {
         refetchDepartments();
         refetchFilterDepartments();
         refetchFilterPositions();
         refetchPositions();
         toast(<ToastMsg text={res?.success?.message} />);
         setEntityName("");
         setSelectedPositionsList([]);
         createModal.close();
         setActionData(initialActionData);
      }

      if (res?.error) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }
      setIsAddLoading(false);
   };

   const onCreatePosition = async () => {
      if (!selectedDepartment?.id && !actionData?.data?.id) {
         toast(<ToastMsg text={"Department is required"} isError />);
         return;
      }

      setIsAddLoading(true);

      const data = {
         name: entityName?.trim(),
         department_id: selectedDepartment?.id,
      };

      const dataToUpdate = {
         ...(entityName?.length ? { new_name: entityName?.trim() } : {}),
         ...(selectedDepartment?.id
            ? { new_department_id: selectedDepartment?.id }
            : {}),
      };

      const res = actionData?.data?.id
         ? await new DepartmentsAndPositionsApi().updatePosition(
              dataToUpdate,
              actionData?.data?.id
           )
         : await new DepartmentsAndPositionsApi().createPosition(data);

      if (res?.success) {
         refetchPositions();
         refetchFilterPositions();
         refetchDepartments();
         toast(<ToastMsg text={res?.success?.message} />);
         createModal.close();
         setEntityName("");
         setSelectedDepartment(null);
         setActionData(initialActionData);
      }
      if (res?.error) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsAddLoading(false);
   };

   useEffect(() => {
      setSearchedValue("");
   }, [activeTab]);

   const onRemoveSelectedItem = (item) => {
      const newItems = selectedPositionsList?.filter((itm) => itm?.id !== item);
      setSelectedPositionsList(newItems);
   };

   const mappedDepartments = departments?.map(mapDepartments);
   const mappedPositions = positions?.map(mapPositions);

   const generateCsvDataDeps = () => {
      return [
         DEPARTMENTS_HEADERS.filter((header) => header !== "actions")?.map(
            (header) => t(header)
         ),
         ...mappedDepartments?.map(({ department, positions, users }) => [
            department,
            positions?.map((pos) => pos.name).join(", "),
            users,
         ]),
      ];
   };

   const generateCsvDataPositions = () => {
      return [
         POSITIONS_HEADERS.filter((header) => header !== "actions")?.map(
            (header) => t(header)
         ),
         ...mappedPositions?.map(({ departments, position, users }) => [
            position,
            departments?.map((dept) => dept.name).join(", "),
            users,
         ]),
      ];
   };

   const isNotExistedTab = activeTab !== "1" && activeTab !== "2";

   if (
      (!userRoleName?.includes(PART_OF_ADMIN_NAME) &&
         userRoleId !== ROLE_ID_SUPER_ADMIN) ||
      isNotExistedTab
   ) {
      return (
         <MainLayout>
            <div className="centered">
               <EmptyBlock
                  text={isNotExistedTab ? "404 Page not found" : "No access"}
               />
            </div>
         </MainLayout>
      );
   }

   return (
      <MainLayout darkBg>
         <main className={styles.users_page}>
            <div className={styles.loader_wrapper}>
               {isLoading && <SecondLoader />}
            </div>
            <div className={styles.tab_navigation}>
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "1" && styles.activeTab
                  )}
                  onClick={() => handleTabClick("1")}
               >
                  {t("Departments")}
               </button>
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "2" && styles.activeTab
                  )}
                  onClick={() => handleTabClick("2")}
               >
                  {t("Positions")}
               </button>
            </div>

            {activeTab === "1" && (
               <>
                  <Subheader
                     key={"departments"}
                     setSearchedValue={setSearchedValueDep}
                     searchedValue={searchedValueDep}
                     csvData={generateCsvDataDeps()}
                     setSelectedItems={setSelectedPositionsList}
                     selectedItems={selectedPositionsList}
                     data={positions}
                     filterDepartments={filterDepartments}
                     lastDepOrPosRef={null}
                     isLoading={isDepLoading}
                     isDepartments
                     fetchDataForCSV={fetchDeps}
                     entityName={entityName}
                     setEntityName={setEntityName}
                     createModal={createModal}
                     onRemoveSelectedItem={onRemoveSelectedItem}
                     setActiveFilter={setActiveFilter}
                     activeFilter={activeFilter}
                     searchedValueDep={filterSearchedValueDep}
                     setSearchedValueDep={setFilterSearchedValueDep}
                     lastFilterDepRef={lastFilterDepartmentRef}
                     actionData={actionData}
                     setActionData={setActionData}
                     isAddLoading={isAddLoading}
                     onCreateDepartment={onCreateDepartment}
                     onCreatePosition={onCreatePosition}
                     searchedValuePos={filterSearchedValuePos}
                     setSearchedValuePos={setFilterSearchedValuePos}
                     filterPositions={filterPositions}
                     lastFilterPosRef={lastFilterPositionRef}
                  />
                  <DepAndPosTable
                     key={"departments_table"}
                     data={mappedDepartments}
                     lastItemRef={lastDepartmentRef}
                     columns={DEPARTMENTS_HEADERS}
                     searchedValue={searchedValue}
                     isLoading={isDepLoading}
                     order={depOrder}
                     setOrder={setDepOrder}
                     orderBy={setDepOrder}
                     setOrder={setDepOrder}
                     setOrderBy={setDepOrderBy}
                     onClickDeleteIcon={onClickDeleteIcon}
                     onClickEditIcon={onClickEditIcon}
                     isDepartments
                  />
               </>
            )}

            {activeTab === "2" && (
               <>
                  <Subheader
                     key={"postions"}
                     setSearchedValue={setSearchedValuePos}
                     searchedValue={searchedValuePos}
                     fetchDataForCSV={fetchPositions}
                     csvData={generateCsvDataPositions()}
                     data={departments}
                     lastDepOrPosRef={lastDepartmentRef}
                     entityName={entityName}
                     setEntityName={setEntityName}
                     createModal={createModal}
                     isLoading={isPosLoading}
                     setSelectedItem={setSelectedDepartment}
                     selectedItem={selectedDepartment}
                     setActiveFilter={setActiveFilter}
                     activeFilter={activeFilter}
                     lastFilterDepRef={lastFilterDepartmentRef}
                     filterDepartments={filterDepartments}
                     searchedValueDep={filterSearchedValueDep}
                     setSearchedValueDep={setFilterSearchedValueDep}
                     actionData={actionData}
                     setActionData={setActionData}
                     isAddLoading={isAddLoading}
                     onCreateDepartment={onCreateDepartment}
                     onCreatePosition={onCreatePosition}
                     searchedValuePos={filterSearchedValuePos}
                     setSearchedValuePos={setFilterSearchedValuePos}
                     filterPositions={filterPositions}
                     lastFilterPosRef={lastFilterPositionRef}
                  />
                  <DepAndPosTable
                     key={"positions_table"}
                     data={mappedPositions}
                     isAccess
                     lastItemRef={lastPositionRef}
                     columns={POSITIONS_HEADERS}
                     searchedValue={searchedValue}
                     isLoading={isPosLoading}
                     order={posOrder}
                     setOrder={setPosOrder}
                     setOrderBy={setPosOrderBy}
                     orderBy={posOrderBy}
                     onClickDeleteIcon={onClickDeleteIcon}
                     onClickEditIcon={onClickEditIcon}
                  />
               </>
            )}

            <InfoModal
               onClose={() => {
                  unableToDeleteModal.close();
                  setActionData(initialActionData);
               }}
               isOpen={unableToDeleteModal.isActive}
               maxHeight={"340px"}
               title={t("Unable to delete")}
               text={
                  activeTab === "2"
                     ? t(
                          "You cannot delete a position if there is a user in it"
                       )
                     : t(
                          "You cannot delete a department if there is a user in it"
                       )
               }
               icon={alertImage}
            />

            <ConfirmModal
               isRemove
               isOpen={confirmModal.isActive}
               onClose={() => {
                  confirmModal.close();
                  setActionData(initialActionData);
               }}
               isOpen={confirmModal.isActive}
               onConfirm={onDeleteEntity}
               maxHeight={"310px"}
               title={t("Are you sure?")}
               subtitle={t("You can recreate it whenever you want")}
               isLoading={isAddLoading}
            />
         </main>
      </MainLayout>
   );
};

export default DepartmentsAndPositionsPage;
