import React, { useEffect, useState } from "react";
import useSortableAndFuzzyData from "../../../hooks/useSortableAndFuzzyData";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from "./styles.module.scss";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Roles from "../../../utils/api/Roles";
import { ROLE_ID_STUDENT, REQUESTED_STATUS_REJECT, REQUESTED_STATUS_ACCEPT, USER_STATUS_ACTIVE, USER_STATUS_BANNED } from "../../../constants/user";
import { COURSE_STATUS_DELETED } from "../../../constants/courses";
import UserApi from "../../../utils/api/UserApi";
import CourseApi from "../../../utils/api/CourseApi";
import { useNavigate, useParams } from "react-router-dom";
import cn from 'classnames';
import UsersBodyItem from "./UsersBodyItem";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import FolderApi from "../../../utils/api/FolderApi";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { useSelector } from "react-redux";
import { STUDENT_STATUS_ACTIVE, STUDENT_STATUS_DELETED, STUDENT_STATUS_PAUSED } from "../../../constants/courses";
import CourseStatusModal from "../../../components/Modals/CourseStatusModal/CourseStatusModal";
import SecondLoader from "../../../components/SecondLoader/SecondLoader";

const UsersTable = ({ columns, data, searchedValue, tableFor, loading, refetchFolderUsers, lastUserRef, folder, updateFolderManager, t }) => {
    const { sortedAndSearchedData, handleSort } = useSortableAndFuzzyData(data, columns);
    
   const [clickedUserId, setClickedUserId] = useState(null);

   const [clickedItem, setClickedItem] = useState(null);
   const [isChangeLoading, setIsChangeLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const confirmModal = useHandleModal();
    const handleStatusModal = useHandleModal();

    const onOpenStatusModal = (item) => {
      handleStatusModal.open();
      setClickedItem(item)
   };

    const openConfirmModal = (userId) => {
      setClickedUserId(userId);
      confirmModal.open();
    }

    const onRemoveAccessToFolder = async () => {
      setIsChangeLoading(true);
      const folderID = params?.topicId ? params?.topicId : params?.id;

      const usersVisibilityData = { 
         user_data: [{
            user_id: clickedUserId,
            add: false
        }]
     };

     const usersData = {
         user_ids: [clickedUserId],
         course_ids: updateFolderManager?.courses?.map((item) => item?.id),
         status: COURSE_STATUS_DELETED,
         notification_for_folder_id: folderID
      }
      
      const res = await new FolderApi().changeFolderVisibilityForUsers(folderID, usersVisibilityData)
      const usersAccessRes = await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersData)

      if(res?.success?.message) {
         refetchFolderUsers();
         toast(<ToastMsg text={res.success.message} />)

         if(params?.topicId) {
            updateFolderManager.removeAccessForCoursesInsideTopic(clickedUserId, folder?.courses, )
         } else {
            updateFolderManager.changeTopicsVisibility([{id: clickedUserId}], false)
         }
      } 

      if(res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError/>)
      setIsChangeLoading(false);
      confirmModal.close();
    }

   if(data?.length === 0 && !loading) {
      return <EmptyBlock />;
   }

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={styles.header}
                     >
                        <span>{t?.returnTranslation(column?.replace('_', ' '))}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
                  {/* needed for making space for options */}
                  <th></th> 
               </tr>
            </thead>
            <tbody className={styles.tbody}>
            <TransitionGroup component={null}>
               {sortedAndSearchedData?.map((row, index) => (
                  <CSSTransition key={`row-${index}`} classNames="fade" timeout={100}>
                  <>
                     <UsersBodyItem 
                        tableFor={tableFor} 
                        row={row} 
                        columns={columns} 
                        index={index}
                        t={t}
                        openConfirmModal={openConfirmModal}
                        onOpenStatusModal={onOpenStatusModal}
                        lastItemRef={index === sortedAndSearchedData?.length - 1 ? lastUserRef : null}
                     />
                  </>
                   
                  </CSSTransition>
               ))}
               </TransitionGroup>

            </tbody>
         </table>

         {data?.length === 0 && !loading && (
             <EmptyBlock />
         )}

         {loading && 
            <div className="default_loader_wrapper">
               <SecondLoader />
            </div>
         }

         <ConfirmModal
               confirmButtonText={t?.remove}
               onConfirm={onRemoveAccessToFolder}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={t?.areYouSure}
               isLoading={isChangeLoading}
            />
        
        <CourseStatusModal
            onClose={handleStatusModal.close}
            isOpen={handleStatusModal.isActive}
            item={clickedItem}
            refetchCourseUsers={refetchFolderUsers}
         />
      </div>
   );
};

export default UsersTable;
