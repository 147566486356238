import CustomStaticTooltip from "../../../../components/CustomStaticTooltip/CustomStaticTooltip";
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_CHECK, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED } from "../../../../constants/tests";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";
import styles from "./styles.module.scss";
import cn from "classnames";

const CheckQuestionResult = ({ question, correctImg, iconWrong, partiallyCorrectIcon }) => {
   const t = useFindTranslationsByString();

   return (
      <div className={styles.auto_check_wrapper}>
         <div className={styles.auto_check_title}>
            <CustomStaticTooltip 
               id={'Automatic grading for task'}
               hint={t('Question that was checked automatically by system')} 
               text={t('Automatic grading for task')}
            />
         </div>
         <div className={styles.complete_btns}>
            <button disabled className={cn(styles.button_true, question?.status === TEST_STATUS_APPROVED ? styles.active_true: '')}>
               <img src={correctImg} alt="" />
               <span>{t("True")}</span>
            </button>
            <button disabled className={cn(styles.button_partly, question?.status === TEST_STATUS_ON_REWORK ? styles.active_partly: '')}>
               <img src={partiallyCorrectIcon} alt="" />
               <span>{t("Partially")}</span>
            </button>
            <button disabled className={cn(styles.button_false, question?.status === TEST_STATUS_REJECTED || !question?.status ? styles.active_wrong : '')}>
               <img src={iconWrong} alt="" />
               <span>{t("False")}</span>
            </button>
         </div>
      </div>
   );
};

export default CheckQuestionResult;
