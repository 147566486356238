import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import plus from "../../../assets/images/plus.svg";
import downloadIcon from "../../../assets/images/file_upload.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import Input from "../../Input/Input";
import UserApi from "../../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import {
   ROLES,
   ROLE_ID_ADMIN,
   ROLE_ID_GUEST,
   ROLE_ID_STUDENT,
   ROLE_ID_SUPER_ADMIN,
} from "../../../constants/user";
import AgencyApi from "../../../utils/api/AgencyApi";
import Dropdown from "../../Dropdown/Dropdown";
import PaginatedDropdown from "../../PaginatedDropdown/PaginatedDropdown";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../Loader/Loader";
import Roles from "../../../utils/api/Roles";
import { transformRoleText } from "../../../utils/rolesHelper";
import { useNavigate } from "react-router-dom";
import {
   validateSpecialUserCreation,
   validateUserCreation,
} from "../../../utils/validationSchemas/validateUserCreation";
import RenameModal from "../RenameModal/RenameModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { useSelector } from "react-redux";
import BranchApi from "../../../utils/api/BranchApi";
import { generateRandomPassword } from "../../../utils/usersHelper";
import StepIndicator from "../../StepIndicator/StepIndicator";
import useFindTranslations from "../../../hooks/useFindTranlsations";

import useGetDepartments from "../../../hooks/api/departmentsAndPostitions/useGetDepartments";
import useGetPositions from "../../../hooks/api/departmentsAndPostitions/useGetPositions";

const CreateSpecialUserModal = ({ onClose, isOpen, refetchData, addUserToList }) => {
   const [roles, setRoles] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [step, setStep] = useState(1);

   const navigate = useNavigate();

   const initialState = {
      id: uuidv4(),
      name: "",
      surname: "",
      email: "",
      phone: "",
      role: "",
   };
   const initialErrors = { name: "", email: "" };
   const companyId = useSelector(state => state.sidebar.companyId);
   const companyName = useSelector(state => state.sidebar.companyName);
   const currentCompanyBranchId = useSelector(state => state.sidebar.currentCompanyBranchId);

   const newDepartmentModal = useHandleModal();
   const newPositionModal = useHandleModal();

   const [specialUser, setSpecialUser] = useState(initialState);
   const [selectedDepartment, setSelectedDepartment] = useState(null);
   const [selectedPosition, setSelectedPosition] = useState(null);
   const [errors, setErrors] = useState(initialErrors);

   const onOpenDepartmentModal = () => {
      newDepartmentModal.open();
      setSelectedDepartment("");
   };

   const onOpenPositionModal = () => {
      newPositionModal.open();
      setSelectedPosition("");
   };

   const handleContinue = () => {
      if (!validateUserCreation(specialUser, setErrors))
         return toast(
            <ToastMsg
               text={
                  errors.email && specialUser.email.length > 0
                     ? errors.email
                     : "Fill in the required fields"
               }
               isError
            />
         );
      setStep(2);
   };

   const onSelectDepartment = (department) => {
      setSelectedDepartment(department);
      setSelectedPosition(null);
   };

   const onSelectPosition = (position) => {
      setSelectedPosition(position);
   };

   const onSelectRole = (selectedRole, roleId, notEditedName) => {
      setSpecialUser({ ...specialUser, role: selectedRole, roleId: roleId, roleName: notEditedName});
   };

   const closeModal = () => {
      onClose();
      setErrors({});
      setSpecialUser({});
      setStep(1);
      setSelectedDepartment(null);
      setSelectedPosition(null);
   };


   const getRoles = async () => {
      const res = await new Roles().getRoles(companyId);

      if (res?.success?.data) {
         const filteredRoles = res?.success?.data
            .filter(
               (role) =>
                  role.id !== ROLE_ID_STUDENT &&
                  role.id !== ROLE_ID_SUPER_ADMIN &&
                  role.id !== ROLE_ID_GUEST
            )
            .map((role) => ({
               ...role,
               name: transformRoleText(role?.name, companyName),
               notEditedName: role?.name
            }));

         setRoles(filteredRoles); 
      }
   };

   const {
      data: departments,
      lastItemRef: lastFilterDepartmentRef,
      searchedValue: filterSearchedValueDep,
      setSearchedValue: setFilterSearchedValueDep,
   } = useGetDepartments({
      companyId: companyId,
   });

   const handleSendInvite = async () => {
      if (!validateSpecialUserCreation(specialUser, setErrors))
         return toast(
            <ToastMsg
               text={
                  errors.email && specialUser.email.length > 0
                     ? errors.email
                     : "Fill in the required fields"
               }
               isError
            />
         );

      setIsLoading(true);

      const randomPassword = generateRandomPassword();

      const specialUserData = {
         email: specialUser?.email?.trim(),
         password: randomPassword,
         confirm_password: randomPassword,
         first_name: specialUser?.name,
         ...(specialUser?.surname?.length ? {last_name: specialUser?.surname} : {}),
         ...(selectedDepartment?.id ? { department: selectedDepartment?.id } : {}),
         ...(selectedPosition?.id ? { position: selectedPosition?.id } : {}),
      };

      const dataToGenerateToken = {
         role_id: specialUser.roleId,
         branch_id: currentCompanyBranchId ? currentCompanyBranchId : 1,
      };

      const resToken = await new UserApi().generateRegistrationToken(
         dataToGenerateToken
      );
      const token = resToken?.success?.data?.registration_token;

      if (token) {
         const resSpecialUser = await new UserApi().registerSpecialUser(
            specialUserData,
            token
         );

         if (resSpecialUser?.error?.message) {
            setIsLoading(false);
            return toast(
               <ToastMsg text={resSpecialUser?.error?.message} isError />
            );
         }

         if (resSpecialUser?.success?.data && addUserToList) {
            const user = resSpecialUser?.success?.data?.user;

            addUserToList({
               ...user,
              role: specialUser?.roleName
            });
         }
      }

      if (resToken?.error?.message) {
         setIsLoading(false);
         return toast(<ToastMsg text={resToken?.error?.message} isError />);
      }

      setIsLoading(false);
      toast(<ToastMsg text={"Special user have been added successfuly"} />);
      closeModal();
      setSpecialUser(initialState);
     if(!addUserToList) refetchData();
   };

   const handleSpecialUserInputChange = (field, value) => {
      const updatedUser = { ...specialUser };
      updatedUser[field] = value;
      setSpecialUser(updatedUser);
      setErrors({ ...errors, [field]: "" });
   };

   const onCloseModal = () => {
      onClose();
      setAdmins(initialState);
      setErrors(initialErrors);
   };

   useEffect(() => {
      if(companyId, companyName, !roles?.length) getRoles();
   }, [companyId, companyName]);

   const t = useFindTranslations();

   return (
      <ModalLayout
         onClose={closeModal}
         isOpen={isOpen}
         maxHeight={"470px"}
         maxWidth={"416px"}
         customBgColor={"#212224"}
         withCross
         visibleOverflow
      >
         <div className={styles.create_user_wrapper}>
            <div className={styles.title}>{t?.createSpecialUser}</div>
            <div className={styles.inputs_wrapper}>
               {step === 1 && (
                  <>
                     <Input
                        value={specialUser?.name}
                        placeholder={t?.name}
                        onChangeValue={(e) =>
                           handleSpecialUserInputChange("name", e.target.value)
                        }
                        isError={errors?.name ? true : false}
                     />
                     <Input
                        value={specialUser?.surname}
                        placeholder={t?.surname}
                        onChangeValue={(e) =>
                           handleSpecialUserInputChange(
                              "surname",
                              e.target.value
                           )
                        }
                        // isError={errors.surname ? true : false}
                     />
                     <Input
                        value={specialUser?.email}
                        placeholder={t?.email}
                        onChangeValue={(e) =>
                           handleSpecialUserInputChange("email", e.target.value)
                        }
                        isError={errors.email ? true : false}
                     />
                     {/* <Input
                        FOR FUTURE
                        value={specialUser?.phone}
                        placeholder="Phone"
                        onChangeValue={(e) =>
                           handleSpecialUserInputChange("phone", e.target.value)
                        }
                     /> */}
                  </>
               )}

               {step === 2 && (
                  <>
                     <PaginatedDropdown
                        data={departments}
                        field={"name"}
                        value={selectedDepartment?.name}
                        placeholder={t?.departmentOptionally}
                        onChange={onSelectDepartment}
                        createBtnText={t?.createNewDepartment}
                        maxHeight={"340px"}
                        onOpenCreateModal={onOpenDepartmentModal}
                        searchValue={filterSearchedValueDep}
                        setSearchValue={setFilterSearchedValueDep}
                        lastItemRef={lastFilterDepartmentRef}
                        crossNeeded
                        searchNeeded
                     />

                     <PaginatedDropdown
                        data={selectedDepartment?.positions}
                        maxHeight={"280px"}
                        field={"name"}
                        value={selectedPosition?.name}
                        placeholder={t?.positionOptionally}
                        onChange={onSelectPosition}
                        crossNeeded
                        disabled={!selectedDepartment?.id}
                        searchNeeded
                     />

                     <Dropdown
                        maxHeight={"240px"}
                        onChange={(value) => onSelectRole(value.name, value.id, value.notEditedName)}
                        value={specialUser?.role}
                        data={roles}
                        placeholder={t?.role}
                        field={"name"}
                        isError={errors.role && !specialUser.role ? true : false}
                     />
                  </>
               )}
            </div>
            <div className={styles.btns}>
               {step === 1 && (
                  <Button title={t?.continue} isBlack onClick={handleContinue} />
               )}

               {step === 2 && (
                  <div className={styles.second_step_btns}>
                     <Button
                        title={t?.previous}
                        isBlack
                        onClick={() => setStep(1)}
                     />
                     <Button disabled={isLoading} title={t?.create} onClick={handleSendInvite} />
                  </div>
               )}
            </div>

            <div className='default_loader_wrapper'>
               {isLoading && 
                  <Loader size={"small"} />
               }
            </div>

           <StepIndicator step={step} />

            <RenameModal
               key={"New deparnment"}
               inputValue={selectedDepartment}
               setInputValue={setSelectedDepartment}
               onClose={newDepartmentModal.close}
               isOpen={newDepartmentModal.isActive}
               onConfirm={newDepartmentModal.close}
               title={"Enter deparnment name"}
            />
            <RenameModal
               key={"New position"}
               inputValue={selectedPosition}
               setInputValue={setSelectedPosition}
               onClose={newPositionModal.close}
               isOpen={newPositionModal.isActive}
               onConfirm={newPositionModal.close}
               title={"Enter position name"}
            />
         </div>
      </ModalLayout>
   );
};

export default CreateSpecialUserModal;
