import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/path";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const StartTestModal = ({ onClose, isOpen, title, onConfirm, isStatisticsUser, notFirstTry, maxHeight }) => {
   const navigate = useNavigate();

   const t = useFindTranslationsByString();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={ maxHeight ? maxHeight : "300px"}
         maxWidth={"450px"}
         withCross
      >
         <div className={styles.modal_wrapper}>
            <div className={styles.title}>
               {title}
            </div>
            {!isStatisticsUser && 
               <p className={styles.info_msg}>
                 {t("Please note that if you withdraw from the test during the test, the result will be canceled")}.
              </p>
            }
            {isStatisticsUser && 
               <p className={styles.info_msg}>
                 {t("You will see the questions as student, but won't be able to send answers and finish the test")}.

                 {notFirstTry && 
                   t("You can close the modal and check last results on background")
                 }
              </p>
            }
          
            <div className={styles.btns}>
               <Button title={ isStatisticsUser ? 'Got it' : t("Start the test")} onClick={onConfirm}/>
            </div>
         </div>
      </ModalLayout>
   );
};

export default StartTestModal;
