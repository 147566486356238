import { useEffect, useRef, useState } from "react";

const useDragging = () => {
   const [isDragging, setIsDragging] = useState(false);
   const [position, setPosition] = useState({
      x: window.innerWidth - 324,
      y: 27,
   });
   const [offset, setOffset] = useState({ x: 0, y: 0 });

   const handleMouseDown = (event) => {
      setIsDragging(true);
      setOffset({
         x: event.clientX - position.x,
         y: event.clientY - position.y,
      });
   };

   const handleMouseMove = (event) => {
      if (isDragging) {
         setPosition({
            x: event.clientX - offset.x,
            y: event.clientY - offset.y,
         });
      }
   };

   const handleMouseUp = (e) => {
      setIsDragging(false);
   };

   return {
      handleMouseUp,
      handleMouseMove,
      handleMouseDown,
      isDragging,
      position,
   };
};


export default useDragging;