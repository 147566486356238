import { useEffect, useRef, useState } from "react";

const useAutoScrollFlexed = (blocks, activeTab = null) => {
   const [blockState, setBlockState] = useState(blocks || []);
   const scrolledBlockRef = useRef(null);
   const [activeIndex, setActiveIndex] = useState(0);

   useEffect(() => {
      if (blocks.length) {
         setBlockState(blocks);
      }
   }, [blocks]);

   const scrollToRef = (ref) => {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: "start" });
   };

   const handleClick = (idx) => {
      setActiveIndex(idx);
      scrollToRef(blockState[idx])
   };

   useEffect(() => {  
      if (blocks?.length) { 
         const handleScroll = () => {
            for (let i = 0; i < blockState.length; i++) {
               if (blockState[i]?.current) {
                  const rect = blockState[i].current.getBoundingClientRect();
                  if (rect.top >= 0 && rect.top <= window.innerHeight) {
                     setActiveIndex(i);
                     break;
                  }
               }
            }
         };

         scrolledBlockRef?.current?.addEventListener('scroll', handleScroll);
         return () => {
            scrolledBlockRef?.current?.removeEventListener('scroll', handleScroll);
         };
      }
   }, [activeTab, blockState]);

   return {
      refs: blockState, 
      scrolledBlockRef,
      handleClick,
      activeIndex
   };
};

export default useAutoScrollFlexed;
