import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import shortLogo from "../../assets/images/favicon.png";
import cross from "../../assets/images/symbols/cross_grey.svg";
import avatar from "../../assets/images/avatar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { pathList } from "../../routes/path";
import { localStorageRemove, localStorageSet } from "../../utils/localStorage";
import {
   toggleSidebar,
   toggleSidebarMobile,
   toggleSidebarFixed,
   setConfirmModalNeeded,
} from "../../store/actions/sidebar";
import { navLinksTop, companiesInfo, navLinksTopMobile } from "./sideBarLinksMock";
import {
   PART_OF_MENTOR_NAME,
   ROLE_ID_STUDENT,
   ROLE_ID_GUEST,
   ROLE_ID_SUPER_ADMIN,
   PART_OF_ADMIN_NAME,
   PART_OF_CURATOR_NAME,
} from "../../constants/user";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";
import cn from "classnames";
import useUserRole from "../../hooks/useUserRole";
import useConfirm from "../../hooks/useConfirm";
import { DEFAULT_TEXT, returnTranslation } from "../../constants/languages";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import { transformRoleText } from "../../utils/rolesHelper";

const MAX_LENGTH = 9;
const MAX_LENGTH_ONE_WORD = 12;

const MobileSidebar = () => {
   const { pathname } = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { handleLinkClick, redirectToPage, confirmModal } = useConfirm();
   const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);
   const currentCompanyLogo = useSelector((state) => state.sidebar.companyLogo);
   const currentCompanyName = useSelector((state) => state.sidebar.companyName);
   const t = useFindTranslationsByString();
   const userState = useSelector((state) => state.user);
   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const avatarLink = userState?.info?.avatar
      ? `${userState?.info?.avatar}`
      : avatar;

   const menuRef = useRef(null);

   const activeMenuClass = (path) =>
      pathname?.includes(path) ? styles.active : "";
   const activeSettingsMenuClass = (path) =>
      pathname?.includes(path) ? styles.activeSettings : "";

   const oneOfActiveSettingsMenuClass = (paths) =>
      paths.some((path) => pathname?.includes(path))
         ? styles.activeSettings
         : "";

   const isSidebarOpen = useSelector((state) => state.sidebar.isOpenMobile);

   const handleSidebarOpen = () => {
      dispatch(toggleSidebarMobile(true));
   };

   const handleSidebarClose = () => {
      dispatch(toggleSidebarMobile(false));
   };

   const onSignOut = () => {
      localStorageSet("token", "");
      localStorageRemove("impersonateId");
      localStorageRemove("isUserAsStudent");
      handleSidebarClose();
      navigate(pathList.login.path);
   };

   useOutsideClick(menuRef, handleSidebarClose);

   const getAbbreviatedName = () => {
      if (!currentCompanyName) return;

      const words = currentCompanyName?.split(" ");
      const abbreviation = words?.map((word) => word.charAt(0)).join("");

      return words?.length > 1 ? abbreviation : currentCompanyName;
   };

   const isNameOneWord = () => {
      const words = currentCompanyName?.split(" ");
      return words?.length === 1;
   };

   const handleClick = (e, path) => {
      handleSidebarClose();
      handleLinkClick(e, path);
   };

   const abbreviatedName = getAbbreviatedName();
   const displayText =
      currentCompanyName?.length > MAX_LENGTH
         ? abbreviatedName
         : currentCompanyName;
   const role = transformRoleText(userRoleName, currentCompanyName);
   const name = userState?.info?.firstName || "";

   return (
      <aside
         className={`${styles.sidebar} ${isSidebarOpen ? styles.open : styles.close}`}
         ref={menuRef}
      >
         <div
            className={cn(
               styles.logo,
               displayText?.length > MAX_LENGTH && isNameOneWord()
                  ? styles.lessFont
                  : "",
               currentCompanyName === "DigitalArt" ? styles.bg_img : ""
            )}
         >
            <img
               src={
                  currentCompanyLogo && currentCompanyLogo !== "undefined"
                     ? currentCompanyLogo
                     : shortLogo
               }
               width="90"
               height="24"
               alt={shortLogo}
            />
            {isSidebarOpen && (
               <span>
                  <CustomTooltip
                     id={"current company name"}
                     text={displayText}
                     noDots
                     limit={isNameOneWord() ? MAX_LENGTH_ONE_WORD : MAX_LENGTH}
                  />
               </span>
            )}
         </div>

         <div onClick={handleSidebarClose} className={styles.cross}>
            <img src={cross} alt="" />
         </div>

         <button
            className={styles.short_profile}
            onClick={(e) => handleClick(e, "/profile/1")}
            type="button"
         >
            <span className={styles.avatar_wrap}>
               <img
                  className={styles.avatar_wrap_image}
                  src={avatarLink}
                  alt=""
                  width="40"
                  height="40"
               />
               <span
                  className={`${styles.profile_status} ${styles.profile_status_online}`}
                  title="online"
               ></span>
            </span>
            <span className={styles.short_profile_info}>
               <span className={styles.short_profile_name}>
                  {name || t("User")}
               </span>
               <span className={styles.short_profile_speciality}>
                  {role ? role : `${t("Check role")}...`}
               </span>
            </span>
         </button>

         <nav className={styles.navigation}>
            <div className={styles.navigation_top}>
               <ul
                  className={`${styles.navigation_list} ${styles.navigation_list_top}`}
               >
                  {navLinksTopMobile?.map((link) => {
                     const translatedTitle = t(link.title);

                     return (
                        <li
                           key={link.title}
                           className={styles.navigation_list_item}
                        >
                           <Link
                              onClick={(e) => handleClick(e, link.path)}
                              to={link.path}
                              className={`${styles.menu_link} ${styles.menu_link_icon} ${link.iconClass} ${activeMenuClass(link.path)}`}
                           >
                              {translatedTitle}
                           </Link>
                        </li>
                     );
                  })}
               </ul>

               <ul className={styles.navigation_list}>
                  <li className={styles.navigation_list_item}>
                     <Link
                        to={companiesInfo.path}
                        onClick={(e) => handleClick(e, companiesInfo.path)}
                        className={`${styles.menu_link} ${styles.menu_link_icon} ${companiesInfo.iconClass} ${activeMenuClass(
                           companiesInfo.path
                        )}`}
                     >
                        {t(companiesInfo.title)}
                     </Link>
                  </li>
               </ul>
            </div>
            <button
               className={`${styles.menu_link} ${styles.menu_link_icon} ${styles.menu_link_icon_exit}`}
               onClick={onSignOut}
               type="button"
            >
               {t("Sign out")}
            </button>
         </nav>
      </aside>
   );
};

export default MobileSidebar;
