import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { STATISTICS_TABS } from "../../../constants/statistics";
import { useSelector } from "react-redux";
import useUserRole from "../../../hooks/useUserRole";

import {
   STAT_TAB_COMPANY,
   STAT_TAB_COURSES,
   STAT_TAB_SCORE,
   STAT_TAB_INTEGRUM,
   STAT_TAB_PLATFORM,
   STAT_TAB_USERS,
} from "../../../constants/statistics";
import {
   PART_OF_MENTOR_NAME,
   PART_OF_CURATOR_NAME,
   PART_OF_ADMIN_NAME,
   ROLE_ID_SUPER_ADMIN
} from "../../../constants/user";

const StatisticsTabs = ({ activeTab, clickOnTab, t }) => {
   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const tabsForSpecialRoles = STATISTICS_TABS?.filter(
      (tab) =>
         tab?.label !== STAT_TAB_PLATFORM && tab?.label !== STAT_TAB_COMPANY
   );

   const tabsForAdmins = STATISTICS_TABS?.filter(
      (tab) =>
         tab?.label !== STAT_TAB_COMPANY
   );

   const tabs =
      !userRoleName ||
      userRoleName?.includes(PART_OF_CURATOR_NAME) ||
      userRoleName?.includes(PART_OF_MENTOR_NAME)
         ? tabsForSpecialRoles
         : userRoleName?.includes(PART_OF_ADMIN_NAME) && userRoleId !== ROLE_ID_SUPER_ADMIN  
         ? tabsForAdmins
         : STATISTICS_TABS;

   useEffect(() => {
      if (
         userRoleName &&
         !userRoleName?.includes(PART_OF_CURATOR_NAME) &&
         !userRoleName?.includes(PART_OF_MENTOR_NAME)
      ) {
         const tab = STATISTICS_TABS?.find(
            (t) => t?.label === STAT_TAB_PLATFORM
         );

         clickOnTab(tab);
      }
   }, [userRoleName]);

   return (
      <div className={styles.subheader}>
         {tabs?.map((tab) => (
            <button
               key={tab.id}
               className={activeTab === tab.label ? styles.active : ""}
               onClick={() => clickOnTab(tab)}
            >
               {t?.returnTranslation(tab.name)}
            </button>
         ))}
      </div>
   );
};

export default StatisticsTabs;
