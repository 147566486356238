import {localStorageGet} from "../utils/localStorage";

export const INIT_FORGET_PASSWORD_FORM = {email: ''};
export const INIT_SIGN_UP_FORM = {name: '', lastname: '', email: '', password: '', passwordRepeat: '', isAgree: false};
export const INIT_LOGIN_FORM = {
    email: localStorageGet('remember-email') || '',
    password: '',
    isRemember: !!localStorageGet('remember-email')
};
export const INIT_WELCOME_FORM = {phone: '', gender: '', dd: '', mm: '', yyyy: '', country: ''};
export const INIT_WELCOME_INVALID_FORM = {phone: false, gender: false, dd: false, mm: false, yyyy: false, country: false};