import React, { useEffect, useState, useRef } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";
import csvImg from "../../../assets/images/symbols/scv.svg";
import plus from "../../../assets/images/symbols/plus.svg";

import Button from "../../../components/Button/Button";

import styles from "./styles.module.scss";
import { CSVLink } from "react-csv";

const UsersSubheader = ({
   searchedValue,
   setSearchedValue,
   refetchData,
   csvData,
   openAccessModal,
   getUserOfCompany,
   isDataLoading,
   t
}) => {
   const csvLinkRef = useRef(null);

   const handleFilterClick = (title) => {
      setActiveStudentsFilter(title);
   };

   return (
      <div className={styles.subheader}>
         <div className={styles.line}></div>
         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <img src={searchImg} alt={""} />
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>
               <Button
                  title={t?.exportToCSV}
                  disabled={isDataLoading}
                  image={csvImg}
                  isBlack
                  maxWidth={"180px"}
                  onClick={()=> getUserOfCompany(1, 1000).then(()=> csvLinkRef.current.link.click())}
               />

            <CSVLink ref={csvLinkRef} style={{ textDecoration: "none" }} data={csvData}/>

            <Button
               title={t?.addAccess}
               image={plus}
               isBlack
               maxWidth={"180px"}
               onClick={openAccessModal}
            />
         </div>
      </div>
   );
};

export default UsersSubheader;
