import Dropzone from "react-dropzone-uploader";
import CommentsBlock from "./CommentsBlock";
import videoThumbnail from '../../../assets/images/video_thumbnail.png';
import { ALLOWED_FILE_TYPES, returnTemporaryQuestionFileLink } from "../../../utils/questionsHelper";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import VideoPlayerModal from "../../../components/Modals/VideoPlayerModal/VideoPlayerModal";

import cn from "classnames";
import styles from "./styles.module.scss";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { HTTPS_REGEX } from "../../../constants/preLinks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import uploadIcon from "../../../assets/images/upload_white.svg";
import DropdownWithSearch from "../../../components/DropdownWithSearch/DropdownWithSearch";

const VideoAnswer = ({
   inputsData,
   setInputsData,
   uploadVideoProgress,
   handleChangeStatus,
   onChangeComment,
   question,
   setChangedLessonFlag
}) => {
   const playerModal = useHandleModal();
   const userId = useSelector(state => state?.user?.info?.id);
   const openVideoModal = () => playerModal.open();
   const [videoUrl, setVideoUrl] = useState('');

   const t = useFindTranslationsByString();

   const hasContentLink = HTTPS_REGEX.test(inputsData?.content);
   const params = useParams();

   const setDefaultTitle = () => {
      setChangedLessonFlag("changed");
      if(!inputsData?.title) {
         setInputsData({ ...inputsData, title: 'default text'});
      }
   };

   function getFileNameFromURL(url) {
      const parts = url.split('/');
      const filenameWithParams = parts[parts.length - 1];
      const filenameParts = filenameWithParams.split('?');
      return filenameParts[0];
  }

   const getUpdatedUrlLink = async() => {
      const fileName = getFileNameFromURL(inputsData?.content);
      const link = await returnTemporaryQuestionFileLink(userId, fileName, params?.id);

      setVideoUrl(link[0]?.fileUrl);
   }

   useEffect(()=> {
      if(inputsData?.content && userId) {
         getUpdatedUrlLink()
      }
   }, [inputsData, userId])

   return (
      <>
         <span className={styles.subtitle}>{t("Upload video")}</span>
         <div className={cn(hasContentLink && !uploadVideoProgress.length && styles.answer)}>
            {hasContentLink && (
               <div className={styles.player_container}>
                  <VideoPlayerModal
                     isOpen={playerModal.isActive}
                     onClose={playerModal.close}
                     url={videoUrl}
                  />
                  <div className={styles.video_name_wrapper} onClick={openVideoModal}>
                     <img src={videoThumbnail}  alt={"Video thumbnail"}/>
                     <CustomTooltip
                        limit={35}
                        maxWidth={'500px'}
                        text={getFileNameFromURL(inputsData?.content)}
                        id={`name_${inputsData.content}`}
                     />
                  </div>

                  <span
                  className={styles.remove_video}
                     onClick={() => {
                        setChangedLessonFlag("changed");
                        setInputsData((prevInputsData) => ({
                           ...prevInputsData,
                           content: "Default content",
                        }));
                     }}
                  ></span>
               </div>
            )}

            {!hasContentLink && (
               <div
                  className={cn(
                     styles.video_answer,
                     styles.video_upload_wrapper
                  )}
               >
                  {uploadVideoProgress.length > 0 && (
                     <div className={styles.progress_bar_container}>
                        <div
                           className={styles.progress_bar}
                           style={{ width: `${uploadVideoProgress}` }}
                        ></div>
                        <span className={styles.progress_percentage}>
                           {uploadVideoProgress}
                        </span>
                     </div>
                  )}

                  {question?.id &&
                     <Dropzone
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent={
                         <span className={styles.custom_dropzone_input}>
                             <img src={uploadIcon} alt="Upload icon"/><span>Upload video</span></span>
                     }
                        accept="video/*"
                        classNames={{
                           dropzone: styles.custom_dropzone,
                           dropzoneActive: styles.custom_dropzone_active,
                           inputLabel: styles.custom_input_label,
                           fileName: styles.custom_filename,
                        }}
                     />
                  }
                  {!question?.id &&
                     <button
                         className={styles.custom_dropzone_input}
                         onClick={()=> {
                        if(!question?.id) {
                           setDefaultTitle();
                           return toast(
                             <ToastMsg
                               text={
                                 "Save questions first and then continue adding videos"
                               }
                               isError
                             />,
                           );
                        }
                     }}><img src={uploadIcon} alt="Upload icon"/><span>{t("Upload a video")}</span></button>
                  }
               </div>
            )}
         </div>

         <div className={styles.section_title}>{t("File options")}</div>
         <DropdownWithSearch
             searchNeeded={false}
             isMulti={false}
             onClear={() =>
              setInputsData((prevInputsData) => ({
                  ...prevInputsData,
                  chosenFileType: '',
              }))}
             selectedItems={inputsData?.chosenFileType}
             placeholder={t("Select")}
             data={ALLOWED_FILE_TYPES}
             title={"File format"}
             value={inputsData?.chosenFileType}
             onChange={(value) =>
                setInputsData &&
                setInputsData((prevInputsData) => ({
                   ...prevInputsData,
                   chosenFileType: value,
                }))
            }
         />
         <CommentsBlock
            commentValue={inputsData?.comment}
            onChangeComment={onChangeComment}
         />
      </>
   );
};

export default VideoAnswer;
