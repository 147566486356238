import { useEffect, useRef, useState } from "react";

const useMoveScroll = (blocks, activeTab = null) => {
   const blockWrapperRef = useRef(null);
   const [dragStartX, setDragStartX] = useState(null);
   const [scrollStartX, setScrollStartX] = useState(0);
   const [isDragging, setIsDragging] = useState(false);

   const handleMouseDown = (e) => {
      setDragStartX(e.clientX);
      setScrollStartX(blockWrapperRef.current.scrollLeft);
      setIsDragging(false);
   };

   const handleMouseMove = (e) => {
      if (dragStartX === null) return;
      const dragX = e.clientX - dragStartX;

      if (Math.abs(dragX) > 12) {
         setIsDragging(true);
      }

      blockWrapperRef.current.scrollLeft = scrollStartX - dragX;
   };

   const handleMouseUp = () => {
      setDragStartX(null);

      setTimeout(() => {
         setIsDragging(false);
      }, 200);
   };

   const moveRight = () => {
      const tagsWrapper = blockWrapperRef?.current;

      if (tagsWrapper) {
         const scrollAmount = 500;
         const scrollLeft = tagsWrapper.scrollLeft + scrollAmount;

         tagsWrapper.scrollTo({
            left: scrollLeft,
            behavior: "smooth",
         });
      }
   };

   const moveLeft = () => {
      const tagsWrapper = blockWrapperRef?.current;

      if (tagsWrapper) {
         const scrollAmount = 500;
         const scrollLeft = tagsWrapper.scrollLeft - scrollAmount;

         tagsWrapper.scrollTo({
            left: scrollLeft,
            behavior: "smooth",
         });
      }
   };

   useEffect(() => {
      const handleMouseLeave = () => {
         // When the mouse leaves the wrapper area, stop dragging
         if (isDragging) {
            setIsDragging(false);
         }
      };

      const handleGlobalMouseUp = () => {
         // Handle mouseup globally to ensure it works even when released outside the block wrapper
         if (isDragging) {
            setTimeout(() => {
               setDragStartX(null);
               setIsDragging(false);
            }, 200);
           
         }
      };

      window.addEventListener("mouseleave", handleMouseLeave);
      window.addEventListener("mouseup", handleGlobalMouseUp);

      return () => {
         // Remove the event listeners when the component unmounts
         window.removeEventListener("mouseleave", handleMouseLeave);
         window.removeEventListener("mouseup", handleGlobalMouseUp);
      };
   }, [isDragging]); // Dependency on isDragging to add/remove event listeners

   return {
      blockWrapperRef,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      moveRight,
      moveLeft,
      isDragging,
   };
};

export default useMoveScroll;
