import Base from "./Base";
import { BIGGER_DATA_LIMIT } from '../../constants/user';

export default class NotificationsApi extends Base {
   getYourNotifications(
      limit,
      page,
      sortFilter = "desc",
      createdAt,
      isArchived,
      roleFilterId,
      signal
   ) {
      return super.get(
         `api/v1/user-notification/get-your-own-notifications?limit=${limit}&page=${page}${
            createdAt ? `&filter[created_at]=${createdAt}` : ""
         }${
            isArchived ? `&filter[is_archived]=${isArchived}` : ""
         }${
            roleFilterId ? `&filter[role]=${roleFilterId}` : ""
         }&order=${sortFilter}`, null, signal 
      );
   }

   getYourNotRedNotifications(limt = BIGGER_DATA_LIMIT, isArchived = false, signal) {
      return super.get(
         `api/v1/user-notification/get-your-own-notifications?limit=${limt}&page=${1}&filter[is_archived]=${isArchived}&order=desc`, null, signal
      );
   }

   getYourAllNotifications(signal) {
      return super.get(
         `api/v1/user-notification/get-your-own-notifications?limit=${BIGGER_DATA_LIMIT}&order=desc`,  null, signal
      );
   }

   archiveYourNotifications(data) {
      return super.put(`api/v1/user/archive-your-notifications`, data);
   }

   deleteNotifications(data) {
      return super.delete(`api/v1/user-notification`, data);
   }
}
