import React from "react";
import { Pie } from "react-chartjs-2";
import styles from "./styles.module.scss";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartStatisticsChartJs = ({ chartData, score, textInside }) => {
  const colors = ["#44DD65", "#0B88F8", "#3E3E41", "#FF5959"];

  const data = {
    labels: chartData.slice(1).map((item) => item[0]), 
    datasets: [
      {
        data: chartData.slice(1).map((item) => item[1]), 
        backgroundColor: colors, 
        borderColor: "transparent",
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    cutout: "81%", 
    plugins: {
      tooltip: {
        callbacks: {
         label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((acc, value) => acc + value, 0);
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(2); 
            return `${tooltipItem.label}: ${value} (${percentage}%)`;
          },
        },
        bodyFont: {
          size: 12,
        },
      },
      legend: {
        display: false, 
      },
    },
    animation: {
      animateRotate: true,
      easing: "linear",
      duration: 500,
    },
  };

  return (
    <div className={styles.chart_container}>
      <div className={styles.canvas_wrapper}>
         <Pie data={data} options={options} />  
      </div>

      {!!score && (
            <span className={styles.correct_percent}>
               <span>{(+score)?.toFixed() || "0"}</span>
               <span className={styles.gray}>{textInside}</span>
            </span>
         )}
         {!score && (
            <span className={styles.correct_percent}>
               <span>{"0"}</span>
               <span className={styles.gray}>{textInside}</span>
            </span>
         )}
    </div>
  );
};

export default PieChartStatisticsChartJs;
