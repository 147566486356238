import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import searchImg from "../../../assets/images/symbols/Search.svg";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import arrow from "../../../assets/images/symbols/arrow.svg";
import icon_grid from "../../../assets/images/icon_grid.svg";
import icon_list from "../../../assets/images/icon_list.svg";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import useMoveScroll from "../../../hooks/useMoveScroll";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const certificatesSortFiltersData = ["First new", "Old first"];

const CertificatesSubheader = ({
   searchedValue,
   setSearchedValue,
   activeSortFilter,
   setActiveSortFilter,
   certificates,
   setActiveTagsFilter,
   activeTagsFilter,
   orientation,
   setOrientation,
   allCertificates
}) => {
   const [showOptions, setShowOptions] = useState(false);

   const t = useFindTranslationsByString();

   const {
      blockWrapperRef,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      moveLeft,
      moveRight,
      isDragging,
   } = useMoveScroll();

   const optionRef = useRef(null);
   const dropDownRef = useRef(null);

   const closeOptions = () => setShowOptions(false);

   useOutsideClick(dropDownRef, closeOptions, optionRef);

   const toggleOptions = (e) => {
      setShowOptions((prev) => !prev);
   };

   const handleTagsChange = (tag) => {
      if (activeTagsFilter?.find(item => item === tag)) setActiveTagsFilter(activeTagsFilter?.filter(item => item !== tag))
      else setActiveTagsFilter([...activeTagsFilter, tag])
  }

   const currentTagsSet = new Set(
      allCertificates?.flatMap((cert) => cert?.course?.tags ?? []) ?? []
   );

   const currentTags = Array.from(currentTagsSet);

   const isActive = (item) => {
      if (activeTagsFilter && activeTagsFilter.length > 0) {
        if (typeof activeTagsFilter[0] === 'string') {
          return activeTagsFilter.includes(item);
        } else if (typeof activeTagsFilter[0] === 'object' && activeTagsFilter[0].id !== undefined) {
          return activeTagsFilter.some(tag => tag.id === item.id);
        }
      }
      return false;
    };

   return (
      <div className={styles.subheader}>
         <div className={styles.orientationWrapper}>
            <button className={orientation === 'grid' ? styles.activeOrientation : "" } onClick={()=> setOrientation('grid')}>
               <img src={icon_grid} alt="" />
            </button>
            <button className={orientation === 'line' ? styles.activeOrientation : "" } onClick={()=> setOrientation('line')}>
               <img src={icon_list} alt="" />
            </button>
         </div>

         <div
            className={cn(
               styles.block,
               certificatesSortFiltersData.includes(activeSortFilter)
                  ? styles.activeFilter
                  : "",
               showOptions ? styles.opened : ""
            )}
         >
            <div
               className={styles.filter_wrapper}
               ref={optionRef}
               onClick={toggleOptions}
            >
               <img src={filtersImg} alt={""} />
               <div className={styles.filter_text}>
                  {certificatesSortFiltersData.includes(activeSortFilter)
                     ? activeSortFilter
                     : t("Filter")}
               </div>
               <img
                  className={cn(
                     styles.options_arrow,
                     showOptions ? styles.options_opened : ""
                  )}
                  src={arrow}
                  alt={""}
               />
            </div>

            {showOptions && (
               <div className={styles.options} ref={dropDownRef}>
                  {certificatesSortFiltersData.map((item) => (
                     <span
                        className={
                           activeSortFilter === item ? styles.active_option : ""
                        }
                        key={item}
                        onClick={() => {
                           closeOptions();
                           setActiveSortFilter(item);
                        }}
                     >
                        {t(item)}
                     </span>
                  ))}
               </div>
            )}
         </div>

         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <img src={searchImg} alt={""} />
               <input
                  placeholder={t("Search")}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>
         </div>

         <div className={styles.line}></div>

         {currentTags && !!currentTags.length && (
            <div className={styles.tags_block__wrapper}>
               <div
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  ref={blockWrapperRef}
                  className={styles.tags_wrapper}
               >
                  <button
                     onClick={() => setActiveTagsFilter([])}
                     className={cn(styles.tag)}
                  >
                     {t("All tags")}
                  </button>
                  {currentTags?.map((item) => (
                     <button
                        className={cn(
                           styles.tag,
                           isActive(item) ? styles.active_tag : ""
                        )}
                        key={item}
                        onClick={() => {
                           handleTagsChange(item);
                        }}
                     >
                        {item}
                     </button>
                  ))}
               </div>
            </div>
         )}
      </div>
   );
};

export default CertificatesSubheader;
