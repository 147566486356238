// CertificateTemplate.js
import React from "react";
import styles from "./styles.module.scss";
import { CERTIFICATE_GLOBAL_TYPE_FIRST } from "../../constants/courses";
import CertificateTypeSecond from "./CertificateTypeSecond/CertificateTypeSecond";
import CertificateTypeFirst from "./CertificateTypeFirst/CertificateTypeFirst";

const CertificateTemplate = ({
   maxWidth,
   maxHeight,
   data,
   onClick,
   size,
   isActive,
   certificateRef
}) => {
   if (
      data?.name === CERTIFICATE_GLOBAL_TYPE_FIRST ||
      data?.globalType === CERTIFICATE_GLOBAL_TYPE_FIRST
   ) {
      return (
         <CertificateTypeFirst
            {...{ maxWidth, maxHeight, data, onClick, size, isActive, certificateRef }}
         />
      );
   } else {
      return (
         <CertificateTypeSecond
            {...{ maxWidth, maxHeight, data, onClick, size, isActive, certificateRef }}
         />
      );
   }
};

export default CertificateTemplate;
