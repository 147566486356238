import React, { useEffect, useState } from "react";

import TableBlock from "../TableBlock/TableBlock";
import {
   PROFILE_TEAMS_TABLE_HEADERS,
   PROFILE_CURATOR_TABLE_HEADERS,
   PROFILE_COMPANIES_TABLE_HEADERS,
   ROLE_ID_STUDENT,
} from "../../../constants/user";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import cn from "classnames";
import useUserRole from "../../../hooks/useUserRole";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ActivityTab = ({
   companies,
   companiesLoading,
   courses,
   coursesLoading,
   relatedToYouTeams,
   teamsLoading,
   lastAgencyRef,
}) => {
   const [activeTab, setActiveTab] = useState("teams");

   const t = useFindTranslationsByString();

   const companyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId } = useUserRole(companyId);

   const handleTabChange = (tab) => {
      setActiveTab(tab);
   };

   const replaceEmptySpaces = (str) => {
      return str?.replace(" ", "_") || "";
   };

   return (
      <div className={styles.activity_tab_wrapper}>
         <div className={styles.tab_navigation}>
            <button
               className={cn(
                  styles.tab,
                  activeTab === "teams" && styles.activeTab
               )}
               onClick={() => handleTabChange("teams")}
            >
               {t("Teams")}
            </button>
            {userRoleId && userRoleId !== ROLE_ID_STUDENT && (
               <button
                  className={cn(
                     styles.tab,
                     styles.curatorTab,
                     activeTab === "curator" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("curator")}
               >
                  {t("Curator")}
               </button>
            )}

            <button
               className={cn(
                  styles.tab,
                  activeTab === "companies" && styles.activeTab
               )}
               onClick={() => handleTabChange("companies")}
            >
               {t("Companies")}
            </button>
         </div>

         <div className={styles.table_content}>
            {activeTab === "teams" && (
               <TableBlock
                  key={relatedToYouTeams}
                  tableFor={"teams"}
                  data={relatedToYouTeams}
                  headers={PROFILE_TEAMS_TABLE_HEADERS}
                  loading={teamsLoading}
               />
            )}
            {activeTab === "curator" && (
               <TableBlock
                  key={"curator"}
                  tableFor={"curator"}
                  data={courses}
                  headers={PROFILE_CURATOR_TABLE_HEADERS}
                  loading={coursesLoading}
               />
            )}
            {activeTab === "companies" && (
               <TableBlock
                  tableFor={"companies"}
                  data={companies}
                  headers={PROFILE_COMPANIES_TABLE_HEADERS}
                  loading={companiesLoading}
                  lastAgencyRef={lastAgencyRef}
               />
            )}
         </div>
         <div className={styles.table_content_mob}>
            {activeTab === "teams" && (
               <div className={cn(styles.mob_content, styles.mob_teams)}>
                  {relatedToYouTeams?.map((team, idx) => (
                     <div key={idx} className={styles.list_item}>
                        <div className={styles.left}>
                           <div>{team?.name || ""}</div>
                        </div>
                        <div
                           className={cn(
                              styles.role,
                              styles[replaceEmptySpaces(team.role)],
                              styles.defaultCartColor
                           )}
                        >
                           {team.role}
                        </div>
                     </div>
                  ))}
               </div>
            )}
            {activeTab === "companies" && (
               <div className={cn(styles.mob_content, styles.mob_companies)}>
                  {companies?.map((company, idx) => (
                     <div
                        key={idx}
                        ref={
                           companies?.length - 1 === idx && lastAgencyRef
                              ? lastAgencyRef
                              : null
                        }
                        className={styles.list_item}
                     >
                        <div className={styles.left}>
                           <div className={styles.company_logo}>
                              <img src={company?.logo} alt="" />
                           </div>
                           <div>{company?.company_name || ""}</div>
                        </div>
                        <div
                           className={cn(
                              styles.role,
                              styles[replaceEmptySpaces(company.role)],
                              styles.defaultCartColor
                           )}
                        >
                           {company.role}
                        </div>
                     </div>
                  ))}
               </div>
            )}
         </div>
      </div>
   );
};

export default ActivityTab;
