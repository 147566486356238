import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from "../../assets/images/symbols/arrow.svg";
import cross from "../../assets/images/symbols/cross_grey.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import cn from "classnames";
import DatePicker from "react-datepicker";
import useFindTranslations from "../../hooks/useFindTranlsations";
import { LEFT_ICON, OPENED, RIGHT_ICON } from "../../constants/classNames";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";
import calendarFilled from "../../assets/images/symbols/calendar_filled.svg";

const CustomDropdown = ({
  data,
  onChange,
  value,
  title = null,
  isWhiteBlack = false,
  searchNeeded,
  img,
  isWithDateRange,
  onChangeDate,
  noLeftRightPadding,
  frontTimeFilterValue,
  setFrontTimeFilterValue,
  maxFrontHeight40,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDatePikers, setShowDatePikers] = useState(false);
  const t = useFindTranslations();
  const tNew = useFindTranslationsByString();

  const formatDate = (date) => {
    if (!date) {
      return "";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const dropdownRef = useRef();
  const inputRef = useRef();
  const inputRefTop = useRef();

  const onClose = () => setIsOpen(false);
  const onToggle = () => setIsOpen(!isOpen);
  const onChangeValue = (val) => {
    onChangeDateValue("");
    setStartDate("");
    setEndDate("");
    onChange(val);
  };
  const onChangeDateValue = (val) => onChangeDate && onChangeDate(val);

  useOutsideClick(dropdownRef, onClose, inputRef);

  const searchHandler = (list) => {
    return list?.filter((item) =>
      searchValue?.length
        ? item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        : true,
    );
  };

  const filteredData = searchHandler(data);

  const applyDateRange = () => {
    if (showDatePikers && (startDate || endDate)) {
      setIsOpen(false);
      setFrontTimeFilterValue("");

      onChangeDate(`${formatDate(startDate)} - ${formatDate(endDate)}`);
    }
    setShowDatePikers((prev) => !prev);
  };
  useEffect(() => {
    if (!isOpen) setSearchValue("");
    if (isOpen) inputRefTop.current.focus();
  }, [isOpen]);

  const showingText = useMemo(() => {
    if (frontTimeFilterValue) return frontTimeFilterValue;
    if (value) {
      if (startDate && endDate) {
        return value;
      }
      if (startDate) {
        return tNew("From") + " " + value.slice(0, -3);
      }
      if (endDate) {
        return tNew("To") + " " + value.slice(3);
      }
    }
    return tNew("Date");
  }, [value, startDate, endDate]);

  return (
    <>
      <div
        id={"datepicker-portal"}
        className={cn(
          styles.dropdown_wrapper,
          maxFrontHeight40 ? styles.maxInputHeight : "",
          isOpen && OPENED && styles.opened,
        )}
        onClick={onToggle}
        ref={inputRef}
      >
        {title && <p className={styles.title}>{t?.returnTranslation(title)}</p>}

        {img && (
          <img
            className={cn(styles.search, styles.logo, LEFT_ICON)}
            src={startDate || endDate ? calendarFilled : img}
            alt=""
          />
        )}

        <Input
          isDefaultColor
          placeholder={showingText}
          ref={inputRefTop}
          customClass={cn(
            isOpen ? styles.input_open : null,
            styles.input,
            (value?.length || startDate || endDate) && styles.white_text,
            !img ? styles.pl_0 : "",
            noLeftRightPadding ? styles.noLeftRightPadding : "",
            maxFrontHeight40 ? styles.noBorder : "",
          )}
          disabled={!searchNeeded}
          value={searchValue}
          onChangeValue={(e) => setSearchValue(e.target.value)}
          isWhiteBlack={isWhiteBlack}
        />

        {(value?.length || startDate || endDate) && (
          <img
            className={cn(styles.cross, styles.crossFront, RIGHT_ICON)}
            onClick={(e) => {
              e.stopPropagation();
              onChangeDateValue("");
              setStartDate("");
              setEndDate("");
              setFrontTimeFilterValue("");
            }}
            src={cross}
            alt=""
          />
        )}

        <div className={styles.click_el}></div>

        {!(value?.length || startDate || endDate) && (
          <img
            src={isWhiteBlack ? arrowWhite : arrow}
            alt={""}
            className={cn(
              styles.arrow,
              isOpen && styles.arrow_open,
              "right_icon",
            )}
          />
        )}

        {isOpen && (
          <div
            className={cn(
              styles.dropdown,
              isWhiteBlack && styles.dropdown_isWhiteBlack,
            )}
          >
            {value?.name === "Select" && (
              <img
                className={styles.crossTop}
                onClick={() => onChange(null)}
                src={cross}
                alt=""
              />
            )}
            {!showDatePikers &&
              filteredData?.map((item, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => onChangeValue(item)}
                    className={cn(
                      styles.dropdown_item,
                      item?.id === value?.id && styles.dropdown_item_active,
                      isWithDateRange ? styles.noCapitalize : "",
                        key === filteredData?.length -1 && styles.last_item
                    )}
                  >
                    <div className={styles.user_info_wrapper}>
                      <div className={styles.right}>
                        <p>{t?.returnTranslation(item?.name)}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            {showDatePikers && (
              <>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={cn(styles.dropdown_item)}
                >
                  <DatePicker
                    isClearable={startDate}
                    portalId="datepicker-portal"
                    dateFormat="yyyy/MM/dd"
                    className={cn(
                      styles.user_info_wrapper,
                      styles.date_picker_input,
                    )}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={tNew("From")}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={cn(styles.dropdown_item, styles.last_item)}
                >
                  <DatePicker
                    isClearable={endDate}
                    portalId="datepicker-portal"
                    dateFormat="yyyy/MM/dd"
                    className={cn(
                      styles.user_info_wrapper,
                      styles.date_picker_input,
                    )}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText={tNew("To")}
                  />
                </div>
              </>
            )}
            {!data?.length && (
              <div className={styles.dropdown_item}>{t?.thereAreNoData}</div>
            )}

            {isWithDateRange && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={cn(styles.dropdown_date_item)}
              >
                <p onClick={applyDateRange} className={styles.date_option}>
                  {showDatePikers ? tNew("Apply") : tNew("Select date range")}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomDropdown;
