import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const TabButton = ({ item, mode, setMode, padding = null }) => {
   
   const t = useFindTranslationsByString();

   return (
      <button
         style={{ padding: padding ? padding : "" }}
         onClick={() => setMode(item?.value)}
         className={cn(
            styles.tabs_item,
            mode === item?.value && styles.tabs_item_active
         )}
      >
         <img
            src={mode === item?.value ? item?.activeImage : item?.image}
            alt={""}
         />
         {t(item?.title)}
      </button>
   );
};

export default TabButton;
