import { pathList } from "../../routes/path";
import styles from "./styles.module.scss";

export const navLinksTop = [
   {
      title: "Courses",
      path: pathList.courses.path,
      iconClass: styles.menu_link_icon_courses,
   },
   // {
   //    title: "Libraries",
   //    path: pathList.libraries.path,
   //    iconClass: styles.menu_link_icon_libraries,
   // },
   // {
   //    title: "Special folders",
   //    path: pathList.specialFolders.path,
   //    iconClass: styles.menu_link_icon_special_folders,
   // },
   // {
   //    title: "Surveys",
   //    path: pathList.surveys.path,
   //    iconClass: styles.menu_link_icon_surveys,
   // },
   {
      title: "Knowledge check",
      path: pathList.knowledgeCheck.path,
      iconClass: styles.menu_link_icon_knowledge_check,
   },
   // {
   //    title: "Statistics",
   //    path: pathList.statistics.path,
   //    iconClass: styles.menu_link_icon_statistics,
   // },
   // {
   //    title: "Applications",
   //    path: pathList.applications.path,
   //    iconClass: styles.menu_link_icon_applications,
   // },
];

export const navLinksBottom = [
   {
      title: "Users",
      path: pathList.users.path,
      iconClass: styles.menu_link_icon_users,
   },
   {
      title: "Users rights",
      path: pathList.usersRights.path,
      iconClass: styles.menu_link_icon_users_guideline,
   },
   // {
   //    title: "Variables",
   //    path: pathList.variables.path,
   //    iconClass: styles.menu_link_icon_variables,
   // },
];

export const companiesInfo = {
   title: "Companies",
   path: pathList.agencies.path,
   iconClass: styles.menu_link_icon_agencies,
}

