import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const AnswerRate = ({ onChooseRate, choosedRate}) => (
   <div className={styles.rating_wrapper}>
      {[...Array(10)].map((_, idx) => (
         <div
            onClick={()=> onChooseRate(idx + 1)}
            key={idx + 1}
            className={cn(styles.rating_item, {
               [styles.active_rating]: idx + 1 === choosedRate,
            })}
         >
            {idx + 1}
         </div>
      ))}
   </div>
);

export default AnswerRate;
