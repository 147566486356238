import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import correctInfo from "../../../assets/images/correct_answer_info.png";
import notSelectedtInfo from "../../../assets/images/not_selected_answer_info.png";
import wrongInfo from "../../../assets/images/wrong_answer_info.png";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const answersInfo = [
   {
      title: "Correct answer chosen by the student",
      text: "The correct answer chosen by the student. Points are credited to",
      icon: correctInfo,
   },
   {
      title: "Wrong answer chosen by the student",
      text: "The wrong answer chosen by the student. Points are not credited to",
      icon: wrongInfo,
   },
   {
      title: "Correct answer not chosen by the student",
      text: "The correct answer is not chosen by the student. No points are awarded",
      icon: notSelectedtInfo,
   },
];

const AnswersInfoModal = ({ onClose, isOpen, title, maxHeight = '90vh' }) => {
   const navigate = useNavigate();

   const t = useFindTranslationsByString();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={maxHeight}
         maxWidth={"440px"}
         withCross
      >
         <div className={styles.info_wrapper}>
            <div className={styles.title}>{title}</div>

            <div className={styles.wrapper}>
               {answersInfo.map(({ icon, text, title }, idx) => (
                  <div key={idx} className={styles.answers_info_wrapper}>
                     <img src={icon} alt="" />
                     <h3>{t(title)}</h3>
                     <p>{t(text)}</p>
                  </div>
               ))}
            </div>

            <Button onClick={onClose} title={t("Close")} />
         </div>
      </ModalLayout>
   );
};

export default AnswersInfoModal;
