import React, { forwardRef, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from "../../assets/images/symbols/arrow.svg";
import cross from "../../assets/images/symbols/cross_grey.svg";
import cn from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import useFindTranslations from "../../hooks/useFindTranlsations";
import Checkbox from "../Checkbox/Checkbox";

const FilterDropDown = ({
  data,
  placeholder,
  onChange,
  value,
  title = null,
  isWhiteBlack = false,
  searchNeeded,
  maxHeight,
  withDate,
  img,
  withCheckBox,
  onChangeDate,
  dateFilter,
  noLeftRightPadding,
  frontTimeFilterValue,
  setFrontTimeFilterValue,
  maxFrontHeight40,
  clearable = true,
  capitalize,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const t = useFindTranslations();

  const dropdownRef = useRef();
  const inputRef = useRef();
  const inputRefTop = useRef();

  const onClose = () => setIsOpen(false);
  const onToggle = () => setIsOpen(!isOpen);
  const onChangeValue = (val) => onChange(val);
  const onChangeDateValue = (val) => onChangeDate && onChangeDate(val);

  useOutsideClick(dropdownRef, onClose, inputRef);

  const searchHandler = (list) => {
    return list?.filter((item) =>
      searchValue?.length
        ? item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        : true,
    );
  };

  const filteredData = searchHandler(data);

  useEffect(() => {
    if (!isOpen) setSearchValue("");
    if (isOpen) inputRefTop.current.focus();
  }, [isOpen]);

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        {dateFilter || t?.selectDate}
      </button>
    ),
  );

  const clearFilter = (e) => {
    e.stopPropagation();
    onChange(null);
    onChangeDateValue("");
  };

  return (
    <>
      <div
        className={cn(
          styles.dropdown_wrapper,
          capitalize && styles.capitalize,
          value && styles.hasValue,
          maxFrontHeight40 ? styles.maxInputHeight : "",
          {
            [styles.open]: isOpen,
          },
        )}
        onClick={onToggle}
        ref={inputRef}
      >
        {title && <p className={styles.title}>{t?.returnTranslation(title)}</p>}

        {img && (
          <img className={cn(styles.search, styles.logo)} src={img} alt="" />
        )}
        {withCheckBox && (
          <div className={styles.checkbox}>
            <Checkbox isChecked={value} />
          </div>
        )}

        <Input
          isDefaultColor
          placeholder={
            value?.name || frontTimeFilterValue
              ? clearable ? frontTimeFilterValue : placeholder
              : clearable ? value || placeholder : placeholder
          }
          ref={inputRefTop}
          customClass={cn(
            isOpen ? styles.input_open : null,
            styles.input,
            !img ? styles.pl_0 : "",
            noLeftRightPadding ? styles.noLeftRightPadding : "",
            maxFrontHeight40 ? styles.noBorder : "",
          )}
          disabled={!searchNeeded}
          value={searchValue}
          onChangeValue={(e) => setSearchValue(e.target.value)}
          isWhiteBlack={isWhiteBlack}
        />

        <div className={styles.click_el}></div>

        {value && clearable ? (
          <img
            className={styles.cross}
            onClick={clearFilter}
            src={cross}
            alt=""
          />
        ) : (
          <img
            src={arrow}
            alt={""}
            className={cn(styles.arrow, isOpen && styles.arrow_open)}
          />
        )}

        {isOpen && (
          <div
            style={{ maxHeight: maxHeight ? maxHeight : "300px" }}
            className={cn(
              styles.dropdown,
              isWhiteBlack && styles.dropdown_isWhiteBlack,
            )}
          >
            {filteredData?.map((item, key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    !item.disabled && onChangeValue(item);
                  }}
                  className={cn(
                    styles.dropdown_item,
                    item.disabled && styles.disabled,
                    item?.id === value?.id && styles.dropdown_item_active,
                  )}
                >
                  <div className={styles.user_info_wrapper}>
                    <div className={styles.right}>
                      <p>{t?.returnTranslation(item?.name)}</p>
                    </div>
                  </div>
                </div>
              );
            })}
            {!data?.length && (
              <div className={styles.dropdown_item}>{t?.thereAreNoData}</div>
            )}

            {withDate && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={cn(styles.dropdown_date_item)}
              >
                <DatePicker
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                  selected={selectedDate}
                  className={styles.date}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  onChange={(date) => {
                    onChangeDateValue(moment(date).format("YYYY-MM-DD"));
                    setSelectedDate(date);
                    onClose();
                  }}
                />

                {dateFilter && (
                  <img
                    className={styles.cross}
                    onClick={() => {
                      onChangeDateValue("");
                    }}
                    src={cross}
                    alt=""
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FilterDropDown;
