import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

export const CheckboxVariants = {
  default: "default",
  rounded: "rounded",
  roundedWithIcon: "roundedWithIcon",
}

const Checkbox = ({
  title,
  setIsChecked,
  isChecked,
  onClickCallback = null,
  isRound,
  isGrey,
  withWhiteBorder,
  extraMargin,
  hiddenTitle = false,
  className,
  inputClassName,
  disabled,
  variant
}) => {
  const onToggleChecked = () => {
    if (onClickCallback) {
      onClickCallback();
      if (isChecked) return;
    }
    if (setIsChecked) setIsChecked(!isChecked);
  };

  return (
    <div
      className={cn(
        styles.checkbox,
        !hiddenTitle && styles.flex,
        isRound && styles.checkbox_isRound,
        isRound && extraMargin && styles.extraMargin,
        isGrey && styles.checkbox_isGrey,
        withWhiteBorder && styles.checkbox_withWhiteBorder,
        className,
      )}
    >
      <div className={cn(styles.checkbox_wrapper,
      {
        [styles.roundedWithIcon] : variant && variant === CheckboxVariants.roundedWithIcon
      },)
      }>
        <input
          type="checkbox"
          className={inputClassName}
          checked={isChecked}
          onClick={onToggleChecked}
          disabled={disabled}
        />
      </div>
      {!hiddenTitle && title && <span>{title}</span>}
    </div>
  );
};

export default Checkbox;
