import styles from "./styles.module.scss";
import cn from "classnames";

import star from "../../../../assets/images/star_custom.svg";
import { DEFAULT_CONTENT } from "../../../../constants/courses";

const defineStars = (rating, total = 10, styles) => {
   const totalStars = total;
   const activeStars = Math.min(rating, totalStars);

   const stars = [];
   for (let i = 0; i < totalStars; i++) {
      stars.push(
         <img
            key={i}
            src={star}
            alt={`Star ${i + 1}`}
            className={i < activeStars ? styles.active_star : ""}
         />
      );
   }

   return stars;
};

const StarsAnswer = ({ questionIdx, question, total }) => {
   return (
      <div className={styles.rating_wrapper}>
         <div>
         {DEFAULT_CONTENT.test(question?.answers?.[0]?.content) ? 0 : question?.answers?.[0]?.content}<span>/{total}</span> 
         </div>
         {defineStars(question?.answers?.[0]?.content, total, styles)}
      </div>
   );
};

export default StarsAnswer;
