import React from "react";
import styles from "./styles.module.scss";
import cross from "../../assets/images/symbols/cross_white.svg";
import successImg from "../../assets/images/success_icon.svg";
import errorImg from "../../assets/images/error_icon.svg";
import cn from "classnames";

const ToastMsg = ({ text, isError, color }) => {
   return (
      <div className={cn(styles.toastMsg, isError && styles.toastMsg_isError)}>
         {color && 
            <div style={{ background: color }} className={styles.colorEl}></div>
         } 
         {!color && (
            <div className={styles.status_warapper}>
               <img src={isError ? errorImg : successImg} alt="status" />
            </div>
         )}

         <div className={styles.text_wrapper}>
         {!color && (
            <span>{text}</span>
         )}
         {color && (
            <span>{text?.length > 90 ? `${text.slice(0, 90)}...` : text}</span>
         )}
         </div>
         <img className={styles.cross} src={cross} alt="close" />
      </div>
   );
};

export default ToastMsg;
