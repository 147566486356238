import Base from "./Base";
import { DEFAULT_DATA_LIMIT } from "../../constants/user";

export default class DepartmentsAndPositionsApi extends Base {
   createDepartment(data) {
      return super.post(`api/v1/department`, data);
   }

   updateDepartment(data, id) {
      return super.put(`api/v1/department/${id}`, data);
   }

   createPosition(data) {
      return super.post(`api/v1/position`, data);
   }

   updatePosition(data, id) {
      return super.put(`api/v1/position/${id}`, data);
   }

   getDepartments({
      page = 1,
      limit = DEFAULT_DATA_LIMIT,
      companyId,
      searchQuery,
      position,
      department,
      order_by,
      order,
      signal,
      isUsersCountNeeded = true,
   }) {
      let endpoint = `api/v1/department?limit=${limit}&page=${page}`;

      if (isUsersCountNeeded) {
         endpoint += `&filter[return_count_users_in_department]=true`;
      }

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (position?.id) {
         endpoint += `&filter[position]=${position?.id}`;
      }

      if (department?.id) {
         endpoint += `&filter[id]=${department?.id}`;
      }

      if (companyId) {
         endpoint += `&filter[company]=${companyId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      }

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint, null, signal);
   }

   getPositions({
      page = 1,
      limit = DEFAULT_DATA_LIMIT,
      companyId,
      searchQuery,
      position,
      department,
      order_by,
      order,
      signal,
      isUsersCountNeeded = true,
   }) {
      let endpoint = `api/v1/position?limit=${limit}&page=${page}`;

      if (isUsersCountNeeded) {
         endpoint += `&filter[return_count_users_in_position]=true`;
      }

      if (searchQuery) {
         endpoint += `&filter[search]=${searchQuery}`;
      }

      if (position?.id) {
         endpoint += `&filter[id]=${position?.id}`;
      }

      if (department?.id) {
         endpoint += `&filter[department]=${department?.id}`;
      }

      if (companyId) {
         endpoint += `&filter[company]=${companyId}`;
      }

      if (order_by) {
         endpoint += `&order_by=${order_by}`;
      }

      if (order) {
         endpoint += `&order=${order}`;
      } else {
         endpoint += `&order=desc`;
      }

      return super.get(endpoint, null, signal);
   }

   deleteDepartment(id) {
      return super.delete(`api/v1/department/${id}`);
   }

   deletePosition(id) {
      return super.delete(`api/v1/position/${id}`);
   }
}
