import React, { useEffect, useState, useRef } from "react";

import csvImg from "../../../assets/images/symbols/scv.svg";
import saveIcon from "../../../assets/images/icon_save.svg";
import copyIcon from "../../../assets/images/copy_icon.svg";

import Button from "../../../components/Button/Button";
import { CSVLink } from "react-csv";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Input from "../../../components/Input/Input";

import styles from "./styles.module.scss";
import cn from "classnames";

const LanguagesSubheader = ({
   refetchData,
   csvData = [],
   activeFilter,
   setActiveFilter,
   isDataLoading,
   exportToSCV,
   copyTable,
   onSave,
   copyText,
   availableLanguages,
   newTranslations,
   isUpdateLoading,
   handleFileUpload,
   progress,
   t
}) => {
   const csvLinkRef = useRef(null);

   const handleFilterClick = (filter) => {
      setActiveFilter(filter);
   };

   return (
      <div className={styles.subheader}>
 
         <div className={cn(styles.select_language, styles.item)}>
            <Dropdown
               data={availableLanguages}
               field={"name"}
               placeholder={t?.returnTranslation('Select language')}
               value={activeFilter?.name}
               onChange={handleFilterClick}
               maxHeight={"210px"}
               maxHeightFrontEl={"45px"}
            />
         </div>

         <div className={styles.item}>
            <Button
               withoutBorders
               onClick={copyTable}
               image={copyIcon}
               isGreen={copyText}
               title={copyText ? `${t?.copied}!`: t?.copyTable}
            />
         </div>

         <div className={styles.subheader_icon_btn}>
            <label
               htmlFor="file-upload-languages"
               className={styles.uploadLabel}
            >
               <img src={csvImg} alt="upload icon" />
               <span>{t?.uploadFromCSV}</span>
            </label>
            <input
               id="file-upload-languages"
               type="file"
               accept=".csv"
               className={styles.fileInput}
               onChange={handleFileUpload}
            />
         </div>

         <div className={styles.item}>
            <Button
               disabled={!newTranslations?.length || isUpdateLoading}
               onClick={onSave}
               image={saveIcon}
               title={isUpdateLoading ? `${t?.loading}... ${progress}%` : t?.saveChanges}
            />
         </div>
      </div>
   );
};

export default LanguagesSubheader;
