import React, { useState } from "react";
import styles from "./styles.module.scss";

const PasswordInput = ({ value, isInvalidPassword, onChangeValue, placeholder, isError, isStrongPassword }) => {
   const [isPasswordType, setIsPasswordType] = useState(false);

   return (
      <p className={`${styles.input_wrap} ${isInvalidPassword ? styles.invalid : ''} ${ isError ? styles.error : ''}`}>
         {isInvalidPassword && <span className={styles.validation_message}>Uncorrect password</span>}
         <input
            className={styles.authorization_form__input}
            type={isPasswordType ? "text" : "password"}
            placeholder={placeholder}
            name="password"
            value={value}
            onChange={onChangeValue}
         />
         <button
            type="button"
            className={`${styles.input_password_btn} ${isPasswordType ? styles.hide : styles.show}`}
            onClick={() => setIsPasswordType(!isPasswordType)}
         />
      </p>
   );
};

export default PasswordInput;
