import React from 'react';
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import styles from './styles.module.scss'
import authorizationBackgroundPerson from '../../assets/images/authorization-background-person.svg';
import SignUp from "../../components/SignUp";

export default function SignUpPage() {
    return (
        <AuthLayout>
            <main>
                <section className={styles.authorization_section}>
                    <div className={styles.authorization_section__container}>

                        <SignUp/>

                        <div className={styles.authorization_block__image_wrap}>
                            <img src={authorizationBackgroundPerson} width="460" height="539" alt="Person"/>
                        </div>
                    </div>
                </section>
            </main>
        </AuthLayout>
    );
};