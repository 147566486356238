import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import UsersAndRolesBodyItem from "./UsersAndRolesBodyItem";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import useSortData from "../../../hooks/useSortData";
import Loader from "../../../components/Loader/Loader";

const UsersAndRolesTable = ({
   columns,
   data,
   loading,
   searchedValue,
   openDeleteUserFromCompanyModal,
   lastElementRef,
   t
}) => {
   const [clickedUserId, setClickedUserId] = useState(null);
   const { sortedData, handleSort } = useSortData(data, columns);

   const navigate = useNavigate();

   if (sortedData?.length === 0 || (!data && !loading)) {
      return <EmptyBlock />;
   }

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={styles.header}
                     >
                        <span>{t?.returnTranslation(column?.replace("_", " "))}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
                  <th></th>
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {sortedData?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <UsersAndRolesBodyItem
                              row={row}
                              columns={columns}
                              index={index}
                              openDeleteUserFromCompanyModal={
                                 openDeleteUserFromCompanyModal
                              }
                              t={t}
                           />
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
               <tr
                  className={"trigger_refetch"}
                  ref={lastElementRef ? lastElementRef : null}
               ></tr>
            </tbody>
         </table>

         {loading && (
            <div className="default_loader_wrapper">
               <Loader size={"small"} />
            </div>
         )}
      </div>
   );
};

export default UsersAndRolesTable;
