import React, { useEffect, useRef, useState } from "react";
import useSortData from "../../../hooks/useSortData";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import cn from "classnames";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { useSelector } from "react-redux";
import TextArea from "../../../components/TextArea/TextArea";

const LanguagesTable = ({
   columns,
   data,
   setData,
   isLoading,
   lastItemRef,
   newTranslations,
   setNewTranslations,
   allTranslations,
}) => {
   const isFixed = useSelector((state) => state.sidebar.isFixed);

   const columnLabels = columns?.map((column) => column?.label);
   const { sortedData, handleSort } = useSortData(data, columnLabels);

   const [editingCell, setEditingCell] = useState(null);
   const [editValue, setEditValue] = useState("");
   const tableWrapperRef = useRef(null);

   const handleCellClick = (rowIndex, columnIndex, row, column) => {
      setEditingCell({ rowIndex, columnIndex, row, column });
      setEditValue(sortedData[rowIndex][columns[columnIndex]?.label] || "");
   };

   const handleInputChange = (e) => {
      setEditValue(e.target.value);
   };

   const handleBlur = () => {
      const updatedTranslations = [...newTranslations];

      const updatedTranslation = {
         id: editingCell?.row.id,
         domain: editingCell?.row?.domain,
         locale: editingCell?.column?.code,
         original_value: editingCell?.row?.originalValue,
         translated_language: editValue,
      };

      const existingTranslationIndex = updatedTranslations.findIndex(
         (t) =>
            t.id === editingCell?.row.id &&
            t.locale === editingCell?.column?.code
      );

      if (existingTranslationIndex >= 0) {
         updatedTranslations[existingTranslationIndex] = updatedTranslation;
      } else {
         updatedTranslations.push(updatedTranslation);
      }

      const updatedData = data.map((item) => {
         if (item.id === editingCell?.row.id) {
            return {
               ...item,
               [`translatedValue_${editingCell?.column?.code}`]: editValue,
            };
         }
         return item;
      });

      setData(updatedData);

      setNewTranslations(updatedTranslations);
      setEditingCell(null);
   };

   const handleKeyDown = (e) => {
      if (e.key === "Enter") {
         handleBlur();
      }
   };

   useEffect(() => {
      let animationFrameId;

      const handleScroll = () => {
         const tableWrapper = tableWrapperRef.current;
         if (!tableWrapper) return;

         const scrollLeft = tableWrapper.scrollLeft;

         const slidableCells = tableWrapper.querySelectorAll(
            `.${styles.slidableCell}`
         );

         animationFrameId = requestAnimationFrame(() => {
            slidableCells.forEach((cell) => {
               cell.style.transform = `right: ${-scrollLeft}px`;
            });
         });
      };

      const tableWrapper = tableWrapperRef.current;
      tableWrapper.addEventListener("scroll", handleScroll);

      return () => {
         tableWrapper.removeEventListener("scroll", handleScroll);
         if (animationFrameId) {
            cancelAnimationFrame(animationFrameId);
         }
      };
   }, [data]);

   if (sortedData?.length === 0 && data?.length > 0) {
      return <EmptyBlock text={"No data found"} />;
   }

   return (
      <div
         ref={tableWrapperRef}
         className={cn(
            styles.table_wrapper,
            isFixed ? styles.less_wrapper : ""
         )}
      >
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={`${column?.name}_${index}`}
                        onClick={() => handleSort(column?.label)}
                        className={cn(styles.header, {
                           [styles.stickyCell]: index === 0,
                           [styles.slidableCell]: index !== 0,
                        })}
                     >
                        <span>{column?.name}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {sortedData?.map((row, rowIndex) => (
                     <CSSTransition
                        key={`row-${rowIndex}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <tr
                           ref={
                              rowIndex === data?.length - 1 ? lastItemRef : null
                           }
                        >
                           {columns.map((column, columnIndex) => {
                              const isEditing =
                                 editingCell?.rowIndex === rowIndex &&
                                 editingCell?.columnIndex === columnIndex;

                              return (
                                 <td
                                    onClick={() =>
                                       !isEditing &&
                                       handleCellClick(
                                          rowIndex,
                                          columnIndex,
                                          row,
                                          column
                                       )
                                    }
                                    key={`cell-${rowIndex}-${columnIndex}`}
                                    className={cn({
                                       [styles.stickyCell]: columnIndex === 0,
                                       [styles.slidableCell]: columnIndex !== 0,
                                       [styles.rowOdd]: rowIndex % 2 === 0,
                                    })}
                                 >
                                    {isEditing && columnIndex !== 0 ? (
                                       <TextArea
                                          isResize
                                          className={styles.editInput}
                                          type="text"
                                          value={editValue}
                                          onChangeValue={handleInputChange}
                                          onBlur={handleBlur}
                                          onKeyDown={handleKeyDown}
                                          autoFocus
                                          maxRows={7}
                                          isLangsTable
                                       />
                                    ) : (
                                       <div className={styles.cellContent}>
                                          {row[column?.label]}
                                       </div>
                                    )}
                                 </td>
                              );
                           })}
                        </tr>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
            </tbody>
         </table>
      </div>
   );
};

export default LanguagesTable;
