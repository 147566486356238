import { useState, useEffect } from "react";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import {
   BIGGER_DATA_LIMIT,
   DEFAULT_DATA_LIMIT,
   PART_OF_MENTOR_NAME,
} from "../../constants/user";
import { TEAM_EXTRA_GROUP } from "../../utils/api/serialization_groups";
import TeamApi from "../../utils/api/TeamApi";

const useGetAllTeams = (excludeCompanyId, serializationGroup) => {
   const [isTeamsLoading, setIsTeamsLoading] = useState(false);
   const [searchedValue, setSearchedValue] = useState("");
   const [teamsList, setTeamsList] = useState([]);
   const [totalTeams, setTotalTeams] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [selectAll, setSelectAll] = useState(false);

   const abortController = new AbortController();
   const signal = abortController.signal;

   const fetchTeams = async (page) => {
      setIsTeamsLoading(true);

      try {
         const res = await new TeamApi().getAllTeams({
            limit: selectAll ? BIGGER_DATA_LIMIT : DEFAULT_DATA_LIMIT,
            page: page ? page : currentPage,
            excludeCompanyId,
            searchedValue,
            signal,
            serializationGroup,
         });

         if (res?.success?.data) {
            const data = res?.success?.data;

            if (page) {
               setTeamsList(data);
            } else {
               setTeamsList((prev) => [...prev, ...data]);
            }

            setTotalTeams(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setTeamsList([]);
         }
      } catch (error) {
         console.error("Error fetching Teams:", error);
      } finally {
         setIsTeamsLoading(false);
      }
   };

   const debouncedFetchTeams = debounce(
      () => fetchTeams(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchTeams = () => {
      setCurrentPage(1);
      debouncedFetchTeams();
   };

   useEffect(() => {
      if (currentPage > 1 && teamsList?.length <= totalTeams) {
         fetchTeams();
      }
   }, [currentPage]);

   useEffect(() => {
      setCurrentPage(1);
      debouncedFetchTeams();

      return () => debouncedFetchTeams.cancel();
   }, [searchedValue, excludeCompanyId]);

   useEffect(() => {
      return () => {
         abortController.abort();
         setSearchedValue("");
         setTeamsList([]);
      };
   }, []);

   const clearAllTeamsFilters = () => {
      if (searchedValue) setSearchedValue("");
   };

   useEffect(() => {
      if (
         selectAll &&
         teamsList?.length !== totalTeams &&
         teamsList?.length < BIGGER_DATA_LIMIT
      ) {
         setCurrentPage(1);
         debouncedFetchTeams();
      }

      return () => debouncedFetchTeams.cancel();
   }, [selectAll]);

   return {
      isTeamsLoading,
      clearAllTeamsFilters,
      teamsList,
      totalTeams,
      currentPage,
      refetchTeams,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      selectAll,
      setSelectAll,
   };
};

export default useGetAllTeams;
