import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import avatarPlaceholder from "../../../assets/images/avatar.svg";
import dots from "../../../assets/images/symbols/three_dots_white.svg";

import { useSelector } from "react-redux";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";

import styles from "./styles.module.scss";
import cn from "classnames";
import useOptions from "../../../hooks/useOptions";

const UsersAndRolesBodyItem = ({
   row,
   columns,
   index,
   openDeleteUserFromCompanyModal,
   t
}) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   const redirectToUserProfileAdminView = (id) => {
      window.open(`/users/profile/${id}`, "_blank");
   };

   return (
      <tr
         key={`row-${index}`}
         className={cn(index % 2 === 0 ? styles.rowOdd : "")}
      >
         {columns.map((column, columnIndex) => (
            <td key={`cell-${index}-${columnIndex}`}>
               <div className={styles.cellContent}>
                  <CustomTooltip
                     id={row[column]}
                     limit={35}
                     text={row[column]}
                  />
               </div>
            </td>
         ))}

         <td className={styles.option_wrapper}>
            <img
               ref={dotsRef}
               onClick={() => toggleOptions()}
               src={dots}
               alt={"options"}
            />
            {isOptionsOpen && (
               <div ref={dropdownRef} className={styles.dropdown}>
                  <p
                     className={styles.options}
                     onClick={() => {
                        redirectToUserProfileAdminView(row?.id);
                        closeOptions();
                     }}
                  >
                     {t?.profile}
                  </p>
                  <p
                     className={styles.options}
                     onClick={() => {
                        openDeleteUserFromCompanyModal(row);
                        closeOptions();
                     }}
                  >
                     {t?.deleteFromCompany}
                  </p>
               </div>
            )}
         </td>
      </tr>
   );
};

export default UsersAndRolesBodyItem;
