import styles from "./styles.module.scss";
import cn from "classnames";

import star from "../../../../assets/images/custom_star_bigger.svg";

const defineStars = (total = 10, rating, styles, onChooseStar) => {
   const activeStars = Math.min(rating, total);

   const stars = [];
   for (let i = 0; i < total; i++) {
      stars.push(
         <img
            onClick={() => onChooseStar(i + 1)}
            key={i}
            src={star}
            alt={`Star ${i + 1}`}
            className={i < activeStars ? styles.active_star : ""}
         />
      );
   }

   return stars;
};

const AnswerStars = ({ total, choosedStar, onChooseStar }) => {
   return (
      <div className={styles.rating_wrapper}>
         {defineStars(total, choosedStar, styles, onChooseStar)}
      </div>
   );
};

export default AnswerStars;
