import styles from "./styles.module.scss";
import cn from "classnames";

const RateAnswer = ({ questionIdx, question }) => {
   return (
      <div className={styles.rating_wrapper}>
         {[...Array(10)].map((_, idx) => (
            <div
               key={idx + 1}
               className={cn(styles.rating_item, {
                  [styles.active_rating]: idx + 1 === +question?.answers?.[0]?.content,
               })}
            >
               {idx + 1}
            </div>
         ))}
      </div>
   );
};

export default RateAnswer;
