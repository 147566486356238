import { useState, useMemo } from "react";
import moment from "moment";

const useSortData = (data) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = useMemo(() => {
    if (!sortColumn) return data;
    return [...data].sort((a, b) => {
      try {
        const isDateColumn = sortColumn.includes("date");
        const isTimeSpent = sortColumn.includes("time") && "timeSpent";
        const isScore = sortColumn.includes("score") && "successPercentage";

        const aValue = a[isTimeSpent || isScore || sortColumn] ?? "";
        const bValue = b[isTimeSpent || isScore || sortColumn] ?? "";

        // Handle empty values by prioritizing non-empty values
        if (aValue === "" && bValue !== "") return 1;
        if (aValue !== "" && bValue === "") return -1;

        if (sortDirection === "asc") {
          if (isTimeSpent) {
            const aDuration = moment.duration(aValue);
            const bDuration = moment.duration(bValue);
            return aDuration.asMilliseconds() - bDuration.asMilliseconds();
          } else if (isDateColumn) {
            return moment(aValue).diff(moment(bValue));
          } else if (typeof aValue === "string" && typeof bValue === "string") {
            return aValue.localeCompare(bValue);
          } else {
            return aValue - bValue;
          }
        } else {
          if (isTimeSpent) {
            const aDuration = moment.duration(bValue);
            const bDuration = moment.duration(aValue);
            return aDuration.asMilliseconds() - bDuration.asMilliseconds();
          } else if (isDateColumn) {
            return moment(bValue).diff(moment(aValue));
          } else if (typeof aValue === "string" && typeof bValue === "string") {
            return bValue.localeCompare(aValue);
          } else {
            return bValue - aValue;
          }
        }
      } catch (error) {
        console.error("Error sorting data:", error);
        return 0;
      }
    });
  }, [data, sortColumn, sortDirection]);

  return {
    sortedData,
    handleSort,
    sortColumn,
    sortDirection,
  };
};

export default useSortData;
