import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Circle.scss";

const Circle = ({ progress, width, height, fontSize, color = '#fff' }) => {
   return (
      <div
         style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            margin: "auto",
            textAlign: "center",
         }}
      >
         <CircularProgressbar
            value={progress}
            styles={{
               root: {
                  width: width,
                  height: height,
               },
               path: {
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  stroke: color,
               },
               trail: {
                  transformOrigin: "center center",
               },
            }}
         />
      </div>
   );
};

export default Circle;
