import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

import arrowLeft from "../../../../assets/images/arrow_left.svg";
import menuIcon from "../../../../assets/images/menu/list_icon.svg";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import useFindTranslationByString from "../../../../hooks/useFindTranslationsByString";

const CourseMobileHeader = ({
   title,
   onCloseMenu,
   onExitCourse,
   isMenuOpened,
   isNoNeedArrowToCloseMenu
}) => {
   const t  = useFindTranslationByString();

   return (
      <div className={cn(styles.wrapper)}>
         <div className={cn(styles.img_wrapper, isNoNeedArrowToCloseMenu ? styles.hide : '')} onClick={isNoNeedArrowToCloseMenu ? ()=> {} : onCloseMenu}>
            {isMenuOpened ? (
               <img src={arrowLeft} alt="" />
            ) : (
               <img src={menuIcon} alt="" />
            )}
         </div>

         <div className={styles.title}>
            <CustomTooltip
               position="bottom"
               id={"title-custo-tooltip-id"}
               limit={20}
               text={title}
            />
         </div>

         <button className={styles.on_exit} onClick={onExitCourse}>
            {t('Exit course')}
         </button>
      </div>
   );
};

export default CourseMobileHeader;
