import Dropzone from "react-dropzone-uploader";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import videoThumbnail from "../../../../assets/images/video_thumbnail.png";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import VideoPlayerModal from "../../../../components/Modals/VideoPlayerModal/VideoPlayerModal";
import watchVideoIcon from "../../../../assets/images/watch_video_icon.svg";

import cn from "classnames";
import styles from "./styles.module.scss";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import { toast } from "react-toastify";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { HTTPS_REGEX } from "../../../../constants/preLinks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { returnTemporaryQuestionFileLink } from "../../../../utils/questionsHelper";
import { useParams } from 'react-router-dom';
import { isMobileDevice } from '././../../../../utils/usersHelper';
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const VideoQuestion = ({
   inputsData,
   handleChangeStatus,
   question,
}) => {
   const playerModal = useHandleModal();
   const openVideoModal = () => playerModal.open();
   const [videoUrl, setVideoUrl] = useState('');
   const userId = useSelector(state => state?.user?.info?.id);
   const params = useParams();
   const hasContentLink = HTTPS_REGEX.test(inputsData?.content);

   const t = useFindTranslationsByString();
   
   function getFileNameFromURL(url) {
      const parts = url.split('/');
      const filenameWithParams = parts[parts.length - 1];
      const filenameParts = filenameWithParams.split('?');
      return filenameParts[0];
  }

  const getUpdatedUrlLink = async() => {
      const fileName = getFileNameFromURL(inputsData?.content);
      const link = await returnTemporaryQuestionFileLink(userId, fileName, params?.id);

      setVideoUrl(link[0]?.fileUrl);
   }

   useEffect(()=> {
      if(inputsData?.content && userId) {
         getUpdatedUrlLink()
      }
   }, [inputsData, userId])

   return (
      <>
         <div className={cn(styles.video_uploaded)}>
            {hasContentLink && (
               <div className={styles.player_container}>
                  <VideoPlayerModal
                     isOpen={playerModal.isActive}
                     onClose={playerModal.close}
                     url={videoUrl}
                  />
                  <div onClick={openVideoModal} className={styles.video_name_wrapper}>
                     <img src={videoThumbnail} />
                     <CustomTooltip
                        limit={isMobileDevice() ? 30 : 70}
                        maxWidth={'600px'}
                        text={getFileNameFromURL(inputsData?.content)}
                        id={`name_${inputsData.content}`}
                     />
                  </div>
                  <div
                     onClick={openVideoModal}
                     className={styles.open_file_btn}
                  >
                     <img src={watchVideoIcon} alt="Play icon" />
                     {t("Watch")}
                  </div>
               </div>
            )}

            {!hasContentLink && 
               <div>{t("No video was added by an admin!")}</div>
            }
         </div>
      </>
   );
};

export default VideoQuestion;
