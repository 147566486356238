import styles from "./index.module.scss";
import cn from  'classnames';

const SecondLoader = ({ size, defaultPosition }) => {
   return (
      <span className={cn(styles.loader, size === 'small' ? styles.smallLoader : '')}></span>
   );
};

export default SecondLoader;
