import moment from "moment";

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const isValidEmail = (email) => {
   // Regular expression for validating an Email
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
 };
 
export function sortObjByKeys(obj) {
    const ordered = {};

    Object.keys(obj).sort().forEach(function (key) {
        ordered[key] = obj[key];
    });

    return ordered;
}

export function getArrayOfNumbersYear(start, stop) {
    const result = [];
    while (stop >= start) {
        result.push(stop--);
    }
    return result;
}

export function getArrayOfNumbers(start, stop) {
    const result = [];
    while (start <= stop) {
        result.push(start++);
    }
    return result;
}

export function isNewFolder(date) {
    return moment(date).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
}