import React from "react";
import styles from "./styles.module.scss";
import Switch from "../../../components/Switch/Switch";
import CopyInput from "../../../components/CopyInput/CopyInput";
import FolderCoverBlock from "../../../components/FolderCoverBlock/FolderCoverBlock";
import { FOLDER_SETTINGS_SUBMENU } from "../../../constants/folders";
import { COLOR_PRESETS } from "../../../constants/courses";
import cn from "classnames";
import { useParams } from "react-router-dom";

const LeftPanelBlock = ({
   setActiveTab,
   activeTab,
   folder,
   data,
   setData,
   isTopic,
   t,
}) => {
   const params = useParams();
   const currentEntityTitle = params?.topicId ? "topic" : "folder";

   const folderOrTopicLink = params?.topicId
      ? `${window.location.origin}/courses/folder/${params?.folderId}/topic/${params?.topicId}`
      : `${window.location.origin}/courses/folder/${params?.id}`;

   const onClickTab = (id) => setActiveTab(id);

   return (
      <div className={styles.settingsBlock}>
         <div className={styles.header}>
            <div>
               <p className={styles.header_title}>{t?.publish}</p>
               <p className={styles.header_text}>{t?.publishHideFolder}</p>
            </div>
            <Switch
               checked={data?.new_status === 2 ? false : true}
               onChange={() =>
                  setData({
                     ...data,
                     new_status: +(data?.new_status === 2 ? 1 : 2),
                  })
               }
            />
         </div>

         <div className={styles.content}>
            <span className={styles.preview}>{t?.preview}</span>
            <FolderCoverBlock
               key={data}
               isTopic={params?.topicId ? true : false}
               folder={{
                  ...folder,
                  title: data?.new_name,
                  color: data?.new_color,
                  folder:
                     COLOR_PRESETS.find(
                        (itemColor) => +itemColor?.id === +data?.new_color
                     )?.color || COLOR_PRESETS[0]?.color,
                  icon: data?.new_icon,
                  folder_icon: data?.new_folder_icon,
                  hexColor: data?.hexColor,
                  status: data?.new_status,
               }}
            />

            <p className={styles.block_title}>{t?.title}</p>
            <p className={styles.title}>{folder?.name || ""}</p>

            <p className={styles.block_title}>
               {t?.linkToTheFolder} - {currentEntityTitle}
            </p>

            <CopyInput value={folderOrTopicLink} titleAfterCopied={t?.copied} />

            <div className={styles.tabs}>
               {FOLDER_SETTINGS_SUBMENU?.map((item, key) => (
                  <div
                     key={key}
                     className={cn(
                        styles.tabs_item,
                        activeTab === item?.value && styles.tabs_item_active
                     )}
                     onClick={() => onClickTab(item?.value)}
                  >
                      <div className={styles.tabs_image}><img
                          src={
                              activeTab === item?.value
                                  ? item?.activeImage
                                  : item?.image
                          }
                          alt={""}
                      /></div>
                     {params?.topicId
                        ? t?.returnTranslation(item.titleForTopic)
                        : t?.returnTranslation(item?.title)}
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default LeftPanelBlock;
