import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";
import styles from "./styles.module.scss";
import cn from "classnames";

const WrittenAnswer = ({ answer, answerIdx, correctImg, iconWrong }) => {
   const t = useFindTranslationsByString();

   return (
      <div className={cn(styles.answer_item, styles.written_answer)} key={answer.content}>
         <p>{answer?.content ? answer?.content : t('No answer from user!')}</p>
      </div>
   );
};

export default WrittenAnswer;
