import { useEffect, useState } from "react";
import FolderApi from "../../utils/api/FolderApi";
import { COLOR_PRESETS } from "../../constants/courses";

const useGetOneFolder = (folderId) => {
   const [folder, setFolder] = useState({});
   const [folderInfo, setFolderInfo] = useState([]);

   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const fetchFolder = async () => {
      const res = await new FolderApi().getOneFolder(folderId);

      if (res?.success?.data) {
         const folder = res?.success?.data || null;

         setFolder({
            ...res?.success?.data,
            folder:
               COLOR_PRESETS.find(
                  (itemColor) => +itemColor?.id === +folder?.color
               )?.color || COLOR_PRESETS[0]?.color,
            hexColor:
               COLOR_PRESETS.find(
                  (itemColor) => +itemColor?.id === +folder?.color
               )?.hexColor || COLOR_PRESETS[0]?.hexColor,
            status: folder?.status,
            title: folder?.name,
            courses: folder?.courses || [],
            readers: null,
            id: folder?.id,
            createdAt: folder?.createdAt,
            folder_icon: folder?.icon || null,
         });
      }
   };

   const getFoldersInfo = async () => {
      const dataToSend = {
         folder_ids: [folder?.id],
      };

      const res = await new FolderApi().getFoldersInfo(dataToSend);
      if (res?.success?.data) {
         setFolderInfo(res?.success?.data?.[0]);
      }
   };

   useEffect(() => {
      fetchFolder();
   }, []);

   useEffect(() => {
      if (folder?.id) {
         getFoldersInfo();
      }
   }, [folder]);

   const mappedFolder = {
      ...folder,
      coursesCount: folderInfo.yourAvailableCoursesInFolderCount,
      readers: folderInfo.usersWithVisibleFolderCount,
   };

   return {
      mappedFolder,
      folder,
      fetchFolder,
      loading,
      error,
   };
};

export default useGetOneFolder;
