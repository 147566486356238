import {
    USER_SET_INFO,
} from "../constants/user";

export function setInfo(value) {
    return function (dispatch) {
        return dispatch({
            type: USER_SET_INFO,
            info: value,
        });
    }
}