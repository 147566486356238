import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED } from "../../../../constants/tests";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const CheckManualQuestion = ({
   correctImgGreen,
   partiallyCorrectIcon,
   iconWrongRed,
   addManualResult,
   question,
}) => {
   const [activeButton, setActiveButton] = useState(null);

   const t = useFindTranslationsByString();

   const handleButtonClick = (result) => {
      addManualResult(question, result);
      setActiveButton(result);
   };

   useEffect(()=> {
      if(question?.testQuestion?.status) {
         setActiveButton(question?.testQuestion?.status);
      }
   }, [])

   return (
      <div className={styles.manual_check_wrrapper}>
         <p>{t("Grade for the task")}</p>
         <div className={styles.complete_btns}>
            <button onClick={()=> handleButtonClick(TEST_STATUS_APPROVED)} 
              className={cn(
                  styles.button_true, {
                  [styles.activeTrue]: activeButton === TEST_STATUS_APPROVED
               })}
           >
               <img src={correctImgGreen} alt="" />
               <span>{t("True")}</span>
            </button>
            <button onClick={()=> handleButtonClick(TEST_STATUS_ON_REWORK)} 
               className={cn(
                  styles.button_half_right, {
                  [styles.activePartially]: activeButton === TEST_STATUS_ON_REWORK
               })}
            >
               <img src={partiallyCorrectIcon} alt="" />
               <span>{t("Partially")}</span>
            </button>
            <button onClick={()=> handleButtonClick(TEST_STATUS_REJECTED)} 
              className={cn(
               styles.button_false, {
               [styles.activeFalse]: activeButton === TEST_STATUS_REJECTED
            })}
            >
               <img src={iconWrongRed} alt="" />
               <span>{t("False")}</span>
            </button>
         </div>
      </div>
   );
};

export default CheckManualQuestion;
