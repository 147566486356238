import styles from "./index.module.scss";

const ListLoader = () => {
   return (
      <div className={styles.loader_wrapper}>
         <div className={styles.loader}></div>
      </div>
   );
};

export default ListLoader;
