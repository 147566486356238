import React, { useEffect, useRef, useState } from "react";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";
import plus from "../../../assets/images/plus.svg";

import { transformRoleText } from "../../../utils/rolesHelper";
import cn from "classnames";
import styles from "./styles.module.scss";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import DropdownOptions from "../DropdownOptions/DropdownOptions";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";

const RoleItem = ({
   role,
   activeRole,
   confirmModal,
   onCopy,
   onRenameModalOpen,
   handleRoleClick,
   companyName,
}) => {
   const [newRoleName, setNewRoleName] = useState("test");

   return (
      <>
         <button
            key={role?.id}
            onClick={() => handleRoleClick(role)}
            className={cn(
               styles.role_item,
               activeRole?.id === role?.id ? styles.active : ""
            )}
         >
            <div
               className={cn(
                  styles.customCircle,
                  activeRole?.id === role?.id ? styles.active : ""
               )}
            ></div>

            <CustomTooltip 
               limit={30} 
               text={transformRoleText(role?.name, companyName)}
               id={`lesson_name_${role?.id}`}
            />

            <DropdownOptions
               onConfirmOpen={() => confirmModal.open()}
               onCopy={onCopy}
               onRenameModalOpen={onRenameModalOpen}
               role={role}
            />
         </button>
      </>
   );
};

export default RoleItem;
