import React, { useRef, useState } from "react";
import csvImg from "../../../assets/images/symbols/scv.svg";
import crossIcon from "../../../assets/images/symbols/cross_grey.svg";

import passed from "../../../assets/images/knowledge_check_mobile/passed.svg";
import failed_icon from "../../../assets/images/knowledge_check_mobile/failed_icon.svg";
import on_check from "../../../assets/images/knowledge_check_mobile/on_check.svg";
import on_rework from "../../../assets/images/knowledge_check_mobile/on_rework.svg";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import filtersFilledImg from "../../../assets/images/symbols/filters_filled.svg";

import Button from "../../../components/Button/Button";

import styles from "./styles.module.scss";
import { CSVLink } from "react-csv";
import { BIGGER_DATA_LIMIT, ROLE_ID_STUDENT } from "../../../constants/user";
import { useSelector } from "react-redux";
import useUserRole from "../../../hooks/useUserRole";
import ExtendedDateDropdown from "../../../components/ExtendedDateDropdown/ExtendedDateDropdown";
import useFindTranslations from "../../../hooks/useFindTranlsations";
import KnowledgeCheckFilters from "../KnowledgeCheckFilters/KnowledgeCheckFilters";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import cn from 'classnames';

const KnowledgeSubheader = ({
  searchedValue,
  setSearchedValue,
  refetchData,
  csvData,
  activeFilter,
  setActiveFilter,
  checked,
  students,
  studentsSearchedValue,
  setStudentsSearchedValue,
  selectedStudents,
  setSelectedStudents,
  lastStudentRef,
  onFilterApply,
  dateFilter,
  setDateFilter,
  isTeamPage,
  frontTimeFilterValue,
  setFrontTimeFilterValue,
  fetchTests,
  isDataLoading,
  setSelectedTeams,
  selectedTeams,
  setTestsFilter,
  testsFilter
}) => {
  const csvLinkRef = useRef(null);

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const t = useFindTranslations();

  const toggleOption = (filter) => {
   if (testsFilter === filter) {
     if (setTestsFilter) setTestsFilter(null);
   } else {
     if (setTestsFilter) setTestsFilter(filter);
   }
 };

  const dropDownRefTestsFilters = useRef(null);
  const testsOptionsRef = useRef(null);
  const [testStatusDropdownOpen, setTestStatusDropdownOpen] = useState(false);


  const closeTestsOptions = () => setTestStatusDropdownOpen(false);

  useOutsideClick(dropDownRefTestsFilters, closeTestsOptions, testsOptionsRef);

  const handleStudentsFilterClick = (user) => {
    if (selectedStudents?.find((item) => item?.id === user?.id))
      setSelectedStudents(
        selectedStudents?.filter((item) => item?.id !== user?.id),
      );
    else setSelectedStudents([...selectedStudents, user]);
  };

  const handleTeamsFilterClick = (team) => {
    if (selectedTeams?.find((item) => item?.id === team?.id))
      setSelectedTeams(selectedTeams?.filter((item) => item?.id !== team?.id));
    else setSelectedTeams([...selectedTeams, team]);
  };

  const currentCompanyId = useSelector((state) => state.sidebar.companyId);
  const { userRoleId } = useUserRole(currentCompanyId);

  const testsOptions = [
   { key: "passed", status: 1, icon: passed, label: t.returnTranslation("Passed") },
   { key: "failed", status: 2, icon: failed_icon, label: t.returnTranslation("Failed") },
   { key: "on_check", status: 0, icon: on_check, label: t.returnTranslation("On check") },
   { key: "on_rework", status: 3, icon: on_rework, label: t.returnTranslation("On rework") },
];

  const renderSelectedTestFilter = (icon, value, onClearHandler) => {
   return (
     <div className={styles.selected_filters_item}>
      <img src={icon} alt="" />
       <span>{value}</span>
       <img
         className={styles.cross}
         onClick={onClearHandler}
         src={crossIcon}
         alt=""
       />
     </div>
   );
 };

  return (
    <div className={styles.subheader}>
      <div className={styles.line}></div>
      <div className={styles.search_and_filters_wrapper}>
         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
                  />

               {searchedValue && (
                  <img src={crossIcon} onClick={()=> setSearchedValue('')} className={styles.clearButton} />
               )}
            </div>
         </div>

      <div
         onClick={()=> setTestStatusDropdownOpen(!testStatusDropdownOpen)}
         className={styles.filter_wrapper_mob}
         ref={dropDownRefTestsFilters}
      >
         <img className={styles.front_img} src={filtersImg} />
         {testStatusDropdownOpen &&
               <div ref={testsOptionsRef} className={styles.student_btns_mob}>
                  {testsOptions?.map((item) => (
                     <button
                        key={item?.label}
                        onClick={() => toggleOption(item)}
                        className={cn(
                           styles.block_medium,
                           testsFilter?.key === item?.key ? styles.activeFilter : ""
                        )}
                     >
                        <img src={item?.icon} alt="" />
                        {item?.label}
                     </button>
                  ))}
               </div>
            }
        </div>
      </div>

      {(testsFilter?.key?.length > 0) &&
            <div className={styles.selected_filters}>
               {testsFilter?.key === 'failed' &&
                  renderSelectedTestFilter(failed_icon, t.returnTranslation("Failed"), ()=> toggleOption('failed'))
               }
               {testsFilter?.key === 'passed' &&
                  renderSelectedTestFilter(passed, t.returnTranslation("Passed"), ()=> toggleOption('passed'))
               }
               {testsFilter?.key === 'on_rework' &&
                  renderSelectedTestFilter(on_rework, t.returnTranslation("On rework"), ()=> toggleOption('on_rework'))
               }
               {testsFilter?.key === 'on_check' &&
                  renderSelectedTestFilter(on_check, t.returnTranslation("On check"), ()=> toggleOption('on_check'))
               }
            </div>
         }

      <div className={styles.filterBlockDate}>
        <ExtendedDateDropdown
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          frontTimeFilterValue={frontTimeFilterValue}
          setFrontTimeFilterValue={setFrontTimeFilterValue}
        />
      </div>

      {userRoleId && userRoleId !== ROLE_ID_STUDENT && !isTeamPage && (
        <KnowledgeCheckFilters
          searchNeeded
          showStatusFilter={checked}
          students={students}
          field={"label"}
          placeholder={t?.users}
          activeFilter={activeFilter}
          handleStudentsFilterClick={handleStudentsFilterClick}
          studentsSearchedValue={studentsSearchedValue}
          setStudentsSearchedValue={setStudentsSearchedValue}
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
          lastStudentRef={lastStudentRef}
          handleFilterClick={handleFilterClick}
          handleTeamsFilterClick={handleTeamsFilterClick}
          onFilterApply={onFilterApply}
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
        />
      )}

      <div className={styles.csv_wrapper}>
        <Button
          withoutBorders
          colorLighter
          isBlack
          onClick={() =>
            fetchTests(1, BIGGER_DATA_LIMIT).then(() =>
              csvLinkRef.current.link.click(),
            )
          }
          title={t?.exportToCSV}
          image={csvImg}
          maxWidth={"180px"}
          disabled={isDataLoading}
        />

        <CSVLink
          ref={csvLinkRef}
          style={{ textDecoration: "none" }}
          data={csvData}
        />
      </div>
    </div>
  );
};

export default KnowledgeSubheader;
