import { TOGGLE_SIDEBAR, TOGGLE_SIDEBAR_FIXED, SET_COMPANY_INFO, SET_COMPANY_ICON, SET_COMPANY_NAME, SET_USER_AS_ADMIN, SET_CURATOR_MODAL_HASH, SET_IS_CONFIRM_MODAL_NEEDED, SET_COMPANY_AVAILABLE_LANGUAGES, SET_COMPANY_APP_TRANSLATION, SET_USER_LANGUAGE, SET_INFO_LOADING, SET_CHOSEN_LANGUAGE, TOGGLE_SIDEBAR_MOBILE, CHANGE_SIDEBAR_PROFILE_MOBILE} from "../constants/sidebar";

export function toggleSidebar(isOpen) {
    return {
        type: TOGGLE_SIDEBAR,
        isOpen: isOpen,
    };
}

export function changeSidebarProfileInfo(info) {
    return {
        type: CHANGE_SIDEBAR_PROFILE_MOBILE,
        mobProfileSidebarInfo: info,
    };
}

export function toggleSidebarMobile(isOpen) {
    return {
        type: TOGGLE_SIDEBAR_MOBILE,
        isOpenMobile: isOpen,
    };
}

export function toggleSidebarFixed(isFixed) {
   return {
     type: TOGGLE_SIDEBAR_FIXED,
     isFixed: isFixed,
   };
 }

 export function setCompanyInfo(companyId, companyName, companyLogo, currentCompanyBranchId) {
   return {
     type: SET_COMPANY_INFO,
     companyName,
     companyLogo,
     companyId,
     currentCompanyBranchId
   };
 }

 export function setCompanyIcon(companyLogo) {
   return {
     type: SET_COMPANY_ICON,
     companyLogo,
   };
 }

 export function setCompanyName(companyName) {
   return {
     type: SET_COMPANY_NAME,
     companyName,
   };
 }

 export function setCompanyLanguages(langs) {
   return {
     type: SET_COMPANY_AVAILABLE_LANGUAGES,
     langs,
   };
 }

 export function setInfoLoading(loading) {
   return {
     type: SET_INFO_LOADING,
     loading,
   };
 }

 export function setChosenLanguage(chosenLanguage) {
   return {
     type: SET_CHOSEN_LANGUAGE,
     chosenLanguage,
   };
 }

 export function setCompanyTranslation(translations) {
   return {
     type: SET_COMPANY_APP_TRANSLATION,
     translations,
   };
 }
 
 export function setUserLanguage(locale) {
   return {
     type: SET_USER_LANGUAGE,
     locale,
   };
 }

export function setUserAsAdmin(value) {
   return {
     type: SET_USER_AS_ADMIN,
     isUserAsStudent: value,
   };
 }

export function setCuratorModalHash(value) {
   return {
     type: SET_CURATOR_MODAL_HASH,
     curatorModalHash: value,
   };
 }

export function setConfirmModalNeeded(value) {
   return {
     type: SET_IS_CONFIRM_MODAL_NEEDED,
     confirmModalNeeded: value,
   };
 }