import React, { useEffect, useState } from "react";
import useSortData from "../../../../hooks/useSortData";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import Roles from "../../../../utils/api/Roles";
import {
   ROLE_ID_STUDENT,
   REQUESTED_STATUS_REJECT,
   REQUESTED_STATUS_ACCEPT,
   USER_STATUS_ACTIVE,
   USER_STATUS_BANNED,
} from "../../../../constants/user";
import UserApi from "../../../../utils/api/UserApi";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import CoursesOrTopicsBodyItem from "./CoursesOrTopicsBodyItem";
import ConfirmModal from "../../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import BranchApi from "../../../../utils/api/BranchApi";
import EmptyBlock from "../../../../components/EmptyBlock/EmptyBlock";
import { useSelector } from "react-redux";
import {
   STUDENT_STATUS_ACTIVE,
   STUDENT_STATUS_DELETED,
   STUDENT_STATUS_PAUSED,
} from "../../../../constants/courses";
import CourseStatusModal from "../../../../components/Modals/CourseStatusModal/CourseStatusModal";
import SecondLoader from "../../../../components/SecondLoader/SecondLoader";

const CoursesOrTopicsTable = ({
   columns,
   data,
   searchedValue,
   tableFor,
   loading,
   deleteItemFromTable,
   refetch,
   lastItemRef,
   isCourses,
   confirmDeleteFromFolderModalOpen,
   onConfirmDeleteOpen,
   onConfirmHideOpen,
   onConfirmOpenRestore,
   onConfirmOpenDelete,
   onConfirmOpenChangeHidenStatus,
   activeStatusFilter,
   onOpenRenameModal,
   t
}) => {
   const { sortedData, handleSort } = useSortData(data);
   const [clickedId, setClickedId] = useState(null);

   const [clickedItem, setClickedItem] = useState(null);
   const [isChangeLoading, setIsChangeLoading] = useState(false);

   const navigate = useNavigate();
   const confirmModal = useHandleModal();
   const handleStatusModal = useHandleModal();

   const onOpenStatusModal = (item) => {
      handleStatusModal.open();
      setClickedItem(item);
   };

   const openConfirmModal = (Id) => {
      setClickedId(Id);
      confirmModal.open();
   };

   const onDeleteFromPlatform = async () => {
      setIsChangeLoading(true);

      const res = await new UserApi().updateUser(clickedUserId, {
         new_status: USER_STATUS_BANNED,
      });

      if (res?.success?.message) {
         if (deleteUserFromTable) deleteUserFromTable(clickedUserId);
         changeUserStatus(clickedUserId, USER_STATUS_BANNED);
         refetch();
         toast(<ToastMsg text={res.success.message} />);
      }

      if (res?.error?.message)
         toast(<ToastMsg text={res?.error?.message} isError />);
      setIsChangeLoading(false);
      confirmModal.close();
   };

   if (data?.length === 0 && !loading) {
      return <EmptyBlock />;
   }

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns?.map((column, index) => (
                     <th
                        key={`${column.title}_${index}`}
                        onClick={() => handleSort(column?.field)}
                        className={styles.header}
                     >
                        <span>{t?.returnTranslation(column?.title)}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
                  {/* needed for making space for options */}
                  <th></th>
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {sortedData?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <CoursesOrTopicsBodyItem
                              tableFor={tableFor}
                              row={row}
                              columns={columns}
                              index={index}
                              openConfirmModal={openConfirmModal}
                              onOpenStatusModal={onOpenStatusModal}
                              isCourses={isCourses}
                              lastItemRef={
                                 index === sortedData?.length - 1
                                    ? lastItemRef
                                    : null
                              }
                              confirmDeleteFromFolderModalOpen={
                                 confirmDeleteFromFolderModalOpen
                              }
                              onConfirmDeleteOpen={onConfirmDeleteOpen}
                              onConfirmHideOpen={onConfirmHideOpen}
                              onConfirmOpenRestore={onConfirmOpenRestore}
                              onConfirmOpenDelete={onConfirmOpenDelete}
                              onConfirmOpenChangeHidenStatus={
                                 onConfirmOpenChangeHidenStatus
                              }
                              onOpenRenameModal={onOpenRenameModal}
                              activeStatusFilter={activeStatusFilter}
                              t={t}
                           />
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
            </tbody>
         </table>

         {data?.length === 0 && !loading && <EmptyBlock />}

         {loading && (
            <div className="default_loader_wrapper">
               <SecondLoader />
            </div>
         )}

         <ConfirmModal
            confirmButtonText={t?.blockUser}
            onConfirm={onDeleteFromPlatform}
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            title={t?.areYouSure}
            isLoading={isChangeLoading}
         />

         <CourseStatusModal
            onClose={handleStatusModal.close}
            isOpen={handleStatusModal.isActive}
            item={clickedItem}
            refetchCourseUsers={refetch}
         />
      </div>
   );
};

export default CoursesOrTopicsTable;
