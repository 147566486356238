import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import book from "../../assets/images/course_second_img.svg";
import person from "../../assets/images/user.svg";

import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { useSelector } from "react-redux";
import {
   PART_OF_MENTOR_NAME,
   ROLE_ID_STUDENT,
} from "../../constants/user";
import cn from "classnames";
import CustomStaticTooltip from "../CustomStaticTooltip/CustomStaticTooltip";
import { v4 as uuidv4 } from "uuid";
import useUserRole from "../../hooks/useUserRole";
import { DEFAULT_TEXT, returnTranslation } from '../../constants/languages';
import DotsButton from "../DotsButton/DotsButton";

const FolderInListItem = ({
   folder,
   activeFilter,
   onOpenDuplicateModal,
   onConfirmDeleteOpen,
   onConfirmHideOpen,
   onConfirmOpenRestore,
   onConfirmOpenDelete,
   onConfirmOpenChangeHidenStatus,
   lastFolderRef,
   onRedirectToEditFolderPage
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const dotsRef = useRef();
   const dropdownRef = useRef();

   const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);
   const translations = useSelector((state) => state.sidebar.translations);

   const onCloseSettings = () => setIsOpen(false);

   useOutsideClick(dropdownRef, onCloseSettings, dotsRef);

   const onOpenDuplicate = (e) => {
      e.stopPropagation();
      if (onOpenDuplicateModal) onOpenDuplicateModal(folder);
      onCloseSettings();
   };

   const navigate = useNavigate();

   const navigateToFolderPage = () => {
      navigate(`/courses/folder/${folder?.id}`);
   };

   const onToggleSettings = (e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
   };

   const hideText = returnTranslation(translations, DEFAULT_TEXT.common.hide);
   const deleteText = returnTranslation(translations, DEFAULT_TEXT.common.delete);
   const restoreText = returnTranslation(translations, DEFAULT_TEXT.common.restore);
   const editText = returnTranslation(translations, DEFAULT_TEXT.common.edit);
   const changeToVisible = returnTranslation(translations, DEFAULT_TEXT.common.changeToVisible);

   const coursesInsideFolder = returnTranslation(translations, DEFAULT_TEXT.courses_and_folders.coursesInsideFolder);
   const usersAccessCoursesFolder = returnTranslation(translations, DEFAULT_TEXT.courses_and_folders.usersAccessCoursesFolder);

   return (
      <>
         <div
            ref={lastFolderRef}
            onClick={navigateToFolderPage}
            className={styles.folderBlockWrapper}
         ><div
             className={cn(styles.folderBlock, "noselect")}
             >
            <p className={styles.title}>
               <div
                   className={styles.folder_image}
                   style={{background: `${folder?.folder}`}}
               ></div>
               <CustomTooltip
                   id={`${folder?.id}_in_list`}
                   text={folder?.title}
                   limit={35}
               />
            </p>

            <div className={styles.coursesOrUsersCountBlock}>

               <div>
                  <img src={book} alt={""}/>
                  <CustomStaticTooltip
                      hint={coursesInsideFolder}
                      text={folder?.coursesCount || 0}
                      id={`${folder?.id}_list_${uuidv4()}`}
                  />
               </div>

            </div>

            <div className={styles.coursesOrUsersCountBlock}>
               <img src={person} alt={""}/>
               <CustomStaticTooltip
                   hint={usersAccessCoursesFolder}
                   text={folder?.readers || 0}
                   id={`${folder?.id})_users_count${uuidv4()}`}
               />
            </div>


            {userRoleId !== ROLE_ID_STUDENT &&
                !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
                userRoleId &&
                !asStudent && (
                    <DotsButton position="relative" dotsRef={dotsRef} onToggle={onToggleSettings} isOpen={isOpen}/>
                )}

            {isOpen && (
                <div ref={dropdownRef} className={styles.dropdown}>
                   {activeFilter === "hidden" && (
                       <p
                           onClick={(e) =>
                               onConfirmOpenChangeHidenStatus(e, folder)
                           }
                       >
                          {changeToVisible}
                       </p>
                   )}

                   {activeFilter === "deleted" && (
                       <>
                          <p onClick={(e) => onConfirmOpenRestore(e, folder)}>
                             {restoreText}
                          </p>
                          <p onClick={(e) => onConfirmOpenDelete(e, folder)}>
                             {deleteText}
                          </p>
                       </>
                   )}

                   {activeFilter !== "deleted" && (
                       <p onClick={(e) => onConfirmDeleteOpen(e, folder)}>
                          {deleteText}
                       </p>
                   )}

                   {activeFilter !== "hidden" && (
                       <p onClick={(e) => onConfirmHideOpen(e, folder)}>{hideText}</p>
                   )}

                   <p onClick={(e) => onRedirectToEditFolderPage(e, folder)}>{editText}</p>
                </div>
            )}
         </div></div>
      </>
   );
};

export default FolderInListItem;
