import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import book from "../../assets/images/courses-fill.svg";
import person from "../../assets/images/profile-fil.svg";
import { ReactComponent as TempFolderIcon } from "../../assets/images/folder_icons/icons_for_icon_picker-20.svg";
import FolderApi from "../../utils/api/FolderApi";
import {
   FOLDER_STATUS_DELETED,
   FOLDER_STATUS_HIDEN,
} from "../../constants/courses";
import { FOLDER_ICONS_LIST } from "../../constants/folders";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { useSelector } from "react-redux";
import {
   PART_OF_ADMIN_NAME,
   PART_OF_MENTOR_NAME,
   ROLE_ID_STUDENT,
   ROLE_ID_SUPER_ADMIN,
} from "../../constants/user";
import cn from "classnames";
import CustomStaticTooltip from "../CustomStaticTooltip/CustomStaticTooltip";
import { v4 as uuidv4 } from "uuid";
import useUserRole from "../../hooks/useUserRole";

const FolderCoverBlock = ({ folder, isTopic}) => {
   const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const returnChoosedIcon = () => {
      const matchingIcon = FOLDER_ICONS_LIST.find(
         (item) => item?.name === folder?.icon
      );

      return matchingIcon ? (
         <matchingIcon.Icon fill={folder?.hexColor} />
      ) : (
         <TempFolderIcon fill={folder?.hexColor || "#fff"} />
      );
   };


   return (
      <>
         <div
            className={cn(
               styles.folderBlock,
               isTopic ? styles.topic : '',
               folder?.status === FOLDER_STATUS_DELETED ||
                  folder?.status === FOLDER_STATUS_HIDEN
                  ? styles.hidden_layout
                  : ""
            )}
            style={{ background: !isTopic ? `${folder?.folder}` : "#9A60DE" }}
         >
            {
               !isTopic && <div className={styles.folder_icon}>{returnChoosedIcon()}</div>
            } 

            <p className={styles.title}>
               {folder?.title && folder.title.length > 21
                  ? `${folder.title.slice(0, 21)}...`
                  : folder?.title}

               {folder?.title && folder.title.length > 21 && (
                  <span className={styles.hiddenTitle}>{folder.title}</span>
               )}
            </p>

            <div className={styles.bottomBlock}>
               <div>
                  <img src={book} alt={""} />
                  <span>{folder?.coursesCount || 0}</span>
               </div>

               <div>
                  <img src={person} alt={""} />
                  <span>{folder?.readers || 0}</span>
               </div>
            </div>
         </div>
      </>
   );
};

export default FolderCoverBlock;
