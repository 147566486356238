import React, { useRef, useState } from "react";
import styles from "./SelectLanguage.module.scss";
import cn from "classnames";
import ListLoader from "../ListLoader/ListLoader";
import { ALL_LANGUAGES_LIST } from "../../constants/languages";
import { useSelector } from "react-redux";
import useOutsideClick from "../../hooks/dom/useOutsideClick";

export const triggerToOpen = {
  hover: "hover",
  click: "click",
};

const SelectLanguage = ({
  chosenLanguage,
  onChangeLanguage,
  langList,
  trigger = triggerToOpen.click,
}) => {
  const [isLangsListOpen, setIsLangsListOpen] = useState(false);

  const availableLanguages = useSelector(
    (state) => state.sidebar.availableLanguages,
  );
  const languagesLoading = useSelector((state) => state.sidebar.loading);
  const dropdownRef = useRef();
  const closeOptions = () => setIsLangsListOpen(false);

  useOutsideClick(dropdownRef, closeOptions);

  const listToMap = langList || availableLanguages;

  return (
    <div
      ref={dropdownRef}
      className={styles.language}
      onMouseEnter={() =>
        trigger === triggerToOpen.hover && setIsLangsListOpen(true)
      }
      onMouseLeave={() =>
        trigger === triggerToOpen.hover && setIsLangsListOpen(false)
      }
    >
      <button
        className={cn(styles.button_language, styles.current, {
          [styles.opened]: isLangsListOpen,
        })}
        type="button"
        onClick={() =>
          trigger === triggerToOpen.click && setIsLangsListOpen((prev) => !prev)
        }
      >
        <>
          {!langList && languagesLoading && isLangsListOpen && (
            <div className={styles.loader}>
              <ListLoader size={"small"} />
            </div>
          )}
          {chosenLanguage}
        </>
      </button>
      {isLangsListOpen && (
        <div className={styles.language_wrapper}>
          <ul
            className={cn(
              styles.language_list,
              availableLanguages?.length > 3 ? styles.scroll : "",
            )}
          >
            {listToMap?.map((lang) => (
              <li key={lang} className={cn(styles.language_list_item)}>
                <button
                  onClick={() => onChangeLanguage(lang)}
                  className={styles.button_language}
                  type="button"
                >
                  {ALL_LANGUAGES_LIST?.find(
                    (l) => l?.code === lang || l?.displayed_code === lang,
                  )?.displayed_code || lang}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}{" "}
    </div>
  );
};

export default SelectLanguage;
