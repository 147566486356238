import { combineReducers } from 'redux';

import user from "./user";
import sidebar from "./sidebar";
import notifications from "./notifications";

import {sortObjByKeys} from "../../utils";

export default combineReducers(sortObjByKeys({
	user,
   sidebar,
   notifications
}))
