import { useSelector } from "react-redux";

const useFindTranslationsByString = () => {
   const translations = useSelector((state) => state.sidebar.translations);

   const returnTranslation = (text) => {
      const normalizedText = String(text).toLowerCase();
    
      const item = translations?.find((item) =>
        item?.originalValue?.toLowerCase() === normalizedText
      );
    
      return item?.translatedValue ? item.translatedValue : text;
    };
      
   return returnTranslation;
};

export default useFindTranslationsByString;
