import styles from './styles.module.scss';

const DraggingArrows = ({ isDragging, hideByDefault }) => {

   return (
      <div className={`${styles.header_arrows} ${isDragging ? styles.dragging : ''} ${!isDragging &&hideByDefault ? styles.hide : ''}`}>
         <div className={styles.header_arrow_up} />
         <div className={styles.header_arrow_down} />
      </div>
   );
};

export default DraggingArrows;
