
import tests from "../../../../assets/images/icon_task1.svg";
import correctTest from "../../../../assets/images/correct_green.svg";
import wrongTest from "../../../../assets/images/close.svg";
import halfCorrectTest from "../../../../assets/images/partially_correct_icon.svg";

import styles from "./styles.module.scss";
import cn from 'classnames';
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED } from "../../../../constants/tests";
import { QUESTIONS_THAT_CAN_BE_AUTOCHECKED_AND_NOT_CHOOSEN } from "../../../../utils/questionsHelper";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const RightPanel = ({ testQuestionsList, isTheory, setIsTheory, activeIndex, handleClick}) => {

   const t = useFindTranslationsByString();

   const defineImg = (question) => {
      if(question?.status === TEST_STATUS_REJECTED) return wrongTest
      if(question?.status === TEST_STATUS_APPROVED) return correctTest
      if(question?.status === TEST_STATUS_ON_REWORK) return halfCorrectTest
      if(question?.status === 0 && QUESTIONS_THAT_CAN_BE_AUTOCHECKED_AND_NOT_CHOOSEN.includes(question?.type)) return wrongTest

      return null;
   }
   return (
      <div className={styles.right_panel}>
        <div className={styles.switch}>
         <CustomSwitch 
               leftText={t('On check')} 
               rightText={t('Theory')}
               isRight={isTheory} 
               setSwitch={setIsTheory}
            />
        </div>

         <div className={styles.structure}>
            <h2>{t("Structure")}</h2>
            <img src={tests} alt="" />
            <span>{testQuestionsList.length}</span>
         </div>

         <div className={styles.module_name}>{t("Module name")}</div>

         <div className={styles.tests_list}>
            {testQuestionsList.map((question, index) => (
               <button
                  key={question?.id}
                  className={`${styles.tests_list_item} ${
                     activeIndex === index ? styles.active_test : ""
                  }`}
                  onClick={() => handleClick(index)}
               >
                  <div className={styles.tests_list_left}>
                     <img
                        className={styles.left_img}
                        src={question.image}
                        alt=""
                     />
                      <span className={styles.q_index}>{index + 1}.</span>
                     <span>{t(question.name)}</span>
                  </div>
                     <div>
                        <img className={cn(!defineImg(question) ? styles.empty_res : '')} src={defineImg(question)} alt="" />
                     </div>
               </button>
            ))}
         </div>
      </div>
   );
};

export default RightPanel;
