import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/path";
import pdfIcon from "../../../assets/images/pdf_icon.svg";
import pngIcon from "../../../assets/images/png_icon.svg";
import downloadIcon from "../../../assets/images/upload.svg";
import avatar from "../../../assets/images/avatar.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import cross from "../../../assets/images/symbols/cross_grey.svg";

import Loader from "../../Loader/Loader";
import { useEffect, useRef, useState } from "react";
import CertificateTemplate from "../../CertificateTemplate/CertificateTemplate";
import CertificateModalLayout from "../../../layouts/ModalLayout/CertificateModalLayout";
import moment from "moment";
import { returnTemporaryLink } from "../../../utils/usersHelper";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";

import cn from "classnames";

const CertificateModal = ({ onClose, onConfirm, isOpen, certificate }) => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [avatarLink, setAvatarLink] = useState("");

   const dropdownRef = useRef();
   const openDropRef = useRef();

   const [isOpenDrop, setIsOpenDrop] = useState(false);
   const onCloseDrop = () => setIsOpenDrop(false);
   const openMobDropdown = () => {
      setIsOpenDrop(true);
   };

   useOutsideClick(dropdownRef, onCloseDrop, openDropRef);

   const t = useFindTranslationsByString();

   const certificateRef = useRef(null);

   const downloadPdf = () => {
      setLoading(true);
      const pdf = new jsPDF({
         format: [
            certificateRef.current.offsetWidth,
            certificateRef.current.offsetHeight,
         ],
      });

      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;

      html2canvas(certificateRef.current).then((canvas) => {
         const imgData = canvas.toDataURL("image/png");

         pdf.addImage(imgData, "JPEG", 0, 0, pageWidth, pageHeight / 2);
         pdf.save("download.pdf");
         setLoading(false);
      });
   };

   const downloadPng = () => {
      setLoading(true);
      html2canvas(certificateRef.current, {
         width: certificateRef.current.offsetWidth,
      }).then((canvas) => {
         const imgData = canvas.toDataURL("image/png");
         const downloadLink = document.createElement("a");
         downloadLink.href = imgData;
         downloadLink.download = "download.png";
         downloadLink.click();
         setLoading(false);
      });
   };

   const getUserAvatar = async () => {
      const linkArray = await returnTemporaryLink(certificate?.user);

      if (linkArray?.[0]?.fileUrl) {
         setAvatarLink(linkArray?.[0]?.fileUrl);
      }
   };

   useEffect(() => {
      if (certificate?.user) {
         getUserAvatar();
      }
   }, [certificate]);

   return (
      <CertificateModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={"462px"}
         maxWidth={"660px"}
         withCross
         customPadding={"0px"}
      >
         <div className={styles.wrapper}>
            <div className={styles.header}>
               <div className={styles.avatar}>
                  <img src={avatarLink || avatar} alt="" />
                  <div className={styles.user_info}>
                     <span className={styles.user_name}>
                        {certificate?.user_name}
                     </span>
                     <span className={styles.date}>
                        {moment(certificate?.dateCompleted).format("DD.MM.YY")}
                     </span>
                  </div>
               </div>

               <div className={styles.download_as} ref={openDropRef}>
                  <Button
                     title={t("Download as")}
                     image={downloadIcon}
                     onClick={openMobDropdown}
                     isBlack
                  />
               </div>

               <div
                  ref={dropdownRef}
                  className={cn(
                     styles.btns,
                     isOpenDrop ? styles.show_mob_btns : ""
                  )}
               >
                  <p className={styles.mob_hide}>{t("Download as")}</p>

                  <div onClick={onCloseDrop} className={styles.close_btns_mob}>
                     <img src={cross} alt="" />
                  </div>

                  <Button
                     title={t("Download as PDF")}
                     image={pdfIcon}
                     onClick={downloadPdf}
                     isBlack
                     disabled={loading}
                  />
                  <Button
                     title={t("Download as PNG")}
                     image={pngIcon}
                     onClick={downloadPng}
                     isBlack
                     disabled={loading}
                  />

                  {!isOpenDrop &&  <div onClick={onClose} className={styles.close_btn}>
                     <img src={cross} alt="" />
                  </div> }

                 
               </div>

               <div onClick={onClose} className={styles.close_btn_mob}>
                  <img src={cross} alt="" />
               </div>
               
            </div>
            <div className={styles.certificateWrapper}>
               <CertificateTemplate
                  certificateRef={certificateRef}
                  size="15"
                  data={certificate}
               />
            </div>

            <div className={styles.certificateWrapperMob}>
               <CertificateTemplate size="15" data={certificate} />
            </div>

            {loading && (
               <div className="default_loader_wrapper">
                  <Loader />
               </div>
            )}
         </div>
      </CertificateModalLayout>
   );
};

export default CertificateModal;
