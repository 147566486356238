import styles from "./styles.module.scss";
import cn from "classnames";

const SequenceAnswers = ({ answer, answerIdx, correctImg, iconWrong }) => {
   return (
      <div
         className={cn(
            styles.answer_item,
            answer.status === 1 ? styles.correct_answer : styles.wrong_answer
         )}
         key={answer.content}
      >
         <span>{answerIdx + 1}</span>
         <p>{answer.content}</p>
         <img src={answer.status === 1 ? correctImg : iconWrong} alt="" />
      </div>
   );
};

export default SequenceAnswers;
