import Switch from "../../../components/Switch/Switch";
import styles from "./styles.module.scss";

const SwitchItem = ({ value, changeValue, title, text }) => {
   return (
      <div className={styles.test_lesson_switch}>
         <Switch title={title} onChange={changeValue} checked={value} />
         <p className={styles.gray_text}>{text}</p>
      </div>
   );
};

export default SwitchItem;
