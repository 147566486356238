import React, { useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import ProgressLine from "../../../components/ProgressLine/ProgressLine";
import FilePlaceholderImg from "../../../components/FilePlaceholderImg/FilePlaceholderImg";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Dropzone from "react-dropzone-uploader";
import { MAX_VIDEO_SIZE_GB } from "../../../constants/courses";
import { formatFileSize } from "../../../utils/coursesHelper";
import upload_icon from "../../../assets/images/upload.svg";
import drop_here from "../../../assets/images/drop_here.png";
import done_icon from "../../../assets/images/file_icons/done.png";
import error_icon from "../../../assets/images/file_icons/error.png";
import cross from "../../../assets/images/symbols/cross_grey.svg";

import styles from "./styles.module.scss";
import cn from "classnames";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const UploadEditorFilesModal = ({
   isOpen,
   onClose,
   filesList,
   handleChangeStatus,
   maxWidth,
   maxHeight,
   stopUploadingFile,
}) => {
   const [selectedFile, setSelectedFile] = useState(null);

   const t = useFindTranslationsByString();

   const closeModal = () => {
      onClose();
   };

   const getFileObjectURL = (file) => {
      try {
        // Check if browser supports object URLs and file type is valid
        if (window.URL && window.URL.createObjectURL && file instanceof File) {
          return window.URL.createObjectURL(file);
        }
      } catch (error) {
        console.error("Error creating object URL:", error);
      }
      return "";
    };

    const handleFileSelection = (file) => {
      setSelectedFile(file === selectedFile ? null : file);
    };

   return (
      <ModalLayout
         isOpen={isOpen}
         onClose={closeModal}
         maxHeight={maxHeight}
         maxWidth={maxWidth}
         withCross
      >
         <div className={styles.top}>
            <div className={styles.top_first}>
               <img src={upload_icon} alt="" />
               <span>{t("Upload file")}</span>
            </div>
            <span className={cn(styles.gray, styles.top_second)}>
               {t("Up to")} {MAX_VIDEO_SIZE_GB}Gb
            </span>
         </div>

         <div 
         style={{ '--upload-file': `"${t('Upload file')}"` }}
         className={cn(styles.upload_wrapper, "dragged_dropzone")}>
            <div className={styles.upload_text}>
               <img className={"noselect"} src={drop_here} alt="" />
               <p className={styles.gray}>{t("Drop file here to upload or")}</p>
            </div>

            <Dropzone
               onChangeStatus={handleChangeStatus}
               multiple={true}
               canCancel={true}
               dragFiles={true}
               inputContent=""
            />
         </div>

         {!!filesList?.length && (
            <p className={styles.gray}>
               {t("You can close the modal and reopen it while you are editing the lesson. Uploading files will not be stopped or paused.")}
            </p>
         )}

         {!!filesList?.length && (
            <div className={styles.list}>
               {filesList?.map((item, idx) => (
                  <div onClick={()=> handleFileSelection(item?.title)} key={idx} className={cn(styles.list_item)}>
                     <div className={styles.file_placeholder_wrapper}>
                        <FilePlaceholderImg file={item}/>
                     </div>
                     <div className={styles.files_display}>
                        <div className={styles.filename}>
                           <CustomTooltip
                              id={`${item?.title}name`}
                              text={item?.title || '-'}
                              limit={30}
                              maxWidth={"300px"}
                              delay={500}
                           />
                        </div>

                        {item?.ext?.startsWith("video/") && selectedFile === item.title ? (
                           <video 
                              onClick={(e)=> e.stopPropagation()}
                              controls>
                              <source
                                 src={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)}
                                 type={item.ext}
                              />
                           </video>
                        ) : item?.ext?.startsWith("audio/")  && selectedFile === item.title ? (
                           <audio  
                              onClick={(e)=> e.stopPropagation()}
                              controls>
                              <source
                                 src={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)}
                                 type={item.ext}
                              />
                           </audio>
                        ) : item?.ext?.startsWith("image/") && selectedFile === item.title ? (
                           <img
                              src={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)}
                              alt={`File ${idx}`}
                           />
                        ) : selectedFile === item.title ? (
                           <a onClick={(e)=> e.stopPropagation()} href={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)} target={'_blank'}>{item?.title || '-'}</a>
                        ) : (
                           ""
                        )}
                     </div>
                     {item?.progress !== 100 && !item?.errorMsg && (
                        <ProgressLine
                           dashIsNotNeeded
                           progress={item?.progress}
                        />
                     )}

                     {item?.fileSize && (
                        <div className={styles.file_size}>
                           {formatFileSize(item?.fileSize)}
                        </div>
                     )}

                     {item?.progress === 100 && (
                        <div className={cn(styles.done, 'noselect')}>
                           <img src={done_icon} alt="" />
                           <div>{t("Download done")}</div>
                        </div>
                     )}

                     {item?.errorMsg && (
                        <div className={cn(styles.done, 'noselect')}>
                           <img src={error_icon} alt="" />
                           <div>{t(item?.errorMsg)}</div>
                        </div>
                     )}

                     <img
                        className={cn(styles.cross, 'noselect')}
                        src={cross}
                        onClick={() => stopUploadingFile(item)}
                     ></img>
                  </div>
               ))}
            </div>
         )}
      </ModalLayout>
   );
};

export default UploadEditorFilesModal;
