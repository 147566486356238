import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import ReactPlayer from "react-player";
import styles from "./styles.module.scss";

const VideoPlayerModal = ({ onClose, onConfirm, isOpen, url }) => {
   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={"427px"}
         maxWidth={"716px"}
      >
         <div className={styles.video_modal}>
            <ReactPlayer
               url={url}
               volume={0.4}
               controls
               width="100%"
               height="100%"
            />
         </div>
      </ModalLayout>
   );
};

export default VideoPlayerModal;
