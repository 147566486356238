import styles from "./index.module.scss";

const Loader = ({ size, defaultPosition }) => {
   return (
      <div
         style={{
            width: size === "small" && "2rem",
            height: size === "small" && "2rem",
         }}
         className={`${styles.loader} ${defaultPosition ? styles.default_position_fixed : ''}`}
      ></div>
   );
};

export default Loader;
