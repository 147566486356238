import React from 'react';
import styles from './styles.module.scss'
import Input from "../../../components/Input/Input";
import TextArea from "../../../components/TextArea/TextArea";
import img from '../../../assets/images/endButton.svg'
import Switch from "../../../components/Switch/Switch";
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const EndOfCourseTab = ({data, setData}) => {

   const t = useFindTranslationsByString();

    return (
        <div className={styles.endOfCourseTab}>
            <div className={styles.block}>
                <Input
                    title={t('Title')}
                    placeholder={`${t("Enter a title")}...`}
                    max={40}
                    withHeader
                    value={data?.new_popup_title}
                    onChangeValue={(event) => setData({...data, new_popup_title: event?.target?.value})}
                />
            </div>

            <div className={styles.block}>
                <TextArea
                    title={t('Pop-up text')}
                    placeholder={`${t("Enter a Description")}...`}
                    max={200}
                    withHeader
                    value={data?.new_popup_text}
                    onChangeValue={(event) => setData({...data, new_popup_text: event?.target?.value})}
                />
            </div>

            {/* <div className={styles.endButton}>
               FOR FUTURE 
                <img src={img} alt={''}/>
                <div>
                    <div className={styles.endButton_switch}>
                        End button
                        <Switch
                            checked={data?.new_is_popup_end_button}
                            onChange={() => setData({
                                ...data,
                                new_is_popup_end_button: +(!data?.new_is_popup_end_button)
                            })}
                        />
                    </div>
                    <p className={styles.endButton_text}>
                        Give students access to export lessons so they can learn offline. An export button will appear
                        in each lesson.
                    </p>
                </div>
            </div>

            <div className={styles.block}>
                <Input
                    title={'Text on button'}
                    placeholder={'Enter a text...'}
                    withHeader
                    value={data?.new_popup_text_on_button}
                    onChangeValue={(event) => setData({...data, new_popup_text_on_button: event?.target?.value})}
                />
            </div>

            <div className={styles.block}>
                <Input
                    title={'Link'}
                    placeholder={'Enter a link...'}
                    withHeader
                    value={data?.new_popup_link}
                    onChangeValue={(event) => setData({...data, new_popup_link: event?.target?.value})}
                />
            </div> */}

        </div>
    );
};

export default EndOfCourseTab;