import React, { useState } from "react";

import styles from "./styles.module.scss";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { ROLE_ID_STUDENT } from "../../../constants/user";
import cn from "classnames";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import CourseBlockDropdown from "../../../components/CourseBlockDropdown/CourseBlockDropdown";
import { useSelector } from "react-redux";

const UsersTestsTable = ({
  columns,
  data,
  userRoleId,
  isTestsLoading,
  isTeam,
  lastTestRef,
  t,
  onSortData,
  sortDirection,
  sortColumn,
}) => {
  const isUserAsStudent = useSelector((state) => state.sidebar.isUserAsStudent);

  const [isHoverCourse, setIsHoverCourse] = useState(false);
  const [activeRow, setActiveRow] = useState({});

  const onRowClick = (testId) => {
    const basePath = "/knowledge_check";
    let path = `${basePath}/check/${testId}`;

    if ((userRoleId && userRoleId === ROLE_ID_STUDENT) || isUserAsStudent) {
      path = `${basePath}/info/${testId}`;
    }

    window.open(path, "_blank", "noopener,noreferrer");
  };

  const onMouseOverCourse = (data) => {
    setIsHoverCourse(true);
    setActiveRow({
      id: data?.lesson?.module?.course?.id,
      rowId: data.id,
      image: data?.lesson?.module?.course?.imageUrl || "",
      title: data?.course_name,
      description: data?.lesson?.module?.course?.description,
    });
  };

  const onMouseLeave = () => {
    setIsHoverCourse(false);
    setActiveRow({});
  };

  if (!isTestsLoading && !data.length) {
    return <EmptyBlock />;
  }

  return (
    <div
      className={cn(
        styles.table_wrapper,
        isTeam ? styles.table_wrapperBigger : "",
      )}
    >
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            {columns.map((column) => (
              <th
                key={column}
                onClick={() => onSortData(column)}
                className={styles.header}
              >
                <span>{t?.returnTranslation(column.replace("_", " "))}</span>
                <div className={styles.arrow_wrapper}>
                  <span
                    className={cn(
                      styles.arrow_top,
                      styles.arrow,
                      sortDirection === "asc" &&
                        sortColumn === column &&
                        styles.active,
                    )}
                  ></span>
                  <span
                    className={cn(
                      styles.arrow_bottom,
                      styles.arrow,
                      sortDirection === "desc" &&
                        sortColumn === column &&
                        styles.active,
                    )}
                  ></span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
            {data?.map((row, index) => (
                  <tr
                    ref={index === data?.length - 1 ? lastTestRef : null}
                    onClick={() => onRowClick(row.id)}
                    key={`row-${row.id}`}
                    className={index % 2 === 0 ? styles.rowOdd : ""}
                  >
                    {columns.map((column, columnIndex) => (
                      <td
                        onMouseLeave={onMouseLeave}
                        onMouseOver={() => onMouseOverCourse(row, columnIndex)}
                        key={`cell-${index}-${columnIndex}`}
                      >
                        {isHoverCourse &&
                          row?.id === activeRow?.rowId &&
                          column === "course_name" && (
                            <div className={styles.dropdown_wrapper}>
                              <div className={styles.course_dropdown}>
                                <CourseBlockDropdown course={activeRow} />
                              </div>
                            </div>
                          )}
                        <div className={styles.cellContent}>
                          <CustomTooltip
                            id={row[column]}
                            limit={30}
                            text={row[column]}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
            ))}
        </tbody>
      </table>

      {data?.length === 0 && !isTestsLoading && <EmptyBlock />}
    </div>
  );
};

export default UsersTestsTable;
