import React, { useEffect, useState } from "react";
import TextArea from "../../../components/TextArea/TextArea";
import Input from "../../../components/Input/Input";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { FEMALE, GENDERS, MALE, OTHER_GENDER, ROLE_ID_STUDENT, USER_STATUS_ACTIVE, USER_STATUS_BANNED } from "../../../constants/user";
import LogoBlock from "../LogoBlock/LogoBlock";
import { TIME_ZONES } from "../../../constants/agencies";
import Button from "../../../components/Button/Button";
import blockIcon from "../../../assets/images/Lock.svg";
import ublockIcon from "../../../assets/images/icon_unlock.svg";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import arrowDown from "../../../assets/images/symbols/arrow.svg";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import { getArrayOfNumbers, getArrayOfNumbersYear } from "../../../utils";
import moment from "moment";
import DropdownsDate from "../../../components/DropdownsDate/DropdownsDate";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import UserApi from "../../../utils/api/UserApi";
import AvatarUploadModal from "../UploadModals/AvatarUploadModal";
import AvatarEditModal from "../UploadModals/AvatarEditModal";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { setInfo } from "../../../store/actions/user";
import styles from "./styles.module.scss";
import StyledPhoneInput from "../../../components/StyledPhoneInput/StyledPhoneInput";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import { localStorageGet, localStorageSet } from "../../../utils/localStorage";
import { useSelector } from "react-redux";
import { setUserAsAdmin } from "../../../store/actions/sidebar";
import { transformRoleText } from "../../../utils/rolesHelper";
import useUserRole from "../../../hooks/useUserRole";
import { returnTemporaryLink } from "../../../utils/usersHelper";
import { isMobileDevice } from "../../../utils/usersHelper";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import cn from 'classnames';

const BasicInfo = ({
   setBasicData,
   basicData,
   setAvatar,
   avatar,
   user
}) => {
   const [renderedIconFile, setRenderedIconFile] = useState(null);
   const [isChangeIconLoading, setIsChangeIconLoading] = useState(false);
   const isUserAsStudent = useSelector(state => state.sidebar.isUserAsStudent);
   const confirmBlockUser = useHandleModal();
   const confirmModalUnblock = useHandleModal();

   const dispatch = useDispatch();

   const t = useFindTranslationsByString();

   const companyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId, userRoleName, userRoleCompanyName } = useUserRole(companyId);

   const uploadAvatarModal = useHandleModal();
   const editAvatarModal = useHandleModal();

   const fetchUserData = async() => {
      const res = await new UserApi().getYourUserData();
      
      if(res?.success?.data) {
         const restrictedAvatarLink = await returnTemporaryLink(res?.success?.data);

         dispatch(setInfo({
            ...res.success.data,
            avatar: restrictedAvatarLink?.[0]?.fileUrl || null
         }));
      }
  }

   const handleBasicDataChange = (fieldName, value) => {
      setBasicData((prevData) => ({
         ...prevData,
         [fieldName]: value,
      }));
   };

   const updateUserStatus = async (newStatus) => {
      const res = await new UserApi().updateUser(user?.id, {
         new_status: newStatus,
      });
   
      if (res?.success && refetchUser) refetchUser();
      if (res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError />);
      if (res?.success?.message) toast(<ToastMsg text={res?.success?.message} />);
   };

   const onDeleteFromPlatform = async () => {
      await updateUserStatus(USER_STATUS_BANNED);
      confirmBlockUser.close();
   };

   const onRestoreUser = async () => {
      await updateUserStatus(USER_STATUS_ACTIVE);
      confirmModalUnblock.close();
   };

   const saveAvatar = async () => {
      setIsChangeIconLoading(true);
      const formData = new FormData();
      formData.append("new_avatar", avatar);
      const res = await new UserApi().changeUserAvatar(formData);

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         fetchUserData();
        uploadAvatarModal.close();
        editAvatarModal.open();
      }

      setIsChangeIconLoading(false);
      if(res?.error?.message) {
        return toast(<ToastMsg text={res?.error?.message} isError />)
      }
   }

   useEffect(()=> {
      if(user?.avatar) setRenderedIconFile(user?.avatar);
   }, [user])

   return (
      <div className={styles.settings_box}>
         {/* {userRoleId && userRoleId !== ROLE_ID_STUDENT && 
            <div className={styles.switch}>
              <CustomSwitch 
                 leftText={transformRoleText(userRoleName, userRoleCompanyName)} 
                 rightText={'Student'}
                 isRight={isUserAsStudent ? true : false} 
                 setSwitch={(value) => {
                    dispatch(setUserAsAdmin(value));
                 }}
              />
            </div>
         } */}
         <div className={styles.setting_box_name}>{t("Basic info")}</div>

       <div className={styles.logo_block_wrapper}>
         <LogoBlock 
               setRenderefIcon={setRenderedIconFile} 
               renderedIconFile={renderedIconFile} 
               avatar={avatar}
               setAvatar={setAvatar}
               uploadAvatarModal={uploadAvatarModal} 
            />
       </div>
       
         <div className={styles.setting_form}>
       
            <div className={styles.block}>
               <div className={styles.setting_form_row}>
                  <div className={styles.subtitle}>{t("Personal Information")}</div>
                  <div className={styles.mob_header}>{t("Name")}</div>
                  <Input
                     placeholder={t("Enter your name")}
                     value={basicData?.first_name}
                     onChangeValue={(e) =>
                        handleBasicDataChange("first_name", e.target.value)
                     }
                  />
                       <div className={styles.mob_header}>{t("Last name")}</div>
                  <Input
                     placeholder={t("Enter your last name")}
                     value={basicData?.last_name}
                     onChangeValue={(e) =>
                        handleBasicDataChange("last_name", e.target.value)
                     }
                  />
                  {/* FOR FUTURE <Dropdown
                     placeholder={"Select your gender * Optionally"}
                     data={GENDERS}
                     field={"name"}
                     value={
                        basicData?.gender === MALE
                           ? "Male"
                           : basicData?.gender === FEMALE
                           ? "Female"
                           : basicData?.gender === OTHER_GENDER
                           ? "Not specified"
                           : ""
                     }
                     onChange={(value) => {
                        handleBasicDataChange("gender", value.type);
                     }}
                  /> */}
               </div>

               <DropdownsDate data={basicData} setData={setBasicData} />
            </div>

            <div className={styles.block}>
               <div className={styles.setting_form_row}>
                  <div className={styles.subtitle}>{t("Contact information")}</div>
                  <div className={styles.mob_header}>{t("Email")}</div>
                  <Input
                     placeholder={t("Enter your email")}
                     value={basicData?.email}
                     onChangeValue={(e) =>
                        handleBasicDataChange("email", e.target.value)
                     }
                  />
                  {/* 
                   FOR FUTURE
                  <StyledPhoneInput 
                     value={basicData?.phone}
                     placeholder={"(+380) * Optionally"}
                     onChange={(value)=> handleBasicDataChange('phone', value)}
                  /> */}
               </div>
            </div>
            <div className={styles.block}>
               <div className={styles.setting_form_row}>
               <div className={styles.mob_header}>{t("City")}</div>
                  <div className={styles.subtitle}>{t("Location information")}</div>
                  <Input
                     placeholder={t("Enter your city")}
                     value={basicData?.city}
                     onChangeValue={(e) =>
                        handleBasicDataChange("city", e.target.value)
                     }
                  />
                     {/* <div className={styles.mob_header}>{t("Time zone")}</div>
                  <Dropdown
                     placeholder={t("Select a timezone")}
                     data={TIME_ZONES}
                     value={basicData?.selected_time_zone}
                     onChange={(value) => {
                        handleBasicDataChange("selected_time_zone", value);
                     }}
                     noTranslate
                     openTop={isMobileDevice()}
                  /> */}
               </div>
            </div>

            {/* <div className={styles.block_user_wrapper}>
               FOR FUTURE
               <Button
                  onClick={confirmBlockUser.open}
                  isRed
                  image={blockIcon}
                  title={"Block account"}
               />
            </div> */}
         </div>

         <AvatarUploadModal 
            setAvatar={setAvatar}
            avatar={avatar}
            setRenderedIconFile={setRenderedIconFile}
            isActive={uploadAvatarModal.isActive} 
            onClose={uploadAvatarModal.close} 
            onSaveIcon={saveAvatar}
            isLoading={isChangeIconLoading}
            desc={`${t("Recommended")}:  500 KB & 400x400px (PNG ${t('or')} JPG)`}
            title={t("Upload new image")} 
         />

         <AvatarEditModal 
            setAvatar={setAvatar}
            avatar={avatar}
            renderedIconFile={renderedIconFile}
            isActive={editAvatarModal.isActive} 
            onClose={editAvatarModal.close}
            desc={`${t("Recommended")}:  500 KB & 400x400px (PNG ${t('or')} JPG)`}
            title={t("Edit avatar")}
            setRenderedIconFile={setRenderedIconFile}
         />

         <ConfirmModal
            isRemove
            confirmButtonText={t("Block account")}
            onConfirm={onDeleteFromPlatform}
            onClose={confirmBlockUser.close}
            isOpen={confirmBlockUser.isActive}
            title={t("Are you sure?")}
         />
          <ConfirmModal
            confirmButtonText={t("Unblock user")}
            onConfirm={onRestoreUser}
            onClose={confirmModalUnblock.close}
            isOpen={confirmModalUnblock.isActive}
            title={t("Are you sure?")}
         />
      </div>
   );
};

export default BasicInfo;
