import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import saveIcon from "../../../assets/images/icon_save.svg";
import Button from "../../../components/Button/Button";
import { useSelector } from "react-redux";
import cn from 'classnames';
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import { isMobileDevice } from "../../../utils/usersHelper";

const ProfileSubheader = ({ onSave, isButtonDisabled }) => {
   const isSideBarFixed = useSelector((state)=> state.sidebar.isFixed);

   const t = useFindTranslationsByString();

   return (
      <header className={cn(styles.settings_header, isSideBarFixed ? styles.smaller : '')}>
         <div className={styles.page_title_box}>
            <h2 className={styles.setting_title}>{t("Settings")}</h2>
            <p className={styles.setting_description}>
               {t("You can configure your account here")}.
            </p>
         </div>
         <form className={styles.create_agency_form} action="#">
            <Button
               onClick={onSave}
               disabled={isButtonDisabled}
               title={t("Save changes")}
               image={saveIcon}
               isHoverPurple={isMobileDevice()}
               isBlack={isMobileDevice()}
            />
         </form>
      </header>
   );
};

export default ProfileSubheader;
