import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import filtersFilledImg from "../../../assets/images/symbols/filters_filled.svg";

import deleteImg from "../../../assets/images/symbols/delete.svg";
import dateIcon from "../../../assets/images/symbols/calendar.svg";
import languages_icon from "../../../assets/images/settings_languages.svg";
import eyeImg from "../../../assets/images/symbols/eye_crossed.svg";
import plusImg from "../../../assets/images/symbols/plus.svg";
import cross from "../../../assets/images/symbols/cross_grey.svg";
import arrow from "../../../assets/images/symbols/arrow.svg";
import searchImg from "../../../assets/images/symbols/Search.svg";
import completedIcon from "../../../assets/images/completed_icon.svg";
import ongoing from "../../../assets/images/time_gray.svg";
import failedIcon from "../../../assets/images/failed_icon.svg";
import icon_grid from "../../../assets/images/icon_grid.svg";
import icon_grid_filled from "../../../assets/images/icon_grid_filled.svg";
import icon_list from "../../../assets/images/icon_list_simple.svg";
import icon_settings from "../../../assets/images/settings_v2.svg";

import { isMobileDevice } from "../../../utils/usersHelper";

import cn from "classnames";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import useFindTranlsations from "../../../hooks/useFindTranlsations";
import { useSelector } from "react-redux";
import { PART_OF_MENTOR_NAME, ROLE_ID_STUDENT } from "../../../constants/user";
import useMoveScroll from "../../../hooks/useMoveScroll";
import LeftRightErrows from "../../../components/LeftRightArrows/LeftRightArrows";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from "../../../components/CustomStaticTooltip/CustomStaticTooltip";
import useUserRole from "../../../hooks/useUserRole";
import { COURSE_LANGUAGES_LIST } from "../../../constants/languages";
import DropdownWithSearch from "../../../components/DropdownWithSearch/DropdownWithSearch";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const coursesSortFiltersData = ["First new", "Old first"];

const FiltersBlock = ({
  title,
  onClickPlus,
  setShowHidden,
  isHidden,
  activeFilter,
  setActiveFilter,
  setActiveSortFilter,
  activeSortFilter,
  setActiveTagsFilter,
  activeTagsFilter,
  currentTags,
  isFolder,
  isFolders,
  searchWhat = "Search folder",
  setStudentCourseFilter,
  studentCourseFilter,
  isCoursesOnCoursesPage = false,
  isCourses,
  byFolder,
  setByFolder,
  paginatedSearchedValue,
  setPaginatedSearchedValue,
  isFolderOnCoursesPage,
  foldersInListView,
  setFoldersInListView,
  createHint,
  isTopic,
  isPlusDropdown,
  onClickCreateTopic,
  onRedirectSettingsPage,
  combinedFilters,
  selectedLanguages,
  onSelectedLanguagesChange,
  clearExtendedFilters,
  isCoursesFiltersPage
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [showFolderPlusOptions, setShowFolderPlusOptions] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [showTagsOptions, setShowTagsOptions] = useState(false);
  const [studentCourseFinishFilterMobileOpen, setStudentCourseFinishFilterMobileOpen] = useState(false);
  const [tags, setTags] = useState(currentTags || []);

  const isSideBarFixed = useSelector((state) => state.sidebar.isFixed);
  const t = useFindTranlsations();
  const tNew = useFindTranslationsByString();

  const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);

  const {
    blockWrapperRef,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    moveLeft,
    moveRight,
    isDragging,
  } = useMoveScroll();

  const currentCompanyId = useSelector((state) => state.sidebar.companyId);
  const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

  const optionRef = useRef(null);
  const dropDownRef = useRef(null);
  const dropDownRefStudentFilters = useRef(null);
  const studentsOptionRef = useRef(null);
  const dateFilterRef = useRef(null);

  // Options for adding either a course to folder or creating a topic.
  const plusFolderRef = useRef(null);
  const dropDownFolderRef = useRef(null);

  const optionTagRef = useRef(null);
  const dropDownTagRef = useRef(null);
  const openDateRef = useRef(null);

  const closeOptions = () => setShowOptions(false);
  const closeStudentOptions = () => setStudentCourseFinishFilterMobileOpen(false);
  const closeTagsOptions = () => setShowTagsOptions(false);
  const closeFolderPlusOptions = () => setShowFolderPlusOptions(false);
  const closeDateOptions = () => setDateFilterOpen(false);

  useOutsideClick(dropDownRefStudentFilters, closeStudentOptions, studentsOptionRef);


  useOutsideClick(dropDownRef, closeOptions, optionRef);
  useOutsideClick(dropDownTagRef, closeTagsOptions, optionTagRef);
  useOutsideClick(dropDownFolderRef, closeFolderPlusOptions, plusFolderRef);
  useOutsideClick(dateFilterRef, closeDateOptions, openDateRef);

  const toggleOptions = (e) => {
    setShowOptions((prev) => !prev);
  };

  const toggleDateOptions = (e) => {
    setDateFilterOpen((prev) => !prev);
  };

  const toggleFolderPlusOptions = (e) => {
    setShowFolderPlusOptions((prev) => !prev);
  };

  const toggleTagsOptions = (e) => {
    setShowTagsOptions((prev) => !prev);
  };

  const toggleHidden = () => {
    if (!setActiveFilter) return;

    if (activeFilter === "hidden") {
      setActiveFilter("");
    } else {
      setActiveFilter("hidden");
    }
  };

  const toggleCompleted = () => {
    if (studentCourseFilter === "completed") {
      if (setStudentCourseFilter) setStudentCourseFilter("");
    } else {
      if (setStudentCourseFilter) setStudentCourseFilter("completed");
    }
  };

  const toggleFailed = () => {
    if (studentCourseFilter === "failed") {
      if (setStudentCourseFilter) setStudentCourseFilter("");
    } else {
      if (setStudentCourseFilter) setStudentCourseFilter("failed");
    }
  };

  const toggleOngoing = () => {
    if (studentCourseFilter === "ongoing") {
      if (setStudentCourseFilter) setStudentCourseFilter("");
    } else {
      if (setStudentCourseFilter) setStudentCourseFilter("ongoing");
    }
  };

  const toggleDeleted = () => {
    if (!setActiveFilter) return;

    if (activeFilter === "deleted") {
      setActiveFilter("");
    } else {
      setActiveFilter("deleted");
    }
  };

  const handleFilterChange = (filterType) => {
    if (!setActiveSortFilter) return;

    if (activeSortFilter === filterType) {
      setActiveSortFilter("");
    } else {
      setActiveSortFilter(filterType);
    }
    toggleOptions();
  };

  const handleTagsChange = (tag) => {
    if (activeTagsFilter?.find((item) => item === tag))
      setActiveTagsFilter(activeTagsFilter?.filter((item) => item !== tag));
    else setActiveTagsFilter([...activeTagsFilter, tag]);
  };

  const handleFolderTopicsChange = (topic) => {
    if (activeTagsFilter?.find((item) => item?.name === topic?.name))
      setActiveTagsFilter(
        activeTagsFilter?.filter((item) => item?.name !== topic?.name),
      );
    else setActiveTagsFilter([...activeTagsFilter, topic]);
  };

  useEffect(() => {
    if (currentTags?.length) {
      if (searchedValue) {
        const filteredTags = currentTags.filter((tag) =>
          tag
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(searchedValue.toLowerCase().replace(/\s/g, "")),
        );
        setTags(filteredTags);
      } else {
        setTags(currentTags);
      }
    }
  }, [currentTags, searchedValue]);

  const totalCharacterCount = currentTags?.reduce((accumulator, currentTag) => {
    return accumulator + currentTag.length;
  }, 0);

  const totalCharacterCountTopics = currentTags?.reduce(
    (accumulator, currentTag) => {
      return accumulator + currentTag?.name?.length;
    },
    0,
  );

  const isSettingsIconShow =
    title &&
    userRoleId &&
    !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
    userRoleId !== ROLE_ID_STUDENT &&
    !asStudent;

  const isActive = (item) => {
    if (activeTagsFilter && activeTagsFilter.length > 0) {
      if (typeof activeTagsFilter[0] === "string") {
        return activeTagsFilter.includes(item);
      } else if (
        typeof activeTagsFilter[0] === "object" &&
        activeTagsFilter[0].id !== undefined
      ) {
        return activeTagsFilter.some((tag) => tag.id === item.id);
      }
    }
    return false;
  };

  const isExtendedFiltersActive =
    coursesSortFiltersData.includes(activeSortFilter) ||
    selectedLanguages?.length;

    const renderSelectedStudentFilter = (icon, value, onClearHandler) => {
      return (
        <div className={styles.selected_filters_item}>
         <img src={icon} alt="" />
          <span>{value}</span>
          <img
            className={styles.cross}
            onClick={onClearHandler}
            src={cross}
            alt=""
          />
        </div>
      );
    };

  const renderSearchBlock = () => {
    return (
      <div
        className={cn(styles.search, {
          [styles.full_width]: isFolderOnCoursesPage,
        })}
      >
        <CustomStaticTooltip
          id={uuidv4()}
          icon={searchImg}
          hint={
            searchWhat === "Search folder"
              ? t?.searchFolderName
              : t?.searchCourseNameTags
          }
        />
        <input
          placeholder={t?.returnTranslation(searchWhat)}
          value={
            paginatedSearchedValue ? paginatedSearchedValue : searchedValue
          }
          onChange={(e) => {
            if (setPaginatedSearchedValue) {
              setPaginatedSearchedValue(e.target.value);
            } else {
              setSearchedValue(e.target.value);
            }
          }}
        />

        {searchedValue || paginatedSearchedValue &&
         <img className={styles.cross_inp} src={cross} onClick={() => {
            if (setPaginatedSearchedValue) {
              setPaginatedSearchedValue('');
            } else {
              setSearchedValue('');
            }
          }}/>
        }
      </div>
    );
  };

  const isFilterActive =
    coursesSortFiltersData.includes(activeSortFilter) ||
    selectedLanguages?.length;

  const isTopicOrFolder = isTopic || isFolder
  
  return (
    <div
      className={cn(styles.wrapper, isTopicOrFolder && styles.withPaddingRight)}
    >
      {isTopicOrFolder && (
        <div className={cn(styles.title, isSettingsIconShow && styles.flex)}>
          <CustomTooltip id={title} limit={68} text={title} />

          {isSettingsIconShow && (
            <div
              className={styles.visit_settings_page}
              onClick={onRedirectSettingsPage}
            >
              <img src={icon_settings} alt={"Settings"} />
              {tNew("Settings")}
            </div>
          )}
        </div>
      )}
      <div className={cn(styles.filtersBlock, "noselect")}>
        {isCourses && combinedFilters && (
          <div
            className={cn(
              styles.block_extended,
              isFilterActive ? cn(styles.activeFilter, styles.bg_default) : "",
              showOptions ? styles.opened : "",
            )}
          >
            <div
              className={styles.filter_wrapper}
              ref={optionRef}
              onClick={toggleOptions}
            >
              <img
                src={isFilterActive ? filtersFilledImg : filtersImg}
                alt={""}
              />
              {isExtendedFiltersActive ? tNew("Applied") : t?.filter}

              {!!isExtendedFiltersActive && (
                <img
                  onClick={clearExtendedFilters}
                  className={styles.cross}
                  src={cross}
                  alt=""
                />
              )}

              {!isFilterActive && (
                <img
                  className={cn(
                    styles.options_arrow,
                    showOptions ? styles.options_opened : "",
                  )}
                  src={arrow}
                  alt={""}
                />
              )}
            </div>

            {showOptions && (
              <div className={styles.option_date} ref={dropDownRef}>
                <button
                  onClick={(e) => e.stopPropagation()}
                  className={cn(
                    styles.first_filter,
                    styles.course_by_folder_filter,
                  )}
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      setByFolder(false);
                    }}
                    className={cn(
                      styles.filter_item,
                      !byFolder && styles.active_first_filter,
                    )}
                  >
                    {t?.all}
                  </span>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      setByFolder(true);
                    }}
                    className={cn(
                      styles.filter_item,
                      byFolder && styles.active_first_filter,
                    )}
                  >
                    {t?.byFolders}
                  </span>
                </button>

                <DropdownWithSearch
                  leftIcon={languages_icon}
                  simpleVariant={true}
                  data={COURSE_LANGUAGES_LIST}
                  field={"label"}
                  placeholder={
                    selectedLanguages?.length
                      ? selectedLanguages?.map((item) => item.name).join(", ")
                      : t?.languages
                  }
                  value={activeFilter?.label}
                  selectedItems={selectedLanguages}
                  onChange={onSelectedLanguagesChange}
                  onClear={() => onSelectedLanguagesChange("")}
                />

                <DropdownWithSearch
                  leftIcon={dateIcon}
                  simpleVariant={true}
                  data={coursesSortFiltersData}
                  field={"label"}
                  placeholder={
                    coursesSortFiltersData.includes(activeSortFilter)
                      ? t?.returnTranslation(activeSortFilter)
                      : t?.date
                  }
                  value={activeFilter?.label}
                  selectedItems={activeSortFilter}
                  onChange={handleFilterChange}
                  onClear={() => setActiveSortFilter("")}
                  isMulti={false}
                />
              </div>
            )}
          </div>
        )}

        {isFolders && combinedFilters && (
          <div
            className={cn(
              styles.block_extended,
              coursesSortFiltersData.includes(activeSortFilter) ||
                selectedLanguages?.length
                ? cn(styles.activeFilter, styles.bg_default)
                : "",
              showOptions ? styles.opened : "",
            )}
          >
            <div
              className={styles.filter_wrapper}
              ref={optionRef}
              onClick={toggleOptions}
            >
              <img
                src={isFilterActive ? filtersFilledImg : filtersImg}
                alt={""}
              />
              {isExtendedFiltersActive ? tNew("Applied") : t?.filter}

              {!!isExtendedFiltersActive && (
                <img
                  onClick={clearExtendedFilters}
                  className={styles.cross}
                  src={cross}
                  alt=""
                />
              )}

              {!isFilterActive && (
                <img
                  className={cn(
                    styles.options_arrow,
                    showOptions ? styles.options_opened : "",
                  )}
                  src={arrow}
                  alt={""}
                />
              )}
            </div>

            {showOptions && (
              <div className={styles.option_date} ref={dropDownRef}>
                <button
                  className={cn(
                    styles.first_filter,
                    isFolders ? styles.first_filter_folders : "",
                  )}
                >
                  <div
                    onClick={() => setFoldersInListView(false)}
                    className={cn(
                      styles.filter_item,
                      !foldersInListView ? styles.active_first_filter : "",
                    )}
                  >
                    <CustomStaticTooltip
                      id={uuidv4()}
                      icon={!foldersInListView ? icon_grid_filled : icon_grid}
                      hint={t?.showAsGrid}
                    />
                  </div>
                  <div
                    onClick={() => setFoldersInListView(true)}
                    className={cn(
                      styles.filter_item,
                      foldersInListView ? styles.active_first_filter : "",
                    )}
                  >
                    <CustomStaticTooltip
                      id={uuidv4()}
                      icon={icon_list}
                      hint={t?.showAsList}
                    />
                  </div>
                </button>

                <div
                  className={cn(
                    styles.date_front,
                    activeSortFilter && styles.activeFilter,
                  )}
                  ref={dateFilterRef}
                >
                  <div
                    className={cn(
                      styles.date_front_first,
                      dateFilterOpen ? styles.active_date : "",
                    )}
                    ref={openDateRef}
                    onClick={toggleDateOptions}
                  >
                    <img className={styles.date_img} src={dateIcon} alt={""} />
                    {coursesSortFiltersData.includes(activeSortFilter)
                      ? t?.returnTranslation(activeSortFilter)
                      : t?.date}
                    <img
                      className={cn(
                        isFilterActive ? styles.cross : styles.options_arrow,

                        dateFilterOpen ? styles.options_opened : "",
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveSortFilter("");
                      }}
                      src={activeSortFilter ? cross : arrow}
                      alt={""}
                    />
                  </div>

                  {dateFilterOpen && (
                    <div className={styles.date_filter_options}>
                      {coursesSortFiltersData.map((item) => (
                        <span
                          className={
                            activeSortFilter === item
                              ? styles.active_option
                              : ""
                          }
                          key={item}
                          onClick={() => handleFilterChange(item)}
                        >
                          {t?.returnTranslation(item)}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {!combinedFilters && (
          <div
            className={cn(
              styles.block_extended,
              styles.filter_date,
              coursesSortFiltersData.includes(activeSortFilter)
                ? styles.activeFilter
                : "",
              showOptions ? styles.opened : "",
              isTopicOrFolder ? styles.block_extended_folders_or_topics_page : ''
            )}
          >
            <div
              className={styles.filter_wrapper}
              ref={optionRef}
              onClick={toggleOptions}
            >
              <img src={dateIcon} alt={""} />
              {coursesSortFiltersData.includes(activeSortFilter)
                ? t.returnTranslation(activeSortFilter)
                : tNew("Date")}

              {!!isExtendedFiltersActive && (
                <img
                  onClick={() => handleFilterChange("")}
                  className={styles.cross}
                  src={cross}
                  alt=""
                />
              )}

              {!isFilterActive && (
                <img
                  className={cn(
                    styles.options_arrow,
                    showOptions ? styles.options_opened : "",
                  )}
                  src={arrow}
                  alt={""}
                />
              )}
            </div>

            {showOptions && (
              <div className={styles.option_date} ref={dropDownRef}>
                {coursesSortFiltersData.map((item) => (
                  <span
                    className={cn(styles.option)}
                    key={item}
                    onClick={() => handleFilterChange(item)}
                  >
                    {t?.returnTranslation(item)}
                  </span>
                ))}
              </div>
            )}
          </div>
        )}

        {isCoursesOnCoursesPage &&
          (userRoleId === ROLE_ID_STUDENT || asStudent) && (
            <div className={styles.student_btns}>
              <button
                onClick={toggleCompleted}
                className={cn(
                  styles.block_medium,
                  studentCourseFilter === "completed"
                    ? styles.activeFilter
                    : "",
                )}
              >
                <img src={completedIcon} alt={""} />
                {t?.completed}
              </button>
              <button
                onClick={toggleFailed}
                className={cn(
                  styles.block_medium,
                  studentCourseFilter === "failed" ? styles.activeFilter : "",
                )}
              >
                <img src={failedIcon} alt={""} />
                {t?.failed}
              </button>
            </div>
          )}

        {userRoleId &&
          !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
          userRoleId !== ROLE_ID_STUDENT &&
          !asStudent && (
            <>
              <button
                onClick={toggleHidden}
                className={cn(
                  styles.block_medium,
                  activeFilter === "hidden" ? styles.activeFilter : "",
                )}
              >
                <img src={eyeImg} alt={""} />
                {t?.hidden}
              </button>

              <div
                onClick={toggleDeleted}
                className={cn(
                  styles.block_medium,
                  styles.deleted,
                  activeFilter === "deleted" ? styles.activeFilter : "",
                )}
              >
                <img src={deleteImg} alt={""} />
                {t?.deletedText}
              </div>
            </>
          )}

        {userRoleId &&
          !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
          userRoleId !== ROLE_ID_STUDENT &&
          !asStudent && (
            <div
              ref={isPlusDropdown ? plusFolderRef : null}
              className={cn(styles.block_small, styles.plus)}
              onClick={() => {
                if (isPlusDropdown) {
                  toggleFolderPlusOptions();
                } else {
                  onClickPlus();
                }
              }}
            >
              <CustomStaticTooltip
                id={uuidv4()}
                icon={plusImg}
                hint={createHint}
              />

              {showFolderPlusOptions && (
                <div
                  className={cn(styles.folder_options)}
                  ref={dropDownFolderRef}
                >
                  <p onClick={onClickCreateTopic}>{t?.createTopic}</p>
                  <p onClick={onClickPlus}>{t?.addCourses}</p>
                </div>
              )}
            </div>
          )}

        {
          <div className={styles.tags_block__wrapper}>
            {(isFolderOnCoursesPage || isTopicOrFolder) && renderSearchBlock()}
            {!isTopicOrFolder &&
              <div className={styles.tags_block_content}>
                {!isFolderOnCoursesPage && renderSearchBlock()}
                {!isFolderOnCoursesPage && (
                  <div
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    ref={blockWrapperRef}
                    className={cn(
                      styles.tags_wrapper,
                      isSideBarFixed && styles.fixed,
                    )}
                  >
                    <button
                      onClick={() => setActiveTagsFilter([])}
                      className={cn(styles.tag, !activeTagsFilter?.length && styles.active_tag)}
                    >
                      {isFolderOnCoursesPage ? t?.allTopics : t?.allTags}
                    </button>
                    {tags?.map((item) => (
                      <button
                        className={cn(
                          styles.tag,
                          isActive(item) ? styles.active_tag : "",
                        )}
                        key={isFolders ? item?.id : item}
                        onClick={() =>
                          isFolderOnCoursesPage
                            ? handleFolderTopicsChange(item)
                            : handleTagsChange(item)
                        }
                      >
                        {isFolders ? item?.name : item}
                      </button>
                    ))}
                  </div>
                )}

                {!isFolderOnCoursesPage && (
                  <div className={styles.arrows}>
                    {!isFolders && totalCharacterCount > 30 && (
                      <LeftRightErrows
                        moveLeft={moveLeft}
                        moveRight={moveRight}
                      />
                    )}

                    {isFolders && totalCharacterCountTopics > 30 && (
                      <LeftRightErrows
                        moveLeft={moveLeft}
                        moveRight={moveRight}
                      />
                    )}
                  </div>
                )}

               { isCoursesOnCoursesPage &&
                  <div
                      onClick={()=> setStudentCourseFinishFilterMobileOpen(!studentCourseFinishFilterMobileOpen)}
                     className={styles.filter_wrapper_mob}
                     ref={dropDownRefStudentFilters}
                  >
                     <img src={filtersImg} />
                     {isCoursesOnCoursesPage && studentCourseFinishFilterMobileOpen &&
                        (userRoleId === ROLE_ID_STUDENT || asStudent) && (
                           <div ref={studentsOptionRef} className={styles.student_btns_mob}>
                           <button
                              onClick={toggleCompleted}
                              className={cn(
                                 styles.completed_btn,
                                 styles.block_medium,
                                 studentCourseFilter === "completed"
                                 ? styles.activeFilter
                                 : "",
                              )}
                           >
                              <img src={completedIcon} alt={""} />
                              {t?.completed}
                           </button>
                           <button
                              onClick={toggleFailed}
                              className={cn(
                                 styles.block_medium,
                                 studentCourseFilter === "failed" ? styles.activeFilter : "",
                              )}
                           >
                              <img src={failedIcon} alt={""} />
                              {t?.failed}
                           </button>
                           <button
                              onClick={toggleOngoing}
                              className={cn(
                                 styles.block_medium,
                                 studentCourseFilter === "ongoing" ? styles.activeFilter : "",
                              )}
                           >
                              <img src={ongoing} alt={""} />
                              {t?.ongoing}
                           </button>
                           </div>
                        )}
                  </div>
               }
              </div>
            }


            {(studentCourseFilter?.length > 0) && !isCoursesFiltersPage &&
                  <div className={styles.selected_filters}>
                     {studentCourseFilter === 'failed' &&
                       renderSelectedStudentFilter(failedIcon, t.failed, toggleFailed)
                     }
                     {studentCourseFilter === 'completed' &&
                       renderSelectedStudentFilter(completedIcon, t.completed, toggleCompleted)
                     }
                  </div>
               }
          </div>
        }
      </div>
    </div>
  );
};

export default FiltersBlock;
