import React, { useEffect } from "react";
import Modal from "react-modal";
import { customStylesSecond } from "./custumModalStyles";
import cross from "../../assets/images/symbols/cross_grey.svg";
import styles from "./styles.module.scss";
import cn from "classnames";

const CertificateModalLayout = ({
   isOpen,
   onClose,
   children,
   maxWidth,
   maxHeight,
   withCross,
   withScroll = false,
   customPadding = null,
   customBgColor,
   visibleOverflow = false,
   isSertificate = false,
}) => {
   useEffect(() => {
      Modal.setAppElement("body");
   }, []);

   useEffect(() => {
      if (isOpen && !withScroll) {
         document.body.style.overflowY = "hidden";
      } else {
         document.body.style.overflowY = "auto";
      }

      // Cleanup function to remove the style when the component unmounts or modal is closed
      return () => {
         document.body.style.overflowY = "auto";
      };
   }, [isOpen]);

   return (
      <Modal
         isOpen={isOpen}
         onRequestClose={onClose}
         style={{
            ...customStylesSecond,
            content: {
               ...customStylesSecond?.content,
               maxWidth: "100%",
               maxHeight: "100%",
               ...(withScroll ? { overflow: "scroll" } : {}),
               ...(visibleOverflow ? { overflow: "visible" } : {}),
               padding: customPadding ? customPadding : "40px",
               height: "100%",
               zIndex: "-1",
            },
         }}
      >
         <div className={styles.modal} onClick={onClose}>
            {withCross && (
               <div
                  className={cn(
                     styles.cross,
                     customPadding && styles.cross_padding
                  )}
               >
                  <img src={cross} alt={""} onClick={onClose} />
               </div>
            )}
            <div
               style={{
                  maxWidth: "100%",
                  minHeight: "105vh",
                  maxHeight: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               <div
                  style={{
                     width: maxWidth,
                     height: maxHeight,
                  }}
                  onClick={(e) => e.stopPropagation()}
               >
                  {children}
               </div>
            </div>
         </div>
      </Modal>
   );
};

export default CertificateModalLayout;
