import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import searchIcon from "../../../assets/images/symbols/Search.svg";
import notFound from "../../../assets/images/symbols/nothing_found.svg";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import Loader from "../../Loader/Loader";
import cn from "classnames";
import useFindTranlsations from "../../../hooks/useFindTranlsations";

const SelectCourseModal = ({
  isOpen,
  onClose,
  setSelectedCourses,
  selectedCourses,
  coursesList,
  onAddCourse,
  oneStep,
  isDataLoading,
  lastCourseRef,
  searchedValue,
  setSearchedValue,
  selectAll,
  setSelectAll,
  coursesLoading,
  setSearchByName,
}) => {
  const [search, setSearch] = useState("");
  const t = useFindTranlsations();

  const onSelectFilters = (course) => {
    if (selectedCourses?.find((item) => item?.id === course?.id))
      setSelectedCourses(
        selectedCourses?.filter((item) => item?.id !== course?.id),
      );
    else setSelectedCourses([...selectedCourses, course]);
  };

  const closeModal = () => {
    onClose();
    if (oneStep) setSelectedCourses([]);
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) setSelectedCourses([]);
  };

  useEffect(() => {
    if (coursesLoading) return;

    if (selectAll) {
      setSelectedCourses(coursesList);
    } else {
      setSelectedCourses(selectedCourses);
    }
  }, [selectAll, coursesLoading]);

  useEffect(() => {
    return () => {
      setSearch("");
      setSelectedCourses([]);
    };
  }, []);

  useEffect(() => {
    setSearchByName && setSearchByName(!!isOpen);

    return () => setSearchByName && setSearchByName(false);
  }, [isOpen])

  const highlightText = (text, highlight) => {
    if (!highlight) return text;
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className={styles.highlight}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={closeModal}
      maxHeight={"550px"}
      maxWidth={"416px"}
      withCross
    >
      <p className={styles.title}>{t?.selectCourse}</p>
      <p className={styles.selected}>
        {t?.selected}: <span>{selectedCourses?.length}</span>
      </p>
      <div className={styles.search}>
        <img src={searchIcon} alt={""} />
        <input
          placeholder={t?.search}
          value={searchedValue ? searchedValue : search}
          onChange={(event) => {
            if (setSearchedValue) {
              setSearchedValue(event?.target?.value);
            } else {
              setSearch(event?.target?.value);
            }
          }}
        />
      </div>

      {coursesList?.length > 0 && (
        <div
          className={cn(styles.list_item, styles.selectAllButton, "noselect")}
          onClick={toggleSelectAll}
        >
          <Checkbox isChecked={selectAll} isGrey hiddenTitle={true} />
          <span>{t?.selectAll}</span>
        </div>
      )}

      {!!coursesList?.length ? (
        <div className={styles.list}>
          {coursesList?.map((item, key) => (
            <div
              ref={
                coursesList?.length - 1 === key && lastCourseRef
                  ? lastCourseRef
                  : null
              }
              key={key}
              onClick={() => onSelectFilters(item)}
              className={cn(
                styles.list_item,
                searchedValue?.length && styles.with_search,
              )}
            >
              <Checkbox
                hiddenTitle={true}
                isChecked={selectedCourses?.some(
                  (sItem) => sItem?.id === item?.id,
                )}
                isGrey
              />
              {searchedValue?.length ? (
                <span>{highlightText(item?.title, searchedValue)}</span>
              ) : (
                item?.title
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.notFound}>
          <img src={notFound} alt={""} />
          {t?.thereAreNoData}
        </div>
      )}

      <Button
        disabled={isDataLoading || !selectedCourses?.length}
        title={t?.next}
        onClick={onAddCourse ? onAddCourse : closeModal}
      />

      {isDataLoading && (
        <div className="default_loader_wrapper">
          <Loader size={"small"} />
        </div>
      )}
    </ModalLayout>
  );
};

export default SelectCourseModal;
