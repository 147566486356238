import { useEffect, useState } from "react";
import useIntersectionObserver from "../../useIntersectionObserver";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../constants/statistics";
import StatisticsApi from "../../../utils/api/StatisticsApi";

const useGetStatisticsCoursesProgressGraph = (
   tableTotalCount,
   setTableTotalCount,
   activeCourseFilters,
   activeTagsFilter,
   order_by,
   order,
) => {
   const [loading, setLoading] = useState(false);
   const [page, setPage] = useState(1);
   const [data, setData] = useState([]);
   const [searchedValue, setSearchedValue] = useState('');

   const fetchData = async (passedPage) => {
      setLoading(true);

      const res = await new StatisticsApi().getCourseProgressStatistics({
         page: passedPage ? passedPage : page,
         courseId: activeCourseFilters?.id,
         searchQuery: searchedValue,
         order_by,
         order
      });

      if (res?.success?.data?.course) {
         const data = res?.success?.data?.course;

         if (passedPage) {
            setData(data);
         } else {
            setData((prev) => [...prev, ...data]);
         }

         setTableTotalCount((prev) => ({
            ...prev,
            courses_progress_graph: res?.success?.totalCount,
         }));
      }

      if (res?.success?.data?.course?.length === 0) {
         setPage(1);
      }

      if (res?.error?.message && page) {
         setData([]);
      }

      setLoading(false);
   };

   const lastItemRef = useIntersectionObserver(() => {
      if (!loading && data?.length < tableTotalCount?.courses_progress_graph) {
         if (data?.length === 0) {
            setPage(1);
         } else {
            setPage((prev) => prev + 1);
         }
      }
   }, [loading, tableTotalCount?.courses_progress_graph]);

   useEffect(() => {
      if (page > 1 && data?.length <= tableTotalCount?.courses_progress_graph) {
         fetchData();
      }
   }, [page]);

   const debouncedFetchData = debounce(
      () => fetchData(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   useEffect(() => {
      setPage(1);
      debouncedFetchData();
      return () => debouncedFetchData.cancel();
   }, [
      searchedValue,
      activeTagsFilter,
      activeCourseFilters,
      order_by, order
   ]);

   return {
      data,
      lastItemRef,
      searchedValue,
      setSearchedValue,
      loading,
   };
};

export default useGetStatisticsCoursesProgressGraph;
