import videoPlaceholder from "../../assets/images/file_icons/video.svg";
import imagePlaceholder from "../../assets/images/file_icons/images.svg";
import audioPlaceholder from "../../assets/images/file_icons/audio.svg";
import docsPlaceholder from "../../assets/images/file_icons/documents.svg";

import styles from "./styles.module.scss";

const FilePlaceholderImg = ({ file }) => {
   return (
      <img
         className={styles.file_placeholder}
         src={
            file?.ext?.startsWith("video/")
               ? videoPlaceholder
               : file?.ext?.startsWith("audio/")
               ? audioPlaceholder
               : file?.ext?.startsWith("image/")
               ? imagePlaceholder
               : docsPlaceholder
         }
         alt="File placeholder"
      />
   );
};

export default FilePlaceholderImg;
