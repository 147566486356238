import { useEffect, useState } from "react";
import useIntersectionObserver from "../../useIntersectionObserver";
import debounce from "lodash/debounce";

import { DEFAULT_DEBOUNCE_DELAY } from "../../../constants/statistics";
import StatisticsApi from "../../../utils/api/StatisticsApi";

const useGetStatisticsCompanies = (
   tableTotalCount,
   setTableTotalCount,
   activeFilters,
   activeCourseFilters,
   activeTeamFilters,
   order_by,
   order
) => {
   const [loading, setLoading] = useState(false);
   const [page, setPage] = useState(1);
   const [data, setData] = useState([]);
   const [searchedValue, setSearchedValue] = useState("");

   const fetchData = async (passedPage) => {
      setLoading(true);

      const res = await new StatisticsApi().getCompaniesStatistics(
         passedPage ? passedPage : page,
         searchedValue,
         activeFilters?.ratingCompanyFilter?.id,
         activeTeamFilters?.ratingTeamFilter?.id,
         order_by,
         order
      );

      if (res?.success?.data?.companies) {
         const data = res?.success?.data?.companies;

         if (passedPage) {
            setData(data);
         } else {
            setData((prev) => [...prev, ...data]);
         }

         setTableTotalCount((prev) => ({
            ...prev,
            companies: res?.success?.totalCount,
         }));
      }

      if (res?.success?.data?.companies?.length === 0) {
         setPage(1);
      }

      if (res?.error?.message && page) {
         setData([]);
      }

      setLoading(false);
   };

   const lastItemRef = useIntersectionObserver(() => {
      if (!loading && data?.length < tableTotalCount?.companies) {
         if (data?.length === 0) {
            setPage(1);
         } else {
            setPage((prev) => prev + 1);
         }
      }
   }, [loading, tableTotalCount?.companies]);

   useEffect(() => {
      if (page > 1 && data?.length <= tableTotalCount?.companies) {
         fetchData();
      }
   }, [page]);

   const debouncedFetchData = debounce(
      () => fetchData(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   useEffect(() => {
      setPage(1);
      debouncedFetchData();
      return () => debouncedFetchData.cancel();
   }, [
      searchedValue,
      activeFilters?.ratingCompanyFilter,
      activeTeamFilters?.ratingTeamFilter,
      order_by, order
   ]);

   return {
      data,
      lastItemRef,
      searchedValue,
      setSearchedValue,
      loading,
   };
};

export default useGetStatisticsCompanies;
