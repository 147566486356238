import React, { useState } from "react";

import dots from "../../../assets/images/symbols/three_dots.svg";
import useOptions from "../../../hooks/useOptions";
import styles from "./styles.module.scss";
import useFindTranslations from "../../../hooks/useFindTranlsations";

const DropdownOptions = ({
   onRenameModalOpen,
   onCopy,
   onConfirmOpen,
   role,
}) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

      const t = useFindTranslations();

      const onCopyRole = () => {
         onCopy();
         closeOptions();
      }

   return (
      <>
         <div className={styles.dots} onClick={toggleOptions} ref={dotsRef}>
            <img src={dots} alt={""} />
         </div>

         {isOptionsOpen && (
            <div ref={dropdownRef} className={styles.dropdown}>
               <p onClick={onRenameModalOpen}>{t?.rename}</p>
               <p onClick={onCopyRole}>{t?.copy}</p>
               <p onClick={onConfirmOpen}>{t?.delete}</p>
            </div>
         )}
      </>
   );
};

export default DropdownOptions;
