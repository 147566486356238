import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import avatarPlaceholder from "../../../assets/images/avatar.svg";

import { useSelector } from "react-redux";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";

import styles from "./styles.module.scss";
import cn from "classnames";

const ProfileBodyItem = ({
   row,
   columns,
   index,
   onRowClick,
   tableFor,
   lastItemRef,
}) => {
   const renderCellContent = (column, row, maxWordsLength) => {
      return (
         <CustomTooltip
            id={row[column]}
            limit={maxWordsLength}
            text={row[column]}
         />
      );
   };

   return (
      <tr
         ref={lastItemRef}
         onClick={() => onRowClick(row.id, row?.course)}
         key={`row-${index}`}
         className={cn(
            index % 2 === 0 ? styles.rowOdd : "",
            onRowClick && tableFor !== "companies" ? styles.link : "",
            tableFor === "companies" ? styles.active_and_no_clickable : ""
         )}
      >
         {columns.map((column, columnIndex) => (
            <td key={`cell-${index}-${columnIndex}`}>
               <div className={styles.cellContent}>
                  {renderCellContent(column, row, 20)}
               </div>
            </td>
         ))}
      </tr>
   );
};

export default ProfileBodyItem;
