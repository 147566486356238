import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TextArea from "../../../components/TextArea/TextArea";
import PaginatedDropdown from "../../../components/PaginatedDropdown/PaginatedDropdown";
import RenameModal from "../../../components/Modals/RenameModal/RenameModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import useGetPositions from "../../../hooks/api/departmentsAndPostitions/useGetPositions";
import useGetDepartments from "../../../hooks/api/departmentsAndPostitions/useGetDepartments";

const PositionBlock = ({
   setSelectedDepartment,
   selectedDepartment,
   setSelectedPosition,
   selectedPosition,
   onSelectDepartment,
   onSelectPosition,
   currentCompanyId,
   userInfo,
}) => {

   const {
      data: departments,
      lastItemRef: lastDepartmentRef,
      refetch: refetchDepartments,
      searchedValue: searchedValueDep,
      setSearchedValue: setSearchedValueDep,
   } = useGetDepartments({
      companyId: currentCompanyId,
   });

   const {
      data: positions,
      lastItemRef: lastPositionRef,
      refetch: refetchPositions,
      searchedValue: searchedValuePos,
      setSearchedValue: setSearchedValuePos,
   } = useGetPositions({
      companyId: currentCompanyId,
   });

   const t = useFindTranslationsByString();

   const filteredDepartments = userInfo?.departments?.filter(d => +d?.company?.id === +currentCompanyId);
   const filteredPositions = userInfo?.positions?.filter(p => +p?.department?.company?.id === +currentCompanyId);

   return (
      <div className={styles.settings_box}>
         <div className={styles.block}>
            <div className={styles.setting_form_row}>
               <PaginatedDropdown
                  searchNeeded
                  data={departments}
                  field={"name"}
                  title={t("Department")}
                  value={selectedDepartment?.name}
                  placeholder={
                     filteredDepartments?.length > 0
                        ? filteredDepartments
                             ?.map((item) => item?.name)
                             ?.join(", ")
                        : t("Department * Optionally")
                  }
                  onChange={onSelectDepartment}
                  maxHeight={"220px"}
                  searchValue={setSearchedValueDep}
                  setSearchValue={searchedValueDep}
                  lastItemRef={lastDepartmentRef}
                  crossNeeded
               />
            </div>
            <div className={styles.setting_form_row}>
               <PaginatedDropdown
                  searchNeeded
                  disabled={
                     !selectedDepartment?.id && !userInfo?.departments?.length
                  }
                  data={
                     selectedDepartment?.id
                        ? selectedDepartment?.positions || []
                        : departments?.find(
                           (d) => +d?.id === +filteredPositions?.[0]?.department?.id)?.positions || []
                  }
                  title={t("Position")}
                  maxHeight={"180px"}
                  field={"name"}
                  value={selectedPosition?.name}
                  placeholder={
                     selectedDepartment?.id
                        ? selectedDepartment?.positions?.[0]?.name || 'No position in selected department'
                        : filteredPositions?.length > 0
                        ? filteredPositions
                             ?.map((item) => item?.name)
                             ?.join(", ")
                        : t("Position * Optionally")
                  }
                  onChange={onSelectPosition}
                  crossNeeded
               />
            </div>
         </div>
      </div>
   );
};

export default PositionBlock;
