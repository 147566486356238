import { useState } from "react";
import useHandleModal from "./dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../components/ToastMsg/ToastMsg";
import FolderApi from "../utils/api/FolderApi";
import { FOLDER_STATUS_ACTIVE } from "../constants/courses";

const useChangeFolder = (refetchFolders) => {
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   const [isOpen, setIsOpen] = useState(false);

   const confirmModalToogleShowStatus = useHandleModal();
   const confirmModal = useHandleModal();
   const confirmHideModal = useHandleModal();
   const confirmModalRestore = useHandleModal();
   const confirmDeleteModal = useHandleModal();
   const confirmModalReturnBack = useHandleModal();

   const [clickedFolder, setClickedFolder] = useState({});

   const onCloseSettings = () => setIsOpen(false);

   const onConfirmDeleteOpen = (e, folder) => {
      e.stopPropagation();
      setClickedFolder(folder);
      onCloseSettings();
      confirmModal.open()
   };

   const onConfirmOpenRestore = (e, folder) => {
      e.stopPropagation();
      onCloseSettings();
      setClickedFolder(folder);
      confirmModalRestore.open();
   };

   const onConfirmHideOpen = (e, folder) => {
      e.stopPropagation();
      onCloseSettings();
      setClickedFolder(folder);
      confirmHideModal.open();
   };

      const onConfirmOpenDelete = (e, folder) => {
      e.stopPropagation();
      setClickedFolder(folder);
      confirmDeleteModal.open();
      onCloseSettings();
   }

   const onConfirmOpenChangeHidenStatus = (e, folder) => {
      e.stopPropagation();
      setClickedFolder(folder);
      confirmModalReturnBack.open();
      onCloseSettings();
   };

   const onFolderDeleteToggle = async (status) => {
      setIsChangeLoading(true);
      const res = await new FolderApi().updateFolder(clickedFolder?.id, {
         new_status: status,
      });

      confirmModal.close();
      confirmModalRestore?.close();

      setIsChangeLoading(false);

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchFolders();
      }
   };

   const onFolderHideToggle = async (status) => {
      setIsChangeLoading(true);

      const res = await new FolderApi().updateFolder(clickedFolder?.id, {
         new_status: status,
      });

      confirmHideModal.close();
      confirmModalReturnBack.close();
      setIsChangeLoading(false);

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchFolders();
      }
   };

   const deleteFolder = async () => {
      setIsChangeLoading(true);
      const res = await new FolderApi().deleteFolder(clickedFolder?.id);

      confirmDeleteModal.close();

      setIsChangeLoading(false);

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchFolders();
      }
   };

   return {
      confirmModalToogleShowStatus,
      confirmModal,
      confirmHideModal,
      onFolderDeleteToggle,
      onConfirmOpenDelete,
      onConfirmOpenChangeHidenStatus,
      confirmModalRestore,
      confirmModalReturnBack,
      confirmDeleteModal,
      isChangeLoading,
      setIsChangeLoading,
      onCloseSettings,
      onConfirmOpenRestore,
      onFolderHideToggle,
      onConfirmHideOpen,
      deleteFolder,
      onConfirmDeleteOpen,
      isOpen
   };
};

export default useChangeFolder;
