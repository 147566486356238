import { ANSWER_STATUS_CHOSEN_AND_RIGHT, ANSWER_STATUS_CHOSEN_AND_WRONG, ANSWER_STATUS_NOT_CHOSEN_AND_RIGHT, ANSWER_STATUS_NOT_CHOSEN_AND_WRONG } from "../../../../constants/tests";
import styles from "./styles.module.scss";
import cn from "classnames";

const statusClassMap = {
   [ANSWER_STATUS_CHOSEN_AND_RIGHT]: styles.correct_answer,
   [ANSWER_STATUS_CHOSEN_AND_WRONG]: styles.wrong_answer,
   [ANSWER_STATUS_NOT_CHOSEN_AND_RIGHT]: styles.correct_not_chosen_answer,
   [ANSWER_STATUS_NOT_CHOSEN_AND_WRONG]: styles.wrong_not_chosen_answer,
 };

const CommonTypes = ({ answer, answerIdx, correctImg, iconWrong }) => { 
   return (
      <div
         className={cn(
            styles.answer_item,
            statusClassMap[answer?.status]
         )}
         key={answer.content}
      >
         <span>{answerIdx + 1}</span>
         <p>{answer.content}</p>
         <img src={answer.status === 1 ? correctImg : iconWrong} alt="" />
      </div>
   );
};

export default CommonTypes;
