import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import cn from "classnames";

const AgencySettingsSubheader = ({
   onSave,
   confirmModal,
   isButtonDisabled,
   t
}) => {
   const params = useParams();
   return (
      <header className={styles.settings_header}>
         <div className={styles.page_title_box}>
            <h2 className={styles.setting_title}>{t?.settings}</h2>
            <p className={styles.setting_description}>
               {t?.settingUpSectionsWithinAgencies}
            </p>
         </div>
         <form className={styles.create_agency_form} action="#">
            <button
               type="button"
               onClick={confirmModal.open}
               className={styles.button_cancel}
            >
               {t?.cancel}
            </button>

            <button
               type="button"
               onClick={onSave}
               className={cn(
                  isButtonDisabled ? styles.button_disabled : "",
                  styles.button_save
               )}
               disabled={isButtonDisabled}
            >
               {params.id === ":id" ? t?.createCompany : t?.saveChanges}
            </button>
         </form>
      </header>
   );
};

export default AgencySettingsSubheader;
