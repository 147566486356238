import React from "react";
import star from "../../../assets/images/statistics_star.svg";
import styles from "./styles.module.scss";

const defineStars = (rating, total = 10) => {
   const totalStars = total;
   const activeStars = Math.min(rating, totalStars);

   const stars = [];
   for (let i = 0; i < totalStars; i++) {
      stars.push(
         <img
            key={i}
            src={star}
            alt={`Star ${i + 1}`}
            className={i < activeStars ? styles.active_star : ""}
         />
      );
   }

   return stars;
};

const TableStars = ({ rating, total }) => {
   const parsedRating = parseFloat(rating) || 0; // Convert to number
   return (
      <div className={styles.rating_wrapper}>
         {defineStars(parsedRating, total)}
      </div>
   );
};

export default TableStars;
