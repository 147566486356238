import React, { FC, useEffect, useRef, useState } from "react";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useSelector } from "react-redux";
import {
   DAILY_VISIBLE_LABELS,
   DATE_TABS,
   DATE_TAB_DAYLY,
   DATE_TAB_MONTHLY,
   DATE_TAB_WEEKLY,
   DATE_TAB_YEARLY,
   MONTHLY_VISIBLE_LABELS,
   generateMinuteLabels,
   generateMonthlyLabels,
   generateWeeklyLabels,
   generateYearlyLabels,
   generateYearlyVisibleLabels,
   getDailyData,
   getDaysInCurrentWeekData,
   getDaysInMonthData,
   getYearData,
} from "../../constants/statistics";

import styles from "./styles.module.scss";
import cn from "classnames";
import Dropdown from "../Dropdown/Dropdown";
import DropdownWithImg from "../DropdownWithImg/DropdownWithImg";
import useUserRole from "../../hooks/useUserRole";
import { ROLE_ID_SUPER_ADMIN } from "../../constants/user";

ChartJS.register(
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler
);

const LineChart = ({ isGreen, currentAmount, activeTab, clickActiveTab, companies, activeFilter, setActiveFilter, data: passedData, lastAgencyRef, t}) => {
   const chartRef = useRef(null);
   const [labels, setLabels] = useState(generateMinuteLabels());
   const [currentOnlineAmount, setCurrentOnlineAmount] = useState(0);
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId)

   const [activeVisibleLabels, setActiveVisibleLabels] =
      useState(DAILY_VISIBLE_LABELS);

   useEffect(() => {
      const tabLabelGenerators = {
         daily: generateMinuteLabels,
         weekly: generateWeeklyLabels,
         monthly: generateMonthlyLabels,
         yearly: generateYearlyLabels,
      };

      const tabVisibleLabelGenerators = {
         daily: DAILY_VISIBLE_LABELS,
         weekly: generateWeeklyLabels,
         monthly: MONTHLY_VISIBLE_LABELS,
         yearly: generateYearlyVisibleLabels,
      };

      const generateLabelsByTab = (tab) => {
         const labelGenerator =
            tabLabelGenerators[tab] || generateMinuteLabels;
         return labelGenerator;
      };

      const generateVisibleLabelsByTab = (tab) => {
         const labelGenerator =
            tabVisibleLabelGenerators[tab] || DAILY_VISIBLE_LABELS;
         return labelGenerator;
      };

      setActiveVisibleLabels(generateVisibleLabelsByTab(activeTab));
      setLabels(generateLabelsByTab(activeTab));
   }, [activeTab]);

   const defineCurrentData = () => {
      if (activeTab === DATE_TAB_DAYLY) {
         return getDailyData(passedData, isGreen)
      }
      if (activeTab === DATE_TAB_WEEKLY) {
         return getDaysInCurrentWeekData(passedData, isGreen);
      }
      if (activeTab === DATE_TAB_MONTHLY) {
         return getDaysInMonthData(passedData, isGreen);
      }
      if (activeTab === DATE_TAB_YEARLY) {
         return getYearData(passedData, isGreen);
      }
   }

   const returnCurrentOnlineAmount = () => {
      if(!isGreen || !passedData?.activeUsers) return 0;
      return passedData?.activeUsers?.perYear?.dailyUserGrowth?.length;
      return 0;
   }
   const returnCurrentRegistratedAmount = () => {
      if (isGreen || !passedData?.userGrowth?.dailyUserGrowthPer15Minutes) return 0;
  
      const lastObjectKey = Object.keys(passedData.userGrowth.dailyUserGrowthPer15Minutes).pop();
      const lastObject = passedData.userGrowth.dailyUserGrowthPer15Minutes[lastObjectKey];
  
      const currentDate = new Date();
      const currentDay = currentDate.getDate().toString().padStart(2, '0');
      // Initialize a counter
      let itemCount = 0;
  
      // Iterate through the nested objects
      for (const month in lastObject) {
         for (const day in lastObject[month]) {
             for (const hour in lastObject[month][day]) {
                 // Increment the counter for each element on the last level
                 itemCount++;
             }
         }
     }
  
      return itemCount;
  };
  

   const data = {
      labels,
      datasets: [
         {
            label: "",
            data: defineCurrentData(),
            borderColor: isGreen
               ? "rgba(74, 237, 81, 1)"
               : "rgba(176, 121, 240, 1)",
            fill: true,
            pointBackgroundColor: "white",
            pointBorderColor: "rgba(0,0,0,0)",
            pointHoverRadius: 6,
            backgroundColor: (context) => {
               const ctx = context.chart.ctx;
               const gradient = ctx.createLinearGradient(0, 0, 0, 260);
               if (isGreen) {
                  gradient.addColorStop(0, "rgba(74, 237, 81, 0.3)");
                  gradient.addColorStop(1, "rgba(74, 237, 81, 0)");
               } else {
                  gradient.addColorStop(0, "rgba(176, 121, 240, 0.3)");
                  gradient.addColorStop(1, "rgba(176, 121, 240, 0)");
               }

               return gradient;
            },
         },
      ],
   };

   const hoverLine = {
      id: 'hoverLine',
      afterDatasetsDraw(chart, args, plugins) {
         const { ctx, tooltip } = chart;

         if(tooltip._active && tooltip._active.length > 0) {
            const activePoint = tooltip._active[0];

            const gradient = ctx.createLinearGradient(activePoint.element.x, activePoint.element.y, activePoint.element.x, chart.chartArea.bottom);
            gradient.addColorStop(0, "rgba(255, 255, 255, 0.4)");
            gradient.addColorStop(1, "rgba(255, 255, 255, 0.0)");

            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([5, 3]);
            ctx.moveTo(activePoint.element.x, activePoint.element.y)
            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
            ctx.lineWidth = 0.8;
            ctx.strokeStyle = gradient;
            ctx.stroke();
            ctx.restore();
         }
      }
   }

   const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         x: {
            grid: {
               display: false,
            },
            ticks: {
               display: false,
            },
         },
         y: {
            min: 0,
            grid: {
               display: false,
            },
            ticks: {
               font: {
                  size: 14,
                  family: "roboto",
                  weight: "400",
               },
               color: "#939495",
            },
         },
      },
      plugins: {
         legend: {
            position: "top",
            display: false,
         },
         labels: {
            display: false,
         },
         filler: {
            propagate: false,
         },
         tooltip: {
            intersect: false,
            mode: "index",
            backgroundColor: "rgba(62, 62, 65, 1)",
            bodyColor: "rgba(255, 255, 255, 0.7)",
            displayColors: false,
            titleAlign: "center",
            titleFont: { weight: "bold", size: "16px" },
            callbacks: {
               title: (tooltipItems, data) => {
                  return `${tooltipItems[0].formattedValue}`;
               },
               label: (tooltipItem, data) => {
                  if (activeTab === DATE_TAB_DAYLY) {
                     return `${tooltipItem.label} ${moment().format(
                        "DD.MM.YY"
                     )}`;
                  }
                  if (activeTab === DATE_TAB_WEEKLY) {
                     return `${tooltipItem.label}`;
                  }

                  if (activeTab === DATE_TAB_MONTHLY) {
                     return `${tooltipItem.label} ${moment().format(
                        `${tooltipItem.label}.MM.YY`
                     )}`;
                  }
                  if (activeTab === DATE_TAB_YEARLY) {
                     return `${tooltipItem.label.split('.')[0]}`;
                  }
               },
            },
         },
      },
      elements: {
         line: {
            tension: 0.35,
            borderWidth: 1,
         },
         point: {
            radius: 0,
         },
      },
      hover: {
         intersect: false,
      },
      indexAxis: "x",
   };

   return (
      <div className={styles.wrapper}>
         <div className={styles.header}>
            <div className={styles.headerLeft}>
               <span className={styles.big}>{t?.totalNumberOfUsers}</span>
               <span className={styles.gray}>{currentAmount} {isGreen ? t?.activeUsersInAll : t?.usersRegisteredInAll}</span>
            </div>
            <div className={styles.headerRight}>
               <span className={styles.big}>{isGreen ? returnCurrentOnlineAmount() : returnCurrentRegistratedAmount()}</span>
               <span className={styles.gray}>
                  {isGreen ? t?.usersWhoHaveBeenOnline : t?.usersRegistered}
               </span>
            </div>
         </div>

         <div className={styles.tabs_wrapper}>
            {!isGreen && userRoleId === ROLE_ID_SUPER_ADMIN && (
               <div className={styles.left}>
                  <button onClick={()=> setActiveFilter({ name: t?.all })} className={styles.platform}>{t?.platform}</button>

                   <DropdownWithImg
                     data={companies}
                     value={activeFilter}
                     placeholder={t?.company}
                     onChange={(value) => setActiveFilter(value)}
                     maxFrontHeight={'30px'}
                     searchNeeded
                     lastItemRef={lastAgencyRef}
                  />
               </div>
            )}

            <div className={styles.tabs}>
               {DATE_TABS.map((tab) => (
                  <button
                     key={tab.id}
                     onClick={() => clickActiveTab(tab)}
                     className={cn(
                        styles.dateTab,
                        activeTab === tab.label ? styles.active_date : ""
                     )}
                  >
                     {t?.returnTranslation(tab.name)}
                  </button>
               ))}
            </div>
         </div>

         <div className={styles.chartWrapper}>
            <Line ref={chartRef} options={options} plugins={[hoverLine]} data={data} />
         </div>
         <div className={styles.labels}>
            {activeVisibleLabels.map((label) => (
               <span key={label}>  {t?.returnTranslation(label)} </span>
            ))}
         </div>
      </div>
   );
};

export default LineChart;
