import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import AgencyApi from "../../../utils/api/AgencyApi";
import { useParams } from "react-router-dom";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";

const LogoBlock = ({
   blockRef,
   largeIconUploadModal,
   faviconUploadModal,
   favicon,
   setFavicon,
   setLargeIcon,
   largeIcon,
   setAgencyFavicon,
   setAgencyLargeIcon,
   t
}) => {
   const [currentFavicon, setCurrentFavicon] = useState(null);
   const [currentLargeIcon, setCurrentLargeIcon] = useState(null);

   const params = useParams();

   const getFavicon = async () => {
      new AgencyApi().getAgencyIconLogo(params.id).then((res) => {
         if (
            res?.success &&
            res?.success?.data[0] !== "No company icon logo found."
         ) {
            setCurrentFavicon(res?.success?.data[0]);
         }
      });
   };

   const deleteFavicon = async () => {
      if(params.id !== ":id") {
         new AgencyApi().deleteAgencyIconLogo(params.id).then((res) => {
            if (res?.success) {
               setFavicon(null);
               setCurrentFavicon(null);
               setAgencyFavicon(null);
               return toast(<ToastMsg text={res?.success?.message}/>)
            }
            if (res?.error) {
               return toast(<ToastMsg text={res?.error?.message} isError />)
            }
         });
      } else {
         setFavicon(null);
         setCurrentFavicon(null);
         setAgencyFavicon(null)
      }
   }; 

   const getLargeIcon = async () => {
      new AgencyApi().getAgencyLargeIcon(params.id).then((res) => {
         if (
            res?.success &&
            res?.success?.data[0] !== "No company large logotype found."
         ) {
            setCurrentLargeIcon(res?.success?.data[0]);
            setAgencyLargeIcon(null);
         } 
      });
   };

   const deleteLargeIcon = async () => {
      if(params.id !== ":id") {
         new AgencyApi().deleteAgencyLargeIcon(params.id).then((res) => {
            if (res?.success) {
               setLargeIcon(null);
               setAgencyLargeIcon(null);
               setCurrentLargeIcon(null);
               return toast(<ToastMsg text={res?.success?.message}/>)
            } 
            if (res?.error) {
               return toast(<ToastMsg text={res?.error?.message} isError />)
            }
         }); 
      } else {
         setLargeIcon(null);
         setAgencyLargeIcon(null);
         setCurrentLargeIcon(null);
      }
   };

   const openUploadModal = () => {
      faviconUploadModal.open();
   }

   const openUploadLargeIcon = () => {
      largeIconUploadModal.open();
   }

   useEffect(() => {
      if(params.id !== ':id') {
         getFavicon();
         getLargeIcon();
      }
   }, []);

   const renderLargeIcon = () => {
      if(largeIcon) {
         return <img
                     src={largeIcon}
                     width="158"
                     height="40"
                 />
      } else if(currentLargeIcon) {
         return <img
                  src={currentLargeIcon}
                  width="158"
                  height="40"
               />
      } else {
         return <></>
      }
   }

   return (
      <div className={styles.settings_box} ref={blockRef}>
         <p className={styles.setting_box_name}>{t?.uploadLogotypes}</p>
         <div className={styles.settings_favicon}>
            <div className={styles.logo_small_wrapper}>
               {favicon ? (
                  <img
                     src={favicon}
                     width="64"
                     height="64"
                  />
               ) : (
                  currentFavicon && 
                     <img src={currentFavicon} width="64" height="64" />
               )}
            </div>
            <div className={styles.logo_small_body}>
               <div className={styles.settings_logo_header}>
                  <span
                     className={`${styles.settings_box_icon} ${styles.settings_box_icon_favicon}`}
                  />
                  <div className={styles.settings_logo_header_text}>
                     <p className={styles.settings_logo_title}>
                        {t?.uploadIconLogotype}
                     </p>
                     <p className={styles.settings_logo_description}>
                        {`${t?.recommended} 500 KB & 400x400px (PNG ${t?.or} JPG)`} 
                     </p>
                  </div>
               </div>
               <div className={styles.logo_small_buttons}>
                  <button   
                     onClick={openUploadModal}
                     type="button"
                     className={styles.button_upload_image}
                  >
                     {t?.uploadNewImage}
                  </button>
                  <button
                     onClick={deleteFavicon}
                     type="button"
                     className={styles.button_delete_image}
                  >
                     {t?.deleteImage}
                  </button>
               </div>
            </div>
         </div>
         <div className={styles.settings_logo_big}>
            <div className={styles.settings_logo_header}>
               <span
                  className={`${styles.settings_box_icon} ${styles.settings_box_icon_logo}`}
               />
               <div className={styles.settings_logo_header_text}>
                  <p className={styles.settings_logo_title}>
                     {t?.uploadLargeLogotype}
                  </p>
                  <p className={styles.settings_logo_description}>
                   {`${t?.recommended} 1 MB & 1400x240px (PNG ${t?.or} JPG)`} 
                  </p>
               </div>
            </div>
            <div className={styles.logo_big_wrapper}>
               {renderLargeIcon()}
            </div>
            <div className={styles.logo_small_buttons}>
               <button
                  onClick={openUploadLargeIcon}
                  type="button"
                  className={styles.button_upload_image}
               >
                     {t?.uploadNewImage}
               </button>
               <button
                  onClick={deleteLargeIcon}
                  type="button"
                  className={styles.button_delete_image}
               >
                     {t?.deleteImage}
               </button>
            </div>
         </div>
      </div>
   );
};

export default LogoBlock;
