import tests from "../assets/images/icon_task1.svg";
import one_choice from "../assets/images/one_choise.svg";
import sequence from "../assets/images/sequence.svg";
import writtenAnswer from "../assets/images/written_answer.svg";
import trueFalse from "../assets/images/true_false.svg";
import compare from "../assets/images/compare_icon.svg";
import rateImg from "../assets/images/rate.svg";
import starsImg from "../assets/images/star.svg";
import fileUploadImg from "../assets/images/file_upload.svg";
import videoAnswerImg from "../assets/images/icon_video.svg";
import correctTest from "../assets/images/correct_icon.svg";
import wrongTest from "../assets/images/wrong_icon.svg";
import halfCorrectTest from "../assets/images/warning_icon.svg";
import { COMPARE_ANSWER_CODE, FILE_UPLOAD_ANSWER_CODE, MULTIPLE_CHOICE_ANSWER_CODE, ONE_CHOICE_ANSWER_CODE, RATE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, STARS_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE, VIDEO_ANSWER_CODE, WRITTEN_ANSWER_CODE } from "../utils/questionsHelper";

export const commonTypes = [
   MULTIPLE_CHOICE_ANSWER_CODE,
   ONE_CHOICE_ANSWER_CODE,
   TRUE_FALSE_ANSWER_CODE,
];
export const typesThatNeededTips = [
   MULTIPLE_CHOICE_ANSWER_CODE,
   ONE_CHOICE_ANSWER_CODE,
   TRUE_FALSE_ANSWER_CODE,
   SEQUENCE_ANSWER_CODE,
   COMPARE_ANSWER_CODE,
];

export const TEST_STATUS_ON_CHECK = 0;
export const TEST_STATUS_APPROVED = 1;
export const TEST_STATUS_REJECTED = 2;
export const TEST_STATUS_ON_REWORK = 3;

export const TESTS_CHECKED_FILTERS = [
   { label: "All", status: null },
   { label: "Pass", status: TEST_STATUS_APPROVED },
   { label: "Failed", status: TEST_STATUS_REJECTED },
   { label: "On rework", status: TEST_STATUS_ON_REWORK }
];


export const statusMap = {
   [TEST_STATUS_APPROVED]: 'Pass',
   [TEST_STATUS_ON_REWORK]: 'Rework',
   [TEST_STATUS_REJECTED]: 'Failed',
   [TEST_STATUS_ON_CHECK]: 'On check'
};

export const USERS_TESTS_DATA = [
   {
      id: 1,
      student: "Kiosse Ivan",
      lesson_name: 'lesson1',
      course_name: "React",
      test_name: "Test1",
      attempt: "1",
      end_date: "27.07.2023",
      pass_time: "10 mins",
   },
   {
      id: 2,
      student: "Kum Petro",
      course_name: "JS",
      lesson_name: 'lesson2',
      test_name: "Test2",
      attempt: "1",
      end_date: "27.07.2023",
      pass_time: "10 mins",
   },
   {
      id: 3,
      student: "Testova Inna",
      course_name: "PHP",
      lesson_name: 'lesson3',
      test_name: "Test3",
      attempt: "1",
      end_date: "27.07.2023",
      pass_time: "10 mins",
   },
   {
      id: 4,
      student: "Kara Boja",
      course_name: "Flutter",
      lesson_name: 'lesson4',
      test_name: "Test3",
      attempt: "1",
      end_date: "27.07.2023",
      pass_time: "10 mins",
   },
   {
      id: 5,
      student: "John Pavlik",
      course_name: "Angular",
      lesson_name: 'lesson5',
      test_name: "Test3",
      attempt: "1",
      end_date: "27.07.2023",
      pass_time: "10 mins",
   },
];

export const USERS_TESTS_COLUMN = [
   "student",
   "team",
   "course_name",
   "test_name",
   "end_date",
   "pass_time",
];

export const USER_TESTS_COLUMN = [
   "course_name",
   "team",
   "test_name",
   "end_date",
   "pass_time",
];

export const USER_TESTS_COLUMN_CHECKED = [
   "course_name",
   "team",
   "test_name",
   "end_date",
   "pass_time",
   "result",
   'score'
];

export const USERS_TESTS_COLUMN_CHECKED = [
   "student",
   "team",
   "course_name",
   "test_name",
   "end_date",
   "pass_time",
   "result",
   'score'
];

export const USERS_TESTS_SORTING_COLUMN =
   {
      "student": "user_name",
      "course_name": "course_name",
      "team": "team_name",
      "test_name": "lesson_name",
      "end_date": "completed_at",
      "pass_time": "time_spent",
      "result": "status",
      'score': "success_percentage"
   }

export const QUESTION_NAMES = {
   0: "One choice",
   1: "Multiple choice",
   2: "File upload",
   3: "Written answer",
   4: "Rate 1-10",
   5: "Stars 1-10",
   6: "True/False",
   7: "Compare",
   8: "Sequence",
   9: "Video Answer",
 };

export const QUESTION_IMAGES = {
   0: one_choice,
   1: tests,
   2: fileUploadImg,
   3: writtenAnswer,
   4: rateImg,
   5: starsImg,
   6: trueFalse,
   7: compare,
   8: sequence,
   9: videoAnswerImg,
 };

 export const ANSWER_STATUS_CHOSEN_AND_RIGHT = 1;
 export const ANSWER_STATUS_CHOSEN_AND_WRONG = 2;
 export const ANSWER_STATUS_NOT_CHOSEN_AND_RIGHT = 3;
 export const ANSWER_STATUS_NOT_CHOSEN_AND_WRONG = 5;

export const testQuestionsList = [
   {
      id: 1,
      name: "Multiple choice",
      content: 'What is JavaScript?',
      description: 'Feel free to use this question as a template and replace the landmark and options with those you intend to use in your actual test.',
      type: MULTIPLE_CHOICE_ANSWER_CODE,
      answers: [
         { content: 'Something has to do with programming', status: 1 }, 
         { content: 'Shit', status: 0 }, 
         { content: 'Programming language', status: 1 }, 
         { content: 'It does not exist, LOL', status: 0 }, 
      ],
      image: tests,
      resultImage: correctTest,
      result: 1,
   },
   {
      id: 2,
      name: "One choice",
      content: "Who is Jacky Chan?",
      type: ONE_CHOICE_ANSWER_CODE,
      answers: [
         { content: 'An actor', status: 0 }, 
         { content: 'A waitress', status: 1 }, 
         { content: 'A dog', status: 0 }, 
      ],
      image: one_choice,
      resultImage: wrongTest,
      result: 0,
   },
   {
      id: 3,
      name: "Sequence",
      type: SEQUENCE_ANSWER_CODE,
      content: "Put in order A-Z",
      answers: [
         { content: 'B', status: 0 }, 
         { content: 'A', status: 0 }, 
         { content: 'C', status: 1 }, 
      ],
      sequence: ['A', 'B', 'C'],
      image: sequence,
      resultImage: halfCorrectTest,
      result: 2,
   },
   {
      id: 4,
      name: "True/False",
      type: TRUE_FALSE_ANSWER_CODE,
      content: "Is is true that climate changes are gonna distroy our planet?",
      answers: [
         { content: 'Yes', status: 1 }, 
         { content: 'No', status: 0 }, 
      ],
      image: trueFalse,
      resultImage: correctTest,
      result: 1,
   },
   {
      id: 5,
      name: "Compare",
      type: COMPARE_ANSWER_CODE,
      content: "Compare following",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
       options: [
         "Poland",
         "Italy",
         "Ukraine"
      ],
      answers: [
         { content: 'Gdansk', status: 1, matchingOption: 1 }, 
         { content: 'Kharkiv', status: 0, matchingOption: 3}, 
         { content: 'Rome', status: 0, matchingOption: 2}, 
      ],
      image: compare,
      resultImage: wrongTest,
      result: 0,
   },
   {
      id: 7,
      name: "Rate",
      type: RATE_ANSWER_CODE,
      content: "Rate this test",
      rating: 5,
      image: rateImg,
      resultImage: correctTest,
      result: 1
   },
   {
      id: 8,
      name: "Stars",
      type: STARS_ANSWER_CODE,
      content: "Give stars to the lesson before",
      rating: 8,
      image: starsImg,
      resultImage: correctTest,
      result: 1
   },
   {
      id: 6,
      name: "Written asnwer",
      comment: 'Quam est purus ultrices pulvinar. Amet in non adipiscing non at.',
      type: WRITTEN_ANSWER_CODE,
      content: "How are you doing today?",
      answers: [
         { content: 'Mus lacus aliquam fusce massa vel egestas. Egestas mauris purus morbi adipiscing. Mauris vestibulum consectetur elementum tortor. Tortor nisl urna massa mauris adipiscing eu commodo odio tellus. Donec eu sem pellentesque netus mi enim quam integer.', status: 1 }, 
      ],
      image: writtenAnswer,
   },
   {
      id: 9,
      name: "File upload",
      comment: 'Quam est purus ultrices pulvinar. Amet in non adipiscing non at.',
      description: 'Feel free to use this question as a template and replace the landmark and options with those you intend to use in your actual test.',
      type: FILE_UPLOAD_ANSWER_CODE,
      content: "Upload presentation for your thesis: Global warm",
      fileLink: 'https://lms-education.s3.us-east-2.amazonaws.com/files/lessons/lesson-AppEntityLesson-1-Knyha-Praktyka-DAO-Toyota-ot-Serheya-64e08e3da7b45.pdf',
      answers: [
         { content: 'Mus lacus aliquam fusce massa vel egestas. Egestas mauris purus morbi adipiscing. Mauris vestibulum consectetur elementum tortor. Tortor nisl urna massa mauris adipiscing eu commodo odio tellus. Donec eu sem pellentesque netus mi enim quam integer.', status: 1 }, 
      ],
      image: fileUploadImg,
   },
   {
      id: 10,
      name: "Video answer",
      comment: 'Quam est purus ultrices pulvinar. Amet in non adipiscing non at.',
      description: 'Feel free to use this question as a template and replace the landmark and options with those you intend to use in your actual test.',
      type: VIDEO_ANSWER_CODE,
      title: 'Answer on the video please',
      content: "https://lms-education.s3.us-east-2.amazonaws.com/files/lessons/lesson-AppEntityLesson-1-test-64e0a4f908ab3.mp4",
      fileLink: 'https://lms-education.s3.us-east-2.amazonaws.com/files/lessons/lesson-AppEntityLesson-1-Knyha-Praktyka-DAO-Toyota-ot-Serheya-64e08e3da7b45.pdf',
      answer: 
         { link: 'https://lms-education.s3.us-east-2.amazonaws.com/files/lessons/lesson-AppEntityLesson-1-SampleVideo-1280x720-1mb-64e0ac1738b68.mp4' }, 
      image: videoAnswerImg,
   },
];
