import React, {useEffect} from 'react';
import Modal from "react-modal";
import { customStyles, customStylesNoBlur, customStylesBlackBg } from "./custumModalStyles";
import cross from '../../assets/images/symbols/cross_grey.svg'
import styles from './styles.module.scss'
import cn from "classnames";
import { isMobileDevice } from '../../utils/usersHelper';

const ModalLayout = ({
                         isOpen,
                         onClose,
                         children,
                         maxWidth,
                         maxHeight,
                         withCross,
                         withScroll = false,
                         customPadding = null,
                         customBgColor,
                         visibleOverflow = false,
                         isSertificate = false,
                         black = true,
                         dFlex,
                         height = '100%',
                         onClickToCloseDropdown,
                         noBlur = true,
                         isBlackLayout,
                         isUploadMobileModalSize
                     }) => {

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    useEffect(() => {
      if (isOpen && !withScroll) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
   
      // Cleanup function to remove the style when the component unmounts or modal is closed
      return () => {
        document.body.style.overflowY = 'auto';
      };
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                ...(noBlur ? customStylesNoBlur : isBlackLayout ? customStylesBlackBg : customStyles), content: {
                    ...customStyles?.content,
                    maxWidth,
                    maxHeight,
                    backgroundColor: customBgColor ? customBgColor : '#28292C',
                    ...(withScroll ? { overflow: 'scroll' } : {}),
                    ...(visibleOverflow ? { overflow: 'visible' } : {}),
                    padding: isMobileDevice() ? '35px 24px 24px 24px' : customPadding ? customPadding : '40px',
                    height,
                  ...( isUploadMobileModalSize ? { width: "101%", inset: `90% 40px 40px 50%`, border: '1px solid transparent'} : {} ),
                }
            }}
        >
            <div onClick={()=> onClickToCloseDropdown ? onClickToCloseDropdown('') : {}} className={cn(styles.modal, dFlex ? styles.dFlex : '')}>
                {withCross && <div 
                     className={cn(
                           styles.cross, 
                           customPadding && styles.cross_padding,
                        )}>
                    <img src={cross} alt={''} onClick={onClose}/>
                </div>}
                {children}
            </div>
        </Modal>
    );
};

export default ModalLayout;