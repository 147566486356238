import Base from "./Base";

export default class AgencyApi extends Base {
    getAgencies(page, limit, serializationGroup, search, signal, isSuperAdmin) {
      let url = `api/v1/company?page=${page}&limit=${limit}${search ? `&filter[name]=${search}` : ''}&check_current_company=0&order=asc`;

      if(!isSuperAdmin) {
         url +=  `&filter[your_available_companies]=1`
      }

      return super.get(url, null, signal, serializationGroup);
    }

    getAgency(agencyId, group) {
        return super.get(`api/v1/company/${agencyId}`, null, null, group);
    }

    getAgencySpecificInfo(data) {
        return super.post(`api/v1/company/get-company-info`, data);
    }

    getAgencyIconLogo(agencyID) {
      return super.get(`api/v1/company/${agencyID}/get-company-icon-logo`);
    }

    getAgencyLargeIcon(agencyID) {
      return super.get(`api/v1/company/${agencyID}/get-company-large-logotype`);
    }

    deleteAgencyIconLogo(agencyID) {
      return super.delete(`api/v1/company/${agencyID}/delete-company-icon-logo`);
    }

    deleteAgencyLargeIcon(agencyID) {
      return super.delete(`api/v1/company/${agencyID}/delete-company-large-logotype`);
   }

    createAgency(data) {
        return super.post('api/v1/company', data);
    }

    postAgencyIconLogo(agencyID, data) {
        return super.post(`api/v1/company/${agencyID}/change-company-icon-logo`, data);
    }

    postAgencyLargeIcon(agencyID, data) {
        return super.post(`api/v1/company/${agencyID}/change-company-large-logotype`, data);
    }

    addUsersToAgency(agencyID, data) {
        return super.post(`api/v1/company/${agencyID}/add-users`, data);
    }

    editAgencySetting(agencyID, data) {
      return super.put(`api/v1/company/${agencyID}`, data);
   }
}