import dots from "../../../assets/images/symbols/three_dots_white.svg";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import useOptions from "../../../hooks/useOptions";
import styles from './styles.module.scss';
import cn from 'classnames';

const TeamBodyItem = ({ row, columns, index, onDeleteFromTeam, onDeleteFromPlatform, isAccess, onRemoveAccess, redirectToUserProfileAdminView, lastItemRef, data, t }) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   return (
      <tr ref={data?.length - 1 === index ? lastItemRef : null } key={`row-${index}`} className={index % 2 === 0 ? styles.rowOdd : ""}>
         {columns.map((column, columnIndex) => (
            <td key={`cell-${index}-${columnIndex}`}>
               <div className={cn(styles.cellContent)}>
                  <CustomTooltip
                     id={`cell-${index}-${columnIndex}`}
                     limit={isAccess ? 80 : 22}
                     text={row[column]}
                  />
               </div>
            </td>
         ))}
         
            <td className={styles.option_wrapper}>
               <img
                  ref={dotsRef}
                  onClick={() => toggleOptions()}
                  src={dots}
                  alt={"options"}
                  />

               {isOptionsOpen && !isAccess &&(
                  <div ref={dropdownRef} className={styles.dropdown}>
                     <p className={styles.options} onClick={() => redirectToUserProfileAdminView(row?.id)}>
                        Profile
                     </p>
                     <p className={styles.options} onClick={() => {
                        closeOptions();
                        onDeleteFromTeam(row?.id)
                     }}>
                        {t("Delete from team")}
                     </p>
                     <p className={styles.options} onClick={() => {
                        closeOptions();
                        onDeleteFromPlatform(row?.id);
                     }}>
                        {t("Block user")}
                     </p>
                  </div>
               )}
               {isOptionsOpen && isAccess &&  (
                  <div ref={dropdownRef} className={styles.dropdown}>
                     <p className={styles.options} onClick={() => {
                        closeOptions();
                       if(onRemoveAccess) onRemoveAccess(row?.id);
                     }}>
                        {t("Remove access")}
                     </p>
                  </div>
               )}
            </td>
      </tr>
   );
};

export default TeamBodyItem;
