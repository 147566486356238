import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import crossImg from "../../../assets/images/symbols/cross_grey.svg";
import arrowImg from "../../../assets/images/symbols/arrow.svg";

import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

import styles from "./styles.module.scss";
import cn from "classnames";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const StatisticsProgressFilter = ({ maxHeight, onConfirm, progressRange }) => {
   const [isOpen, setIsOpen] = useState(false);
   const [progressFrom, setProgressFrom] = useState("");
   const [progressTo, setProgressTo] = useState("");
   const dropdownRef = useRef();
   const inputRef = useRef();

   const t = useFindTranslationsByString();


   const onClose = () => {
      setIsOpen(false);
   };

   const onToggle = (e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
   };

   const onChangeValue = (val) => onChange(val);

   useOutsideClick(dropdownRef, onClose, inputRef);

   return (
      <div
         className={cn(styles.filter, isOpen ? styles.opened : "")}
         onClick={onToggle}
         ref={inputRef}
      >
         <div className={cn(styles.click_el, "noselect")}>
            {progressRange?.length === 0 && t("Progress")}
            {progressRange?.length > 0 && (
               <span>{progressRange.substring(0, 7)}</span>
            )}

            {progressRange?.length > 0 && (
               <img
                  onClick={(e) => {
                     e.stopPropagation();
                     onConfirm("");
                     setProgressFrom("");
                     setProgressTo("");
                  }}
                  className={styles.cross}
                  src={crossImg}
                  alt=""
               />
            )}

            <img
               className={cn(styles.arrow, isOpen ? styles.arrowOpened : "")}
               src={arrowImg}
               alt=""
            />
         </div>

         {isOpen && (
            <div
               onClick={(e) => {
                  e.stopPropagation();
               }}
               style={{ maxHeight: maxHeight ? maxHeight : "220px" }}
               className={cn(styles.dropdown)}
            >
               <div className={styles.dropdown_input}>
                  <Input
                     placeholder={"From"}
                     value={progressFrom}
                     onChangeValue={(e) => {
                        let newValue = e.target.value.replace(/\D/g, ''); 
                        if (newValue !== '' && parseInt(newValue) > 100) {
                           newValue = '100';
                        }
                        setProgressFrom(newValue.slice(0, 3));
                     }}
                     isDefaultColor
                     type="number"
                  />
               </div>
               <div className={styles.dropdown_input}>
                  <Input
                     placeholder={"To"}
                     value={progressTo}
                     onChangeValue={(e) => {
                        let newValue = e.target.value.replace(/\D/g, ''); 
                        if (newValue !== '' && parseInt(newValue) > 100) {
                           newValue = '100';
                        }
                        setProgressTo(newValue.slice(0, 3));
                     }}
                     
                     isDefaultColor
                     type="number"
                  />
               </div>

               <div className={styles.confirmBtn}>
                  <Button
                     disabled={progressFrom === "" || progressTo === ""}
                     title={"Apply"}
                     onClick={(e) => {
                        const range = `${progressFrom}-${progressTo}`;
                        onConfirm(range);
                        onClose();
                     }}
                  />
               </div>
            </div>
         )}
      </div>
   );
};

export default StatisticsProgressFilter;
