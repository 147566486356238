import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/path";
import alertImage from "../../../assets/images/alert_image.png";
import Loader from "../../Loader/Loader";
import useFindTranslations from "../../../hooks/useFindTranlsations";

const ChooseAdminAsStudentModal = ({
   onClose,
   onStudent,
   onAdmin,
   isOpen,
   title,
   isRemove,
   isLoading,
   subtitle,
}) => {
   const navigate = useNavigate();

   const t = useFindTranslations();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={"340px"}
         maxWidth={"500px"}
         withCross
      >
         <div className={styles.confirm_wrapper}>
            <img className={styles.alert_icon} src={alertImage} alt="" />
            <div className={styles.title}>
               {title}
               {isRemove && (
                  <div className={styles.info_msg}>
                     {t?.youWontBeAbleToRestoreIt}
                  </div>
               )}
            </div>

            <div className={styles.subtitle}>{subtitle}</div>

            <div className={styles.btns}>
               <Button title={t?.asInitialUserRole} onClick={onAdmin} isBlack />
               <Button
                  disabled={isLoading}
                  title={t?.addAsStudent}
                  onClick={onStudent}
               />
            </div>

            <div className="default_loader_wrapper">
               {isLoading && <Loader size={"small"} />}
            </div>
         </div>
      </ModalLayout>
   );
};

export default ChooseAdminAsStudentModal;
