import { useState, useEffect } from "react";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { DEFAULT_DATA_LIMIT, SMALLER_DATA_LIMIT, BIGGER_DATA_LIMIT } from "../../constants/user";
import CourseApi from "../../utils/api/CourseApi";
import moment from "moment";

const initialMapCourses = (item) => ({
   id: item?.id,
   name: item?.name || "",
   title: item?.name || "",
   description: item?.description || "",
   tagList: item?.tags || [],
   isInFolder: !!item?.folder?.id,
   folder: item?.folders?.[0]?.name || '-',
   status: item?.status,
   createdAt: item?.createdAt,
   was: moment(item?.createdAt).format('YYYY-MM-DD') || '-',
   progress: "0%",
});

const useGetCoursesOfUserOrTeam = (
   companyId,
   itemId,
   isTeam,
   isExcludedCourses,
   isYourAvailableCourses,
   serializationGroup
) => {
   const [isCoursesLoading, setIsCoursesLoading] = useState(true);
   const [searchedValue, setSearchedValue] = useState("");
   const [courseList, setCourseList] = useState([]);
   const [totalCourses, setTotalCourses] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [selectAll, setSelectAll] = useState(false);

   const abortController = new AbortController();
   const signal = abortController.signal;

   const clearAllCourseFilters = () => {
      if (searchedValue?.length) searchedValue("");
   };

   const fetchCourses = async (page, limit) => {
      setIsCoursesLoading(true);

      try {
         const res = await new CourseApi().getCoursesOfUserOrTeam({
            limit: limit ? limit : (selectAll ? BIGGER_DATA_LIMIT : DEFAULT_DATA_LIMIT),
            page: page ? page : currentPage,
            companyId,
            itemId,
            isTeam,
            searchedValue,
            isExcludedCourses,
            serializationGroup,
            isYourAvailableCourses,
            signal
         });

         if (res?.success?.data) {
            const courses = res?.success?.data?.map(initialMapCourses) || [];

            if (page) {
               setCourseList(courses);
            } else {
               setCourseList((prev) => [...prev, ...courses]);
            }

            setTotalCourses(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setCourseList([]);
         }
      } catch (error) {
         console.error("Error fetching courses:", error);
      } finally {
         setIsCoursesLoading(false);
      }
   };

   const debouncedFetchCourses = debounce(
      () => fetchCourses(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchCourses = () => {
      debouncedFetchCourses();
      setCurrentPage(1);
   };

   useEffect(() => {
      if (currentPage > 1 && courseList?.length <= totalCourses) {
         fetchCourses();
      }
   }, [currentPage]);

   useEffect(()=> {
      return () => {
         abortController.abort();
         setCourseList([]);
      }
   }, [])

   useEffect(() => {
      if (companyId && itemId) {
         setCurrentPage(1);
         debouncedFetchCourses();
      }

      return () => debouncedFetchCourses.cancel();
   }, [
      searchedValue,
      companyId,
   ]);

   useEffect(() => {
      if (selectAll && courseList?.length !== totalCourses && courseList?.length < BIGGER_DATA_LIMIT) {
         setCurrentPage(1);
         debouncedFetchCourses();
      }

      return () => debouncedFetchCourses.cancel();
   }, [selectAll]);

   return {
      isCoursesLoading,
      courseList,
      setCourseList,
      totalCourses,
      currentPage,
      debouncedFetchCourses,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      setTotalCourses,
      refetchCourses,
      clearAllCourseFilters,
      selectAll,
      setSelectAll,
      fetchCourses
   };
};

export default useGetCoursesOfUserOrTeam;
