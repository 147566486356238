import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import FolderApi from "../../../utils/api/FolderApi";

import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import {
   TOPICS_HEADERS,
   COURSES_INSIDE_FOLDER_HEADERS,
} from "../../../constants/folders";

import { FOLDER_STATUS_ACTIVE, FOLDER_STATUS_DELETED, FOLDER_STATUS_HIDEN } from "../../../constants/courses";

import TabSubheader from "./TabSubheader/TabSubheader";
import CoursesOrTopicsTable from "./CoursesOrTopicsTable/CoursesOrTopicsTable";

import useUserRole from "../../../hooks/useUserRole";
import useGetYourCoursesInCompany from "../../../hooks/api/useGetYourCoursesInCompany";
import useGetTopics from "../../../hooks/api/userGetTopics";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useChangeFolder from "../../../hooks/useChangeFolder";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

import SelectCourseModal from "../../../components/Modals/SelectCourseModal/SelectCourseModal";
import AddFolderModal from "../../../components/Modals/AddFolderModal/AddFolderModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import RenameModal from "../../../components/Modals/RenameModal/RenameModal";

import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";

import styles from "./styles.module.scss";
import cn from "classnames";

const CoursesAndTopicsTab = ({
   coursesManager,
   foldersList,
   lastFolderRef,
   lastCourseInFolderRef,
   foldersLoading,
   currentCompanyId,
   isUserAsStudentAndNotSuperAdmin,
   isExcludeCoursesWhereAdminAsStudent,
   refetchFolders,
   refetchFolder,
   updateFolderManager,
   t
}) => {
   const params = useParams();
   const currentFolderOrTopicId = params?.topicId ? params?.topicId : params.id;

   const handleAddCourseModal = useHandleModal();
   const handleAddTopicCourseModal = useHandleModal();
   const handleCreateTopicModal = useHandleModal();
   const confirmDeleteFromFolderModal = useHandleModal();
   const renameModal = useHandleModal();

   const [selectedTopicCourses, setSelectedTopicCourses] = useState([]);
   const [selectedCourses, setSelectedCourses] = useState([]);
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   const [clickdedCourse, setClickdedCourse] = useState({});
   const [clickdedTopic, setClickdedTopic] = useState({});
   const [newTopicName, setNewTopicNamae] = useState('');
   
   const [isAddLoading, setIsAddLoading] = useState(false);

   const onOpenDeleteCourseFromFolderModal = (id) => {
      confirmDeleteFromFolderModal.open();
      setClickdedCourse(id)
   }

   const onOpenRenameModal = (id) => {
      renameModal.open();
      setClickdedTopic(id)
   }

   const renameTopic = async () => {
      setIsChangeLoading(true);
      const data = {
         new_name: newTopicName,
      };

      const res = await new FolderApi().editFolderSetting(clickdedTopic, data);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchFolders();
         renameModal.close();
         setNewTopicNamae("");
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsChangeLoading(false);
   }

   const onRemoveSelectedTopicCourses = (id) =>
      setSelectedTopicCourses(
         selectedTopicCourses?.filter((item) => item?.id !== id)
      );

   const createFolderSuccessCallback = () => {
      refetchFolders();
      coursesManager.setSearchedValue("");
      handleCreateTopicModal.close();
   };

   const { 
      onFolderHideToggle,
      onConfirmDeleteOpen,
      onFolderDeleteToggle,
      onConfirmOpenChangeHidenStatus,
      deleteFolder,
      confirmModalReturnBack,
      onConfirmOpenRestore: onConfirmOpenRestoreF,
      confirmDeleteModal: confirmDeleteModalF,
      confirmModal: confirmModalF,
      confirmHideModal: confirmHideModalF,
      confirmModalRestore: confirmModalRestoreF,
      isChangeLoading: isChangeLoadingF,
      onConfirmHideOpen: onConfirmHideOpenF,
      onConfirmOpenDelete: onConfirmOpenDeleteF,
    } = useChangeFolder(refetchFolders);

   // all your courses apart from that one in folder
   const {
      courseList,
      isCoursesLoading,
      refetchCourses,
      totalCourses,
      setCurrentPage,
      currentPage,
      clearAllCourseFilters,
      searchedValue,
      setSearchedValue,
      selectAll,
      setSelectAll,
   } = useGetYourCoursesInCompany(
      currentCompanyId,
      isUserAsStudentAndNotSuperAdmin,
      false,
      currentFolderOrTopicId,
      isExcludeCoursesWhereAdminAsStudent
   );

   const lastCourseRef = useIntersectionObserver(() => {
      if (!isCoursesLoading && courseList?.length < totalCourses) {
         if (courseList?.length === 0) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      }
   }, [isCoursesLoading, totalCourses]);

   const [activeTab, setActiveTab] = useState(
      params?.topicId ? "courses" : "topics"
   );

   const handleTabChange = (tab) => {
      setActiveTab(tab);
   };

   useEffect(() => {
      coursesManager?.setSearchedValue("");
      coursesManager?.setActiveCourseSortFilter("");
      coursesManager?.setActiveCourseFilter("");
   }, [activeTab]);

   const onAddCourse = async () => {
      setIsAddLoading(true);
      const res = await new FolderApi().addCoursesToFolder(
         currentFolderOrTopicId,
         {
            courses: selectedCourses?.map((item) => item?.id),
         }
      );

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         setSelectedCourses([]);
         coursesManager.refetchCourses();
         updateFolderManager.onUpdateFolderMaterialsForUsers(selectedCourses, true);
         updateFolderManager.refetchAll();
         refetchFolder();
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      handleAddCourseModal.close();
      setIsAddLoading(false);
   };

   const onCourseDeleteFromFolder = async () => {
      setIsChangeLoading(true);
      const res = await new FolderApi().removeCourseFromFolder(currentFolderOrTopicId, clickdedCourse);
      
      confirmDeleteFromFolderModal.close();

      setIsChangeLoading(false);
      
      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         coursesManager.refetchCourses();
         updateFolderManager.onUpdateFolderMaterialsForUsers(clickdedCourse, false);
         updateFolderManager.refetchAll();
      }
   };

   const courseListWithoutThatOneInFolder = courseList?.filter((course) =>
      course?.folders?.every((folder) => folder?.id !== +currentFolderOrTopicId)
   );

   return (
      <div translate="no" className={styles.coursesAndTopicsTab}>
         <div className={styles.content}>
            <div className={styles.tab_navigation}>
               {!params?.topicId && (
                  <button
                     key={"topics"}
                     className={cn(
                        styles.tab,
                        activeTab === "topics" && styles.activeTab
                     )}
                     onClick={() => handleTabChange("topics")}
                  >
                     {t?.topics}
                  </button>
               )}
               <button
                  key={"courses"}
                  className={cn(
                     styles.tab,
                     activeTab === "courses" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("courses")}
               >
                  {t?.courses}
               </button>
            </div>

            <div className={styles.wrapper}>
               {activeTab === "topics" && (
                  <div className={styles.table_area}>
                     <TabSubheader
                        activeStatusFilter={coursesManager?.activeCourseFilter}
                        setActiveStatusFilter={
                           coursesManager?.setActiveCourseFilter
                        }
                        searchedValue={coursesManager?.searchedValue}
                        setSearchedValue={coursesManager?.setSearchedValue}
                        activeSortFilter={
                           coursesManager?.activeCourseSortFilter
                        }
                        setActiveSortFilter={
                           coursesManager?.setActiveCourseSortFilter
                        }
                        onClickCreateTopic={handleCreateTopicModal.open}
                        t={t}
                     />
                     <div
                        className={cn(
                           styles.tab_table,
                           foldersList && foldersList?.length > 6
                              ? styles.scroll
                              : ""
                        )}
                     >
                        <CoursesOrTopicsTable
                           key={foldersList}
                           columns={TOPICS_HEADERS}
                           data={foldersList}
                           lastItemRef={lastFolderRef}
                           loading={foldersLoading}
                           onConfirmDeleteOpen={onConfirmDeleteOpen}
                           onConfirmHideOpen={onConfirmHideOpenF}
                           onConfirmOpenRestore={onConfirmOpenRestoreF}
                           onConfirmOpenDelete={onConfirmOpenDeleteF}
                           onConfirmOpenChangeHidenStatus={onConfirmOpenChangeHidenStatus}
                           onOpenRenameModal={onOpenRenameModal}
                           activeStatusFilter={coursesManager.activeCourseFilter}
                           t={t}
                        />
                     </div>
                  </div>
               )}

               {activeTab === "courses" && (
                  <div className={styles.table_area}>
                     <TabSubheader
                        activeStatusFilter={coursesManager?.activeCourseFilter}
                        setActiveStatusFilter={
                           coursesManager?.setActiveCourseFilter
                        }
                        isCourses
                        searchedValue={coursesManager?.searchedValue}
                        setSearchedValue={coursesManager?.setSearchedValue}
                        activeSortFilter={
                           coursesManager?.activeCourseSortFilter
                        }
                        setActiveSortFilter={
                           coursesManager?.setActiveCourseSortFilter
                        }
                        onClickPlus={handleAddCourseModal.open}
                        t={t}
                     />
                     <div
                        className={cn(
                           styles.tab_table,
                           coursesManager?.courseList &&
                              coursesManager?.courseList?.length > 6
                              ? styles.scroll
                              : ""
                        )}
                     >
                        <CoursesOrTopicsTable
                           isCourses
                           key={coursesManager?.courseList}
                           columns={COURSES_INSIDE_FOLDER_HEADERS}
                           data={coursesManager?.courseList}
                           lastItemRef={lastCourseInFolderRef}
                           loading={coursesManager?.isCoursesLoading}
                           confirmDeleteFromFolderModalOpen={onOpenDeleteCourseFromFolderModal}
                           t={t}
                        />
                     </div>
                  </div>
               )}
            </div>

            {/* Create a topic */}
            <AddFolderModal
               isTopic
               selectedCourses={selectedTopicCourses}
               setSelectedCourses={setSelectedTopicCourses}
               onOpenSelectCourseModal={() => {
                  handleAddTopicCourseModal.open();
                  handleCreateTopicModal.close();
                  setSearchedValue("");
               }}
               isOpen={handleCreateTopicModal.isActive}
               onClose={() => {
                  handleCreateTopicModal.close();
                  setSearchedValue("");
               }}
               createSuccessCallback={createFolderSuccessCallback}
               coursesList={courseList}
               onRemove={onRemoveSelectedTopicCourses}
               extraUsers={updateFolderManager.users}
            />

            <SelectCourseModal
               onClose={() => {
                  handleAddTopicCourseModal.close();
                  handleCreateTopicModal.open();
               }}
               isOpen={handleAddTopicCourseModal.isActive}
               selectedCourses={selectedTopicCourses}
               setSelectedCourses={setSelectedTopicCourses}
               coursesList={courseList}
               lastCourseRef={lastCourseRef}
               selectAll={selectAll}
               setSelectAll={setSelectAll}
               coursesLoading={isCoursesLoading}
               searchedValue={searchedValue}
               setSearchedValue={setSearchedValue}
            />

            {/* Topics change status */}
            <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteFromTheBin}
               onConfirm={deleteFolder}
               onClose={confirmDeleteModalF.close}
               isOpen={confirmDeleteModalF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
               subtitle={t?.youWillNotBeAbleToChangeYourChoice}
            /> 

            <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteText}
               onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_DELETED)}
               onClose={confirmModalF.close}
               isOpen={confirmModalF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.hideText}
               onConfirm={() => onFolderHideToggle(FOLDER_STATUS_HIDEN)}
               onClose={confirmHideModalF.close}
               isOpen={confirmHideModalF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.restore}
               onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_ACTIVE)}
               onClose={confirmModalRestoreF.close}
               isOpen={confirmModalRestoreF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.show}
               onConfirm={() => onFolderHideToggle(FOLDER_STATUS_ACTIVE)}
               onClose={confirmModalReturnBack.close}
               isOpen={confirmModalReturnBack.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            <RenameModal
               key={t?.newName}
               inputValue={newTopicName}
               setInputValue={setNewTopicNamae}
               onClose={renameModal.close}
               isOpen={renameModal.isActive}
               onConfirm={renameTopic}
               isLoading={isChangeLoading}
               title={t?.renameTopic}
            />
 
            { /* Select a course to add to the folder/topic */ }
            <SelectCourseModal
               onClose={() => {
                  handleAddCourseModal.close();
                  setSelectedCourses([]);
                  setSelectAll(false);
               }}
               isOpen={handleAddCourseModal.isActive}
               setSelectedCourses={setSelectedCourses}
               selectedCourses={selectedCourses}
               onAddCourse={onAddCourse}
               isDataLoading={isAddLoading || isCoursesLoading}
               coursesList={courseListWithoutThatOneInFolder}
               lastCourseRef={lastCourseRef}
               searchedValue={searchedValue}
               setSearchedValue={setSearchedValue}
               selectAll={selectAll}
               setSelectAll={setSelectAll}
               coursesLoading={isCoursesLoading}
            />

            <ConfirmModal
               confirmButtonText={t?.deleteText}
               onConfirm={onCourseDeleteFromFolder}
               onClose={confirmDeleteFromFolderModal.close}
               isOpen={confirmDeleteFromFolderModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />
         </div>
      </div>
   );
};

export default CoursesAndTopicsTab;
