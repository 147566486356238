import { useSelector } from "react-redux";
import { DEFAULT_TEXT } from "../constants/languages";

const useFindTranslations = () => {
   const translations = useSelector((state) => state.sidebar.translations);

   const returnTranslation = (text) => {
      const normalizedText = String(text).toLowerCase();
    
      const item = translations?.find((item) =>
        item?.originalValue?.toLowerCase() === normalizedText
      );
    
      return item?.translatedValue ? item.translatedValue : text;
    };

   const texts = {
      returnTranslation,
      // Login page text
      email: returnTranslation(DEFAULT_TEXT.authorization.email),
      password: returnTranslation(DEFAULT_TEXT.authorization.password),
      stayLogged: returnTranslation(DEFAULT_TEXT.authorization.stayLogged),
      forgotPassword: returnTranslation(DEFAULT_TEXT.authorization.forgotPassword),
      signIn: returnTranslation(DEFAULT_TEXT.authorization.signIn),
      changedMind: returnTranslation(DEFAULT_TEXT.authorization.changedMind),
      goBack: returnTranslation(DEFAULT_TEXT.authorization.goBack),
      send: returnTranslation(DEFAULT_TEXT.authorization.send),
      // Home page + some common
      hey: returnTranslation(DEFAULT_TEXT.home.greeting),
      recentTestsResults: returnTranslation(DEFAULT_TEXT.home.recentTestsResults),
      user: returnTranslation(DEFAULT_TEXT.common.user),
      users: returnTranslation(DEFAULT_TEXT.common.users),
      teams: returnTranslation(DEFAULT_TEXT.common.teams),
      coursesRatio: returnTranslation(DEFAULT_TEXT.home.coursesRatio),
      myFolders: returnTranslation(DEFAULT_TEXT.home.myFolders),
      myCourses: returnTranslation(DEFAULT_TEXT.home.myCourses),
      ongoing: returnTranslation(DEFAULT_TEXT.common.ongoing),
      completed: returnTranslation(DEFAULT_TEXT.common.completed),
      failed: returnTranslation(DEFAULT_TEXT.common.failed),
      notStarted: returnTranslation(DEFAULT_TEXT.common.notStarted),
      certificates: returnTranslation(DEFAULT_TEXT.common.certificates),
      courses: returnTranslation(DEFAULT_TEXT.common.courses),
      folders: returnTranslation(DEFAULT_TEXT.common.folders),
      topics: returnTranslation(DEFAULT_TEXT.common.topics),
      folder: returnTranslation(DEFAULT_TEXT.common.folder),
      noAvailableCourses: returnTranslation(DEFAULT_TEXT.common.noAvailableCourses),
      areYouSure: returnTranslation(DEFAULT_TEXT.modals.areYouSure),
      youWontBeAbleToRestoreIt: returnTranslation(DEFAULT_TEXT.modals.youWontBeAbleToRestoreIt),
      restore: returnTranslation(DEFAULT_TEXT.common.restore),
      confirm: returnTranslation(DEFAULT_TEXT.common.confirm),
      courseWasPaused: returnTranslation(DEFAULT_TEXT.modals.courseWasPaused),
      deleteText: returnTranslation(DEFAULT_TEXT.common.delete),
      hideText: returnTranslation(DEFAULT_TEXT.common.hide),
      openCourse: returnTranslation(DEFAULT_TEXT.common.openCourse),
      youCannotContinueLearning: returnTranslation(DEFAULT_TEXT.common.youCannotContinueLearning),
      // Courses and folders + common
      showAsGrid: returnTranslation(DEFAULT_TEXT.courses_and_folders.showAsGrid),
      showAsList: returnTranslation(DEFAULT_TEXT.courses_and_folders.showAsList),
      filter: returnTranslation(DEFAULT_TEXT.common.filter),
      hidden: returnTranslation(DEFAULT_TEXT.common.hidden),
      deletedText: returnTranslation(DEFAULT_TEXT.common.deleted),
      addCourses: returnTranslation(DEFAULT_TEXT.common.addCourses),
      createTopic: returnTranslation(DEFAULT_TEXT.common.createTopic),
      searchFolderName: returnTranslation(DEFAULT_TEXT.courses_and_folders.searchFolderName),
      searchCourseNameTags: returnTranslation(DEFAULT_TEXT.courses_and_folders.searchCourseNameTags),
      createNewCourse: returnTranslation(DEFAULT_TEXT.modals.createNewCourse),
      createNewFolder: returnTranslation(DEFAULT_TEXT.modals.createNewFolder),
      createNewTopic: returnTranslation(DEFAULT_TEXT.modals.createNewTopic),
      allTopics: returnTranslation(DEFAULT_TEXT.courses_and_folders.allTopics),
      allTags: returnTranslation(DEFAULT_TEXT.courses_and_folders.allTags),
      goToSettingsPage: returnTranslation(DEFAULT_TEXT.common.goToSettingsPage),
      apply: returnTranslation(DEFAULT_TEXT.common.apply),
      all: returnTranslation(DEFAULT_TEXT.courses_and_folders.all),
      byFolders: returnTranslation(DEFAULT_TEXT.courses_and_folders.byFolders),
      languages: returnTranslation(DEFAULT_TEXT.common.languages),
      date: returnTranslation(DEFAULT_TEXT.common.date),
      selectMainLanguage: returnTranslation(DEFAULT_TEXT.common.selectMainLanguage),
      selectAvailableLanguages: returnTranslation(DEFAULT_TEXT.common.selectAvailableLanguages),
      description: returnTranslation(DEFAULT_TEXT.common.description),
      title: returnTranslation(DEFAULT_TEXT.common.title),
      chooseCourses: returnTranslation(DEFAULT_TEXT.courses_and_folders.chooseCourses),
      next: returnTranslation(DEFAULT_TEXT.common.next),
      colourPresets: returnTranslation(DEFAULT_TEXT.common.colourPresets),
      addAccess: returnTranslation(DEFAULT_TEXT.common.addAccess),
      selectCourse: returnTranslation(DEFAULT_TEXT.common.selectCourse),
      selectAll: returnTranslation(DEFAULT_TEXT.common.selectAll),
      selectCompany: returnTranslation(DEFAULT_TEXT.common.selectCompany),
      selectTeam: returnTranslation(DEFAULT_TEXT.common.selectTeam),
      selectUser: returnTranslation(DEFAULT_TEXT.common.selectUser),
      company: returnTranslation(DEFAULT_TEXT.common.company),
      selected: returnTranslation(DEFAULT_TEXT.common.selected),
      search: returnTranslation(DEFAULT_TEXT.common.search),
      create: returnTranslation(DEFAULT_TEXT.common.create),
      selectByDepartment: returnTranslation(DEFAULT_TEXT.common.selectByDepartment),
      selectByPosition: returnTranslation(DEFAULT_TEXT.common.selectByPosition),
      cancel: returnTranslation(DEFAULT_TEXT.common.cancel),
      thereAreNoData: returnTranslation(DEFAULT_TEXT.common.thereAreNoData),
      thereAreNoUsers: returnTranslation(DEFAULT_TEXT.common.thereAreNoUsers),
      addAsStudent: returnTranslation(DEFAULT_TEXT.common.addAsStudent),
      markedAsStudent: returnTranslation(DEFAULT_TEXT.common.markedAsStudent),
      coursesInsideTopic: returnTranslation(DEFAULT_TEXT.courses_and_folders.coursesInsideTopic),
      usersAccessCoursesFolder: returnTranslation(DEFAULT_TEXT.courses_and_folders.usersAccessCoursesFolder),
      usersThatHaveAccessToCourse: returnTranslation(DEFAULT_TEXT.courses_and_folders.usersThatHaveAccessToCourse),
      usersAccessCoursesTopic: returnTranslation(DEFAULT_TEXT.courses_and_folders.usersAccessCoursesTopic),
      coursesInsideFolder: returnTranslation(DEFAULT_TEXT.courses_and_folders.coursesInsideFolder),
      deleteFromTheBin: returnTranslation(DEFAULT_TEXT.common.deleteFromTheBin),
      retakeCourse: returnTranslation(DEFAULT_TEXT.common.retakeCourse),
      asInitialUserRole: returnTranslation(DEFAULT_TEXT.modals.asInitialUserRole),
      // folder and topic settings
      publish: returnTranslation(DEFAULT_TEXT.common.publish),
      organizeManageInformation: returnTranslation(DEFAULT_TEXT.folder_settings.organizeManageInformation),
      basicSettings: returnTranslation(DEFAULT_TEXT.common.basicSettings),
      preview: returnTranslation(DEFAULT_TEXT.common.preview),
      linkToTheFolder: returnTranslation(DEFAULT_TEXT.common.linkToTheFolder),
      linkToTheCourse: returnTranslation(DEFAULT_TEXT.common.linkToTheCourse),
      publishHideFolder: returnTranslation(DEFAULT_TEXT.folder_settings.publishHideFolder),
      accessToTheFolder: returnTranslation(DEFAULT_TEXT.common.accessToTheFolder),
      accessToTheTopic: returnTranslation(DEFAULT_TEXT.common.accessToTheTopic),
      topicsAndCourses: returnTranslation(DEFAULT_TEXT.common.topicsAndCourses),
      saveChanges: returnTranslation(DEFAULT_TEXT.common.saveChanges),
      numberOfCourses: returnTranslation(DEFAULT_TEXT.common.numberOfCourses),
      numberOfUsers: returnTranslation(DEFAULT_TEXT.common.numberOfUsers),
      name: returnTranslation(DEFAULT_TEXT.common.name),
      noAccess: returnTranslation(DEFAULT_TEXT.common.noAccess),
      wasNotOnline: returnTranslation(DEFAULT_TEXT.common.wasNotOnline),
      enterATitle: returnTranslation(DEFAULT_TEXT.common.enterATitle),
      enterADescription: returnTranslation(DEFAULT_TEXT.common.enterADescription),
      folderIcon: returnTranslation(DEFAULT_TEXT.common.folderIcon),
      copied: returnTranslation(DEFAULT_TEXT.common.copied),
      newName: returnTranslation(DEFAULT_TEXT.common.newName),
      renameTopic: returnTranslation(DEFAULT_TEXT.modals.renameTopic),
      deleteCourseFrom: returnTranslation(DEFAULT_TEXT.modals.deleteCourseFrom),
      createTopic: returnTranslation(DEFAULT_TEXT.common.createTopic),
      deleteFromFolder: returnTranslation(DEFAULT_TEXT.common.deleteFromFolder),
      delete: returnTranslation(DEFAULT_TEXT.common.delete),
      edit: returnTranslation(DEFAULT_TEXT.common.edit),
      hide: returnTranslation(DEFAULT_TEXT.common.hide),
      settings: returnTranslation(DEFAULT_TEXT.common.settings),
      rename: returnTranslation(DEFAULT_TEXT.common.rename),
      changeToVisible: returnTranslation(DEFAULT_TEXT.common.changeToVisible),
      blockUser: returnTranslation(DEFAULT_TEXT.common.blockUser),
      unblockUser: returnTranslation(DEFAULT_TEXT.common.unblockUser),
      youWillNotBeAbleToChangeYourChoice: returnTranslation(DEFAULT_TEXT.modals.youWillNotBeAbleToChangeYourChoice),
      selectTeam: returnTranslation(DEFAULT_TEXT.common.selectTeam),
      select: returnTranslation(DEFAULT_TEXT.common.select),
      profile: returnTranslation(DEFAULT_TEXT.common.profile),
      removeAccess: returnTranslation(DEFAULT_TEXT.common.removeAccess),
      // Knowledge check
      awaitingChecking: returnTranslation(DEFAULT_TEXT.common.awaitingChecking),
      checked: returnTranslation(DEFAULT_TEXT.common.checked),
      student: returnTranslation(DEFAULT_TEXT.common.student),
      status: returnTranslation(DEFAULT_TEXT.common.status),
      exportToCSV: returnTranslation(DEFAULT_TEXT.common.exportToCSV),
      selectDate: returnTranslation(DEFAULT_TEXT.common.selectDate),
      selectDateRange: returnTranslation(DEFAULT_TEXT.common.selectDateRange),
      // statistics
      usersInTotal: returnTranslation(DEFAULT_TEXT.statistics.usersInTotal),
      usersRegisteredInAll: returnTranslation(DEFAULT_TEXT.statistics.usersRegisteredInAll),
      activeUsersInAll: returnTranslation(DEFAULT_TEXT.statistics.activeUsersInAll),
      totalNumberOfUsers: returnTranslation(DEFAULT_TEXT.statistics.totalNumberOfUsers),
      usersRegistered: returnTranslation(DEFAULT_TEXT.statistics.usersRegistered),
      usersWhoHaveBeenOnline: returnTranslation(DEFAULT_TEXT.statistics.usersWhoHaveBeenOnline),
      countingCourses: returnTranslation(DEFAULT_TEXT.statistics.countingCourses),
      coursesInTotal: returnTranslation(DEFAULT_TEXT.statistics.coursesInTotal),
      resultsOfTasksInTheTests: returnTranslation(DEFAULT_TEXT.statistics.resultsOfTasksInTheTests),
      resultsOfTheTests: returnTranslation(DEFAULT_TEXT.statistics.resultsOfTheTests),
      numberOfCertificatesIssued: returnTranslation(DEFAULT_TEXT.statistics.numberOfCertificatesIssued),
      certificatesInTotal: returnTranslation(DEFAULT_TEXT.statistics.certificatesInTotal),
      successRatingAmongTheCompanies: returnTranslation(DEFAULT_TEXT.statistics.successRatingAmongTheCompanies),
      usersInCompany: returnTranslation(DEFAULT_TEXT.statistics.usersInCompany),
      usersRating: returnTranslation(DEFAULT_TEXT.statistics.usersRating),
      usersAverageScoreForAllCompletedCourses: returnTranslation(DEFAULT_TEXT.statistics.usersAverageScoreForAllCompletedCourses),
      averageScoreOfUsersThatHaveBeenPassedThisCourse: returnTranslation(DEFAULT_TEXT.statistics.averageScoreOfUsersThatHaveBeenPassedThisCourse),
      averageScoreForCompletedCourses: returnTranslation(DEFAULT_TEXT.statistics.averageScoreForCompletedCourses),
      averageScoreForAllCompletedTestsInACourse: returnTranslation(DEFAULT_TEXT.statistics.averageScoreForAllCompletedTestsInACourse),
      statistics: returnTranslation(DEFAULT_TEXT.statistics.statistics),
      // companies + common
      addCompany: returnTranslation(DEFAULT_TEXT.common.addCompany),
      companies: returnTranslation(DEFAULT_TEXT.companies.companies),
      switchCompany: returnTranslation(DEFAULT_TEXT.companies.switchCompany),
      createCompany: returnTranslation(DEFAULT_TEXT.companies.createCompany),
      nameOfCompany: returnTranslation(DEFAULT_TEXT.companies.nameOfCompany),
      uploadLogotypes: returnTranslation(DEFAULT_TEXT.companies.uploadLogotypes),
      security: returnTranslation(DEFAULT_TEXT.companies.security),
      disableContentSelection: returnTranslation(DEFAULT_TEXT.companies.disableContentSelection),
      studentsWillNotBeAbleToSelectContent: returnTranslation(DEFAULT_TEXT.companies.studentsWillNotBeAbleToSelectContent),
      strongPassword: returnTranslation(DEFAULT_TEXT.companies.strongPassword),
      pupilPasswordsMustBeAtLeast: returnTranslation(DEFAULT_TEXT.companies.pupilPasswordsMustBeAtLeast),
      termsOfUse: returnTranslation(DEFAULT_TEXT.companies.termsOfUse),
      checkTheTermsOfUseBox: returnTranslation(DEFAULT_TEXT.companies.checkTheTermsOfUseBox),
      usersAndRoles: returnTranslation(DEFAULT_TEXT.companies.usersAndRoles),
      deleteFromCompany: returnTranslation(DEFAULT_TEXT.companies.deleteFromCompany),
      settingUpSectionsWithinAgencies: returnTranslation(DEFAULT_TEXT.companies.settingUpSectionsWithinAgencies),
      add: returnTranslation(DEFAULT_TEXT.common.add),
      module: returnTranslation(DEFAULT_TEXT.common.module),
      somethingWentWrong: returnTranslation(DEFAULT_TEXT.common.somethingWentWrong),
      youAreOnCompany: returnTranslation(DEFAULT_TEXT.common.youAreOnCompany),
      loading: returnTranslation(DEFAULT_TEXT.common.loading),
      more: returnTranslation(DEFAULT_TEXT.common.more),
      checkRole: returnTranslation(DEFAULT_TEXT.common.checkRole),
      main: returnTranslation(DEFAULT_TEXT.common.main),
      uploadIconLogotype: returnTranslation(DEFAULT_TEXT.common.uploadIconLogotype),
      uploadLargeLogotype: returnTranslation(DEFAULT_TEXT.common.uploadLargeLogotype),
      allTranslatedInformationWillBeLost: returnTranslation(DEFAULT_TEXT.modals.allTranslatedInformationWillBeLost),
      uploadingFilesWillBeStopped: returnTranslation(DEFAULT_TEXT.modals.uploadingFilesWillBeStopped),
      platform: returnTranslation(DEFAULT_TEXT.common.platform),
      enterAName: returnTranslation(DEFAULT_TEXT.common.enterAName),
      choose: returnTranslation(DEFAULT_TEXT.common.choose),
      chooseRole: returnTranslation(DEFAULT_TEXT.modals.chooseRole),
      selectTimezone: returnTranslation(DEFAULT_TEXT.common.selectTimezone),
      addAccessToCompany: returnTranslation(DEFAULT_TEXT.common.addAccessToCompany),
      recommended: returnTranslation(DEFAULT_TEXT.course_settings.recommended),
      or: returnTranslation(DEFAULT_TEXT.common.or),
      to: returnTranslation(DEFAULT_TEXT.common.to),
      uploadNewImage: returnTranslation(DEFAULT_TEXT.profile.uploadNewImage),
      deleteImage: returnTranslation(DEFAULT_TEXT.common.deleteImage),
      enterEmail: returnTranslation(DEFAULT_TEXT.common.enterEmail),
      // user_rights 
      editRoleCapabilitiesAndPermissions: returnTranslation(DEFAULT_TEXT.user_rights.editRoleCapabilitiesAndPermissions),
      enterRoleName: returnTranslation(DEFAULT_TEXT.user_rights.enterRoleName),
      homework: returnTranslation(DEFAULT_TEXT.user_rights.homework),
      acceptedHomework: returnTranslation(DEFAULT_TEXT.user_rights.acceptedHomework),
      accessToAcceptedHomeworkSection: returnTranslation(DEFAULT_TEXT.user_rights.accessToAcceptedHomeworkSection),
      viewingLessons: returnTranslation(DEFAULT_TEXT.user_rights.viewingLessons),
      abilityToViewLessons: returnTranslation(DEFAULT_TEXT.user_rights.abilityToViewLessons),
      lessonModules: returnTranslation(DEFAULT_TEXT.user_rights.lessonModules),
      abilityToCustomizeAndConnectModules: returnTranslation(DEFAULT_TEXT.user_rights.abilityToCustomizeAndConnectModules),
      addingStudentToTeam: returnTranslation(DEFAULT_TEXT.user_rights.addingStudentToTeam),
      abilityToAddNewStudentManually: returnTranslation(DEFAULT_TEXT.user_rights.abilityToAddNewStudentManually),
      showStudentContactDetails: returnTranslation(DEFAULT_TEXT.user_rights.showStudentContactDetails),
      abilityToViewStudentContactDetails: returnTranslation(DEFAULT_TEXT.user_rights.abilityToViewStudentContactDetails),
      abilityToAddAndRemoveUsers: returnTranslation(DEFAULT_TEXT.user_rights.abilityToAddAndRemoveUsers),
      tags: returnTranslation(DEFAULT_TEXT.user_rights.tags),
      addingEditingDeletingTags: returnTranslation(DEFAULT_TEXT.user_rights.addingEditingDeletingTags),
      mainSettings: returnTranslation(DEFAULT_TEXT.user_rights.mainSettings),
      appearance: returnTranslation(DEFAULT_TEXT.user_rights.appearance),
      abilityToEditDescriptionAndPhoto: returnTranslation(DEFAULT_TEXT.user_rights.abilityToEditDescriptionAndPhoto),
      studentPermissions: returnTranslation(DEFAULT_TEXT.user_rights.studentPermissions),
      editingStudentPermissionsSettings: returnTranslation(DEFAULT_TEXT.user_rights.editingStudentPermissionsSettings),
      roleCapabilities: returnTranslation(DEFAULT_TEXT.user_rights.roleCapabilities),
      editRightsSettingsForAllRoles: returnTranslation(DEFAULT_TEXT.user_rights.editRightsSettingsForAllRoles),
      addRole: returnTranslation(DEFAULT_TEXT.user_rights.addRole),
      rights: returnTranslation(DEFAULT_TEXT.common.rights),
      copy: returnTranslation(DEFAULT_TEXT.common.copy),
      roles: returnTranslation(DEFAULT_TEXT.common.roles),
      students: returnTranslation(DEFAULT_TEXT.common.students),
      blocked: returnTranslation(DEFAULT_TEXT.common.blocked),
      addTeam: returnTranslation(DEFAULT_TEXT.common.addTeam),
      department: returnTranslation(DEFAULT_TEXT.common.department),
      position: returnTranslation(DEFAULT_TEXT.common.position),
      undefined: returnTranslation(DEFAULT_TEXT.common.undefined),
      addStudent: returnTranslation(DEFAULT_TEXT.common.addStudent),
      addSpecialUser: returnTranslation(DEFAULT_TEXT.common.addStudent),
      changeRole: returnTranslation(DEFAULT_TEXT.modals.changeRole),
      transitioningFromStudentToSpecialRole: returnTranslation(DEFAULT_TEXT.modals.transitioningFromStudentToSpecialRole),
      uploadFromCSV: returnTranslation(DEFAULT_TEXT.common.uploadFromCSV),
      surname: returnTranslation(DEFAULT_TEXT.common.surname),
      positionOptionally: returnTranslation(DEFAULT_TEXT.common.positionOptionally),
      creteNewPosition: returnTranslation(DEFAULT_TEXT.common.creteNewPosition),
      departmentOptionally: returnTranslation(DEFAULT_TEXT.common.departmentOptionally),
      creteNewDepartment: returnTranslation(DEFAULT_TEXT.common.creteNewDepartment),
      addStudents: returnTranslation(DEFAULT_TEXT.common.addStudents),
      createSpecialUser: returnTranslation(DEFAULT_TEXT.modals.createSpecialUser),
      continue: returnTranslation(DEFAULT_TEXT.common.continue),
      role: returnTranslation(DEFAULT_TEXT.common.role),
      previous: returnTranslation(DEFAULT_TEXT.common.previous),
      // language settings page
      selectLanguage: returnTranslation(DEFAULT_TEXT.common.selectLanguage),
      copyTable: returnTranslation(DEFAULT_TEXT.common.copyTable),
      default: returnTranslation(DEFAULT_TEXT.common.default),
      sections: returnTranslation(DEFAULT_TEXT.common.sections),
      returnToYourAccount: returnTranslation(DEFAULT_TEXT.common.returnToYourAccount),
   };
      
   return texts;
};

export default useFindTranslations;
