import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from "../../assets/images/symbols/arrow.svg";
import plus from "../../assets/images/symbols/plus.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import serchIcon from "../../assets/images/symbols/Search.svg";
import cn from "classnames";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import useFindTranslations from "../../hooks/useFindTranlsations";

const DropdownWithImg = ({
   data,
   placeholder,
   onChange,
   value,
   field = null,
   title = null,
   isWhiteBlack = false,
   createBtnText,
   onOpenCreateModal,
   searchNeeded,
   maxHeight,
   isError,
   lastItemRef,
   allCoursesSearchedValue,
   setAllCoursesSearchedValue,
   frontColor,
   checkDropdownStatus,
   limit,
   noNeedAllOption
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   
   const dropdownRef = useRef();
   const inputRef = useRef();
   const inputRefTop = useRef();

   const t = useFindTranslations();

   const onClose = () => {
      setIsOpen(false);
      if(checkDropdownStatus) checkDropdownStatus(false);
    } 
    
   const onToggle = () => {
      if(checkDropdownStatus) checkDropdownStatus(!isOpen);
      setIsOpen(!isOpen);
   } 
   const onChangeValue = (val) => onChange(val);

   useOutsideClick(dropdownRef, onClose, inputRef);

   const searchHandler = (list) => {
      return list?.filter((item) =>
         searchValue?.length
            ? item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
            : true
      );
   };

   const filteredData = searchHandler(data);

   useEffect(() => {
      if (!isOpen) setSearchValue("");
      if (isOpen) inputRefTop.current.focus();
   }, [isOpen]);

   return (
      <>
         {title && <p className={styles.title}>{title}</p>}
         <div
            className={cn(
               styles.dropdown_wrapper,
               value?.length && searchNeeded ? styles.value : "",
               frontColor ? styles.gray : ''
            )}
            onClick={onToggle}
            ref={inputRef}
         >
            {searchNeeded && isOpen && (
               <img className={styles.search} src={serchIcon} alt="" />
            )}
            {value?.logo && !isOpen && (
               <img
                  className={cn(styles.search, styles.logo)}
                  src={value?.logo}
                  alt=""
               />
            )}

            <Input
               isDefaultColor
               placeholder={value?.name || placeholder}
               ref={inputRefTop}
               customClass={cn(
                  isOpen ? styles.input_open : null,
                  styles.input,
                  (!isOpen && !value?.name) ||
                     (!isOpen && value?.name === "All")
                     ? styles.pl_0
                     : ""
               )}
               disabled={!isOpen}
               value={allCoursesSearchedValue ? allCoursesSearchedValue : searchValue}
               onChangeValue={(e) => {
                  if(setAllCoursesSearchedValue) {
                     setAllCoursesSearchedValue(e.target.value);
                  } else {
                     setSearchValue(e.target.value)
                  }
               }}
               isWhiteBlack={isWhiteBlack}
               isError={isError ? styles.error : ""}
            />

            <div className={styles.click_el}></div>

            <img
               src={isWhiteBlack ? arrowWhite : arrow}
               alt={""}
               className={cn(styles.arrow, isOpen && styles.arrow_open)}
            />

            {isOpen && (
               <div
                  style={{ maxHeight: maxHeight ? maxHeight : "300px" }}
                  className={cn(
                     styles.dropdown,
                     isWhiteBlack && styles.dropdown_isWhiteBlack,
                     createBtnText && styles.with_create_btn
                  )}
               >
                  {!noNeedAllOption && 
                       <div
                       key={"all"}
                       onClick={() => onChangeValue({ name: "All" })}
                       className={cn(
                          styles.dropdown_item,
                          styles.dropdown_item_active
                       )}
                    >
                       <div className={styles.user_info_wrapper}>
                          <div className={styles.right}>
                             <p>{t?.all}</p>
                          </div>
                       </div>
                    </div>
                  }
             
                  {filteredData?.map((item, key) => {
                     return (
                        <div
                         ref={lastItemRef && data?.length - 1 === key ? lastItemRef : null}
                           key={key}
                           onClick={() => onChangeValue(item)}
                           className={cn(
                              styles.dropdown_item,
                              item?.id === value?.id &&
                                 styles.dropdown_item_active
                           )}
                        >
                           <div className={styles.user_info_wrapper}>
                              {item?.logo && 
                                 <img
                                    className={styles.logo}
                                    src={item?.logo}
                                    alt=""
                                 />
                              }
                              <div className={styles.right}>
                                 <p>
                                    <CustomTooltip text={item?.name} id={item?.id} limit={limit ? limit : 20}/>
                                 </p>
                              </div>
                           </div>
                        </div>
                     );
                  })}
                  {!data?.length && (
                     <div className={styles.dropdown_item}>{t?.thereAreNoData}</div>
                  )}
                  {createBtnText && isOpen && (
                     <div
                        onClick={onOpenCreateModal}
                        className={styles.create_btn}
                     >
                        <img className={styles.plus} src={plus} alt="" />
                        {t?.returnTranslation(createBtnText)}
                     </div>
                  )}
               </div>
            )}
         </div>
      </>
   );
};

export default DropdownWithImg;
