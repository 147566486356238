import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import email from "../../../assets/images/email.svg";
import statusActive from "../../../assets/images/active.svg";
import statusPaused from "../../../assets/images/paused.svg";
import statusDeleted from "../../../assets/images/deleted.svg";
import Switch from "../../Switch/Switch";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import UserApi from "../../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { ROLE_ID_STUDENT } from "../../../constants/user";
import CourseApi from "../../../utils/api/CourseApi";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const CourseLanguageStatusModal = ({
   onClose,
   isOpen,
   item,
   refetchData,
   languages,
   courseId
}) => {
   const [status, setStatus] = useState(0);
   const [isChangeLoading, setIsChangeLoading] = useState(false);

   const t = useFindTranslationsByString();

   const params = useParams();

   const onChangeStatus = (id) => setStatus(id);

   const onSave = async () => {
      setIsChangeLoading(true);

      try {
         let new_langs = []
  
         if (status === 1 && languages?.length) {
            const langExist =  languages?.find(lang => lang === item?.code);

            if(langExist) {
               onClose();
               return;
            };

             new_langs = [...languages, item?.code];
         }  
         
         if (status === 0 && languages?.length) {
            new_langs = languages?.filter(lang => lang !== item?.code);
         }

         if(!languages?.length) {
            new_langs = [item?.code]
         }

         const data = {
            new_available_languages: new_langs,
         };

         const res = await new CourseApi().editCourseSetting(courseId, data);

         if (res?.success) {
            refetchData();
            return toast(<ToastMsg text={res?.success?.message} />);
         }
         if (res?.error?.message) {
            return toast(<ToastMsg text={res?.error?.message} isError />);
         }
      } catch (error) {
         console.error("Failed to update languge status:", error);
      } finally {
         setIsChangeLoading(false);
         onClose();
      }
   };

   useEffect(() => {
      setStatus(item?.status);
   }, [item?.status, isOpen]);

   return (
      <ModalLayout
         maxWidth={"506px"}
         isOpen={isOpen}
         onClose={onClose}
         maxHeight={"280px"}
         withCross
      >
         <div className={styles.list}>
            <div className={styles.status}>
               <div className={styles.status_wrapper}>
                  <img src={statusActive} alt={""} />
                  <div>
                     <p className={styles.status_title}>{t("Active")}</p>
                     <p className={styles.status_text}>
                        {t("The user can choose and take a course in this language")}
                     </p>
                  </div>
               </div>
               <Checkbox
                  isRound
                  isChecked={status === 1}
                  setIsChecked={() => onChangeStatus(1)}
               />
            </div>

            <div className={styles.status}>
               <div className={styles.status_wrapper}>
                  <img src={statusPaused} alt={""} />
                  <div>
                     <p className={styles.status_title}>{t("Paused")}</p>
                     <p className={styles.status_text}>
                        {t("The user cannot see and cannot take the course in this language")}
                     </p>
                  </div>
               </div>
               <Checkbox
                  isRound
                  isChecked={status === 0}
                  setIsChecked={() => onChangeStatus(0)}
               />
            </div>
         </div>

         <div className={styles.buttons}>
            <Button title={"Cancel"} onClick={onClose} isBlack />
            <Button
               disabled={isChangeLoading}
               title={t("Save changes")}
               onClick={onSave}
            />
         </div>

         {isChangeLoading && (
            <div className="default_loader_wrapper">
               <Loader size={"small"} />
            </div>
         )}
      </ModalLayout>
   );
};

export default CourseLanguageStatusModal;
