import styles from './styles.module.scss';
import TextArea from '../../../components/TextArea/TextArea';
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const CommentsBlock = ({ commentValue, onChangeComment }) => {

   const t = useFindTranslationsByString();
   
  return (
    <div className={styles.additional_info}>
      <h2>{t("Additional info")}</h2>
      <div className={styles.additional_info_titles}>
        <p>{t("Comments")}</p>
        <p>* {t("Visible only to admins")}</p>
      </div>
      <TextArea
        isResize
        minRows={4}
        placeholder={t("Enter the comments")}
        height={75}
        value={commentValue}
        onChangeValue={onChangeComment}
      />
    </div>
  );
};

export default CommentsBlock;
