export const transformRoleText = (text = '', companyName) => {
   const transformedText = text
      .toLowerCase()
      .replace(/_/g, " ")
      .replace("role", "");

   if (companyName) {
      return transformedText.trim().replace(companyName.toLowerCase(), "");
   } else {
      return transformedText.trim();
   }
};
