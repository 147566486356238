import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import ProfileTable from "../ProfileTable/ProfileTable";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";

const TableBlock = ({ headers, data, tableFor, loading, lastAgencyRef}) => {
   return (
      <div className={styles.table_block}>
         <div className={styles.table_wrapper}>
            <ProfileTable
               columns={headers}
               data={data}
               tableFor={tableFor}
               loading={loading}
               lastAgencyRef={lastAgencyRef}
            />
         </div>
      </div>
   );
};

export default TableBlock;
