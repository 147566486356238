
// ROLES
export const ROLE_EXTRA_GROUP_ONE = 'role:default+permission';

// USER TEST
export const TEST_EXTRA_GROUP_ONE = 'user_test:default+comment_test+comment_test_file+question+answer+user_test_question+pass_attempts_counter+user_answer';
export const TEST_EXTRA_GROUP_TWO = 'user_test:default+question+answer+user+user_answer';
export const TEST_PLUS_TEAM_EXTRA_GROUP_THREE = 'user_test:default+team';

// FOLDER
export const FOLDER_EXTRA_GROUP_ONE = 'folder:default+course';

// COMPANY
export const COMPANY_EXTRA_GROUP_ONE = 'company:default+company_security+branch+course+user_branch_role+user'
export const COMPANY_EXTRA_GROUP_TWO = 'company:default+company_security+branch'

// LESSON 
export const QUESTIONS_IN_LESSON_GROUP = 'question:default+answer+lesson'

// MODULE 
export const MODULES_EXTRA_GROUP = 'module:default+question'

// COURSE
export const COURSE_SETTINGS_GROUP = 'course:default+user_course+certificate_template+author'
export const COURSE_WITH_CERTIFICATE_GROUP = 'course:default+completed_user_course+user+certificate_template';
export const COURSE_WITH_CERTIFICATE_ONLY_GROUP = 'course:default+branch+company+certificate_template';
export const COURSE_COMPLETED_GROUP = 'course:default+user_course_result+user';
export const COURSE_PLUS_COMPANY_GROUP = 'course:default+branch+company';

// USER
export const USER_EXTRA_GROUP_ONE = 'user:default+user_course+course'
export const USER_EXTRA_GROUP_TWO = 'user:default_sensitive+team+user_course+course'
export const USER_EXTRA_GROUP_TEAM = 'user:default+team'

// TEAM
export const TEAM_DEFAULT_GROUP = 'team:default';
export const TEAM_EXTRA_GROUP = 'team:default+mentor+user+user_course+course+branch';
export const TEAM_EXTRA_GROUP_TABLE = 'team:default_sensitive+mentor+user+branch';
export const TEAM_EXTRA_GROUP_MENTOR_AND_BRANCH = 'team:default+mentor+user+branch';

// USER ANSWER
export const ANSWER_EXTRA_GROUP_ONE = 'user_answer:default+question'