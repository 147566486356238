import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/path";
import Input from "../../Input/Input";
import useFindTranslations from "../../../hooks/useFindTranlsations";

const RenameModal = ({
   onClose,
   onConfirm,
   isOpen,
   title,
   inputValue,
   setInputValue,
   isLoading
}) => {
   const navigate = useNavigate();

   const t = useFindTranslations();

   return (
      <ModalLayout
         onClose={()=> {
            onClose();
            setInputValue('');
         }}
         isOpen={isOpen}
         maxHeight={"257px"}
         maxWidth={"416px"}
         withCross
      >
         <div className={styles.confirm_wrapper}>
            <p className={styles.title}>{title}</p>
            <Input
               placeholder={`${t?.enterAName}...`}
               max={40}
               withHeader
               autoFocus
               title={t?.title}
               value={inputValue}
               onChangeValue={(event) =>
                  setInputValue(event?.target?.value)
               }
            />
            <div className={styles.btns}>
               <Button title={t?.cancel} onClick={onClose} isBlack />
               <Button disabled={isLoading || !inputValue?.trim()?.length} title={t?.saveChanges} onClick={onConfirm} />
            </div>
         </div>
      </ModalLayout>
   );
};

export default RenameModal;
