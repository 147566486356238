import { useEffect, useRef, useState } from "react";
import { AGENCY_SETTINGS_SUBMENU } from "../constants/agencies";

// Hook for agency settings page

const useAutoScroll = () => {
   const sectionRef = useRef(null);

   const basicBlockRef = useRef(null);
   const logoBlockRef = useRef(null);
   const endBlockRef = useRef(null);

   // FOR FUTURE
   // const domainBlockRef = useRef(null);
   // const sendEmailBlockRef = useRef(null);
   // const integrationBlockRef = useRef(null);

   const settingsRefs = [
      basicBlockRef,
      logoBlockRef,
      // FOR FUTURE
      // domainBlockRef,
      // sendEmailBlockRef,
      // integrationBlockRef,
      endBlockRef,
   ];

   const [activeElement, setActiveElement] = useState("basicInfo");

   const scrollToBlock = (itemId) => {
      sectionRef.current?.scrollTo({
         top: settingsRefs[itemId - 1].current.offsetTop - 150,
         behavior: "smooth",
      });
   };

   const handleListItemClick = (e, item) => {
      e.preventDefault();
      setActiveElement(item?.el);
      scrollToBlock(item?.id);
   };

   useEffect(() => {
      const handleScroll = () => {
         let activeItem = null;

         for (let i = 0; i < settingsRefs.length; i++) {
            const blockRect = settingsRefs[i].current.getBoundingClientRect();
            const blockInView =
               blockRect.top >= 0 && blockRect.top <= window.innerHeight * 0.8;

            if (blockInView) {
               // Find the element associated with the block
               activeItem = AGENCY_SETTINGS_SUBMENU.find(
                  (item) => item.id === i + 1
               );
               break;
            }
         }
         const isLastBlockInView = settingsRefs[settingsRefs.length - 1].current.getBoundingClientRect().bottom <= window.innerHeight;

         if (isLastBlockInView) {
            activeItem = AGENCY_SETTINGS_SUBMENU[AGENCY_SETTINGS_SUBMENU.length - 1];
         }

         setActiveElement(() => activeItem?.el);
      };

      sectionRef.current?.addEventListener("scroll", handleScroll);

      return () => {
         sectionRef.current?.removeEventListener("scroll", handleScroll);
      };
   }, []);

   return {
      activeElement,
      basicBlockRef,
      logoBlockRef,
      // FOR FUTURE
      // domainBlockRef,
      // sendEmailBlockRef,
      // integrationBlockRef,
      endBlockRef,
      sectionRef,
      handleListItemClick,
      setActiveElement
   };
};

export default useAutoScroll;
