import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import RightsSubheader from "./RightsSubheader/RightsSubheader";
import styles from "./styles.module.scss";
import RolesBlock from "./RolesBlock/RolesBlock";
import SettingItems from "./SettingItems/SettingItems";
import Roles from "../../utils/api/Roles";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import { ROLE_ID_GUEST, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, pageStaticPermissionsInfo } from "../../constants/user";
import { useSelector } from "react-redux";
import { ROLE_EXTRA_GROUP_ONE } from "../../utils/api/serialization_groups";
import useFindTranslations from "../../hooks/useFindTranlsations";

const UsersRights = () => {
   const [roles, setRoles] = useState([]);
   const [roleLoading, setRoleLoading] = useState(false);
   const [activeRole, setActiveRole] = useState(null);
   const [inputsData, setInputsData] = useState({});

   const companyId = useSelector(state => state.sidebar.companyId);
   const companyBranchId = useSelector(state => state.sidebar.currentCompanyBranchId);
   const userId = useSelector((state) => state?.user?.info?.id);

   const t = useFindTranslations()

   const getRoles = async () => {
      setRoleLoading(true);
      const res = await new Roles().getRoles(companyId, ROLE_EXTRA_GROUP_ONE);

      if (res?.success?.data) {
         const excludedRoleIds = [ROLE_ID_GUEST, ROLE_ID_STUDENT];
         const filteredRoles = res?.success?.data?.filter(role => !excludedRoleIds?.includes(role?.id));

         setRoles(filteredRoles);
      }
      setRoleLoading(false);
   };

   const updateRolePermissions = async () => {
      const data =  {
            permission_data: generatePermissionData()
        }

      const res = await new Roles().updateRolePermissions(activeRole.id, data);
        if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message}/>)
          getRoles();
        }
        if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
        }
   }

   const onSave = () => {
      updateRolePermissions();
   };

   const handleRoleClick = (role) => {
      setActiveRole(role);
   };

   useEffect(() => {
      if(companyBranchId) {
         getRoles();
      }
   }, [companyBranchId, userId]);

   useEffect(() => {
      if (activeRole) {
          const updatedInputsData = { ...inputsData };
  
          activeRole.permissions.forEach(permission => {
              pageStaticPermissionsInfo.forEach(staticPermission => {
                  if (staticPermission.permission_ids.includes(permission.id)) {
                      updatedInputsData[staticPermission.label] = true;
                  }
              });
          });
  
          // Set false for permissions not included in the activeRole
          pageStaticPermissionsInfo.forEach(staticPermission => {
              if (!activeRole.permissions.some(permission => staticPermission.permission_ids.includes(permission.id))) {
                  updatedInputsData[staticPermission.label] = false;
              }
          });
  
          setInputsData(updatedInputsData);
      }
  }, [activeRole]);

   const generatePermissionData = () => {
      const permissionData = [];
  
      pageStaticPermissionsInfo.forEach(permission => {
          const { label, permission_ids } = permission;
          const inputValue = inputsData[label]; 
          const addValue = inputValue ? true : false;
  
          permission_ids.forEach(id => {
              permissionData.push({ permission_id: id, add: addValue });
          });
      });
  
      return permissionData;
  };
  
   return (
      <MainLayout>
          {roleLoading && 
            <div className="default_loader_wrapper">
               <SecondLoader />
            </div>
         }
         <div className={styles.users_rights}>
            <div className={styles.roles_block}>
               <RolesBlock
                  roles={roles}
                  getRoles={getRoles}
                  activeRole={activeRole}
                  handleRoleClick={handleRoleClick}
                  generatePermissionData={generatePermissionData}
                  t={t}
               />
            </div>
            <div className={styles.content}>
               <RightsSubheader t={t}onSave={onSave} />
               {activeRole && 
                  <SettingItems
                     inputsData={inputsData}
                     setInputsData={setInputsData}
                     activeRole={activeRole}
                     t={t}
                  />
               }
            </div>
         </div>
      </MainLayout>
   );
};

export default UsersRights;
