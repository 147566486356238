import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import book from "../../assets/images/courses-fill.svg";
import person from "../../assets/images/profile-fil.svg";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import coursePlaceholderImg from "../../assets/images/course_thumbnail.png";
import {
   FOLDER_STATUS_DELETED,
   FOLDER_STATUS_HIDEN,
} from "../../constants/courses";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { useSelector } from "react-redux";
import {
   PART_OF_MENTOR_NAME,
   ROLE_ID_STUDENT,
} from "../../constants/user";
import cn from "classnames";
import CustomStaticTooltip from "../CustomStaticTooltip/CustomStaticTooltip";
import { v4 as uuidv4 } from "uuid";
import useUserRole from "../../hooks/useUserRole";
import { DEFAULT_TEXT, returnTranslation } from "../../constants/languages";
import DotsButton from "../DotsButton/DotsButton";

const TopicBlock = ({
   folder,
   topic,
   onConfirmDeleteOpen,
   onConfirmHideOpen,
   onConfirmOpenRestore,
   onConfirmOpenDelete,
   onConfirmOpenChangeHidenStatus,
   lastTopicRef,
   activeFilter,
   onRedirectToEditTopicPage
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const dotsRef = useRef();
   const dropdownRef = useRef();

   const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);
   const translations = useSelector((state) => state.sidebar.translations);

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const onCloseSettings = () => setIsOpen(false);

   useOutsideClick(dropdownRef, onCloseSettings, dotsRef);

   const navigate = useNavigate();

   const navigateToTopicPage = () => {
      navigate(`/courses/folder/${folder?.id}/topic/${topic?.id}`);
   };

   const onToggleSettings = (e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
   };

   const hideText = returnTranslation(translations, DEFAULT_TEXT.common.hide);
   const deleteText = returnTranslation(translations, DEFAULT_TEXT.common.delete);
   const editText = returnTranslation(translations, DEFAULT_TEXT.common.edit);
   const changeToVisible = returnTranslation(translations, DEFAULT_TEXT.common.changeToVisible);
   const restoreText = returnTranslation(translations, DEFAULT_TEXT.common.restore);
   const coursesInsideTopic = returnTranslation(translations, DEFAULT_TEXT.courses_and_folders.coursesInsideTopic);
   const usersAccessCoursesTopic = returnTranslation(translations, DEFAULT_TEXT.courses_and_folders.usersAccessCoursesTopic);

   return (
      <>
         <div
            ref={lastTopicRef}
            onClick={navigateToTopicPage}
            className={cn(styles.folderBlock, "noselect", topic?.status === FOLDER_STATUS_DELETED || topic.status === FOLDER_STATUS_HIDEN ? styles.hidden_layout : '')}
            style={{ background: `${folder?.folder}` }}
         >
            <div className={styles.topBlock}>
               {topic?.coursesWithUpdatedImages &&
               topic?.coursesWithUpdatedImages.length > 0
                  ? topic?.coursesWithUpdatedImages
                       .slice(0, 3)
                       .map((course) => (
                          <img
                             className={styles.course_img}
                             src={course.image || coursePlaceholderImg}
                             alt="Course Icon"
                             key={course.id}
                          />
                       ))
                  : null}
               {[
                  ...Array(
                     Math.max(
                        0,
                        3 - (topic?.coursesWithUpdatedImages?.length || 0)
                     )
                  ),
               ].map((_, index) => (
                  <div className={styles.no_course_image} key={index}></div>
               ))}
            </div>

            <p className={styles.title}>
               <CustomTooltip id={topic?.id} text={topic?.title} limit={22} />
            </p>

            <div className={styles.bottomBlock}>
               <div className={styles.book}>
                  <img src={book} alt={""} />
                  <CustomStaticTooltip
                     hint={coursesInsideTopic}
                     text={topic?.coursesCount || 0}
                     id={`${topic?.id}${uuidv4()}`}
                  />
               </div>

               <div className={styles.person}>
                  <img src={person} alt={""} />
                  <CustomStaticTooltip
                     hint={usersAccessCoursesTopic}
                     text={topic?.readers || 0}
                     id={`${folder?.id}${uuidv4()}`}
                  />
               </div>

               {userRoleId !== ROLE_ID_STUDENT &&
                  !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
                  userRoleId &&
                  !asStudent && (
                     <DotsButton className={styles.dots} dotsRef={dotsRef} isOpen={isOpen} onToggle={onToggleSettings}/>
                  )}

               {isOpen && (
                  <div
                     onClick={(e) => e.stopPropagation()}
                     ref={dropdownRef}
                     className={styles.dropdown}
                  >
                     {activeFilter === "hidden" && (
                        <p
                           onClick={(e) =>
                              onConfirmOpenChangeHidenStatus(e, topic)
                           }
                        >
                           {changeToVisible}
                        </p>
                     )}

                     {activeFilter === "deleted" && (
                        <>
                           <p onClick={(e) => onConfirmOpenRestore(e,topic)}>
                              {restoreText}
                           </p>
                           <p onClick={(e) => onConfirmOpenDelete(e,topic)}>
                              {deleteText}
                           </p>
                        </>
                     )}

                     {activeFilter !== "deleted" && (
                        <p onClick={(e) => onConfirmDeleteOpen(e, topic)}>
                           {deleteText}
                        </p>
                     )}

                     {activeFilter !== "hidden" && (
                        <p onClick={(e) => onConfirmHideOpen(e, topic)}>{hideText}</p>
                     )}

                     <p onClick={(e) => onRedirectToEditTopicPage(e, topic)}>{editText}</p>
                  </div>
               )}
            </div>
         </div>
      </>
   );
};

export default TopicBlock;
