import { useState, useEffect } from "react";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { DEFAULT_DATA_LIMIT } from "../../constants/user";
import CourseApi from "../../utils/api/CourseApi";
import {
   mapCoursesWithNewLinks,
   returnTemporaryCoursesLinks,
} from "../../utils/coursesHelper";
import courseImg from "../../assets/images/course_thumbnail.png";
import { useSelector } from "react-redux";
import { COLOR_PRESETS } from "../../constants/courses";
import FolderApi from "../../utils/api/FolderApi";
import { FOLDER_EXTRA_GROUP_ONE } from "../../utils/api/serialization_groups";
import { isNewFolder } from "../../utils";

const initialMapFolders = (item) => ({
   folder: COLOR_PRESETS.find((itemColor) => +itemColor?.id === +item?.color)?.color || COLOR_PRESETS[0]?.color,
   hexColor: COLOR_PRESETS.find((itemColor) => +itemColor?.id === +item?.color)?.hexColor || COLOR_PRESETS[0]?.hexColor,
   status: item?.status,
   isNew: isNewFolder(item?.createdAt) || false,
   title: item?.name,
   courses: item?.courses || [],
   readers: null,
   id: item?.id,
   createdAt: item?.createdAt,
   folder_icon: item?.icon || null
});

const useGetFolders = (
   companyId,
   isCoursesImagesNeeded = false,
   isTopic = false,
   isNotNeededCoursesInJson,
) => {
   const [isFoldersLoading, setIsFoldersLoading] = useState(true);
   const [searchedValue, setSearchedValue] = useState("");
   const [receivedFoldersList, setFoldersList] = useState([]);
   const [foldersInfo, setFoldersInfo] = useState([]);
   const [foldersWithUpdatedLinks, setFoldersWithUpdatedLinks] = useState([]);
   const [totalFolders, setTotalFolders] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);

   const userId = useSelector(state => state?.user?.info?.id);

   const abortController = new AbortController();
   const signal = abortController.signal;

   const [activeFolderStatusFilter, setActiveFolderStatusFilter] = useState('');
   const [activeFolderSortFilter, setActiveFolderSortFilter] = useState('');
   const [activeTopicFilter, setActiveTopicFilter] = useState([]);

   const clearAllFoldersFilters = () => {
      if (activeTopicFilter?.length) setActiveTopicFilter([]);
      if (activeFolderSortFilter?.length) setActiveFolderSortFilter('');
      if (activeFolderStatusFilter?.length) setActiveFolderStatusFilter('');
      if (searchedValue?.length) searchedValue('');
   };

    const clearExtendedFilters = (e) => {
        e.stopPropagation();
        if (activeFolderSortFilter?.length) setActiveFolderSortFilter('');
    }

   const fetchFolders = async (page) => {
      setIsFoldersLoading(true);

      try {
         const res = await new FolderApi().getYourFolders({
            limit: DEFAULT_DATA_LIMIT,
            page: page ? page : currentPage,
            companyId,
            userId,
            isTopic,
            order: activeFolderSortFilter === "Old first" ? "asc" : "desc",
            status: activeFolderStatusFilter,
            topicsIdsFilter: activeTopicFilter,
            searchedValue,
            serializationGroup: !isNotNeededCoursesInJson ? FOLDER_EXTRA_GROUP_ONE : '',
            signal
         });

         if (res?.success?.data) {
            const folders = res?.success?.data?.map(initialMapFolders) || [];

            if (page) {
               setFoldersList(folders);
            } else {
               setFoldersList((prev) => [...prev, ...folders]);
            }

            setTotalFolders(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setFoldersList([]);
         }
      } catch (error) {
         console.error("Error fetching folders:", error);
      } finally {
         setIsFoldersLoading(false);
      }
   };

   const getFoldersInfo = async () => {
      const dataToSend = {
          folder_ids: receivedFoldersList.map(folder => folder?.id)
      };

      const res = await new FolderApi().getFoldersInfo(dataToSend);
      if(res?.success?.data) {
         setFoldersInfo(res?.success?.data)
      }
   }

   const debouncedFetchFolders = debounce(
      () => fetchFolders(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchFolders = () => {
      debouncedFetchFolders();
      setCurrentPage(1);
   };

   useEffect(()=> {
      if(receivedFoldersList?.length) {
         getFoldersInfo();
      }
   }, [receivedFoldersList])

   useEffect(() => {
      if (currentPage > 1 && receivedFoldersList?.length <= totalFolders) {
         fetchFolders();
      }
   }, [currentPage]);

   useEffect(()=> {
      return () => {
         abortController.abort();
         setFoldersList([]);
      }
   }, [])


   const getCoursesInsideFolderImages = async () => {
      try {
          const allCourses = receivedFoldersList?.reduce((courses, folder) => {
              const filteredCourses = folder?.courses?.filter(course => 
                  course?.image && !course?.image?.includes('/static/media/')
              );
  
             // Add the first three filtered courses from the folder
               filteredCourses?.slice(0, 3)?.forEach(course => {
                  if (!courses?.some(c => c?.id === course?.id)) {
                     courses?.push(course);
                  }
            });
  
              return courses;
          }, []);

          const links = await returnTemporaryCoursesLinks(userId || 1, allCourses);
  
          const updatedFolders = receivedFoldersList.map(folder => {
              const coursesWithUpdatedLinks = folder.courses?.map(course => mapCoursesWithNewLinks(course, links)).sort((course1, course2) => {
               const course1HasImage = course1?.image ? -1 : 1;
               const course2HasImage = course2?.image ? -1 : 1;
       
               return course1HasImage - course2HasImage;
           });

              return { ...folder, coursesWithUpdatedLinks };
          });

          setFoldersWithUpdatedLinks(updatedFolders);
      } catch (error) {
          console.error("Error updating links for courses:", error);
      }
  };
  

   useEffect(()=> {
      if(receivedFoldersList?.length && isCoursesImagesNeeded) {
         getCoursesInsideFolderImages();
      }
   }, [receivedFoldersList])

   useEffect(() => {
      if (companyId && userId) {
         setCurrentPage(1);
         debouncedFetchFolders();
      }

      return () => debouncedFetchFolders.cancel();
   }, [
      searchedValue,
      activeTopicFilter,
      activeFolderSortFilter,
      activeFolderStatusFilter,
      companyId,
      userId
   ]);

   const foldersListWithUpdatedInfo = receivedFoldersList.map(folder => {
      const folderInfo = foldersInfo.find(info => info?.folderId === folder?.id);
      if (folderInfo) {
          return {
              ...folder,
              coursesCount: folderInfo.yourAvailableCoursesInFolderCount,
              readers: folderInfo.usersWithVisibleFolderCount
          };
      } else {
          return folder; 
      }
  });  

  const foldersList = foldersListWithUpdatedInfo.map(folder => {
   const updatedFolder = foldersWithUpdatedLinks.find(updatedFolder => updatedFolder.id === folder.id);

   if (updatedFolder) {
       return {
         ...folder,
         coursesWithUpdatedImages: (updatedFolder?.coursesWithUpdatedLinks || [])
       };
   } else {
       return folder;
   }
});

   return {
      isFoldersLoading,
      foldersList,
      setFoldersList,
      totalFolders,
      currentPage,
      debouncedFetchFolders,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      setTotalFolders,
      refetchFolders,
      activeTopicFilter,
      setActiveTopicFilter,
      clearAllFoldersFilters,
      activeFolderStatusFilter,
      setActiveFolderStatusFilter,
      setActiveFolderSortFilter,
      activeFolderSortFilter,
       clearExtendedFilters
   };
};

export default useGetFolders;
