import React, { useEffect, useRef, useState } from "react";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";
import plus from "../../../assets/images/plus.svg";

import { transformRoleText } from "../../../utils/rolesHelper";
import cn from "classnames";
import styles from "./styles.module.scss";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import useOptions from "../../../hooks/useOptions";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import DropdownOptions from "../DropdownOptions/DropdownOptions";
import Roles from "../../../utils/api/Roles";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import RoleItem from "../RoleItem/RoleItem";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import RenameModal from "../../../components/Modals/RenameModal/RenameModal";
import { useSelector } from "react-redux";

const RolesBlock = ({ roles, handleRoleClick, activeRole, getRoles, generatePermissionData, t}) => {
   const [newRoleName, setNewRoleName] = useState("");
   const [addRoleName, setAddRoleName] = useState("");
   const [isRoleLoading, setIsRoleLoading] = useState(false);
   const confirmModal = useHandleModal();
   const renameModal = useHandleModal();
   const addRoleModal = useHandleModal();
   const companyId = useSelector(state => state.sidebar.companyId);
   const companyName = useSelector(state => state.sidebar.companyName);
   const branchId = useSelector(state => state.sidebar.currentCompanyBranchId);

   const onCopy = async() => {
     // Add a new role
     const addRoleRes = await new Roles().createRole({
         name: `ROLE_${activeRole.name.toUpperCase()}`,
         ...(branchId ? {branch_id: branchId } : {})
     });

     if (addRoleRes?.error?.message) {
         toast(<ToastMsg text={addRoleRes?.error?.message} isError />);
         return;
     }
 
     // Update role permissions for the newly created role
     const permissionData = generatePermissionData();
     const updatePermissionsRes = await new Roles().updateRolePermissions(addRoleRes.success.data?.role?.id, {
         permission_data: permissionData,
     });

     if (updatePermissionsRes?.success?.message) {
         toast(<ToastMsg text={updatePermissionsRes?.success?.message} />);
         getRoles(); // Fetch updated roles list
     }
 
     if (updatePermissionsRes?.error?.message) {
         toast(<ToastMsg text={updatePermissionsRes?.error?.message} isError />);
     }

   };

   const handleDeleteRole = async () => {
      setIsRoleLoading(true);
      const res = await new Roles().deleteRole(activeRole?.id);
      confirmModal.close();
      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         getRoles();
      }
      setIsRoleLoading(false);
      if (res?.error?.message)
         return toast(<ToastMsg text={res?.error?.message} isError />);
   };

   const onRenameRole = async () => {
      if (!newRoleName.length)
         return toast(<ToastMsg text={"Field cannot be ampty"} isError />);

      setIsRoleLoading(true);
      const res = await new Roles().updateRole(activeRole?.id, {
         new_name: `ROLE_${newRoleName.toUpperCase()}_${companyName}`,
      });

      setNewRoleName("");
      renameModal.close();

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         getRoles();
      }

      setIsRoleLoading(false);

      if (res?.error?.message)
         return toast(<ToastMsg text={res?.error?.message} isError />);
   };

   const handleAddRole = async () => {
      if (!addRoleName.length)
         return toast(<ToastMsg text={"Field cannot be ampty"} isError />);

      setIsRoleLoading(true);

      const res = await new Roles().createRole({
         name: `ROLE_${addRoleName.toUpperCase()}_${companyName}`,
         branch_id: branchId
      });

      setAddRoleName("");
      addRoleModal.close();

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         getRoles();
      }

      setIsRoleLoading(false);

      if (res?.error?.message)
         return toast(<ToastMsg text={res?.error?.message} isError />);
   };

   return (
      <div className={styles.roles_block}>
         <div className={styles.roles_top}>{t?.roles}</div>
         <div className={styles.roles_content}>
            {roles?.map((role) => (
               <RoleItem
                  key={role?.id}
                  companyName={companyName}
                  handleRoleClick={handleRoleClick}
                  handleDeleteRole={handleDeleteRole}
                  onRenameModalOpen={renameModal.open}
                  confirmModal={confirmModal}
                  role={role}
                  onCopy={onCopy}
                  activeRole={activeRole}
               />
            ))}

            <button className={styles.plus_btn} onClick={addRoleModal.open}>
               <img className={styles.plus} src={plus} alt="plus" />
               {t?.addRole}
            </button>
         </div>

         <ConfirmModal
            onConfirm={handleDeleteRole}
            isRemove
            confirmButtonText={t?.delete}
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            title={t?.areYouSure}
            isLoading={isRoleLoading}
         />

         <RenameModal
            key={"rename"}
            inputValue={newRoleName}
            setInputValue={setNewRoleName}
            onClose={renameModal.close}
            onConfirm={onRenameRole}
            customClass={ 'error_input'}
            isOpen={renameModal.isActive}
            title={t?.enterATitle}
            isLoading={isRoleLoading}
         />
         <RenameModal
            key={"add new role"}
            inputValue={addRoleName}
            setInputValue={setAddRoleName}
            onClose={addRoleModal.close}
            isOpen={addRoleModal.isActive}
            onConfirm={handleAddRole}
            isLoading={isRoleLoading}
            title={t?.enterRoleName}
         />
      </div>
   );
};

export default RolesBlock;
