import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

function CountdownTimer({ initialTime, handleTimePassed, isStopTimer}) {
   const [remainingTime, setRemainingTime] = useState();

   const initializeTime = () => {
      setRemainingTime(
         initialTime?.hours * 60 * 60 * 1000 + initialTime?.minutes * 60 * 1000
      );
   };

   useEffect(() => {
      const intervalId = setInterval(() => {
         if (remainingTime > 0 && !isStopTimer) {
            setRemainingTime((prevRemainingTime) => prevRemainingTime - 1000);
         } else {
            handleTimePassed();
            clearInterval(intervalId);
         }
      }, 1000);

      // Clear the interval when isStopTimer becomes true
      if (isStopTimer) {
         clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
   }, [remainingTime, isStopTimer]);

   useEffect(() => {
      initializeTime();
   }, [initialTime]);

   const hours = Math.floor(remainingTime / (60 * 60 * 1000));
   const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
   const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

   // Add leading zeros to minutes and seconds when they are less than 10
   const formattedMinutes = minutes.toString().padStart(2, "0");
   const formattedSeconds = seconds.toString().padStart(2, "0");

   return (
      <div className={styles.time_ramain}>
         <h3>Time remaining:</h3>
         <div className={styles.time}>{`${
            hours ? hours + ":" : ""
         }${formattedMinutes}:${formattedSeconds}`}</div>
      </div>
   );
}

export default CountdownTimer;
