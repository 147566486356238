import React, { useState } from "react";
import styles from "./styles.module.scss";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input/Input";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import Button from "../../components/Button/Button";
import UserApi from "../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";

const ResetPasswordPage = () => {
   const navigate = useNavigate();

   const params = useParams();
   const [isPasswordsError, setIsPasswordsError] = useState(false);
   const [isSuccess, setIsSuccess] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [newPassword, setNewPassword] = useState("");
   const [confirmNewPassword, setConfirmNewPassword] = useState("");

   const onSubmit = async () => {
      if (!params.token) return;

      setIsLoading(true);
      const data = {
         reset_token: params.token,
         new_password: newPassword,
         confirm_new_password: confirmNewPassword,
      };

      const res = await new UserApi().createNewPasswordByToken(data);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         setIsSuccess(true);
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
         setIsPasswordsError(true);
      }

      setIsLoading(false);
   };

   return (
      <section className={styles.section}>
         <div className={styles.changePassword_wrapper}>
            {isSuccess && (
               <Button
                  onClick={() => navigate("/login")}
                  isBlack
                  title={"Go to login page"}
               />
            )}

            {!isSuccess && (
               <>
                  <h3>Set new password</h3>
                  <div className={styles.input_item}>
                     <PasswordInput
                        isError={isPasswordsError}
                        value={newPassword}
                        placeholder={"New password"}
                        onChangeValue={(e) => {
                           setNewPassword(e.target.value);
                           setIsPasswordsError(false);
                        }}
                     />
                  </div>
                  <div className={styles.input_item}>
                     <PasswordInput
                        isError={isPasswordsError}
                        value={confirmNewPassword}
                        placeholder={"Confirm new password"}
                        onChangeValue={(e) => {
                           setConfirmNewPassword(e.target.value);
                           setIsPasswordsError(false);
                        }}
                     />
                  </div>
                  <div className={styles.btn}>
                     <Button
                        disabled={isLoading}
                        onClick={onSubmit}
                        isBlack
                        title={isLoading ? "loading..." : "Submit"}
                     />
                  </div>
               </>
            )}
         </div>
      </section>
   );
};

export default ResetPasswordPage;
