import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

import left from "../../assets/images/arrow_left.svg";
import right from "../../assets/images/arrow_right.svg";

import cn from "classnames";

const LeftRightErrows = ({ moveRight, moveLeft, noBorder }) => {
   return (
      <div className={cn(styles.nav, noBorder ? styles.no_border : '')}>
         <button onClick={moveLeft} className={styles.block_small}>
            <img src={left} alt="" />
         </button>
         <button onClick={moveRight} className={styles.block_small}>
            <img src={right} alt="" />
         </button>
      </div>
   );
};

export default LeftRightErrows;
