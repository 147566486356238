import userAvatar from "../../../assets/images/avatar.svg";
import cousersCompleted from "../../../assets/images/courses_completed.svg";
import foldersCompleted from "../../../assets/images/folders_completed.svg";
import booksRead from "../../../assets/images/books_read.svg";
import sendIcon from "../../../assets/images/upload.svg";
import blockIcon from "../../../assets/images/Lock.svg";
import ublockIcon from "../../../assets/images/icon_unlock.svg";
import editImg from "../../../assets/images/edit_icon.svg";

import PieChart from "../../../components/PieChart/PieChart";
import Button from "../../../components/Button/Button";
import { GENDERS, ROLE_ID_SUPER_ADMIN, USER_STATUS_ACTIVE, USER_STATUS_BANNED } from "../../../constants/user";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import UserApi from "../../../utils/api/UserApi";

import styles from "./styles.module.scss";
import cn from "classnames";
import moment from "moment/moment";
import ChangeUserDataModal from "../../../components/Modals/ChangeUserDataModal/ChangeUserDataModal";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useUserRole from "../../../hooks/useUserRole";
import { localStorageSet } from "../../../utils/localStorage";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ProfileLeftPanel = ({ chartData, user, refetchUser }) => {
   const confirmModalBlock = useHandleModal();
   const confirmModalUnblock = useHandleModal();
   const changeUserDataModal = useHandleModal();
   const confirmEnterAccount = useHandleModal();

   const t = useFindTranslationsByString();

   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId)

   const params = useParams();

   const onEnterAccount = () => {
      localStorageSet('impersonateId', params?.id);
      location.reload();
      confirmEnterAccount.close();
   }

   const updateUserStatus = async (newStatus) => {
      const res = await new UserApi().updateUser(user?.id, {
         new_status: newStatus,
      });
   
      if (res?.success && refetchUser) refetchUser();
      if (res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError />);
      if (res?.success?.message) toast(<ToastMsg text={res?.success?.message} />);
   };
   
   const onDeleteFromPlatform = async () => {
      await updateUserStatus(USER_STATUS_BANNED);
      confirmModalBlock.close();
   };
   
   const onRestoreUser = async () => {
      await updateUserStatus(USER_STATUS_ACTIVE);
      confirmModalUnblock.close();
   };

   const avatar = user?.avatar ? user?.avatar : userAvatar;

   return (
      <div className={styles.left_panel}>
         <div className={styles.left_panel_content}>
            <div className={styles.chart_container}>
               <PieChart chartData={chartData} profileImg={avatar} />
            </div>
            <div className={styles.user_top_info}>
               <div className={styles.name}>
                  {user?.firstName} {user?.lastName}
               </div>
               <div className={styles.email}>{user?.email}</div>
               <div className={styles.phone}>{user?.phone}</div>
            </div>
            {/*
                  FOR FUTURE
            <div className={styles.completed_info}>
               <div className={styles.completed_info_item}>
                  <div className={styles.completed_item_left}>
                     <img src={cousersCompleted} alt="" />
                     Courses completed:
                  </div>
                  <div>
                     0 / <span>15</span>
                  </div>
               </div>
               <div className={styles.completed_info_item}>
                  <div className={styles.completed_item_left}>
                     <img src={foldersCompleted} alt="" />
                     Folders completed:
                  </div>
                  <div>
                     0 / <span>10</span>
                  </div>
               </div>
               <div className={styles.completed_info_item}>
                  <div className={styles.completed_item_left}>
                     <img src={booksRead} alt="" />
                     Books read:
                  </div>
                  <div>
                     0 / <span>19</span>
                  </div>
               </div>
            </div> */}

            <div className={styles.user_info}>
               {/* FOR FUTURE <div className={styles.user_info_item}>
                  <span>Gender:</span>
                  <div className={styles.item_common}>{GENDERS[user?.gender]?.name || '-'}</div>
               </div>
               
                <div className={styles.user_info_item}>
                  <span>Date of birth:</span>
                  <div className={styles.item_common}>
                     {user?.birthday ? moment(user.birthday).format('DD.MM.YYYY') : "-"}
                  </div>
               </div>
               <div className={styles.user_info_item}>
                  <span>City, Country:</span>
                  <div className={styles.item_common}>
                     {user?.region ? user?.region : "Ukraine"}
                  </div>
               </div>
               <div className={styles.user_info_item}>
                  <span>Timezone:</span>
                  <div className={styles.item_common}>
                     +03:00 UTC/GMT Europe/Kyiv
                  </div>
               </div> */}
               <div className={styles.user_info_item}>
                  <span>{t("Department")}:</span>
                  <div className={styles.item_common}>
                     {user.department ? user.department : t("No department")}
                  </div>
               </div>
               <div className={styles.user_info_item}>
                  <span>{t("Position")}:</span>
                  <div className={styles.item_common}>
                     {user.position ? user.position : t("No position")}
                  </div>
               </div>
            </div>

            {/* 
              FOR FUTURE <div className={styles.profile_btn}>
               <Button image={sendIcon} title={" Send login details"} />
            </div> */}

            {userRoleId && userRoleId === ROLE_ID_SUPER_ADMIN && 
                  <div className={styles.profile_btn}>
                  <Button
                     isBlack
                     image={editImg}
                     title={t("Edit user data")}
                     onClick={changeUserDataModal.open}
                     isWrap
                  /> 
               </div>
            }
         

            {user?.status === USER_STATUS_ACTIVE && (
               <div className={styles.profile_btn}>
                  <Button
                     onClick={confirmModalBlock.open}
                     isRed
                     image={blockIcon}
                     title={t("Block the user")}
                     isWrap
                  />
               </div>
            )}

            {user?.status === USER_STATUS_BANNED && (
               <div className={styles.profile_btn}>
                  <Button
                     isGreen
                     image={ublockIcon}
                     title={t("Unblock user")}
                     onClick={confirmModalUnblock.open}
                     isWrap
                  />
               </div>
            )}
            {userRoleId === ROLE_ID_SUPER_ADMIN && (
               <Button
                  title={t("Enter user account")}
                  onClick={confirmEnterAccount.open}
                  isWrap
               />
            )}

            <ConfirmModal
               confirmButtonText={t("Block user")}
               onConfirm={onDeleteFromPlatform}
               onClose={confirmModalBlock.close}
               isOpen={confirmModalBlock.isActive}
               title={t("Are you sure?")}
            />

            <ConfirmModal
               confirmButtonText={t("Unblock user")}
               onConfirm={onRestoreUser}
               onClose={confirmModalUnblock.close}
               isOpen={confirmModalUnblock.isActive}
               title={t("Are you sure?")}
            />

            <ConfirmModal
               maxHeight="300px"
               maxWidth="470px"
               confirmButtonText={t("Enter user account")}
               onConfirm={onEnterAccount}
               onClose={confirmEnterAccount.close}
               isOpen={confirmEnterAccount.isActive}
               title={t("Are you sure?")}
            />

            <ChangeUserDataModal
               onClose={changeUserDataModal.close}
               isOpen={changeUserDataModal.isActive}
               data={user}
               refetchData={refetchUser}
               // addUserToList={addUserToList}
            />
         </div>
      </div>
   );
};

export default ProfileLeftPanel;
