import styles from "./styles.module.scss";
import cn from "classnames";

const ProgressLine = ({ progress, dashIsNotNeeded }) => {
   return (
      <div className={styles.wrapper}>
         {(progress > 0 || dashIsNotNeeded) && (
            <div className={styles.result_line}>
               <span>{progress || 0}%</span>
               <div
                  className={cn(
                     styles.result_line_wrapper,
                     dashIsNotNeeded ? styles.maxWidth100 : ""
                  )}
               >
                  <span
                     style={{ width: `${progress || 0}%` }}
                     className={cn(styles.line, styles.purple_line)}
                  ></span>
               </div>
            </div>
         )}
         {!progress && !dashIsNotNeeded && "-"}
      </div>
   );
};

export default ProgressLine;
