import moment from "moment";
import { getArrayOfNumbers, getArrayOfNumbersYear } from "../../utils";
import Dropdown from "../Dropdown/Dropdown";
import styles from "./styles.module.scss";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const DropdownsDate = ({ data, setData }) => {
   const t = useFindTranslationsByString();

   return (
      <div className={styles.date}>
         <div className={styles.item_wrapper}>
            <div className={styles.pre_label}>{t("Day of birth")}</div>
            <Dropdown
               data={getArrayOfNumbers(1, 31)}
               placeholder={t("Day")}
               value={data?.date_dd || ""}
               onChange={(value) => {
                  if (value && data?.date_mm && data?.date_yyyy) {
                     if (setData)
                        setData({
                           ...data,
                           date_dd: value,
                           date: `${data?.date_yyyy}-${data?.date_mm}-${value}`,
                        });
                  } else {
                     if (setData) setData({ ...data, date_dd: value });
                  }
               }}
            />
         </div>
         <div className={styles.item_wrapper}>
            <div className={styles.pre_label}>{t("Month")}</div>
            <Dropdown
               data={getArrayOfNumbers(1, 12)}
               placeholder={t("Month")}
               value={data?.date_mm || ""}
               onChange={(value) => {
                  if (data?.date_dd && value && data?.date_yyyy) {
                     if (setData)
                        setData({
                           ...data,
                           date_mm: value,
                           date: `${data?.date_yyyy}-${value}-${data?.date_dd}`,
                        });
                  } else {
                     if (setData) setData({ ...data, date_mm: value });
                  }
               }}
            />
         </div>
         <div className={styles.item_wrapper}>
            <div className={styles.pre_label}>{t("Year")}</div>
            <Dropdown
               data={getArrayOfNumbersYear(
                  new Date().getFullYear() - 100,
                  new Date().getFullYear() - 14
               )}
               placeholder={t("Year")}
               value={data?.date_yyyy || ""}
               onChange={(value) => {
                  if (data?.date_dd && data?.date_mm && value) {
                     if (setData)
                        setData({
                           ...data,
                           date_yyyy: value,
                           date: `${value}-${data?.date_mm}-${data?.date_dd}`,
                        });
                  } else {
                     if (setData) setData({ ...data, date_yyyy: value });
                  }
               }}
            />
         </div>
      </div>
   );
};

export default DropdownsDate;
