import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./styles.module.scss";
import coursePageStyles from "../CoursesPage/styles.module.scss";
import arrow_right from "../../assets/images/arrow_right.svg";
import info from "../../assets/images/icon_info.svg";
import ongoing_courses_icon from "../../assets/images/certificates/ongoing_courses_icon.svg";
import completed_courses_icon from "../../assets/images/certificates/completed_courses_icon.svg";
import certificates_icon from "../../assets/images/certificates/certificates_icon.svg";
import pausedIcon from "../../assets/images/symbols/pause.svg";
import personIcon from "../../assets/images/symbols/person.svg";
import { useSelector } from "react-redux";
import Circle from "../../components/Circle/Circle";
import Planets from "../../components/Planets/Planets";
import courseImg from "../../assets/images/course_thumbnail.png";
import { COURSE_STATUS_ACTIVE, FOLDER_STATUS_DELETED, FOLDER_STATUS_HIDEN } from "../../constants/courses";
import FolderBlock from "../../components/FolderBlock/FolderBlock";
import { pathList } from "../../routes/path";
import useMoveScroll from "../../hooks/useMoveScroll";
import LeftRightErrows from "../../components/LeftRightArrows/LeftRightArrows";
import CourseBlock from "../../components/CourseBlock/CourseBlock";
import cn from 'classnames';
import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_REWORK } from "../../constants/tests";
import KnowledgeCheckAPI from "../../utils/api/KnowledgeCheckAPI";
import { Link, useNavigate } from "react-router-dom";
import useChangeFolder from "../../hooks/useChangeFolder";
import useChangeCourse from "../../hooks/useChangeCourse";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import StatisticsApi from "../../utils/api/StatisticsApi";
import useUserRole from "../../hooks/useUserRole";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetFolders from "../../hooks/api/useGetFolders";
import useGetCoursesCount from "../../hooks/api/useGetCoursesCount";
import CoursesInfoLineBlock from "../../components/CoursesInfoLineBlock/CoursesInfoLineBlock";
import useFindTranlsations from "../../hooks/useFindTranlsations";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";
import { isMobileDevice } from "../../utils/usersHelper";

const HomePage = () => {
	const userState = useSelector((state) => state.user);
	const navigate = useNavigate();

	const [tests, setTests] = useState([]);
	const [currentLevel, setCurrentLevel] = useState(1);
	const [integrationPercentage, setIntegrationPercentage] = useState(undefined);


	const onRedirectToEditFolderPage = (e, folder) => {
		e.stopPropagation();
		navigate(`/courses/folder/${folder?.id}/edit`);
	}

	const {
		blockWrapperRef,
		handleMouseDown,
		handleMouseMove,
		handleMouseUp,
		isDragging,
		moveLeft,
		moveRight
	} = useMoveScroll();

	const isSideBarFixed = useSelector(state => state.sidebar.isFixed);
	const currentCompanyId = useSelector(state => state.sidebar.companyId);
	const userId = useSelector(state => state?.user?.info?.id);
	const {userRoleId} = useUserRole(currentCompanyId)

	const {coursePassingInfo, userCertificatesCount} = useGetCoursesCount(currentCompanyId, userId, true);

	const isUserAsStudent = useSelector(state => state.sidebar.isUserAsStudent);

	const t = useFindTranlsations();
	const tNew = useFindTranslationsByString();


	const filterActiveCourses = (course) => course?.status === COURSE_STATUS_ACTIVE;

	const {
		blockWrapperRef: blockWrapperRefCourse,
		handleMouseDown: handleMouseDownCourse,
		handleMouseMove: handleMouseMoveCourse,
		handleMouseUp: handleMouseUpCourse,
		isDragging: isDraggingCourse,
		moveLeft: moveLeftCourse,
		moveRight: moveRightCourse
	} = useMoveScroll();

	const initialMapCourses = (item) => ({
		id: item?.id,
		image: item?.image || courseImg,
		title: item?.name || '',
		description: item?.description || '',
		tagList: item?.tags || [],
		isInFolder: !!item?.folder?.id,
		folder: item?.folder || {},
		status: item?.status,
		createdAt: item?.createdAt,
		userCourses: item?.userCourses || [],
		percentageToReceiveCertificate: item?.percentageToReceiveCertificate || 0
	})

	const getUserOwnTests = async () => {
		const res = await new KnowledgeCheckAPI().getYourCheckedTests(currentCompanyId);

		if (res?.success?.data) {
			const data = res?.success?.data
			setTests(data?.user_tests.map(test => ({
				...test,
				total_questions: data?.extra_data.find(q => q.id === test.id).total_questions
			})));
		}
	}
	useEffect(() => {
		getUserOwnTests();
	}, [])

	const {
		foldersList,
		isFoldersLoading,
		refetchFolders,
		totalFolders,
		setCurrentPage: setCurrentFolderPage,
		currentPage: currentFolderPage
	} = useGetFolders(currentCompanyId);

	const lastFolderRef = useIntersectionObserver(
		() => {
			if (!isFoldersLoading && foldersList?.length < totalFolders) {
				if (courseList?.length === 0) {
					setCurrentFolderPage(1);
				} else {
					setCurrentFolderPage((prev) => prev + 1);
				}
			}
		},
		[isFoldersLoading, totalFolders]
	);

	const {
		onConfirmHideOpen,
		onConfirmDeleteOpen,
		confirmModal,
		confirmHideModal,
		isChangeLoading,
		onFolderDeleteToggle,
		onFolderHideToggle,
	} = useChangeFolder(refetchFolders);


	const isUserAsStudentAndNotSuperAdmin = userRoleId === ROLE_ID_SUPER_ADMIN || userRoleId === ROLE_ID_STUDENT ? false : isUserAsStudent;

	const isExcludeCoursesWhereAdminAsStudent = userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT;

	const {
		courseList,
		isCoursesLoading,
		refetchCourses,
		totalCourses,
		setCurrentPage
	} = useGetYourCoursesInCompany(currentCompanyId, isUserAsStudentAndNotSuperAdmin, null, null, isExcludeCoursesWhereAdminAsStudent);

	const lastCourseRef = useIntersectionObserver(
		() => {
			if (!isCoursesLoading && courseList?.length < totalCourses) {
				if (courseList?.length === 0) {
					setCurrentPage(1);
				} else {
					setCurrentPage((prev) => prev + 1);
				}
			}
		},
		[isCoursesLoading, totalCourses]
	);

	const {
		onConfirmPausedOpen,
		confirmPausedModal,
		onRedirectClickedCourse
	} = useChangeCourse(refetchCourses);

	const getYourIntegration = async () => {
		const res = await new StatisticsApi().getYourIntegration();

		if (res?.success?.data) {
			setIntegrationPercentage(Math.round(res?.success?.data?.[0].integrationPercentage))
		}
	}

	useEffect(() => {
		getYourIntegration();
	}, [])

	//  LEVEL COMMENT
	//  const clickPlanet = ({ level }) => setCurrentLevel(level);
	//  const selectedLevelData = levelsData.find(data => data.level === currentLevel);

	return (
		<MainLayout>
			<main className={styles.admin_page_body}>
				<section className={styles.progress_bord}>
					<div className={styles.left}>
						<div className={styles.progress_card}>
							<div
								className={`${styles.progressbar_single} ${styles.left_planet_wrapper}`}
							>
								<div
									className={`${styles.progress_level} ${
										styles.planet_img
									} ${styles[`progress_level_${currentLevel}`]}`}
								>
									{/* <span className={styles.progress_level__name}>{integrationPercentage}</span>
                                <span className={styles.progress_level__text}>Integration</span>
                              <Circle
                                 progress={integrationPercentage}
                                 width="85%"
                                 height="100%"
                              /> */}
								</div>
							</div>

							<div>
								<h2 className={styles.progress_card__title}>
									{t?.hey}, {userState?.info?.firstName || t?.user}!
								</h2>

                        <p className={cn(styles.progress_card__text, styles.progress_card__text_phone)}>The ratio of completed courses to those assigned</p>
								<p className={styles.progress_card__text}>
									{t?.recentTestsResults}
								</p>

                        <div className={styles.integraion_line_wrapper}>
                           <span>{integrationPercentage || 0}%</span>
                           <div className={styles.integraion_line}>
                              <div style={{width: `${integrationPercentage || 0}%`}}
                                    className={styles.line}></div>
                           </div>
                           <img className={styles.hint_img} src={info} alt=""/>

                           <div className={styles.hint}>
                              {t.coursesRatio}
                           </div>
                        </div>

                        {isUserAsStudent && isMobileDevice() &&
                           <div className={styles.stud_mode}>
                              <img src={personIcon} alt="" />
                              <span>Student mode</span>
                           </div>
                        }
							</div>
						</div>

						<div className={styles.tests}>

							{tests?.map(test => <Link key={test?.id} to={`/knowledge_check/info/${test?.id}`}
							                          className={styles.test_item}>
									<div className={styles.test_results}>
										<span className={styles.result_score}>{test?.successPercentage}%</span>
										<Circle
											progress={test?.successPercentage}
											width="85%"
											height="100%"
											color={test?.status === TEST_STATUS_APPROVED ? '#36BA53' : (test?.status === TEST_STATUS_ON_REWORK ? 'yellow' : '#D64343')}
										/>
									</div>
									<div className={styles.test_info}>
										<p className={styles.title}>
											{test?.lesson?.name}
										</p>
										<p className={styles.subtitle}>{test.total_questions} {tNew('tasks')}</p>
									</div>
									<div>
										<img src={arrow_right} alt=""/>
									</div>
								</Link>
							)}
						</div>
					</div>

					<div className={cn(styles.right, isSideBarFixed ? styles.smaller : '')}>
						<div className={styles.main_progress}>
							<div className={styles.comets}>
                        <span
	                        className={`${styles.comet} ${styles.comet_1}`}
                        ></span>
								<span
									className={`${styles.comet} ${styles.comet_2}`}
								></span>
								<span
									className={`${styles.comet} ${styles.comet_3}`}
								></span>
								<span
									className={`${styles.comet} ${styles.comet_4}`}
								></span>
								<span
									className={`${styles.comet} ${styles.comet_5}`}
								></span>
								<span
									className={`${styles.comet} ${styles.comet_6}`}
								></span>
							</div>

							<div className={styles.planets_wrapper}>
								<Planets
									setCurrentLevel={setCurrentLevel}
									currentLevel={currentLevel}
								/>
							</div>
							{/* <span className={styles.main_progress__label}>{0}% Completed</span> */}
						</div>

						<div className={styles.courses_info_wrapper}>
							<CoursesInfoLineBlock
								numberOfItems={coursePassingInfo?.ongoing}
								link={`/courses/228/0`}
								icon={ongoing_courses_icon}
								title={t?.ongoing}
								coursesText={t.courses}
							/>

							<CoursesInfoLineBlock
								numberOfItems={coursePassingInfo?.completed}
								link={`/courses/228/1`}
								icon={completed_courses_icon}
								title={t?.completed}
								coursesText={t.courses}
							/>

							<CoursesInfoLineBlock
								numberOfItems={userCertificatesCount}
								link={'/profile/4'}
								icon={certificates_icon}
								title={t?.certificates}
								coursesText={t.courses}
							/>
						</div>

						<div className={cn(styles.list, 'noselect')}>

                     <p className={cn(styles.myEntity_text, styles.myEntity_text_phone)}>{t.myFolders}</p>
							<div className={styles.move_arrows}>
								<p className={styles.myEntity_text}>{t.myFolders}</p>

                        <div className={styles.arrows_wrapper}>
							   	<LeftRightErrows noBorder moveLeft={moveLeft} moveRight={moveRight}/>
                        </div>
							</div>

							<div
								onMouseDown={handleMouseDown}
								onMouseMove={handleMouseMove}
								onMouseUp={handleMouseUp}
								ref={blockWrapperRef}
								className={cn(styles.list_wrapper)}
							>
								{isFoldersLoading && foldersList?.length === 0 && (
									<div className={styles.sceleton_wrapper}>
										{Array(4).fill(null).map((item, idx) => (
											<div key={`folder_teamplate_first${idx}`}
											     className={cn(coursePageStyles.loadingSkeleton, styles.loading_sceleton)}>
												<div className={coursePageStyles.loadingTitle}></div>
											</div>
										))}
									</div>
								)
								}
								{foldersList?.map((item, key) => (
									<FolderBlock
										onRedirectToEditFolderPage={onRedirectToEditFolderPage}
										isDragging={isDragging}
										key={item?.id}
										refetchFolders={refetchFolders}
										folder={item}
										onConfirmDeleteOpen={onConfirmDeleteOpen}
										onConfirmHideOpen={onConfirmHideOpen}
										lastFolderRef={lastFolderRef}
									/>
								))}

								{foldersList?.length === 0 && !isFoldersLoading &&
									t?.myFolders
								}
							</div>
						</div>

						<div className={styles.list}>
                  <p className={cn(styles.myEntity_text, styles.myEntity_text_phone)}>{t.myCourses}</p>
							<div className={styles.move_arrows}>
								<p>{t?.myCourses}</p>
								<LeftRightErrows noBorder moveLeft={moveLeftCourse} moveRight={moveRightCourse}/>
							</div>

							<div
								onMouseDown={handleMouseDownCourse}
								onMouseMove={handleMouseMoveCourse}
								onMouseUp={handleMouseUpCourse}
								ref={blockWrapperRefCourse}
								className={cn(styles.list_wrapper, styles.courses, 'noselect')}
							>
								{isCoursesLoading && courseList?.length === 0 && (
									<div className={styles.sceleton_wrapper}>
										{Array(4).fill(null).map((item, idx) => (
											<div key={`course_template_first${idx}`}
											     className={cn(coursePageStyles.sceletonCourse_wrapper, styles.sceletonCourse_wrapper)}>
												<div className={cn(coursePageStyles.sceletonCourse, styles.image)}>
												</div>
												<div
													className={cn(coursePageStyles.loadingTitleCourse, styles.title)}></div>
											</div>
										))}
									</div>
								)}

								{courseList?.map((item, key) => (
									<CourseBlock
										lastCourseRef={lastCourseRef}
										isHomePage
										isDragging={isDraggingCourse}
										refetchCourses={refetchCourses}
										key={key}
										course={item}
										maxWidth={'300px'}
										onConfirmPausedOpen={onConfirmPausedOpen}
									/>
								))}

								{courseList?.length === 0 && !isCoursesLoading &&
									t?.noAvailableCourses
								}
							</div>
						</div>

					</div>
				</section>
			</main>

			<ConfirmModal
				isRemove
				confirmButtonText={t?.deleteText}
				onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_DELETED)}
				onClose={confirmModal.close}
				isOpen={confirmModal.isActive}
				isLoading={isChangeLoading}
				title={t?.areYouSure}
			/>

			<ConfirmModal
				confirmButtonText={t?.hideText}
				onConfirm={() => onFolderHideToggle(FOLDER_STATUS_HIDEN)}
				onClose={confirmHideModal.close}
				isOpen={confirmHideModal.isActive}
				isLoading={isChangeLoading}
				title={t?.areYouSure}
			/>

			<ConfirmModal
				icon={pausedIcon}
				confirmButtonText={t?.openCourse}
				onConfirm={onRedirectClickedCourse}
				onClose={confirmPausedModal.close}
				isOpen={confirmPausedModal.isActive}
				title={t?.courseWasPaused}
				subtitle={`${t?.youCannotContinueLearning}.`}
			/>
		</MainLayout>
	);
};

export default HomePage;
