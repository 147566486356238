import React from 'react';
import {useSelector} from 'react-redux';
import styles from './styles.module.scss'
import Button from "../../../components/Button/Button";
import saveImg from '../../../assets/images/symbols/save.svg';
import cn from 'classnames';
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const Subheader = ({title, text, onSave, onCancel}) => {
   const isSideBarFixed = useSelector(state => state.sidebar.isFixed);
   const t = useFindTranslationsByString();

    return (
        <div className={cn(styles.subheader, isSideBarFixed ? styles.sidebar_fixed : "")}>
            <div>
                <p className={styles.title}>{t(title)}</p>
                <p className={styles.text}>{t(text)}</p>
            </div>

            <div className={styles.buttons}>
                <Button title={t('Cancel')} isBlack onClick={onCancel}/>
                <Button title={t('Save changes')} image={saveImg} onClick={onSave}/>
            </div>

        </div>
    );
};

export default Subheader;