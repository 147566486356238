import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { STUDENTS_TAB } from "../../../constants/courses";
import {
  USERS_JUST_FIELDS,
} from "../../../constants/folders";

import PaginatedDropdown from "../../../components/PaginatedDropdown/PaginatedDropdown";
import plus from "../../../assets/images/symbols/plus.svg";
import searchImg from "../../../assets/images/symbols/Search.svg";
import img from "../../../assets/images/Image_orange.png";
import Button from "../../../components/Button/Button";
import useHandleModal from "../../../hooks/dom/useHandleModal";

import UsersPageTable from "../UsersTable/UsersTable";
import SelectUsersAndTeamsModal from "../../../components/Modals/SelectUsersAndTeamsModal/SelectUsersAndTeamsModal";

const AccessToTheFolderTab = ({
  selectedUserList,
  setSelectedUserList,
  selectedTeamList,
  setSelectedTeamList,
  userList,
  lastModalUserRef,
  modalUserSearchedValue,
  setModalUserSearchedValue,
  modalTeamSearchedValue,
  setModaTeamrSearchedValue,
  teamList,
  folderUserList,
  fetchTeams,
  folder,
  refetchData,
  loading,
  isModalUsersLoading,
  changeUserStatus,
  deleteUserFromTable,
  onGiveAccess,
  companyId,
  lastFolderUserRef,
  folderSearchedValue,
  setFolderSearchedValue,
  isUsersLoading,
  selectAllUsers,
  setSelectAllUsers,
  selectAllTeams,
  setSelectAllTeams,
  lastTeamRef,
  teamsLoading,
  selectedTeamFilter,
  setSelectedTeamFilter,
  updateFolderManager,
  t,
  selectedDepartment,
  setSelectedDepartment,
  selectedPosition,
  setSelectedPosition,
}) => {
  const [activeTab, setActiveTab] = useState(STUDENTS_TAB);
  const [searchValue, setSearchValue] = useState("");

  const selectUsersAndTeamsModal = useHandleModal();

  const onClickTab = (value) => setActiveTab(value);

  useEffect(() => {
    setSearchValue("");
    setSelectedUserList([]);
  }, [activeTab]);

  useEffect(() => {
    setSearchValue("");
    setSelectedUserList([]);
  }, [activeTab]);

  return (
    <div translate="no" className={styles.accessToTheCourseTab}>
      <div className={styles.leftBlock}>
        <div className={styles.search_wrapper}>
          <div className={styles.search}>
            <img src={searchImg} alt={""} />
            <input
              placeholder={t?.search}
              value={folderSearchedValue}
              onChange={(e) => setFolderSearchedValue(e.target.value)}
            />
          </div>
          <div className={styles.team_dropdown}>
            <PaginatedDropdown
              optionsWrapperClassname={styles.team_dropdown_options}
              classNameDropdownWrapper={styles.team_dropdown_wrapper}
              value={selectedTeamFilter?.name}
              lastItemRef={lastTeamRef}
              onChange={(team) => setSelectedTeamFilter(team)}
              data={[{ name: t?.all }, ...teamList]}
              placeholder={t?.selectTeam}
              field={"name"}
              maxHeight={"220px"}
            />
          </div>
          <Button
            className={styles.add_access}
            onClick={selectUsersAndTeamsModal?.open}
            title={t?.addAccess}
            isBlack
            image={plus}
            maxWidth={"180px"}
          />
        </div>

        <div className={styles.table_wrapper}>
          <UsersPageTable
            key={loading}
            columns={USERS_JUST_FIELDS}
            data={folderUserList}
            tableFor={"students"}
            changeUserStatus={changeUserStatus}
            deleteUserFromTable={deleteUserFromTable}
            loading={loading}
            refetchFolderUsers={refetchData}
            lastUserRef={lastFolderUserRef}
            folder={folder}
            updateFolderManager={updateFolderManager}
            t={t}
          />
        </div>
      </div>

      <SelectUsersAndTeamsModal
        onClose={selectUsersAndTeamsModal.close}
        isOpen={selectUsersAndTeamsModal.isActive}
        setSelectedUsers={setSelectedUserList}
        selectedUsers={selectedUserList}
        searchedValue={modalUserSearchedValue}
        setSearchedValue={setModalUserSearchedValue}
        usersList={userList}
        onConfirm={onGiveAccess}
        teamList={teamList}
        isUsersLoading={isModalUsersLoading || isUsersLoading}
        lastUserRef={lastModalUserRef}
        setSelectedTeamList={setSelectedTeamList}
        selectedTeamList={selectedTeamList}
        selectAllUsers={selectAllUsers}
        setSelectAllUsers={setSelectAllUsers}
        selectAllTeams={selectAllTeams}
        setSelectAllTeams={setSelectAllTeams}
        lastTeamRef={lastTeamRef}
        teamsLoading={teamsLoading}
        teamSearchedValue={modalTeamSearchedValue}
        setTeamSearchedValue={setModaTeamrSearchedValue}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedPosition={selectedPosition}
        setSelectedPosition={setSelectedPosition}
      />
    </div>
  );
};

export default AccessToTheFolderTab;
