import {
   DELETE_NOTIFICATION,
   SET_NOTIFICATIONS_INFO,
   TOGGLE_MUTED,
} from "../constants/notifications";
import { SET_INIT_STATE } from "../constants";

const initState = {
   info: null,
   muted: localStorage.getItem("muted") === "true",
};

export default function reducer(state = initState, action) {
   switch (action.type) {
      case SET_INIT_STATE:
         return initState;

      case SET_NOTIFICATIONS_INFO:
         return {
            ...state,
            ...{
               info: action?.info,
            },
         };
         case DELETE_NOTIFICATION:
            const notificationIdsToDelete = action.ids;
            const updatedNotifications = Array.isArray(state.info?.notRedNotifications)
                ? state.info.notRedNotifications.filter(
                    (notification) => !notificationIdsToDelete.includes(notification.id)
                )
                : [];
        
            return {
                ...state,
                info: {
                    ...state.info,
                    notRedNotifications: updatedNotifications,
                    notificationAmount: state.info.notificationAmount - notificationIdsToDelete.length
                }
            };
      case TOGGLE_MUTED:
         localStorage.setItem("muted", action.muted);

         return {
            ...state,
            ...{
               muted: action.muted
            },
         };

      default:
         return state;
   }
}
