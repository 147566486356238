import Base from "./Base";

export default class TranlationApi extends Base {
   getTranslations({
      limit = 20_000,
      page = 1,
      companyId,
      domain,
      locale,
      excluded_locale,
      signal,
      serializationGroup,
   }) {
      let url = `api/v1/translation?limit=${limit}&page=${page}`;

      if (companyId) {
         url += `&filter[company]=${companyId}`;
      }

      if (domain && domain !== 'all') {
         url += `&filter[domain]=${domain}`;
      }

      if (locale) {
         url += `&filter[locale]=${locale}`;
      }

      if (excluded_locale) {
         url += `&filter[exclude_locale]=${excluded_locale}`;
      }

      url += `&order=desc`;

      return super.get(url, null, signal, serializationGroup);
   }

   setDefaultTranslations() {
      let url = `api/v1/translation/default`;
      return super.post(url, {});
   }

   setTranslations(data) {
      let url = `api/v1/translation`;
      return super.put(url, data);
   }

   deleteCompanyTranslations(data) {
      let url = `api/v1/company/translations`;
      return super.delete(url, data);
   }
}
