import cn from "classnames";
import Input from "../../../components/Input/Input";
import Switch from "../../../components/Switch/Switch";
import styles from "./styles.module.scss";
import SwitchItem from "./SwitchItem";
import { useEffect, useRef, useState } from "react";
import TextArea from "../../../components/TextArea/TextArea";
import { parseIsoDuration } from "../../../utils/coursesHelper";
import arrowUp from "../../../assets/images/arrow_u.svg";
import arrowDown from "../../../assets/images/arrow_d.svg";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const LessonTypeTest = ({ inputsData, setInputsData, loading }) => {
   const passingScoreRef = useRef(null);
   const passingAttemptsRef = useRef(null);

   const t = useFindTranslationsByString();

   const [limitedHours, setLimitedHours] = useState("");
   const [limitedMinutes, setLimitedMinutes] = useState("");

   const changePassingScore = (e) => {
      const newPassingScore = parseInt(e.target.value);
      setInputsData((prev) => ({
         ...prev,
         new_passing_score: newPassingScore,
      }));
   };

   function formatTimeDuration(hours, minutes) {
      return `PT${hours}H${minutes}M`;
   }

   // Tranform ISO duration and get hours and minutes. Useful for future.
   useEffect(() => {
      if (inputsData.new_time_allowed.length) {
         const { hours, minutes } = parseIsoDuration(
            inputsData.new_time_allowed
         );

         setLimitedHours(hours);
         setLimitedMinutes(minutes);
      }
   }, [loading]);

   useEffect(() => {
      setInputsData((prev) => ({
         ...prev,
         new_time_allowed: formatTimeDuration(limitedHours, limitedMinutes),
      }));
   }, [limitedHours, limitedMinutes]);

   return (
      <div className={styles.lesson_block}>
         <Input
            placeholder={`${t("Enter a title")}...`}
            withHeader
            title={t("Title")}
            value={inputsData?.new_name}
            onChangeValue={(event) =>
               setInputsData({
                  ...inputsData,
                  new_name: event?.target?.value,
               })
            }
         />

         <div className={cn(styles.block, styles.passing_score)}>
            <p className={styles.title}>{t("Passing score")}</p>
            <p className={styles.gray_text}>
               {t("Set the score that students must achieve in order to pass the tests")}
            </p>
            <div
               onClick={() => passingScoreRef.current.focus()}
               className={cn(styles.passing_number_wrapper, 'noselect')}
            >
               <input
                  ref={passingScoreRef}
                  className={styles.styled_number_input}
                  min="0"
                  type="number"
                  value={inputsData?.new_passing_score ?? ""}
                  max="100"
                  onChange={changePassingScore}
               />
                <img src={arrowUp} className={styles.up_arrow}></img>
                <img src={arrowDown} className={styles.down_arrow}></img>

               <span>
                  <p>%</p> <p>/</p> 100%{" "}
               </span>
            </div>
         </div>

         <div className={styles.block}>
            <SwitchItem
               title={t("Shuffle answers")}
               text={t("Shuffle questions and answers so they appear randomly")}
               changeValue={() => {
                  setInputsData({
                     ...inputsData,
                     new_is_shuffle_answers: +!inputsData?.new_is_shuffle_answers,
                  });
               }}
               value={inputsData.new_is_shuffle_answers}
            />
         </div>
         <div className={styles.block}>
            <SwitchItem
               title={t("Pass attempts")}
               text={t("Limit the number of times students attempt an assignment")}
               changeValue={() => {
                  setInputsData({
                     ...inputsData,
                     new_pass_attempts:
                        inputsData.new_pass_attempts >= 1 ? "" : 1,
                  });
               }}
               value={inputsData.new_pass_attempts ? true : false}
            />

            <div
               onClick={() => {
                  passingAttemptsRef.current.focus();
                  passingAttemptsRef.current.select();
               }}
               className={cn(styles.passing_attemts_wrapper, 'noselect')}
            >
               <input
                  ref={passingAttemptsRef}
                  className={styles.styled_number_input}
                  min="1"
                  type="number"
                  value={inputsData?.new_pass_attempts}
                  max="100"
                  onChange={(e) => {
                     setInputsData({
                        ...inputsData,
                        new_pass_attempts: +e.target.value,
                     });
                  }}
               />
                <img src={arrowUp} className={styles.up_arrow}></img>
                <img src={arrowDown} className={styles.down_arrow}></img>
            </div>
         </div>
         <div className={styles.block}>
            <SwitchItem
               title={t("Timer")}
               text={t("Limit the amount of time students can spend taking the test. When the time runs out, the number of earned points will be calculated automatically")}
               changeValue={() => {
                  setInputsData({
                     ...inputsData,
                     new_time_allowed: inputsData?.new_time_allowed.length
                        ? ""
                        : "PT1H",
                  });
               }}
               value={inputsData.new_time_allowed.length ? true : false}
            />

         <div className={styles.limit_wrapper}>
            <input
               value={limitedHours}
               onChange={(e) => {
                  setLimitedHours(e.target.value);
                  if(!limitedMinutes) setLimitedMinutes(0)
               }}
               type="number"
               placeholder={t("hours")}
               min={0}
               className={cn(styles.styled_number_input, 'noselect')}
            />
            <img src={arrowUp} className={styles.up_arrow}></img>
            <img src={arrowDown} className={styles.down_arrow}></img>
         </div>
          
         <div className={styles.limit_wrapper}>
            <input
               value={limitedMinutes}
               onChange={(e) => {
                  setLimitedMinutes(e.target.value)
                  if(!limitedHours) setLimitedHours(0)
               }}
               type="number"
               placeholder={t("minutes")}
               min={0}
               className={styles.styled_number_input} // Apply the same styles
            />
            <img src={arrowUp} className={styles.up_arrow}></img>
            <img src={arrowDown} className={styles.down_arrow}></img>
         </div>
           
         </div>

         <div className={styles.block}>
            <h2 className={styles.title}>
               {t("Calculation of points in the presence of several options")}
            </h2>
            <p className={styles.gray_text}>
               {t("Soft counting counts a certain number of points if not all correct answers are chosen. Hard does not count points if not all correct answers are selected")}
            </p>

            <div className={styles.is_hard_btns}>
               <button
                  className={cn(
                     styles.is_hard_btn,
                     !inputsData?.new_is_hard ? styles.active_hard : ""
                  )}
                  onClick={() =>
                     setInputsData({
                        ...inputsData,
                        new_is_hard: 0,
                     })
                  }
               >
                  {" "}
                  {t("Soft")}
               </button>
               <button
                  className={cn(
                     styles.is_hard_btn,
                     inputsData?.new_is_hard ? styles.active_hard : ""
                  )}
                  onClick={() =>
                     setInputsData({
                        ...inputsData,
                        new_is_hard: 1,
                     })
                  }
               >
                  {" "}
                  {t("Hard")}
               </button>
               <div className={styles.active_indicator}>
                  {!inputsData?.new_is_hard ? t("Soft") : t("Hard")}
               </div>
            </div>
         </div>

         <div className={styles.block}>
            <SwitchItem
               title={t("Show test results")}
               text={t("The student can see the correctness of his answers and where he made his mistake after passing the test")}
               changeValue={() => {
                  setInputsData({
                     ...inputsData,
                     new_is_show_test_result:
                        +!inputsData?.new_is_show_test_result,
                  });
               }}
               value={inputsData.new_is_show_test_result}
            />
         </div>
         <div className={styles.block}>
            <SwitchItem
               title={t("Show score to student")}
               text={t("The student will see the number of points he has scored after passing the test")}
               changeValue={() => {
                  setInputsData({
                     ...inputsData,
                     new_is_show_test_score:
                        +!inputsData?.new_is_show_test_score,
                  });
               }}
               value={inputsData.new_is_show_test_score}
            />
         </div>
         <div className={styles.block}>
            <SwitchItem
               title={t("Welcome message")}
               text={t("Write the text of the greeting that will be displayed before the start of the text")}
               changeValue={() => {
                  setInputsData({
                     ...inputsData,
                     new_welcome_message: inputsData.new_welcome_message?.length
                        ? ""
                        : "Default text",
                  });
               }}
               value={inputsData.new_welcome_message?.length ? true : false}
            />

            <TextArea
               max={200}
               placeholder={t("Enter the message")}
               value={inputsData.new_welcome_message}
               onChangeValue={(e) => {
                  setInputsData({
                     ...inputsData,
                     new_welcome_message: e.target.value,
                  });
               }}
               withCounter
            />
         </div>
         <div className={styles.block}>
            <SwitchItem
               title={t("Congratulatory message")}
               text={t("Write a congratulatory text about the end of the test, which the student will see after the test is completed")}
               changeValue={() => {
                  setInputsData({
                     ...inputsData,
                     new_congratulatory_message:
                        inputsData?.new_congratulatory_message.length > 0
                           ? ""
                           : t("Default text"),
                  });
               }}
               value={
                  inputsData.new_congratulatory_message.length ? true : false
               }
            />
            <TextArea
               max={200}
               placeholder={t("Enter the message")}
               value={inputsData.new_congratulatory_message}
               onChangeValue={(e) => {
                  setInputsData({
                     ...inputsData,
                     new_congratulatory_message: e.target.value,
                  });
               }}
               withCounter
            />
         </div>
      </div>
   );
};

export default LessonTypeTest;
