import React from 'react';
import styles from './styles.module.scss'

const Switch = ({checked, onChange, title = null}) => {
    return (
        <div className={styles.switch_wrapper}>
            <div className={styles.switch} onClick={onChange}>
                <input id={title} type="checkbox" checked={checked} onChange={onChange}/>
                <span className={`${styles.slider} ${styles.round}`}></span>
            </div>
            {title && <label htmlFor={title} className={styles.title}>{title}</label>}
        </div>
    );
};

export default Switch;