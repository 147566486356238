import Button from "../../../../components/Button/Button";
import styles from "./styles.module.scss";
import file from "../../../../assets/images/file_icon.svg";
import watchVideoIcon from "../../../../assets/images/watch_video_icon.svg";
import cn from "classnames";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import VideoPlayerModal from "../../../../components/Modals/VideoPlayerModal/VideoPlayerModal";
import { HTTPS_REGEX } from "../../../../constants/preLinks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
   returnTemporaryQuestionFileLink,
   returnTemporaryAnswerFileLinks,
} from "../../../../utils/questionsHelper";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

function getFileNameFromURL(url) {
   if (!url?.length) return;

   const parts = url?.split("/");
   const filenameWithParams = parts[parts.length - 1];
   const filenameParts = filenameWithParams.split("?");
   return filenameParts[0];
}

const FileAnswer = ({ questionId, fileLink, isVideo, courseId }) => {
   const playerModal = useHandleModal();
   const openVideoModal = () => playerModal.open();
   const userId = useSelector((state) => state?.user?.info?.id);
   const [fileUrls, setFileUrls] = useState([]);

   const t = useFindTranslationsByString();

   const getUpdatedUrlLink = async () => {
      const fileName = getFileNameFromURL(fileLink);
      const links = fileLink?.split(",");

      const updatedLinks = await returnTemporaryAnswerFileLinks(
         userId,
         links,
         courseId
      );

      setFileUrls(updatedLinks);
   };

   useEffect(() => {
      if (fileLink && userId) {
         getUpdatedUrlLink();
      }
   }, [fileLink, userId]);

   return (
      <div className={styles.file_answer_wrapper}>
         {fileUrls?.length === 0 && !isVideo && (
            <div className={styles.no_file}>
              {t("No file was added!")}
            </div>
         )}

         {fileUrls?.map((item, index) => (
            <div className={styles.file_item} key={index}>
               <div className={styles.file_title}>
                 {item?.fileUrl && <img src={file} alt="" />} 
                  <CustomTooltip
                     text={getFileNameFromURL(item?.fileUrl)}
                     limit={35}
                     id={questionId}
                  />
               </div>

               <div className={styles.f_wrapper}>
                  {isVideo ? (
                     item?.fileUrl ? (
                        <div
                           onClick={openVideoModal}
                           className={styles.open_file_btn}
                        >
                           <img src={watchVideoIcon} alt="Play icon" />
                           {t("Watch")}
                        </div>
                     ) : (
                        <div className={styles.no_file}>
                           {t("No video was added by an admin!")}
                        </div>
                     )
                  ) : HTTPS_REGEX.test(item?.fileUrl) ? (
                     <a
                        className={styles.open_file_btn}
                        target="_blank"
                        href={item?.fileUrl}
                     >
                        {t('Open')}
                     </a>
                  ) : (
                     <div className={styles.no_file}>{t("No file was added!")}</div>
                  )}

                  <VideoPlayerModal
                     isOpen={playerModal.isActive}
                     onClose={playerModal.close}
                     url={item?.fileUrl}
                  />
               </div>
            </div>
         ))}
      </div>
   );
};

export default FileAnswer;
