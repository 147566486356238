import { Fragment } from 'react';

import iconInfo from "../../../../assets/images/icon_info.svg";
import correctImg from "../../../../assets/images/symbols/check.svg";
import iconWrong from "../../../../assets/images/symbols/cross_white.svg";
import iconWrongRed from "../../../../assets/images/close.svg";
import partiallyCorrectIcon from "../../../../assets/images/partially_correct_icon.svg";
import correctImgGreen from "../../../../assets/images/correct_green.svg";
import compareImg from "../../../../assets/images/compare_icon.svg";

import CommonTypes from "../AnswersTypes/CommonTypes";
import { COMPARE_ANSWER_CODE, FILE_UPLOAD_ANSWER_CODE, RATE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, STARS_ANSWER_CODE, VIDEO_ANSWER_CODE, WRITTEN_ANSWER_CODE } from "../../../../utils/questionsHelper";
import { commonTypes, typesThatNeededTips } from "../../../../constants/tests";
import { isMobileDevice } from "../../../../utils/usersHelper";

import CheckQuestionResult from "../CheckQuestionResult/CheckQuestionResult.js";
import WrittenAnswer from "../AnswersTypes/WrittenAnswer";
import FileAnswer from "../AnswersTypes/FileAnswer";
import SequenceAnswers from "../AnswersTypes/SequenceAnswers";
import CompareAnswer from "../AnswersTypes/CompareAnswer";
import RateAnswer from "../AnswersTypes/RateAnswer";
import StarsAnswer from "../AnswersTypes/StarsAnswer";

import styles from "./styles.module.scss";
import cn from "classnames";
import TestResultsBlock from "../../../../components/TestResultsBlock/TestResultsBlock";
import SecondLoader from "../../../../components/SecondLoader/SecondLoader.js";
import VideoFromUser from "../AnswersTypes/VideoFromUser.js";
import EmptyBlock from "../../../../components/EmptyBlock/EmptyBlock.js";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString.js";
import CompareAnswerMobile from "../AnswersTypes/CompareAnswerMobile";

const CenterBlock = ({
   lessonContent,
   lessonName,
   isTheory,
   testQuestionsList,
   openAnswersInfoModal,
   commentInputValue,
   fileName,
   addManualResult,
   chartData,
   testData,
   dataLoading
}) => {

   const t = useFindTranslationsByString();

   const renderLessonContent = () => {
      if (lessonContent?.length && isTheory) {
         return (
            <div className={cn(styles.lesson_wrapper)}>
               <h3 className={styles.lesson_name}>{lessonName}</h3>
               <div dangerouslySetInnerHTML={{ __html: lessonContent }} />
            </div>
         );
      } else if (lessonContent?.length === 0 && isTheory) {
         return (
            <div className={cn(styles.lesson_wrapper, styles.center_block)}></div>
         );
      }
      if(!lessonContent && isTheory) return <EmptyBlock />
      return null;
   };

   const renderedLesssonContent = renderLessonContent();

   if(dataLoading) {
      return <div className={styles.emptyBlock}>
         <div className="default_loader_wrapper"><SecondLoader /></div>
      </div>
   }

   return (
     <>
       {renderedLesssonContent}

       {!isTheory && (
         <div className={styles.center_block}><div className={styles.content_wrapper}>
            <h3 className={styles.test_title}>{testData?.lesson?.name}</h3>
            {testQuestionsList.map((question, idx) => (
                <div className={styles.test_question_item} key={`${question.id}_qstion`}>
                   <div className={cn(styles.small_title, styles.question_type)}>
                      <span>{t("Question")} {idx + 1}:</span>
                      <img className={styles.left_img} src={question.image} alt=""/>
                      <span>{t(question.name)}</span>
                   </div>
                   <div className={styles.question_name}>
                      {question.title ? question?.title : question.content}
                   </div>

                   {question?.description && (
                       <div className={styles.description}>
                          <span>{question.description}</span>
                       </div>
                   )}

                   {question.type === VIDEO_ANSWER_CODE && (
                       <FileAnswer
                           fileLink={question?.content}
                           key={question.id}
                           questionId={question?.id}
                           isVideo
                           courseId={testData?.lesson?.module?.course?.id}
                       />
                   )}

                   {question?.type === SEQUENCE_ANSWER_CODE &&
                       testData?.lesson?.isShowTestResult && (
                           <div className={styles.sequence_right_answers_wrapper}>
                              <div className={styles.small_title}>
                                 <span>{t("Right pairs")}</span>
                              </div>
                              <div className={styles.sequence_right_answers}>
                                 {question?.sequence?.map((answer, answerIdx) => (
                                     <div
                                         key={`${answerIdx}_seq_ans_code`}
                                         className={styles.sequence_right_answer}
                                     >
                                        {answerIdx + 1} <span> {answer} </span>
                                     </div>
                                 ))}
                              </div>
                           </div>
                       )}

                   {question?.type === COMPARE_ANSWER_CODE &&
                       testData?.lesson?.isShowTestResult && (
                           <div className={cn(styles.sequence_right_answers_wrapper)}>
                              <div className={styles.small_title}>
                                 <span>{t("Right pairs")}</span>
                              </div>
                              <div className={cn(styles.sequence_right_answers, styles.compare_right_answers_wrapper)}>
                                 {question?.options?.map((option, optionIdx) => (
                                     <div
                                         key={`${optionIdx}_right_pairs`}
                                         className={styles.compare_right_wrapper}
                                     >
                                        <div className={styles.compare_left_side}>
                                           {" "}
                                           <span className={styles.nums_mob}>{optionIdx + 1}.</span>
                                           <div>{option}{" "}</div>
                                        </div>

                                        <div className={styles.compare_right_side}>
                                           <img className={styles.compareImg} src={compareImg} alt=""/>
                                            <div className={styles.compare_answer_right}>
                                                {
                                                   question?.answers?.find(
                                                      (answer) =>
                                                            answer.matchingOption === optionIdx,
                                                   )?.initialContent
                                                }
                                            </div>

                                             <div className={styles.mob_answer_right}>
                                                {
                                                   (() => {
                                                      const ans = question?.initialAns?.find(
                                                         (answer) =>
                                                               answer.matchingOption === optionIdx,
                                                      )

                                                      return ans?.id ? String.fromCharCode(97 + ans?.matchingOption) + ")" : null;
                                                   })()
                                                }
                                                 <img
                                                   className={styles.resultIcon}
                                                   src={correctImg}
                                                   alt=""
                                                />
                                             </div>
                                        </div>
                                     </div>
                                 ))}
                              </div>
                           </div>
                       )}

                     {question?.type === COMPARE_ANSWER_CODE && 
                       <div className={styles.mobile_answers_list}>
                        {question?.initialAns?.map((item, key) => (
                              <div key={`${key}_mobilee`} className={styles.compare_mobile_ans_items}>
                                    <span 
                                       className={styles.letters}>{String.fromCharCode(97 + item?.matchingOption) + ")"}
                                    </span>
                                 <span>{item?.content}</span>
                              </div>
                          ))}
                       </div>
                     }

                   {typesThatNeededTips.includes(question?.type) &&
                       testData?.lesson?.isShowTestResult && (
                           <div className={styles.small_title}>
                              <span>{t("Answers")}</span>
                              <img
                                  onClick={openAnswersInfoModal}
                                  src={iconInfo}
                                  alt=""
                              />
                           </div>
                       )}

                   {!typesThatNeededTips.includes(question?.type) && (
                       <div className={styles.small_title}>
                          <span>{t("Answers")}</span>
                       </div>
                   )}

                   {commonTypes.includes(question?.type) && (
                       <div className={styles.answers_block}>
                          {question?.answers?.map((answer, answerIdx) =>
                              testData?.lesson?.isShowTestResult ? (
                                  <CommonTypes
                                      key={`${answerIdx}_common`}
                                      answer={answer}
                                      answerIdx={answerIdx}
                                      correctImg={correctImg}
                                      iconWrong={iconWrong}
                                      correctImgGreen={correctImgGreen}
                                      iconWrongRed={iconWrongRed}
                                  />
                              ) : null,
                          )}

                          <CheckQuestionResult
                              key={`${idx}_common_res`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}

                   {question.type === WRITTEN_ANSWER_CODE && (
                       <div className={styles.answers_block}>
                          {question?.answers?.map((answer, answerIdx) => (
                              <WrittenAnswer
                                  key={`${answerIdx}_written_ans`}
                                  answer={answer}
                                  answerIdx={answerIdx}
                                  correctImg={correctImg}
                                  iconWrong={iconWrong}
                                  correctImgGreen={correctImgGreen}
                                  iconWrongRed={iconWrongRed}
                              />
                          ))}

                          <CheckQuestionResult
                              key={`${idx}_written_res`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              manualChecked
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}

                   {question.type === FILE_UPLOAD_ANSWER_CODE && (
                       <div className={styles.answers_block}>
                          <FileAnswer
                              key={`${question?.id}_file`}
                              fileLink={question?.answers?.[0]?.content}
                              questionId={question?.id}
                          />

                          <CheckQuestionResult
                              key={`${idx}_res_file`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              manualChecked
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}

                   {question.type === VIDEO_ANSWER_CODE && (
                       <div className={styles.answers_block}>
                          <div className={styles.video_wrapper}>
                             <VideoFromUser
                                 question={question}
                                 courseId={testData?.lesson?.module?.course?.id}
                             />
                          </div>

                          <CheckQuestionResult
                              key={`${idx}_res_video`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              manualChecked
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}

                   {question.type === SEQUENCE_ANSWER_CODE && (
                       <div className={styles.answers_block}>
                          {question?.sequenceAnswers?.map((answer, answerIdx) =>
                              testData?.lesson?.isShowTestResult ? (
                                  <SequenceAnswers
                                      key={`${answer.id}_seq`}
                                      answer={answer}
                                      answerIdx={answerIdx}
                                      correctImg={correctImg}
                                      iconWrong={iconWrong}
                                      correctImgGreen={correctImgGreen}
                                      iconWrongRed={iconWrongRed}
                                  />
                              ) : null,
                          )}

                          <CheckQuestionResult
                              key={`${question?.id}_res_seq`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}

                   {question.type === COMPARE_ANSWER_CODE && (
                       <div className={styles.answers_block}>
                          {question?.answers?.map((answer, answerIdx) =>
                              testData?.lesson?.isShowTestResult ? (
                                 <Fragment key={`${answer.id}_fragment`}>
                                       <CompareAnswer
                                          key={`${answerIdx}_compaww`}
                                          answer={answer}
                                          question={question}
                                          answerIdx={answerIdx}
                                          compareImg={compareImg}
                                          correctImg={correctImg}
                                          iconWrong={iconWrong}
                                          correctImgGreen={correctImgGreen}
                                          iconWrongRed={iconWrongRed}
                                       />
                                       {isMobileDevice() && 
                                          <CompareAnswerMobile
                                             key={`${answer?.id}_compare_mobile`}
                                             answer={answer}
                                             initialAns={question?.initialAns?.find((q, initIdx) => initIdx === answerIdx)}
                                             question={question}
                                             answerIdx={answerIdx}
                                             compareImg={compareImg}
                                             correctImg={correctImg}
                                             iconWrong={iconWrong}
                                             correctImgGreen={correctImgGreen}
                                             iconWrongRed={iconWrongRed}
                                          />
                                       }
                                 </Fragment>
                              ) : null,
                           )}


                          <CheckQuestionResult
                              key={`${question.id}_compa`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}

                   {question.type === RATE_ANSWER_CODE && (
                       <div className={styles.answers_block}>
                          <RateAnswer key={question.id} question={question}/>
                          <CheckQuestionResult
                              key={`${idx}_rate`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}

                   {question.type === STARS_ANSWER_CODE && (
                       <div className={styles.answers_block}>
                          <StarsAnswer
                              key={`${question.id}_stars`}
                              question={question}
                              total={question?.rating}
                          />
                          <CheckQuestionResult
                              key={`${idx}_stars_ress`}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                          />
                       </div>
                   )}
                </div>
            ))}

            <TestResultsBlock
                originalWidth={true}
                status={+testData?.status}
                comment={testData?.commentTests?.[0]}
                score={chartData[1][1] || testData?.successPercentage}
                commentInputValue={commentInputValue}
                courseId={testData?.lesson?.module?.course?.id}
            />
         </div></div>
       )}
     </>
   );
};

export default CenterBlock;
