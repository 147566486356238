import React, { useEffect, useState } from "react";
import { ALL_STUDENTS_TABLE_HEADERS } from "../../../constants/user";

import styles from "./styles.module.scss";
import TeamTable from "../TeamTable/TeamTable";
import SecondLoader from "../../../components/SecondLoader/SecondLoader";

const TableBlock = ({ searchedValue, headers, data, tableFor, refetchUsers, isUsersloading, isAccess, onRemoveAccess, redirectToUserProfileAdminView, allCoursesListLoading, lastCourseRef}) => {
   return (
      <div className={styles.table_block}>
         <div className={styles.table_wrapper}>
            <TeamTable
               searchedValue={searchedValue}
               columns={headers}
               data={data}
               tableFor={tableFor}
               isAccess={isAccess}
               refetchUsers={refetchUsers}
               isUsersloading={isUsersloading}
               onRemoveAccess={onRemoveAccess}
               redirectToUserProfileAdminView={redirectToUserProfileAdminView}
               lastItemRef={lastCourseRef}
            />
         </div>

         {allCoursesListLoading && 
            <div className={"default_loader_wrapper"}>
                <SecondLoader />
            </div>
         }
      </div>
   );
};

export default TableBlock;
