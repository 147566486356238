import React from "react";
import courseImg from "../../assets/images/course_thumbnail.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
   ROLE_ID_STUDENT,
   PART_OF_MENTOR_NAME,
} from "../../constants/user";
import cn from "classnames";
import styles from "./styles.module.scss";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import useUserRole from "../../hooks/useUserRole";
import useFindTranslations from "../../hooks/useFindTranlsations";

const CourseBlockDropdown = ({ course }) => {
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId)

   const navigate = useNavigate();

   const t = useFindTranslations();

   const onContent = () => {
      navigate(`/courses/course/${course?.id}/default_lang/${course?.defaultLanguage || 'en_US'}/${course?.availableLanguages?.length > 1}`);
   };

   const courseEditLink = `/courses/edit/${course?.id}/default_lang/${course?.defaultLanguage || 'en_US'}/${course?.availableLanguages?.length > 1}`;

   const onEdit = () => {
      navigate(courseEditLink);
   };

   const courseImage =
      course?.image && !course?.image?.includes("static/media/")
         ? course.image
         : courseImg;

   return (
      <div 
      style={{ '--course-open': `"${t?.openCourse}"` }}
         className={styles.courseBlock}>
         <div
            onClick={(e) => {
               e.stopPropagation();
               if (
                  userRoleId !== ROLE_ID_STUDENT &&
                  !userRoleName?.includes(PART_OF_MENTOR_NAME)
               ) {
                  onEdit();
               } else {
                  onContent();
               }
            }}
            className={cn(styles.image_wrapper)}
         >
            <img src={courseImage} alt={""} className={styles.image} />
         </div>

         <div
            onClick={(e) => {
               e.stopPropagation();
               if (
                  userRoleId !== ROLE_ID_STUDENT &&
                  !userRoleName?.includes(PART_OF_MENTOR_NAME)
               ) {
                  onEdit();
               } else {
                  onContent();
               }
            }}
            className={styles.content}
         >
            <p className={styles.title}>
               <CustomTooltip id={`${course?.id}name`} text={course?.title ? course?.title : course?.name} limit={20}/>
            </p>
            <CustomTooltip maxWidth={'200px'} id={course?.id} text={course?.description} limit={18}/>
         </div>
      </div>
   );
};

export default CourseBlockDropdown;
