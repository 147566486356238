import React, { useEffect, useState, useRef } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import FolderApi from "../../../utils/api/FolderApi";
import cn from "classnames";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import PaginatedDropdown from "../../PaginatedDropdown/PaginatedDropdown";
import DropdownDepOrPos from "./DropdownDepOrPos/DropdownDepOrPos";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useGetActiveUsersOfCompany from "../../../hooks/api/useGetActiveUsersOfCompany";
import { useParams } from "react-router-dom";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const CreateDepOrPosModal = ({
   title,
   onClose,
   isOpen,
   createSuccessCallback,
   onRemove,
   selectedItems,
   setSelectedItems,
   onOpenSelectModal,
   entityName,
   setEntityName,
   isDepartments,
   lastItemRef,
   departments,
   setSelectedItem,
   selectedItem,
   data,
   confirmBtnText,
   actionData,
   isAddLoading,
}) => {
   const [isDisabled, setIsDisabled] = useState(true);
   const [isChangeLoading, setIsChangeLoading] = useState(false);

   const t = useFindTranslationsByString();
   const params = useParams();

   useEffect(() => {
      if (entityName?.length) setIsDisabled(false);
      else setIsDisabled(true);
   }, [entityName]);

   const closeModal = () => {
      onClose();
      setEntityName("");
      if (setSelectedItems) setSelectedItems([]);
      if (setSelectedItem) setSelectedItem("");
   };

   return (
      <>
         <ModalLayout
            onClose={closeModal}
            isOpen={isOpen}
            maxHeight={"300px"}
            maxWidth={"400px"}
            withCross
            noBlur
         >
            <p className={styles.title}>{title}</p>
            <div className={styles.desc}>
               <Input
                  max={40}
                  placeholder={
                     actionData?.data?.id ? actionData?.data?.name : t("Title")
                  }
                  value={entityName?.length ? entityName : actionData?.data?.name || ''}
                  onChangeValue={(e) => {
                     setEntityName(e.target.value);
                  }}
                  withCounter
               />
            </div>

            {isDepartments && (
               <div className={styles.dropdown}>
                  <DropdownDepOrPos
                     placeholder={
                        actionData?.data?.positions?.length > 0
                           ? actionData?.data?.positions
                                ?.map((item) => item?.name)
                                ?.join(", ")
                           : `${t("Select")} ${t("Position")?.toLowerCase()}`
                     }
                     value={selectedItems
                        ?.map((item) => item?.name)
                        ?.join(" ,")}
                     data={selectedItems}
                     onClickPlus={onOpenSelectModal}
                     onClickMinus={onRemove}
                  />
               </div>
            )}

            {!isDepartments && (
               <div className={styles.dropdown}>
                  <PaginatedDropdown
                     value={selectedItem?.name}
                     lastItemRef={lastItemRef}
                     onChange={setSelectedItem}
                     data={data}
                     placeholder={
                        actionData?.data?.department
                           ? actionData?.data?.department?.name
                           : "Department"
                     }
                     field={"name"}
                     maxHeight={"100px"}
                  />
               </div>
            )}

            <Button
               title={t(confirmBtnText)}
               disabled={
                  isAddLoading
                     ? isAddLoading
                     : actionData?.data?.id
                     ? false
                     : isDisabled
               }
               onClick={createSuccessCallback}
            />

            {isChangeLoading && (
               <div className="default_loader_wrapper">
                  <Loader size={"small"} />
               </div>
            )}
         </ModalLayout>
      </>
   );
};

export default CreateDepOrPosModal;
